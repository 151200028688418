export interface IGlobalSettings {
    theme?: "dark" | "light",
    spinnerCalls?: {
        [caller: string]: boolean
    }
}

const initialState: IGlobalSettings = {
    theme: "light",
    spinnerCalls: {}
};

// TODO: store preferences on local storage

export const globalSettingsReducer = (state: IGlobalSettings = initialState, action: any): IGlobalSettings => {
    switch (action.type) {
        case 'SET_USER_PREF':
            return {
                ...state,
                ...action.payload,
            };
        case 'SHOW_SPINNER':
            const spinnerCalls = state.spinnerCalls || {};
            spinnerCalls[action.payload.caller] = true;

            return {
                ...state,
                spinnerCalls,
            };
        case 'HIDE_SPINNER':
            if (state.spinnerCalls && state.spinnerCalls[action.payload.caller])
                delete state.spinnerCalls[action.payload.caller];

            return {
                ...state,
            };
        default:
            return state;
    }
};