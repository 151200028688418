import React from 'react';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { Icon, IconButton } from '@material-ui/core';

interface IProps {
    children: React.ReactNode
    setShowMessage: any
}

export const VAlerts: React.FC<IProps> = (props: IProps) => {
    const notistackRef = React.createRef<SnackbarProvider>();

    const onClickDismiss = (key: SnackbarKey) => () => {
        notistackRef.current?.closeSnackbar(key);
    }

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            ref={notistackRef}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                    <Icon
                        fontSize="small"
                        className="fas fa-times" />
                </IconButton>
            )}>
            <VAlertsHandler setShowMessage={props.setShowMessage}>
                {props.children}
            </VAlertsHandler>
        </SnackbarProvider>
    );
}

const VAlertsHandler: React.FC<IProps> = (props: IProps) => {
    const { enqueueSnackbar } = useSnackbar();

    props.setShowMessage(enqueueSnackbar);

    return (
        <SnackbarProvider maxSnack={3}>
            {props.children}
        </SnackbarProvider>
    );
}