import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { LineChart } from 'echarts/charts';
import { GridComponent } from 'echarts/components';
import { getBalanceEuropeProduction } from '../../../apis/vitusApi';
import { Grid } from '@material-ui/core';
import { createSpinner } from '../../../utils/spinnerManager';
import { IBaseBalanceRangeResponse } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { handleApiError, months } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import { colors, defaultLineRangeChartOptions, isRange, ITabProps, rangeLineColor, rangeLineOptions } from './gasBalanceUtils';

echarts.use(
    [LineChart, GridComponent]
);

interface IState {
    countriesData: IBaseBalanceRangeResponse["success"]["countries"],
}

class EuropeProduction extends React.Component<ITabProps, IState> {
    state: IState = {
        countriesData: [],
    }

    componentDidMount() {
        if (!this.props.data)
            this.getData();
        else
            this.storeData(this.props.data);
    }

    getData() {
        const spinner = createSpinner();

        getBalanceEuropeProduction().then(response => {
            if (response.data.success) {
                this.storeData(response.data.success);
                this.props.onDataChanged(response.data.success);
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    storeData(response: IBaseBalanceRangeResponse["success"]) {
        this.setState({
            countriesData: response.countries || [],
        });
    }

    render() {
        return (
            <Grid container justify="flex-start" alignItems="flex-end">
                {
                    this.state.countriesData.map((country, idx) => {
                        return (
                            <ChartRow
                                key={`row_${country.country_name}`}
                                country_name={country.country_name}
                                data={country.data}
                            />
                        );
                    })
                }
            </Grid>
        )
    }
}

type IChartRowProps = IBaseBalanceRangeResponse["success"]["countries"][0];

class ChartRow extends React.Component<IChartRowProps, {}> {
    lineRangeChartRef: ReactECharts | null = null;

    componentDidMount() {
        if (this.props.data)
            this.updateLineChart();
    }

    componentDidUpdate(prevProps: IChartRowProps) {
        if (this.props.data && prevProps.data !== this.props.data)
            this.updateLineChart();
    }


    updateLineChart = () => {
        const lineChartInstance = this.lineRangeChartRef?.getEchartsInstance();
        lineChartInstance.showLoading();

        const data = this.props.data;

        lineChartInstance.clear();

        const lines = Object.keys(data[0]).filter(k => k !== "Month" && k !== "Day")

        const dataset = {
            dimensions: ['date_value', ...lines],
            source: data.map(originalRow => {
                const row = { ...originalRow };

                row["date_value"] = `${('0' + row.Month).slice(-2)}_${('0' + row.Day).slice(-2)}`;
                row["month"] = months[row.Month - 1];
                row["day"] = row.Day;
                return row;
            }),
        }

        lineChartInstance.setOption({
            ...defaultLineRangeChartOptions,
            color: colors.map((color, idx) => (isRange(lines[idx]) ? rangeLineColor : color)),
            series: lines.map(l => ({ type: 'line', ...(isRange(l) ? rangeLineOptions : {}) })),
            dataset: dataset,
            legend: {}
        });

        lineChartInstance.hideLoading();
    }


    render() {
        return (
            <Grid item xs={12}>
                <VChart
                    title={!this.props.country_name ? "" : `Average of 7 Day Rolling ${this.props.country_name} Gas Production (cm)`}
                    height={350}
                    report={
                        <ReactECharts
                            ref={(e) => { this.lineRangeChartRef = e; }}
                            echarts={echarts}
                            option={defaultLineRangeChartOptions}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{ width: '99%' }}
                        />
                    } >
                </VChart>
            </Grid>
        );
    }
}

export default EuropeProduction;