import { Box, Button, Collapse, Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { getAllDatasets } from "../../../apis/vitusApi";
import { ReportView, ReportParams } from "../../../system/ReportBase";
import AlertManager from "../../../utils/alertManager";
import { generateUuid, handleApiError, ViewTypes } from "../../../utils/common";
import { DatasetMetaData } from "../../../utils/types";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import ReportViewer from "../../ReportViewer/ReportViewer";
import CreateDataset from '../CreateDataset/CreateDataset';
import React from "react";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { orderBy } from "lodash";
import WarningIcon from '@material-ui/icons/Warning';

interface IState {
    datasets: DatasetMetaData[],
}

class DatasetList extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            viewType: ViewTypes.Administration,
            reportKey: "DATASET_LIST",
            name: "Datasets",
            path: "/datasetList",
            thumbnail: ""
        }
    );

    state = {
        datasets: []
    }

    componentDidMount() {
        const spinner = createSpinner();

        getAllDatasets().then(response => {
            if (response.data.success) {
                this.setState({ datasets: response.data.success.datasets })
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    render() {
        return (
            <ReportViewer {...DatasetList.params}>
                <Grid container spacing={3} justify="flex-start">
                    <Grid container item xs={12} justify="flex-end">
                        <Link color="inherit" to={CreateDataset.params.cleanPath || CreateDataset.params.path}>
                            <Button
                                color="primary"
                                style={{ position: "absolute", right: "50px", top: "80px", height: "40", textDecoration: undefined }}
                                variant="contained"
                                onClick={() => false}>
                                New Dataset
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        {
                                            datasetTableColumns.map((column) => (
                                                <TableCell key={generateUuid()} style={textStyles}>
                                                    {column.title}
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.datasets.map((dataset: DatasetMetaData) =>
                                            <CollapsibleRow key={`details_${dataset.Name}`}
                                                path={`${CreateDataset.params.cleanPath}/${dataset.Id}`}
                                                dataset={dataset} />
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </ReportViewer>
        );
    }
}

export default DatasetList;

const textStyles = {
    fontSize: 12,
}

const datasetTableColumns: {
    key: string,
    title: string,
    renderer: (dataset: DatasetMetaData) => React.ReactElement
}[] = [
        {
            key: "Name",
            title: "Dataset Name",
            renderer: (dataset: DatasetMetaData) => (
                <Typography style={textStyles}>
                    {dataset.Name}
                </Typography>
            )
        },
        {
            key: "ColumnCount",
            title: "Column Count",
            renderer: (dataset: DatasetMetaData) => (
                <Typography style={textStyles}>
                    {Object.keys(dataset.Columns).length}
                </Typography>
            )
        },
        {
            key: "DataSourceCounts",
            title: "Source Counts",
            renderer: (dataset: DatasetMetaData) => {
                const dsCounts: { [key: string]: number } = {};

                dataset.DataSources?.forEach(ds => {
                    if (!dsCounts[ds.SourceType])
                        dsCounts[ds.SourceType] = 1;
                    else
                        dsCounts[ds.SourceType]++;
                });

                return (
                    <Typography style={textStyles}>
                        {Object.keys(dsCounts).map(dsType => `${dsType}: ${dsCounts[dsType]}`).join(', ')}
                    </Typography>
                )
            }
        },
        {
            key: "UsedInCharts",
            title: "Used In",
            renderer: (dataset: DatasetMetaData) => (
                <Typography style={textStyles}>
                    {dataset.UserChartCount} Charts
                </Typography>
            )
        }
    ]

function CollapsibleRow(props: { dataset: DatasetMetaData, path: string }) {
    const dataset: DatasetMetaData = props.dataset;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment key={`frag_${dataset.Name}`}>
            <TableRow>
                <TableCell style={{ width: 10 }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {
                    datasetTableColumns.map((column) => (
                        <TableCell key={`${dataset.Name}_${column.key}`}
                            id={`${dataset.Name}_${column.key}`} component="th" scope="row">
                            {column.renderer(dataset)}
                        </TableCell>
                    ))
                }
                <TableCell style={{ width: 10 }}>
                    {
                        dataset.DataSources?.filter(ds => !ds.IsActive).length ?
                            <Tooltip title={<Typography>There are inactive sources!</Typography>}>
                                <WarningIcon fontSize="large" htmlColor="#eed202" />
                            </Tooltip>
                            :
                            null
                    }
                </TableCell>
                <TableCell style={{ width: 10 }}>
                    <Link to={props.path}>
                        <IconButton>
                            <Icon className="fas fa-edit" />
                        </IconButton>
                    </Link>
                </TableCell>
            </TableRow >
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <React.Fragment>
                            <Box margin={1}>
                                <Typography style={{ fontWeight: 500 }} gutterBottom component="div">
                                    Columns
                                </Typography>

                                <Table size="small" >
                                    <TableBody>
                                        <TableRow>
                                            {
                                                Object.keys(dataset.Columns).map(col => {

                                                    return (
                                                        <TableCell key={generateUuid()}>
                                                            {`${col}: ${dataset.Columns[col].type}`}
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                            <Box margin={1}>
                                <Typography style={{ fontWeight: 500 }} gutterBottom component="div">
                                    Data Source Details
                                </Typography>
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Id
                                            </TableCell>
                                            <TableCell>
                                                Source Type
                                            </TableCell>
                                            <TableCell>
                                                Source Info
                                            </TableCell>
                                            <TableCell>
                                                Active
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            orderBy(dataset.DataSources, item => item.IsActive, ["desc"])?.map(datasource => (
                                                <TableRow key={generateUuid()}>
                                                    <TableCell>
                                                        {datasource.Id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {datasource.SourceType}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Object.keys(datasource.SourceInfo).filter(k => Reflect.get(datasource.SourceInfo, k)).map(s => {
                                                            let value: string = Reflect.get(datasource.SourceInfo, s);

                                                            if (value && value.length > 53)
                                                                value = value.slice(0, 50) + "..."

                                                            return `${s}: ${value || "N/A"}`;
                                                        }).join(', ') || "N/A"}
                                                    </TableCell>
                                                    <TableCell>
                                                        {datasource.IsActive ?
                                                            <Icon style={{ color: "green" }} fontSize="small" className="fas fa-check" />
                                                            :
                                                            <Icon style={{ color: "red" }} fontSize="small" className="fas fa-times" />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </React.Fragment>
                    </Collapse>
                </TableCell>

            </TableRow>
        </React.Fragment >
    );
}