import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Checkbox, Icon, IconButton, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmpty, sortBy } from 'lodash';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            display: "inline-block",
        },
        menuItem: {
            padding: 0
        },
        option: {
            padding: 0
        },
        tagWrapper: {
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            padding: "2px 4px",
        },
        deleteTag: {
            padding: "0 0 0 10px",
            height: "1em",
            width: "1em",
        }
    }),
);

interface IProps {
    getOptionLabel?: (option: { [key: string]: any }) => string,
    getOptionDisabled?: (option: { [key: string]: any }) => boolean,
    title: string,
    required?: boolean,
    options: {}[],
    sortBy?: (row: any) => any,
    value?: {} | null,
    onChange?: (newValue: {} | string | null) => void,
    multiple?: boolean,
    width?: number | string,
    largeText?: boolean,
    disableClearable?: boolean,
    style?: { [key: string]: any },
    renderTags?: boolean,
    tagRenderer?: (option: {}) => React.ReactNode,
}

export const uncheckedCheckboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedCheckboxIcon = <CheckBoxIcon fontSize="small" />;

const VSelect: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    const [value, setValue] = React.useState<{} | null>(props.value || null);

    React.useEffect(() => {
        setValue(props.value || null)
    }, [props.value]);

    const getOptionTitle = (option: {}) => props.getOptionLabel ? props.getOptionLabel(option) : option;

    const getOptionLabel = (option: {}) => {
        const result = props.getOptionLabel && props.getOptionLabel(option);
        if (!result || isEmpty(result))
            return "";

        return result;
    };

    const textStyle = props.largeText ? { fontWeight: 600, fontSize: 16 } : { fontWeight: 400 };

    return (
        <Autocomplete
            disableClearable={props.disableClearable}
            style={{ width: props.width ?? "150px", ...(props.style || {}) }}
            multiple={props.multiple}
            limitTags={1}
            disableCloseOnSelect={props.multiple}
            className={classes.formControl}
            options={props.sortBy ? sortBy(props.options, row => props.sortBy?.(row)) : props.options}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={props.getOptionDisabled || (() => false)}
            getOptionSelected={(option, value,) => {
                const currentLabel = getOptionLabel(option);
                const selectedLabel = getOptionLabel(value);

                return currentLabel === selectedLabel;
            }}
            value={value}
            onChange={(event: any, newValue: {} | null) => {
                setValue(newValue);
                if (props.onChange)
                    props.onChange(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        style: textStyle
                    }}
                    label={props.title}
                    required={!!props.required} />
            )}
            renderOption={(option, { selected }) => {
                return (
                    <React.Fragment>
                        {props.multiple &&
                            <Checkbox
                                icon={uncheckedCheckboxIcon}
                                checkedIcon={checkedCheckboxIcon}
                                className={classes.option}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />}
                        <Typography style={textStyle}>
                            {getOptionTitle(option)}
                        </Typography>
                    </React.Fragment>
                )
            }}
            renderTags={props.renderTags === false ? () => null : (tagValue, getTagProps: (params: { index: number })
                => { onDelete?: React.EventHandler<React.SyntheticEvent>, [key: string]: any }) =>
                tagValue.map((option, index) => {

                    const { onDelete, ...rest } = getTagProps({ index });

                    if (props.tagRenderer)
                        return props.tagRenderer(option)

                    return (
                        <div {...rest} className={classes.tagWrapper} key={`${getOptionTitle(option)}`}>
                            <span>{getOptionTitle(option)}</span>
                            <IconButton
                                className={classes.deleteTag}
                                onClick={onDelete}>
                                <Icon className="fas fa-times" style={{ fontSize: "10px" }} />
                            </IconButton>
                        </div>
                    )
                })
            }
        />
    );
}

export default VSelect;
