import { ReportParams, ReportView } from "../../system/ReportBase";
import { generateUuid, handleApiError } from '../../utils/common';
import { getCustomCharts } from '../../apis/vitusApi';
import { createSpinner } from "../../utils/spinnerManager";
import AlertManager from "../../utils/alertManager";
import messages from '../../utils/messages';
import { CustomChartObject } from "../../utils/types";
import {
    Paper, TableBody, TableContainer, Table, TableHead, TableCell, TableRow, Typography, Icon, Button, Grid
} from "@material-ui/core";
import React from "react";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ReportViewer from "../ReportViewer/ReportViewer";
import { Link } from 'react-router-dom';
import CustomCharts from '../CustomChart/CustomChart';

interface IState {
    customCharts: CustomChartObject[],
}

class CustomChartsList extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "CUSTOM_CHART_LIST",
            name: "Custom Chart List",
            path: "/customChartList",
            thumbnail: "customChartList.png"
        }
    );

    state: IState = {
        customCharts: [],
    }

    componentDidMount() {
        const spinner = createSpinner();

        getCustomCharts().then(response => {
            if (response.data.success) {
                this.setState({
                    customCharts: response.data.success.custom_charts
                })
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    render() {
        return (
            <ReportViewer {...CustomChartsList.params}>
                <Grid container spacing={3} justify="flex-start">
                    <Grid container item xs={12} justify="flex-end">
                        <Link color="inherit" to={CustomCharts.params.cleanPath || CustomCharts.params.path}>
                            <Button
                                color="primary"
                                style={{ position: "absolute", right: "50px", top: "80px", height: "40", textDecoration: undefined }}
                                variant="contained"
                                onClick={() => false}>
                                New Chart
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        {
                                            chartTableColumns.map((column) => (
                                                <TableCell key={generateUuid()} style={textStyles}>
                                                    {column.title}
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.customCharts.map((chart) => (
                                        <CollapsibleRow key={`details_${chart.name}`}
                                            path={`${CustomCharts.params.cleanPath}/${chart.id}`}
                                            chart={chart} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </ReportViewer>
        );
    }
}

export default CustomChartsList;


const textStyles = {
    fontSize: 12
}

const chartTableColumns: {
    key: string,
    title: string,
    renderer: (chart: CustomChartObject) => React.ReactElement
}[] = [
        {
            key: "name",
            title: "Chart Name",
            renderer: (chart: CustomChartObject) => (
                <Typography style={textStyles}>
                    {chart.name}
                </Typography>
            )
        },
        {
            key: "datasets",
            title: "Datasets",
            renderer: (chart: CustomChartObject) => {
                const datasets = chart.datasets.map(ds => (`${ds.alias}(${ds.name})`));

                return (
                    <React.Fragment>
                        <Typography style={textStyles}>
                            {datasets.join(',  ')}
                        </Typography>
                    </React.Fragment>
                )
            }
        }
    ];

function CollapsibleRow(props: { chart: CustomChartObject, path: string }) {
    const chart: CustomChartObject = props.chart;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment key={chart.name}>
            <TableRow>
                <TableCell style={{ width: 10 }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {
                    chartTableColumns.map((column) => (
                        <TableCell key={`${chart.name}_${column.key}`} id={`${chart.name}_${column.key}`} component="th" scope="row">
                            {column.renderer(chart)}
                        </TableCell>
                    ))
                }
                <TableCell style={{ width: 10 }}>
                    <Link to={props.path}>
                        <IconButton>
                            <Icon className="fas fa-edit" />
                        </IconButton>
                    </Link>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Dataset Details
                            </Typography>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell >
                                            Alias
                                        </TableCell>
                                        <TableCell >
                                            Data Type
                                        </TableCell>
                                        <TableCell >
                                            Chart Type
                                        </TableCell>
                                        <TableCell >
                                            X Axis
                                        </TableCell>
                                        <TableCell >
                                            Y Axis
                                        </TableCell>
                                        <TableCell >
                                            Chart Groups
                                        </TableCell>
                                        <TableCell >
                                            Filters
                                        </TableCell>
                                        <TableCell >
                                            Groups
                                        </TableCell>
                                        <TableCell >
                                            Aggregations
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        chart.datasets.map(dataset => (
                                            <TableRow key={generateUuid()}>
                                                <TableCell >
                                                    <Typography>
                                                        {dataset.alias}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography>
                                                        {dataset.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography>
                                                        {!dataset.chart_type ? "" : dataset.chart_type.charAt(0).toUpperCase() + dataset.chart_type.substr(1).toLowerCase()}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography>
                                                        {dataset.x_axis}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography>
                                                        {dataset.y_axis}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    <Typography>
                                                        {dataset.chart_groups?.join(', ')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    {
                                                        Object.keys(dataset.filter || {}).map(filter => {
                                                            if (!filter || !dataset.filter)
                                                                return null;

                                                            const { name, type, ...rest } = dataset.filter[filter];

                                                            const values = [];

                                                            if (rest.min || rest.min === 0) {
                                                                if (rest.min.selectedDateReference)
                                                                    values.push(`Min=${rest.min.selectedDateReference}${rest.min.selectedOperation}${rest.min.selectedValueForFloating}`);
                                                                else
                                                                    values.push("Min=" + rest.min);
                                                            }

                                                            if (rest.max || rest.max === 0) {
                                                                if (rest.max.selectedDateReference)
                                                                    values.push(`Max=${rest.max.selectedDateReference}${rest.max.selectedOperation}${rest.max.selectedValueForFloating}`);
                                                                else
                                                                    values.push("Max=" + rest.max);
                                                            }

                                                            if (rest.value || rest.value === 0)
                                                                values.push("Value=" + rest.value);

                                                            return (
                                                                <Typography key={generateUuid()}>
                                                                    {`${filter}: ${values.join(", ")}`}
                                                                </Typography>
                                                            )
                                                        })
                                                    }
                                                </TableCell>
                                                <TableCell >
                                                    <Typography>
                                                        {dataset.group_by?.groups.join(', ')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell >
                                                    {
                                                        Object.keys(dataset.group_by?.aggregations || {}).map(agg => (
                                                            <Typography key={generateUuid()}>
                                                                {`${agg}: ${dataset.group_by?.aggregations[agg]}`}
                                                            </Typography>
                                                        ))
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
