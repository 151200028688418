import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { getCumulativeGasDemand } from '../../../apis/vitusApi';
import _, { sortBy } from 'lodash';
import { Grid } from '@material-ui/core';
import { createSpinner } from '../../../utils/spinnerManager';
import { CumulativeGasDemandDailyRow, CumulativeGasDemandDailyTotalRow, CumulativeGasDemandSeason, ICumulativeGasDemandResponse } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { handleApiError, makeTitle, shortenNumber } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import { ITabProps } from './gasBalanceUtils';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption | BarSeriesOption>;

echarts.use(
    [LineChart, BarChart, GridComponent]
);

interface IProps {
    season: ICumulativeRowProps["season"],
}

interface IState {
    countriesData: CumulativeGasDemandSeason["countries"],
}

class CumulativeGasDemand extends React.Component<ITabProps & IProps, IState> {
    state: IState = {
        countriesData: [],
    }

    componentDidMount() {
        if (!this.props.data)
            this.getData();
        else
            this.storeData(this.props.data);
    }

    getData() {
        const spinner = createSpinner();

        getCumulativeGasDemand().then(response => {
            if (response.data.success) {
                this.storeData(response.data.success);
                this.props.onDataChanged(response.data.success);
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    storeData(response: ICumulativeGasDemandResponse["success"]) {
        this.setState({
            countriesData: response.seasons[this.props.season].countries || [],
        });
    }

    render() {
        return (
            <Grid container justify="flex-start" alignItems="flex-end" spacing={1}>
                {
                    this.state.countriesData.map(country => {
                        return (
                            <CumulativeChartRow
                                season={this.props.season}
                                key={`row_${country.country_name}`}
                                countryName={country.country_name}
                                barData={country.total_data}
                                lineData={country.daily_data}
                            />
                        );
                    })
                }
            </Grid>
        )
    }
}


interface ICumulativeRowProps {
    season: "injection" | "withdraw",
    countryName: string,
    barData: CumulativeGasDemandDailyTotalRow[],
    lineData: CumulativeGasDemandDailyRow[],
}


class CumulativeChartRow extends React.Component<ICumulativeRowProps, {}> {
    lineChartRef: ReactECharts | null = null;
    barChartRef: ReactECharts | null = null;

    barChartOptions: ECOption = {
        color: ["#003f5c", "#58508d", "#bc5090", "#ff6361", "#ffa600", "#444e86"],
        animation: false,
        xAxis: {
            type: 'category',
            axisLabel: {
                rotate: 30
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: function (value: number) {
                    return shortenNumber(value);
                },
            }
        },
        grid: {
            left: 65,
        },
    };

    lineChartOptions: ECOption = {
        color: ["#003f5c", "#58508d", "#bc5090", "#ff6361", "#ffa600"],
        animation: false,
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
            scale: true,
            axisLabel: {
                formatter: function (value: number) {
                    return shortenNumber(value);
                },
            }
        },
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'valueDesc'
        },
        series: [{
            data: [],
            type: 'line'
        }],
        dataZoom: [{}],
    }

    componentDidMount() {
        if (this.props.barData)
            this.updateBarChart();

        if (this.props.lineData)
            this.updateLineChart();
    }

    componentDidUpdate(prevProps: ICumulativeRowProps) {
        if (this.props.barData && prevProps.barData !== this.props.barData)
            this.updateBarChart();

        if (this.props.lineData && prevProps.lineData !== this.props.lineData)
            this.updateLineChart();
    }

    updateBarChart = () => {
        const barChartInstance = this.barChartRef?.getEchartsInstance();
        barChartInstance.showLoading();

        const data = this.props.barData;

        barChartInstance.clear();

        barChartInstance.setOption({
            ...this.barChartOptions,
            series: sortBy(data.map(d => {
                return {
                    data: [[d.SeasonName, d.Rolling]],
                    label: {
                        show: true,
                        formatter: shortenNumber(d.Rolling),
                    },
                    type: 'bar',
                    barWidth: 60,
                    barGap: '-100%',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                };
            }), d => d.data[0][0]),
        });

        barChartInstance.hideLoading();
    }

    updateLineChart = () => {
        const lineChartInstance = this.lineChartRef?.getEchartsInstance();
        lineChartInstance.showLoading();

        const data = this.props.lineData;

        lineChartInstance.clear();

        const series = _(data)
            .groupBy("SeasonName")
            .map((value, key) => (
                {
                    type: 'line',
                    name: key,
                    data: value.map(d => [d.DateTitle, d.Cumulative]),
                    symbol: 'none',
                }
            ))
            .sortBy(d => d.name)
            .value();

        lineChartInstance.setOption({
            ...this.lineChartOptions,
            series: series,
            legend: {
                type: 'scroll',
                data: sortBy(series.map(s => s.name)),
            }
        });

        lineChartInstance.hideLoading();
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={8}>
                    <VChart
                        title={!this.props.countryName ? "" : `${this.props.countryName} ${makeTitle(this.props.season)} Season Cumulative Demand by Season Type`}
                        height={300}
                        toolbox={{
                            chartRef: this.lineChartRef,
                            getTableData: () => {
                                const rowsDict = this.props.lineData.reduce((
                                    dict: { [date: string]: { [seasonName: string]: number | string } }, row: CumulativeGasDemandDailyRow) => {
                                    if (!dict[row.DateTitle])
                                        dict[row.DateTitle] = { Date: row.DateTitle };
                                    dict[row.DateTitle][row.SeasonName] = row.Cumulative;
                                    return dict;
                                }, {});

                                const data = {
                                    columns: ['Date', ..._(this.props.lineData.map(l => l.SeasonName)).uniq().sortBy().value()],
                                    rows: Object.keys(rowsDict).map(r => rowsDict[r])
                                }

                                return data;
                            },
                        }}
                        report={
                            <ReactECharts
                                ref={(e) => { this.lineChartRef = e; }}
                                echarts={echarts}
                                option={this.lineChartOptions}
                                notMerge={true}
                                lazyUpdate={true}
                                style={{ width: '99%', height: 250 }}
                            />
                        } >
                    </VChart>
                </Grid>
                <Grid item xs={4} >
                    <VChart
                        title={!this.props.countryName ? "" : `${this.props.countryName} ${makeTitle(this.props.season)} Season Comparison (Cumulative/cm)`}
                        height={300}
                        toolbox={{
                            chartRef: this.barChartRef,
                            hiddenTools: { dataView: true, downloadExcel: true, },
                        }}
                        report={
                            <ReactECharts
                                ref={(e) => { this.barChartRef = e; }}
                                echarts={echarts}
                                option={this.barChartOptions}
                                notMerge={true}
                                lazyUpdate={true}
                                style={{ width: '99%', height: 250 }}
                            />
                        } >
                    </VChart>
                </Grid>
            </React.Fragment >
        )
    }
}

export default CumulativeGasDemand;