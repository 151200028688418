import ReportViewer from '../../ReportViewer/ReportViewer';
import VerticalTabs, { VerticalTab } from '../../../components/VTabs/VTabs';
import PSDRealized from './PSDRealized';
import AlertManager from '../../../utils/alertManager';
import { ReportParams, ReportView } from '../../../system/ReportBase';
import PSDRealizedWithForecast from './PSDRealizedWithForecast';

const TABS: { [key: string]: { title: string } } = {
    realized: { title: 'Realized' },
    realizedWithForecasts: { title: 'Realized With Forecasts' },
}

class PowerSupplyDemand extends ReportView {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "POWER_SUPPLY_DEMAND",
            name: "Power Supply-Demand",
            path: "/psd",
            thumbnail: "power_supply_demand.gif"
        }
    );

    onTabChanged(n: string) {
        if (Object.keys(TABS).map(key => TABS[key].title).includes(n))
            return;

        AlertManager.notImplemented();
        return false;
    }

    render() {
        return (
            <ReportViewer {...PowerSupplyDemand.params}>
                <VerticalTabs onTabChanged={(n) => this.onTabChanged(n)}>
                    <VerticalTab title={TABS.realized.title}>
                        <PSDRealized/>
                    </VerticalTab>
                    <VerticalTab title={TABS.realizedWithForecasts.title}>
                        <PSDRealizedWithForecast/>
                    </VerticalTab>
                </VerticalTabs>
            </ReportViewer>
        );
    }
}

export default PowerSupplyDemand;