import React from 'react';
import chroma from "chroma-js";
import { isEmpty, max, min } from 'lodash';
import { stickyColumnWithHeaderStyle, styles as localStyles } from '../../utils/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { addThousandsDelimeter } from '../../utils/common';
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

// making a Column class and defining below getters in it ruins screen performance
export type Column = (string | { key: string, label?: string, style?: React.CSSProperties });

const styles = (theme: any) => createStyles({
    tableBody: {
        "& *:hover": {
            filter: 'brightness(85%)'
        },
    }
});


export function getColumnKey(column: IHeatMapProps['columns'][0]) {
    if (typeof column === 'string')
        return column;
    return column.key;
}

export function getColumnLabel(column: IHeatMapProps['columns'][0]) {
    if (typeof column === 'string')
        return column;
    return column.label || column.key;
}

export function getColumnStyle(column: IHeatMapProps['columns'][0]) {
    if (typeof column === 'string' || !column.style)
        return {};
    return column.style;
}

export interface IHeatMapParams {
    columns: Column[],
    rows: { [key: string]: any }[],
    style?: React.CSSProperties,
    minValue?: number,
    maxValue?: number,
    stickyHeader?: boolean,
    stickyFirstColumn?: boolean,
    uncoloredColumns?: string[],
}

export interface IHeatMapState extends IHeatMapParams { }
export interface IHeatMapProps extends WithStyles<typeof styles>, IHeatMapParams { }

const panelStyle: React.CSSProperties = {
    display: 'inline-block',
    ...localStyles.borderedContainer
}

const cellStyle: React.CSSProperties = {
    padding: 6,
    background: 'white',
}

class VHeatMap extends React.Component<IHeatMapProps, IHeatMapState>{
    state: IHeatMapState = {
        columns: [],
        rows: [],
    }

    chromaScale(value: number) {
        //0.01 and 0.02 do not have any special meaning. They are estimations to make a good looking
        // heat map which always shows red negatives and green positives.

        //Divided to 2 to narrow down color distribution
        let minValue = (this.state.minValue || 0) / 2;
        let maxValue = (this.state.maxValue || 0) / 2;

        //Making sure the domain function has a proper number interval
        minValue = minValue < -0.02 ? minValue : -0.02;
        maxValue = maxValue > 0.02 ? maxValue : 0.02;

        // [red, light red, white, light green, green]
        const scaleFunc = chroma.scale(["#FF5050", "#ffcaca", "white", "#dcf5dc", "#50D050"])
            .domain([minValue, -0.01, 0, 0.01, maxValue]);

        return scaleFunc(value).hex();
    }

    generateTableHead() {
        return (
            <TableRow>
                {this.state.columns.map((column, idx) => {
                    const label = getColumnLabel(column);
                    const style = getColumnStyle(column);

                    return <TableCell key={`col_${label}`}
                        style={{ ...style, ...(idx === 0 ? stickyColumnWithHeaderStyle.header : {}), ...cellStyle }}>{label}</TableCell>
                })}
            </TableRow>
        );
    }

    generateBackgroundColor(value: number) {
        if (!value)
            return {};

        return { backgroundColor: this.chromaScale(value) }
    };

    generateTableBody() {
        const makeCell = (rowNum: number, colNum: number, column: Column, value: any, style?: React.CSSProperties) => {
            return (
                <TableCell key={`cell_${rowNum}_${colNum}`} style={
                    {
                        ...cellStyle,
                        ...style,
                        ...(this.props.uncoloredColumns?.find(c => c === getColumnKey(column)) ? {} : this.generateBackgroundColor(value)),
                        ...(colNum === 0 ? stickyColumnWithHeaderStyle.cell : {})
                    }}>
                    {addThousandsDelimeter(value, true)}
                </TableCell>
            )
        }

        return this.state.rows.map((row, rowNum) => {
            return (
                <TableRow key={`row_${rowNum}`}>
                    {
                        this.state.columns.map((col, colNum) => {
                            return makeCell(rowNum, colNum, col, row[getColumnKey(col)], getColumnStyle(col))
                        })
                    }
                </TableRow>
            );
        });
    }

    componentDidMount() {
        this.setData();
    }

    componentDidUpdate(prevProps: IHeatMapProps) {
        if ((!isEmpty(this.props.rows) && prevProps.rows !== this.props.rows)
            || (!isEmpty(this.props.columns) && prevProps.columns !== this.props.columns))
            this.setData();
    }

    setData() {
        const allNumbers = this.props.rows.flatMap(r => Object.values(r).filter(v => !isNaN(v)));
        const minValue = min(allNumbers);
        const maxValue = max(allNumbers);
        this.setState({ minValue, maxValue, rows: this.props.rows, columns: this.props.columns });
    }

    render(): React.ReactNode {
        return (
            <div style={{ ...panelStyle, ...(this.props.style || {}), overflow: 'scroll' }}>
                <Table stickyHeader={this.props.stickyHeader} size="small">
                    <TableHead>{this.generateTableHead()}</TableHead>
                    <TableBody className={this.props.classes.tableBody}>{this.generateTableBody()}</TableBody>
                </Table>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(VHeatMap);
