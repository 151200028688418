import { ReportView, ReportParams } from "../../../system/ReportBase";
import { getPropertyName, handleApiError, shallowCopy, toLongDateString, ViewTypes } from "../../../utils/common";
import ReportViewer from "../../ReportViewer/ReportViewer";
import {
    IconButton, Grid, Icon, Paper, Radio, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TextField,
    InputProps, TextFieldProps, List, ListItem, ListItemText, Typography,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel
} from "@material-ui/core";
import { createUser, deleteUser, getUserDetails, getUsersAndRoles, saveUser } from "../../../apis/vitusApi";
import AlertManager from "../../../utils/alertManager";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import { User } from "../../../utils/types";
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import VBox from "../../../components/VBox/VBox";
import React from "react";
import VTransferList from "../../../components/VTransferList/VTransferList";
import { sortBy } from "lodash";
import { hashPass } from "../../../utils/authUtils";

const cellStyles = {
    fontSize: 12,
    padding: 5,
}

const boxStyle: React.CSSProperties = {
    height: "calc(50vh - 120px)",
    overflowX: "hidden",
    overflowY: "scroll",
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: 10,
    width: "100%",
};


interface IState {
    roles: string[],
    report_groups: { [id: number]: { Id: number, Name: string } },
    users: { [userId: number]: User },
    selectedUser?: User,
    createdUser: User,
    editModeOpen: boolean,
    showEditRoles: boolean,
    showEditReportGroups: boolean,
    showNewUserDialog: boolean,
    showConfirmDeleteUser: boolean,
    userToDelete?: { userId: number, userName: string },
}

class UserManagement extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            viewType: ViewTypes.Administration,
            reportKey: "USER_MANAGEMENT",
            name: "User Management",
            path: "/userManagement",
            thumbnail: ""
        }
    );

    state: IState = {
        roles: [],
        report_groups: {},
        users: {},
        selectedUser: undefined,
        createdUser: this.createNewUserInstance(),
        editModeOpen: false,
        showEditRoles: false,
        showEditReportGroups: false,
        showNewUserDialog: false,
        showConfirmDeleteUser: false,
    }

    componentDidMount() {
        const spinner = createSpinner();

        getUsersAndRoles().then(response => {
            if (response.data.success) {
                this.setState({
                    roles: response.data.success.roles,
                    report_groups: response.data.success.report_groups.reduce((map: IState["report_groups"], group) => {
                        map[group.Id] = group;
                        return map;
                    }, {}),
                    users: response.data.success.users_and_roles.reduce((map: IState["users"], user) => {
                        map[user.Id] = user;
                        return map;
                    }, {}),
                })
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    getUser(userId?: number) {
        if (!userId)
            return;

        const spinner = createSpinner();

        getUserDetails({ user_id: userId }).then(response => {
            if (response.data.success) {
                this.setState({
                    selectedUser: response.data.success.user,
                    users: { ...this.state.users, [userId]: response.data.success.user }
                });
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    userSelected(userId?: number) {
        if (!userId)
            return;

        if (this.state.users[userId])
            this.setState({ selectedUser: this.state.users[userId] });
    }

    createTextField(params: {
        onFieldUpdated: (user: User) => void,
        user: User,
        editModeOpen: boolean,
        label: string,
        value?: string,
        width?: number,
        readOnly?: boolean,
        relatedTo: keyof User
    }) {
        let textFieldProps: TextFieldProps = {
            size: "small",
            label: params.label,
            value: params.value || params.user[params.relatedTo] || "",
            onChange: (e) => {
                if (!params.user)
                    return;

                const newUser = shallowCopy(params.user);

                switch (typeof newUser[params.relatedTo]) {
                    case "string":
                        newUser[params.relatedTo] = e.target.value as never;
                        break;
                    case "number":
                        newUser[params.relatedTo] = +e.target.value as never;
                        break;
                    case "object":
                        newUser[params.relatedTo] = new Date(e.target.value) as never;
                        break;
                    default:
                        throw Error("Unhandled property type!");
                }

                params.onFieldUpdated(newUser);
            }
        };

        let inputProps: InputProps;

        if (params.editModeOpen && !params.readOnly)
            inputProps = {}
        else
            inputProps = { readOnly: true, disableUnderline: true }

        if (params.width) {
            textFieldProps.style = { width: 300 };
            inputProps = { ...inputProps, style: { ...inputProps.style, width: 300 } }
        }

        if (params.readOnly)
            inputProps = { ...inputProps, readOnly: true }

        inputProps = { ...inputProps }


        textFieldProps.InputProps = inputProps;

        return (
            <TextField
                autoComplete={`new-${params.relatedTo}`}
                {...textFieldProps} />
        );
    }

    cleanSelectedUser() {
        if (this.state.selectedUser?.Id)
            this.setState({
                selectedUser: this.state.users[this.state.selectedUser?.Id],
                showEditRoles: false,
                showEditReportGroups: false,
                editModeOpen: false
            })
    }

    callSaveUser() {
        if (!this.state.selectedUser)
            return;

        const spinner = createSpinner();

        const oldUser = this.state.users[this.state.selectedUser.Id];
        const updatedUser = this.state.selectedUser;

        saveUser({ old_user: oldUser, updated_user: updatedUser }).then(response => {
            if (response.data.success) {
                const savedUser = response.data.success.user;

                this.setState({
                    selectedUser: savedUser,
                    users: { ...this.state.users, [savedUser.Id]: savedUser }
                });

                this.cleanSelectedUser();
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    userDetails(params: { onFieldUpdated: (user: User) => void, user: User, editModeOpen: boolean, onOpenEditMode?: () => void, onRefresh?: () => void, isNewUser?: boolean }) {
        const { onFieldUpdated, user, editModeOpen, onOpenEditMode, onRefresh, isNewUser } = params;

        const makeBaseInfoReadonly = !isNewUser;

        return (
            <Paper style={{ height: "100%", padding: 6 }}>
                <Grid container justify="flex-start">
                    {
                        onOpenEditMode &&
                        <Grid container item xs={12} justify="flex-end" style={{ height: 0 }}>
                            {
                                (editModeOpen) ?
                                    <React.Fragment>
                                        <IconButton size="small" onClick={() => this.cleanSelectedUser()}>
                                            <CancelIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => this.callSaveUser()}>
                                            <SaveIcon />
                                        </IconButton>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {
                                            onOpenEditMode &&
                                            <IconButton size="small" onClick={onOpenEditMode}>
                                                <EditIcon />
                                            </IconButton>
                                        }
                                        {
                                            onRefresh &&
                                            <IconButton size="small" onClick={onRefresh}>
                                                <Icon className="fas fa-sync-alt" />
                                            </IconButton>
                                        }
                                    </React.Fragment>
                            }
                        </Grid>
                    }
                    <Grid item xs={4}>
                        {this.createTextField({
                            onFieldUpdated,
                            user: user,
                            editModeOpen: editModeOpen,
                            label: "User Name",
                            relatedTo: getPropertyName<User>().UserName,
                            readOnly: makeBaseInfoReadonly,
                        })}
                    </Grid>
                    {
                        params.isNewUser &&
                        <Grid item xs={4}>
                            <TextField size="small"
                                label="Password"
                                value={user.Password || ""}
                                autoComplete='new-password'
                                type="password"
                                onChange={(e) => {
                                    onFieldUpdated({
                                        ...user, Password: e.target.value
                                    })
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={4}>
                        {this.createTextField({
                            onFieldUpdated,
                            user: user,
                            editModeOpen: editModeOpen,
                            label: "Name",
                            relatedTo: getPropertyName<User>().Name,
                        })}
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                        {this.createTextField({
                            onFieldUpdated,
                            user: user,
                            editModeOpen: editModeOpen,
                            label: "Title",
                            relatedTo: getPropertyName<User>().Title,
                        })}
                    </Grid>
                    <Grid item xs={4}>
                        {this.createTextField({
                            onFieldUpdated,
                            user: user,
                            editModeOpen: editModeOpen,
                            label: "Phone Number",
                            relatedTo: getPropertyName<User>().PhoneNumber,
                        })}
                    </Grid>
                    <Grid item xs={10}>
                        {this.createTextField({
                            onFieldUpdated,
                            user: user,
                            editModeOpen: editModeOpen,
                            label: "Email Address",
                            width: 300,
                            relatedTo: getPropertyName<User>().EmailAddress,
                        })}
                    </Grid>
                    {
                        !params.isNewUser &&
                        <>
                            <Grid item xs={4}>
                                {this.createTextField({
                                    onFieldUpdated,
                                    user: user,
                                    editModeOpen: editModeOpen,
                                    label: "Created By",
                                    relatedTo: getPropertyName<User>().CreatedBy,
                                    readOnly: true,
                                })}
                            </Grid>
                            <Grid item xs={6}>
                                {this.createTextField({
                                    onFieldUpdated,
                                    user: user,
                                    editModeOpen: editModeOpen,
                                    label: "Created At",
                                    value: toLongDateString(user.CDate),
                                    readOnly: true,
                                    relatedTo: getPropertyName<User>().CDate,
                                })}
                            </Grid>
                            <Grid item xs={4}>
                                {this.createTextField({
                                    onFieldUpdated,
                                    user: user,
                                    editModeOpen: editModeOpen,
                                    label: "Updated By",
                                    relatedTo: getPropertyName<User>().UpdatedBy,
                                    readOnly: true,
                                })}
                            </Grid>
                            <Grid item xs={6}>
                                {this.createTextField({
                                    onFieldUpdated,
                                    user: user,
                                    editModeOpen: editModeOpen,
                                    label: "Updated At",
                                    value: toLongDateString(user.UDate),
                                    readOnly: true,
                                    relatedTo: getPropertyName<User>().UDate,
                                })}
                            </Grid>
                        </>
                    }

                    <Grid item xs={12}>
                    </Grid>

                    {
                        !isNewUser &&
                        <>
                            <Grid item xs={4}>
                                <VBox title="Roles" style={{ minHeight: 80 }}>
                                    <Grid container justify="flex-start" >
                                        <Grid container item xs={12} justify="flex-end" style={{ height: 0 }}>
                                            {
                                                !editModeOpen &&
                                                <IconButton style={{ zIndex: 1000 }} size="small" onClick={() => this.setState({ showEditRoles: true })}>
                                                    <EditIcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <List component="div" >
                                                {
                                                    user.Roles?.map(role => (
                                                        <ListItem key={`rli_${role}`} dense style={{ padding: 0 }}>
                                                            <Icon style={{ width: 20, fontSize: 10 }} className="fas fa-circle" />
                                                            <ListItemText primary={<Typography>{role}</Typography>} />
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </Grid>
                                    </Grid>
                                </VBox>
                            </Grid>
                            <Grid item xs={4}>
                                <VBox title="Report Groups" style={{ minHeight: 80 }}>
                                    <Grid container justify="flex-start" >
                                        <Grid container item xs={12} justify="flex-end" style={{ height: 0 }}>
                                            {
                                                !editModeOpen &&
                                                <IconButton style={{ zIndex: 1000 }} size="small" onClick={() => this.setState({ showEditReportGroups: true })}>
                                                    <EditIcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <List component="div" >
                                                {
                                                    user.ReportGroups?.map(reportGroup => (
                                                        <ListItem key={`rgli_${this.state.report_groups?.[reportGroup]?.Name ?? "na"}`} dense style={{ padding: 0 }}>
                                                            <Icon style={{ width: 20, fontSize: 10 }} className="fas fa-circle" />
                                                            <ListItemText primary={<Typography>{this.state.report_groups?.[reportGroup]?.Name}</Typography>} />
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </Grid>
                                    </Grid>
                                </VBox>
                            </Grid>
                        </>
                    }
                </Grid>
            </Paper >
        );
    }

    getRolesDialog() {
        return (
            <Dialog fullWidth maxWidth="md" onClose={() => this.cleanSelectedUser()}
                open={this.state.showEditRoles}>
                <DialogTitle>Edit Roles of {this.state.selectedUser?.UserName}</DialogTitle>
                <DialogContent dividers>
                    <VTransferList
                        leftTitle="Existing Roles"
                        rightTitle="Other Roles"
                        leftItems={this.state.selectedUser?.Roles || []}
                        rightItems={this.state.roles.filter(role => !this.state.selectedUser?.Roles?.find(existingRole => existingRole === role))}
                        style={boxStyle}
                        onChange={(left, right) => {
                            const user = { ...this.state.selectedUser } as User;
                            user.Roles = left;
                            this.setState({ selectedUser: user })
                        }}
                        listItemComponent={(role: string) => (<ListItemText primary={role} />)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.cleanSelectedUser()}>
                        Cancel
                    </Button>
                    <Button autoFocus onClick={() => this.callSaveUser()} color="secondary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    getReportGroupsDialog() {
        const existingGroups = this.state.selectedUser?.ReportGroups?.map(groupId => this.state.report_groups?.[groupId]?.Name) || [];
        const otherGroups = Object.keys(this.state.report_groups)
            .filter(groupId => !this.state.selectedUser?.ReportGroups.find(existingGroupId => +existingGroupId === +groupId))
            .map(groupId => this.state.report_groups[+groupId].Name) || [];

        return (
            <Dialog fullWidth maxWidth="md" onClose={() => this.cleanSelectedUser()}
                open={this.state.showEditReportGroups}>
                <DialogTitle>Edit Report Groups of {this.state.selectedUser?.UserName}</DialogTitle>
                <DialogContent dividers>
                    <VTransferList
                        leftTitle="Existing Groups"
                        rightTitle="Other Groups"
                        leftItems={existingGroups}
                        rightItems={otherGroups}
                        style={boxStyle}
                        onChange={(left, right) => {
                            const reportGroupsByName: { [key: string]: number } = {};
                            Object.keys(this.state.report_groups).forEach(k => {
                                reportGroupsByName[this.state.report_groups[+k].Name] = +k;
                            })

                            const user = { ...this.state.selectedUser } as User;
                            user.ReportGroups = left.map(l => reportGroupsByName[l]);
                            this.setState({ selectedUser: user })
                        }}
                        listItemComponent={(role: string) => (<ListItemText primary={role} />)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.cleanSelectedUser()}>
                        Cancel
                    </Button>
                    <Button autoFocus onClick={() => this.callSaveUser()} color="secondary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    createNewUserInstance() {
        const now = new Date();
        return {
            Id: 0,
            UserName: "",
            Password: "",
            EmailAddress: "",
            Name: "",
            Title: "",
            PhoneNumber: "",
            CreatedBy: "",
            UpdatedBy: "",
            CDate: now,
            UDate: now,
            Roles: [],
            ReportGroups: [],
        } as User;
    }

    cleanNewUser() {
        this.setState({ createdUser: this.createNewUserInstance(), showNewUserDialog: false })
    }

    callCreateUser() {
        if (!this.state.createdUser || !this.validateNewUser())
            return;

        const spinner = createSpinner();

        const new_user = {
            ...this.state.createdUser,
            Password: hashPass(this.state.createdUser.Password)
        };

        createUser({ new_user }).then(response => {
            if (response.data.success) {
                const savedUser = response.data.success.user;

                this.setState({
                    selectedUser: savedUser,
                    users: { ...this.state.users, [savedUser.Id]: savedUser }
                });

                this.cleanNewUser();
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    validateNewUser() {
        const user = this.state.createdUser;

        if (!user.UserName) {
            AlertManager.showError("Please provide UserName"); //TODO: message
            return false;
        }

        if (!user.Password) { 
            AlertManager.showError("Please provide Password"); //TODO: message
            return false;
        }

        if (user.Password.length < 8) { 
            AlertManager.showError("Password must be at least 8 characters"); //TODO: message
            return false;
        }

        return true;
    }

    getNewUserDialog() {
        const user = this.state.createdUser;

        return (
            <Dialog fullWidth maxWidth="md" onClose={() => this.cleanNewUser()}
                open={this.state.showNewUserDialog}>
                <DialogTitle>Create New User</DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            {
                                this.userDetails({
                                    onFieldUpdated: (newUser) => this.setState({ createdUser: newUser }),
                                    user: user,
                                    editModeOpen: true,
                                    isNewUser: true,
                                })
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Paper style={{ padding: 6 }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Roles</FormLabel>
                                    <FormGroup>
                                        {
                                            this.state.roles.map(role => (
                                                <FormControlLabel key={`check_${role}`}
                                                    control={
                                                        <Checkbox checked={user.Roles.indexOf(role) >= 0}
                                                            name={role}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                let newRoles: User["Roles"];
                                                                let changedRole = event.target.name;

                                                                if (event.target.checked)
                                                                    newRoles = [...this.state.createdUser.Roles, changedRole]
                                                                else
                                                                    newRoles = this.state.createdUser.Roles.filter(r => r !== changedRole)

                                                                this.setState({ createdUser: { ...this.state.createdUser, Roles: newRoles } })
                                                            }} />
                                                    }
                                                    label={role}
                                                />
                                            ))
                                        }
                                    </FormGroup>
                                </FormControl>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper style={{ padding: 6 }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Report Groups</FormLabel>
                                    <FormGroup>
                                        {
                                            Object.keys(this.state.report_groups).map(group => (
                                                <FormControlLabel key={`check_${group}`}
                                                    control={
                                                        <Checkbox checked={user.ReportGroups.indexOf(+group) >= 0}
                                                            name={group.toString()}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                let newGroups: User["ReportGroups"];
                                                                let changedGroup = +event.target.name;

                                                                if (event.target.checked)
                                                                    newGroups = [...this.state.createdUser.ReportGroups, changedGroup]
                                                                else
                                                                    newGroups = this.state.createdUser.ReportGroups.filter(r => r !== changedGroup)

                                                                this.setState({ createdUser: { ...this.state.createdUser, ReportGroups: newGroups } })
                                                            }} />
                                                    }
                                                    label={this.state.report_groups[+group].Name}
                                                />
                                            ))
                                        }
                                    </FormGroup>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.cleanNewUser()}>
                        Cancel
                    </Button>
                    <Button autoFocus onClick={() => this.callCreateUser()} color="secondary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    confirmDeleteUser(userId: number, userName: string) {
        this.setState({ showConfirmDeleteUser: true, userToDelete: { userId, userName } })
    }

    cleanUserToDelete() {
        this.setState({ showConfirmDeleteUser: false, userToDelete: undefined })
    }

    callDeleteUser() {
        const userInfo = this.state.userToDelete;

        if (!userInfo)
            return;

        const spinner = createSpinner();

        deleteUser({ user_id: userInfo.userId, user_name: userInfo.userName }).then(response => {
            if (response.data.success) {
                const savedUser = response.data.success.user;

                this.setState({
                    selectedUser: savedUser,
                    users: { ...this.state.users, [savedUser.Id]: savedUser }
                });

                this.cleanUserToDelete();
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    render() {
        return (
            <ReportViewer {...UserManagement.params}>
                <Grid container spacing={1} justify="flex-start">
                    <Grid container item xs={12} justify="flex-end" style={{ height: 0 }}>
                        <Button style={{ marginTop: -20 }}
                            onClick={() => this.setState({ showNewUserDialog: true })}>
                            <Icon style={{ marginRight: 5 }} className="fas fa-plus" />
                            New User
                        </Button>
                    </Grid>
                    <Grid item xs={7}>
                        <TableContainer component={Paper} title="Cache Records">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ ...cellStyles, width: 20 }}>
                                        </TableCell>
                                        <TableCell style={{ ...cellStyles, width: 180 }}>
                                            User Name
                                        </TableCell>
                                        <TableCell style={{ ...cellStyles, width: 450 }}>
                                            Roles
                                        </TableCell>
                                        <TableCell style={{ ...cellStyles, width: 100 }}>
                                            Report Groups
                                        </TableCell>
                                        <TableCell style={{ ...cellStyles, width: 20 }}>
                                            Delete
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        sortBy(Object.keys(this.state.users),
                                            userId => `${this.state.users[+userId].ReportGroups.length}_${this.state.users[+userId].Roles.length}`)
                                            .reverse()
                                            .map(userId => {
                                                const user = this.state.users[+userId];

                                                return (
                                                    <TableRow key={`row_${user.Id}`}
                                                        onClick={() => this.userSelected(user.Id)}>
                                                        <TableCell style={{ ...cellStyles }}>
                                                            <Radio
                                                                checked={user.Id === this.state.selectedUser?.Id}
                                                                value={user.UserName}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={cellStyles}>
                                                            {user.UserName}
                                                        </TableCell>
                                                        <TableCell style={cellStyles}>
                                                            {user.Roles.join(',')}
                                                        </TableCell>
                                                        <TableCell style={cellStyles}>
                                                            {user.ReportGroups.map(grId => this.state.report_groups?.[grId]?.Name).join(',')}
                                                        </TableCell>
                                                        <TableCell style={cellStyles}>
                                                            <IconButton onClick={() => this.confirmDeleteUser(user.Id, user.UserName)}>
                                                                <Icon fontSize="small" className="fas fa-times" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {
                        <Grid item xs={5}>
                            {
                                this.state.selectedUser?.Id && this.userDetails({
                                    onFieldUpdated: (newUser) => this.setState({ selectedUser: newUser }),
                                    user: this.state.selectedUser,
                                    editModeOpen: this.state.editModeOpen,
                                    onOpenEditMode: () => this.setState({ editModeOpen: true }),
                                    onRefresh: () => this.getUser(this.state.selectedUser?.Id)
                                })
                            }
                        </Grid>
                    }
                    {
                        this.state.showEditRoles && this.getRolesDialog()
                    }
                    {
                        this.state.showEditReportGroups && this.getReportGroupsDialog()
                    }
                    {
                        this.state.showNewUserDialog && this.getNewUserDialog()
                    }
                    {
                        this.state.showConfirmDeleteUser &&
                        <Dialog onClose={() => this.cleanUserToDelete()} open={this.state.showConfirmDeleteUser}>
                            <DialogTitle>Confirm deleting</DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    Are you sure you want to delete <span style={{ fontWeight: 500 }}>{this.state.userToDelete?.userName}</span>?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => this.cleanUserToDelete()}>
                                    Cancel
                                </Button>
                                <Button autoFocus onClick={() => this.callDeleteUser()}
                                    color="secondary">
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </Grid>
            </ReportViewer >
        );
    }
}

export default UserManagement;