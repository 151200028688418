import React from "react";
import CoordinateSystemChart from "./CoordinateSystemChart";
import BaseChart, { ChartType, chartTypes } from "./BaseChart";
import { Dataset } from "../../../utils/types";
import CustomHeatmapChart from "./CustomHeatmapChart";
import { isEmpty } from "lodash";
import PlainTable from "./PlainTable";

const errorElement = <div style={{ color: "red", fontWeight: 500, fontSize: 15 }}>Unavailable</div>;

const chartComponents: { [key in ChartType]: typeof React.Component } = {
    "line": CoordinateSystemChart,
    "bar": CoordinateSystemChart,
    "scatter": CoordinateSystemChart,
    "heatmap": CustomHeatmapChart,
    "table": PlainTable,
}

interface IProps {
    ref: React.LegacyRef<BaseChart>,
    [key: string]: any,
    title: string,
}

class ChartAdapter {
    static checkErrors(datasets: Dataset[]) {
        const existingChartTypes: { [key: string]: boolean } = {};

        datasets.forEach(d => {
            existingChartTypes[d.chart_type?.toLowerCase() || ""] = true;
        })

        if (existingChartTypes[chartTypes.heatmap] && datasets.length > 1) {
            return "Heatmap can not be used when there are multiple datasets."; //TODO: message
        }

        if (existingChartTypes[chartTypes.table] && datasets.length > 1) {
            return "Table can not be used when there are multiple datasets."; //TODO: message
        }
    }

    static validateDatasets(datasets: Dataset[]) {
        const error = ChartAdapter.checkErrors(datasets)

        if (error)
            return false;

        return true;
    }

    static generateChart(datasets: Dataset[], props: IProps) {
        if (isEmpty(datasets.filter(d => d.chart_type)))
            return null;

        if (!ChartAdapter.validateDatasets(datasets))
            return errorElement;

        const chartType: ChartType = datasets?.[0]?.chart_type?.toLowerCase() as ChartType;

        if (!chartType || !chartComponents[chartType as ChartType])
            return errorElement;

        return React.createElement(chartComponents[chartType as ChartType], props);
    }
}

export default ChartAdapter;