import { setRecentReports } from "../../utils/localStorageManager";
import { UpdateRecentReportsAction } from "../actions";

export interface IReportInfo {
    recentReports?: UpdateRecentReportsAction["payload"][]
}

const initialState: IReportInfo = {
    recentReports: undefined
};

const STORED_REPORT_COUNT = 10;

export const reportInfoReducer = (state: IReportInfo = initialState, action: any): IReportInfo => {
    switch (action.type) {
        case "UPDATE_RECENT":
            const newState = {
                ...state,
                recentReports: [
                    action.payload,
                    ...(state.recentReports?.filter(r => r.name !== action.payload.name)?.slice(0, STORED_REPORT_COUNT - 1) || [])
                ]
            };

            setRecentReports(action.payload.userName, newState.recentReports);

            return newState;
        case "INITIALIZE_RECENT":
            return {
                ...state,
                recentReports: action.payload || []
            };
        default:
            return state;
    }

}