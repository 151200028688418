import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import { ScatterChart, ScatterSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { getYoYChanges } from '../../../apis/vitusApi';
import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { createSpinner } from '../../../utils/spinnerManager';
import { IYoyChangesResponse, YoyChangesByDataType } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { handleApiError, shortenNumber } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import { ITabProps } from './gasBalanceUtils';
import { TabPanel } from '../../../components/VTabs/VTabs';
import { styles } from '../../../utils/styles';

type ECOption = echarts.ComposeOption<GridComponentOption | BarSeriesOption | ScatterSeriesOption>;

echarts.use(
    [BarChart, ScatterChart, GridComponent]
);

interface IProps {
    season: "injection" | "withdraw",
}

interface IState {
    netBalance?: IYoyChangesResponse["success"]["net_balance"],
    productionData?: IYoyChangesResponse["success"]["production"],
    demandData?: IYoyChangesResponse["success"]["demand"],
    flowData?: IYoyChangesResponse["success"]["gas_flow"],
    lngData?: IYoyChangesResponse["success"]["lng"],
}

class YoyChanges extends React.Component<ITabProps & IProps, IState> {


    state: IState = {

    }

    componentDidMount() {
        if (!this.props.data)
            this.getData();
        else
            this.storeData(this.props.data);
    }

    getData() {
        const spinner = createSpinner();

        getYoYChanges().then(response => {
            if (response.data.success) {
                this.storeData(response.data.success);
                this.props.onDataChanged(response.data.success);
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    storeData(response: IYoyChangesResponse["success"]) {
        this.setState({
            netBalance: response.net_balance,
            productionData: response.production,
            demandData: response.demand,
            flowData: response.gas_flow,
            lngData: response.lng,
        });
    }

    render() {
        return (
            <Grid container justify="flex-start" alignItems="flex-start">
                <ChartRow
                    season={this.props.season}
                    data={this.state.productionData}
                    title={`EU Production Date to Year YoY (${this.state.productionData?.unit || "n/a"})`}
                    description={this.state.productionData?.description &&
                        <div style={{ padding: 10 }}>
                            <span>
                                {this.state.productionData?.description}
                            </span>
                        </div>
                    }
                />
                <ChartRow
                    season={this.props.season}
                    data={this.state.demandData}
                    title={`EU Demand Date to Year YoY (${this.state.demandData?.unit || "n/a"})`}
                    description={this.state.demandData?.description &&
                        <div style={{ padding: 10 }}>
                            <span>
                                {this.state.demandData?.description}
                            </span>
                        </div>
                    }
                />
                <ChartRow
                    season={this.props.season}
                    data={this.state.flowData}
                    title={`EU Gas Flow YoY (${this.state.flowData?.unit || "n/a"})`}
                    description={this.state.flowData?.description &&
                        <div style={{ padding: 10 }}>
                            <span>
                                {this.state.flowData?.description}
                            </span>
                        </div>
                    }
                />
                <ChartRow
                    season={this.props.season}
                    data={this.state.lngData}
                    title={`EU LNG YoY (${this.state.lngData?.unit || "n/a"})`}
                    netBalance={this.state.netBalance}
                    description={
                        this.state.lngData?.description &&
                        <div style={{ padding: 10 }}>
                            <span>
                                {this.state.lngData?.description}
                            </span>
                        </div>
                    }
                />
            </Grid >
        );
    }
}

interface IChartRowProps {
    title: string,
    data?: YoyChangesByDataType,
    description?: React.ReactNode,
    season: IProps["season"],
    netBalance?: IState["netBalance"]
}

interface IChartRowState {
    selectedTabIndex: number,
}


class ChartRow extends React.Component<IChartRowProps, IChartRowState> {
    state: IChartRowState = {
        selectedTabIndex: 0,
    }

    barChartRef: ReactECharts | null = null;

    barChartOptions: ECOption = {
        animation: false,
        xAxis: {
            type: 'category',
        },
        yAxis: [{
            type: 'value',
            axisLabel: {
                formatter: function (value: number) {
                    return shortenNumber(value);
                },
            }
        },
        {
            type: 'value',
            nameLocation: 'start',
            max: 0
        }],
    };

    componentDidMount() {
        if (this.props.data)
            this.updateChart();
    }

    componentDidUpdate(prevProps: IChartRowProps) {
        if (this.props.data && prevProps.data !== this.props.data)
            this.updateChart();
    }

    updateChart() {
        const data = this.props.data?.[this.props.season];

        if (!data)
            return;

        if (this.props.netBalance)
            this.setState({ selectedTabIndex: this.props.netBalance[this.props.season].length - 1 })

        const barChartInstance = this.barChartRef?.getEchartsInstance();
        barChartInstance.showLoading();

        barChartInstance.clear();

        barChartInstance.setOption({
            ...this.barChartOptions,
            legend: {
                type: 'scroll',
                data: ["Change", "Production", "Change Percentage"],
            },
            series: [
                {
                    name: "Change",
                    data: data.map(d => [d.Season, d.Change]),
                    label: {
                        show: true,
                        formatter: (params: { data: any[] }) => shortenNumber(params.data[1])
                    },
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                },
                {
                    name: "Production",
                    data: data.map(d => [d.Season, d.Value]),
                    label: {
                        show: true,
                        formatter: (params: { data: any[] }) => shortenNumber(params.data[1])
                    },
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                },
                {
                    name: "Change Percentage",
                    yAxisIndex: 1,
                    data: data.map(d => [d.Season, d.ChangePercentage]),
                    type: 'scatter',
                    symbol: 'triangle',
                    itemStyle: {
                        color: 'red',
                    }
                }
            ],
        });

        barChartInstance.hideLoading();
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={8} >
                    <VChart
                        title={this.props.title}
                        height={350}
                        report={
                            <ReactECharts
                                ref={(e) => { this.barChartRef = e; }}
                                echarts={echarts}
                                option={this.barChartOptions}
                                notMerge={true}
                                lazyUpdate={true}
                                style={{ width: '99%' }}
                            />
                        } >
                    </VChart>
                </Grid>
                <Grid item xs={4}>
                    {this.props.description}
                    {
                        this.props.netBalance &&
                        <div style={styles.borderedContainer}>
                            {
                                this.props.netBalance?.[this.props.season]?.map((balanceRow, idx) => {
                                    return (
                                        <TabPanel value={this.state.selectedTabIndex}
                                            index={idx}
                                            key={`tab_content_${balanceRow.Season}`}>
                                            <div style={{ padding: 10 }}>
                                                <Typography variant="h1">
                                                    {shortenNumber(balanceRow.Value)}
                                                </Typography>
                                                <Typography variant="h4">
                                                    Total Net Balance (cm)
                                                </Typography>
                                            </div>
                                        </TabPanel>
                                    );
                                })
                            }
                            <Tabs value={this.state.selectedTabIndex}
                                variant="fullWidth"
                                onChange={(_, value) => this.setState({ selectedTabIndex: value })} >
                                {
                                    this.props.netBalance?.[this.props.season]?.map((balanceRow) => {
                                        return (
                                            <Tab key={`tab_${balanceRow.Season}`}
                                                label={balanceRow.Season}
                                                style={{ minWidth: 40 }}
                                            />
                                        )
                                    })
                                }

                            </Tabs>
                        </div>
                    }
                </Grid>
            </React.Fragment >
        )
    }
}

export default YoyChanges;