import ReportViewer from '../../ReportViewer/ReportViewer';
import VerticalTabs, { VerticalTab } from '../../../components/VTabs/VTabs';
import { ReportParams, ReportView } from '../../../system/ReportBase';
import EuropeGasDemand from './EuropeGasDemand';
import CumulativeGasDemand from './CumulativeGasDemand';
import React from 'react';
import EuropeGasDemandSeason from './EuropeGasDemandSeason';
import GasBurn from './GasBurn';
import GasFlows from './GasFlows';
import YoyChanges from './YoyChanges';
import Storages from './Storages';
import EuropeProduction from './EuropeProduction';
import NetFlows from './NetFlows';
import StorageChange from './StorageChange';
import LngFlows from './LngFlows';
import { createSpinner } from '../../../utils/spinnerManager';
import { getAllGasBalanceReport, getBalanceNetFlows } from '../../../apis/vitusApi';
import AlertManager from '../../../utils/alertManager';
import { handleApiError } from '../../../utils/common';
import messages from '../../../utils/messages';
import { Typography } from '@material-ui/core';

type TabInfo = { title: string, dataType: string };

const TABS: { [key: string]: TabInfo } = {
    europeGasDemand: { title: 'Europe Gas Demand', dataType: 'europe_gas_demand' },
    cumulativeGasDemandInjection: { title: 'Cumulative Gas Demand - Injection', dataType: 'cumulative_gas_demand' },
    cumulativeGasDemandWithdraw: { title: 'Cumulative Gas Demand - Withdraw', dataType: 'cumulative_gas_demand' },
    europeGasDemandWithdrawSeason: { title: 'Europe Gas Demand Withdraw Season', dataType: 'cumulative_gas_demand' },
    europeGasDemandInjectionSeason: { title: 'Europe Gas Demand Injection Season', dataType: 'cumulative_gas_demand' },
    gasBurn: { title: 'Gas Burn', dataType: 'gas_burn' },
    gasFlowsInjection: { title: 'Gas Flows - Injection', dataType: 'gas_flows' },
    gasFlowsWithdraw: { title: 'Gas Flows - Withdraw', dataType: 'gas_flows' },
    netFlows: { title: 'Net Flows', dataType: 'gas_flows' },
    yoyChangesInjection: { title: 'YOY Changes - Injection', dataType: 'yoy_changes' },
    yoyChangesWithdraw: { title: 'YOY Changes - Withdraw', dataType: 'yoy_changes' },
    storages: { title: 'Storages', dataType: 'eu_gas_storage' },
    storageChange: { title: 'Storage Change', dataType: 'eu_gas_storage' },
    europeProduction: { title: 'Europe Production', dataType: 'eu_production' },
    lngFlowsInjection: { title: 'LNG Flows - Injection', dataType: 'lng_flows' },
    lngFlowsWithdraw: { title: 'LNG Flows - Withdraw', dataType: 'lng_flows' },
}

interface IState {
    dataCache: { [tabName: string]: any },
    isCacheReady: boolean,
}

class GasBalanceReport extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "GAS_BALANCE_REPORT",
            name: "Gas Balance Report",
            path: "/gasBalanceReport",
            thumbnail: ""
        }
    );

    state: IState = {
        dataCache: {},
        isCacheReady: false,
    }

    componentDidMount() {
        const spinner = createSpinner();

        getAllGasBalanceReport().then(response => {
            if (response.data.success) {
                const data = response.data.success;
                const dataCache: IState["dataCache"] = {};

                Object.keys(data).forEach(dataKey => {
                    dataCache[dataKey] = data[dataKey];
                })

                this.setState({ dataCache: dataCache, isCacheReady: true });
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });

        getBalanceNetFlows().then(response => {
            if (response.data.success)
                this.onDataChanged(TABS.gasFlowsInjection.dataType, response.data.success);

            if (response.data.error)
                AlertManager.showError("Could not get flow: " + messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        });
    }

    onDataChanged(tabName: string, data: any) {
        this.setState({ dataCache: { [tabName]: data, ...this.state.dataCache } });
    }

    getReportTab(props: { tabInfo: TabInfo, Component: typeof React.Component, [key: string]: any }) {
        const { tabInfo, Component, ...rest } = props;
        return (
            <VerticalTab title={tabInfo.title}>
                <Component
                    data={this.state.dataCache[tabInfo.dataType]}
                    onDataChanged={(data: any) => this.onDataChanged(tabInfo.dataType, data)}
                    {...rest} />
            </VerticalTab>
        )
    }

    render() {
        return (
            <ReportViewer {...GasBalanceReport.params}>
                {
                    !this.state.isCacheReady ?
                        <Typography>
                            Preparing report...
                        </Typography>
                        :
                        <VerticalTabs>
                            {
                                this.getReportTab({
                                    tabInfo: TABS.europeGasDemand,
                                    Component: EuropeGasDemand
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.cumulativeGasDemandWithdraw,
                                    Component: CumulativeGasDemand,
                                    season: "withdraw"
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.cumulativeGasDemandInjection,
                                    Component: CumulativeGasDemand,
                                    season: "injection"
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.europeGasDemandWithdrawSeason,
                                    Component: EuropeGasDemandSeason,
                                    season: "withdraw"
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.europeGasDemandInjectionSeason,
                                    Component: EuropeGasDemandSeason,
                                    season: "injection"
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.gasBurn,
                                    Component: GasBurn
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.europeProduction,
                                    Component: EuropeProduction
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.gasFlowsWithdraw,
                                    Component: GasFlows,
                                    season: "withdraw"
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.gasFlowsInjection,
                                    Component: GasFlows,
                                    season: "injection"
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.netFlows,
                                    Component: NetFlows,
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.storageChange,
                                    Component: StorageChange
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.storages,
                                    Component: Storages
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.lngFlowsWithdraw,
                                    Component: LngFlows,
                                    season: "withdraw"

                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.lngFlowsInjection,
                                    Component: LngFlows,
                                    season: "injection"

                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.yoyChangesWithdraw,
                                    Component: YoyChanges,
                                    season: "withdraw"
                                })
                            }
                            {
                                this.getReportTab({
                                    tabInfo: TABS.yoyChangesInjection,
                                    Component: YoyChanges,
                                    season: "injection"
                                })
                            }
                        </VerticalTabs>
                }
            </ReportViewer>
        );
    }
}

export default GasBalanceReport;