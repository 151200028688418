import React from "react";
import { ViewTypes } from "../utils/common";

export class ReportView<P = {}, S = {}> extends React.Component<P, S> {
    static params: ReportParams;
}

export class ReportParams {
    readonly reportKey: string;
    readonly name: string;
    readonly cleanPath?: string;
    readonly path: string;
    readonly thumbnail: string;
    readonly viewType: ViewTypes;
    readonly shortName?: string;
    

    constructor(params: { reportKey: string, name: string, path: string, thumbnail: string, cleanPath?: string, viewType?: ViewTypes, shortName?: string }) {
        this.reportKey = params.reportKey;
        this.name = params.name;
        this.path = params.path;
        this.thumbnail = params.thumbnail;
        this.cleanPath = params.cleanPath;
        this.viewType = params.viewType || ViewTypes.Report;
        this.shortName = params.shortName;
    }
}
