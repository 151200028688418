import React from 'react';
import { createStyles, Grid, Icon, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';

interface IActiveFilterProps {
    getActiveFilter?: () => { [key: string]: any },
    customToString?: { [key: string]: (value: any) => string }
}

interface IProps extends IActiveFilterProps {
    children: React.ReactNode,
    showHide?: boolean,
    initialShow?: boolean,
    onShowChanged?: (show: boolean) => void,
    show?: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerContainer: {
            border: "1px solid rgba(224, 224, 224, 1)",
            width: "100%"
        },
        innerContainer: {
            padding: "0 10px",
        }
    }),
);

const VActiveFilter: React.FC<IActiveFilterProps> = (props: IActiveFilterProps) => {
    let activeFilter = "Not found";

    if (props.getActiveFilter) {
        const activeFilterObj = props.getActiveFilter();

        activeFilter = Object.keys(activeFilterObj).sort().map(key => {
            let value = activeFilterObj[key];

            if (props.customToString && props.customToString[key]) {
                value = props.customToString[key](value)
            }

            value = value?.toString();

            if (!value)
                value = "All";

            const formattedKeyParts = key.replaceAll('_', ' ').split(' ');

            const formattedKey = formattedKeyParts.map(k => k.charAt(0).toUpperCase() + k.substr(1).toLowerCase()).join(' ');

            return `${formattedKey}: ${value}`
        }).join(" | ");
    }

    return (
        <Typography variant="caption">
            <i className="fas fa-filter" /> {activeFilter}
        </Typography>
    )
}

const VPageFilter: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    const [show, setShow] = React.useState(!!props.initialShow);

    const isVisible = () => {
        if (props.onShowChanged)
            return props.show;
        return show;
    };

    const handleShowChanged = () => {
        if (props.onShowChanged)
            props.onShowChanged(!isVisible())
        else
            setShow(!isVisible())
    };

    return (
        <Grid container
            justify="space-evenly"
            className={classes.outerContainer}>
            <Grid item xs={12} container className={classes.innerContainer}>
                {props.getActiveFilter &&
                    <Grid item xs={11} container alignItems="center"
                        className={classes.innerContainer}
                        onClick={() => handleShowChanged()}
                        style={{ cursor: "pointer" }}>
                        <VActiveFilter getActiveFilter={props.getActiveFilter} customToString={props.customToString} />
                    </Grid>}
                {
                    props.showHide &&
                    <Grid item xs={1} container justify="flex-end">
                        <IconButton
                            style={{ maxHeight: "48px", maxWidth: "48px" }}
                            onClick={() => handleShowChanged()}
                        >
                            {isVisible() ? <Icon className="fas fa-chevron-up" /> : <Icon className="fas fa-chevron-down" />}
                        </IconButton>
                    </Grid>
                }
                {isVisible() &&
                    <Grid item
                        xs={12}
                        spacing={0}
                        container
                        className={classes.innerContainer}>
                        {props.children}
                    </Grid>
                }
            </Grid>
        </Grid >
    );
}

export default VPageFilter;