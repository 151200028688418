import { YAXisOption } from "echarts/types/dist/shared";
import { min, max } from "lodash";
import * as echarts from 'echarts/core';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { round } from "../../../utils/common";

echarts.use(
    [GridComponent, LineChart]
);

function arrableToArray<T>(param: T | T[]): T[] {
    if (!param)
        return [];

    if (param instanceof Array)
        return param;

    return [param];
}

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption> & { series: (GridComponentOption | LineSeriesOption)[] };

abstract class ProgressionExtension {
    abstract getColor(): string;
    abstract getLabel(): string;
    abstract addExtensionCharts(options: ECOption, pivotTable: { [forecastDate: string]: { value: number, date: string }[] }): void;

    createYAxis(options: ECOption, axisDefinition: YAXisOption) {
        const yAxis = arrableToArray(options.yAxis) || [];

        let yAxisIndex = 0;
        const existingAxis = yAxis.find(y => y?.name === axisDefinition.name);

        if (!existingAxis) {
            yAxisIndex = yAxis.length;
            const newAxis = { ...axisDefinition };
            newAxis.offset = (yAxisIndex - 1) * 60;
            yAxis.push(newAxis);
            options.yAxis = yAxis as any;
        }
        else {
            yAxisIndex = yAxis.indexOf(existingAxis);
        }

        return yAxisIndex
    }
}

abstract class RangeChartCreator extends ProgressionExtension {
    abstract getChartLabel(): string;

    getLabel(): string {
        return `Min Max ${this.getChartLabel()} Range`
    }

    createRangeChart(yAxisIndex: number, options: ECOption, minData: (string | number)[][], maxData: (string | number)[][]) {
        options.series.push({
            color: this.getColor(),
            type: 'line',
            name: `Min ${this.getChartLabel()}`,
            data: minData,
            yAxisIndex,
            symbol: 'none',
        })
        options.series.push({
            color: this.getColor(),
            type: 'line',
            name: `Max ${this.getChartLabel()}`,
            data: maxData,
            yAxisIndex,
            symbol: 'none',
        })
    }
}

class MinMaxValuesExtension extends RangeChartCreator {
    getColor(): string {
        return "#75F3DC"
    }

    getChartLabel(): string {
        return "Value";
    }

    addExtensionCharts(options: ECOption, pivotTable: { [forecastDate: string]: { value: number, date: string }[] }): void {
        const minData = Object.keys(pivotTable).map(forecast => [forecast, min(pivotTable[forecast].map(f => f.value)) || -1]);
        const maxData = Object.keys(pivotTable).map(forecast => [forecast, max(pivotTable[forecast].map(f => f.value)) || -1]);
        this.createRangeChart(0, options, minData, maxData)
    }
}

class MinMaxDatesExtension extends RangeChartCreator {
    getColor(): string {
        return "#CFC7F1"
    }

    getChartLabel(): string {
        return "Date";
    }

    addExtensionCharts(options: ECOption, pivotTable: { [forecastDate: string]: { value: number, date: string }[] }): void {
        const minData = Object.keys(pivotTable).map(forecast => [forecast, min(pivotTable[forecast].map(f => f.date)) || -1]);
        const maxData = Object.keys(pivotTable).map(forecast => [forecast, max(pivotTable[forecast].map(f => f.date)) || -1]);

        const datesYAxis: YAXisOption = {
            type: 'time',
            name: 'Dates',
            position: 'right',
            alignTicks: true,
            offset: 0,
            boundaryGap: ['10%', '10%'],
            axisLine: { show: true, },
            axisLabel: {
                formatter: '{dd} {MMM}',
            }
        };
        const yAxisIndex = this.createYAxis(options, datesYAxis);

        this.createRangeChart(yAxisIndex, options, minData, maxData)
    }
}

abstract class CountsChartCreator extends ProgressionExtension {
    createCountsChart(options: ECOption, data: (string | number)[][]) {
        const countsYAxis: YAXisOption = {
            type: 'value',
            name: 'Count',
            position: 'right',
            axisLine: { show: true, },
            alignTicks: true,
            offset: 0
        };

        let yAxisIndex = this.createYAxis(options, countsYAxis);

        options.series.push({
            color: this.getColor(),
            type: 'line',
            symbol: 'none',
            name: this.getLabel(),
            data,
            yAxisIndex,
        })
    }
}

class CountsWithZerosExtension extends CountsChartCreator {
    getColor(): string {
        return "#F38B75";
    }

    getLabel(): string {
        return "Forecast Counts (Include zero values)";
    }

    addExtensionCharts(options: ECOption, pivotTable: { [forecastDate: string]: { value: number; date: string; }[]; }): void {
        const data = Object.keys(pivotTable).map(forecast => [forecast, pivotTable[forecast].length]);
        this.createCountsChart(options, data)
    }

}

class CountsIgnoreZerosExtension extends CountsChartCreator {
    getColor(): string {
        return "#C1F375";
    }

    getLabel(): string {
        return "Forecast Counts (Ignore zero valus)";
    }

    addExtensionCharts(options: ECOption, pivotTable: { [forecastDate: string]: { value: number; date: string; }[]; }): void {
        const data = Object.keys(pivotTable).map(forecast => [forecast, pivotTable[forecast].filter(v => v.value).length]);
        this.createCountsChart(options, data)
    }

}

class StandardDeviationExtension extends ProgressionExtension {
    getColor(): string {
        return "#F04747";
    }

    getLabel(): string {
        return "Standard Deviations";
    }

    getStandardDeviation(arr: number[]) {
        const n = arr.length
        const mean = arr.reduce((a, b) => a + b) / n
        const result = Math.sqrt(arr.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
        return round(result, 3);
    }

    addExtensionCharts(options: ECOption, pivotTable: { [forecastDate: string]: { value: number; date: string; }[]; }): void {
        const data = Object.keys(pivotTable).map(forecast => [forecast, this.getStandardDeviation(pivotTable[forecast].map(v => v.value))]);

        const datesYAxis: YAXisOption = {
            type: 'value',
            name: 'StandardDeviation',
            position: 'right',
            alignTicks: true,
            offset: 0,
            axisLine: { show: true, },
        };
        const yAxisIndex = this.createYAxis(options, datesYAxis);

        options.series.push({
            color: this.getColor(),
            type: 'line',
            symbol: 'none',
            name: this.getLabel(),
            data,
            yAxisIndex,
        })
    }

}

export const ProgressionExtensions: { [key: string]: ProgressionExtension } = {
    standardDeviations: new StandardDeviationExtension(),
    minMaxValues: new MinMaxValuesExtension(),
    minMaxDates: new MinMaxDatesExtension(),
    countsWithZeros: new CountsWithZerosExtension(),
    countsIgnoreZeros: new CountsIgnoreZerosExtension(),
}