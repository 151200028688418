import { Route, Redirect, RouteProps } from 'react-router-dom';
import { IAuthState } from '../store/reducers/authReducer';
import { connect } from 'react-redux';
import { GlobalStates } from '../store/store';
import { ReportView } from './ReportBase';
import { endpointNames } from '../utils/staticInfo';

interface IProps {
    auth: IAuthState,
    component?: ReportView | RouteProps["component"]
}

class PrivateRoute extends Route<IProps>  {
    render() {
        const { component, ...rest } = this.props;

        if (this.props.auth.isLoggedIn)
            return (
                <Route {...rest} component={component} />
            );

        return (
            <Route {...rest} render={(routeProps) => (
                <Redirect to={{
                    pathname: endpointNames.login,
                    state: { from: routeProps.location }
                }} />
            )} />
        )
    }
};

const mapStateToProps = (state: GlobalStates) => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps)(PrivateRoute);