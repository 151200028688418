import React from 'react';
import { makeTitle } from "../../../utils/common";
import { Button, FormControl, Grid, Icon, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { IWorkflowTasksResponse } from '../../../apis/vitusApiTypes';
import { CSSProperties } from "react";
import VSelect from '../../../components/VSelect/VSelect';

import { isEmpty } from 'lodash';
const filterContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 20,
};

const allColumns = [
    "taskName", "status", "lastSuccessDate",
    "startDate", "endDate", "nextRunDate",
    "preConditionTaskList", "preConditionMinute",
    "nextTaskList", "cronSchedule", "startScript",
    "script_server", "retryCount", "isActive"
]

interface IState {
    tasks: IWorkflowTasksResponse["success"]["tasks"],
    searchText: string,
    visibleColumns: string[],
}

interface IProps {
    callGetWorkflowTable: () => void,
    tasks?: IState["tasks"],
}

class WorkflowTable extends React.Component<IProps, IState> {
    state = {
        tasks: [] as IState["tasks"],
        searchText: "",
        visibleColumns: allColumns,
    }

    componentDidMount() {
        if (this.props.tasks)
            this.setState({ tasks: this.props.tasks });
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.tasks !== this.props.tasks)
            this.setState({ tasks: this.props.tasks ?? [] as IState["tasks"] });
    }

    checkIfMatchesSearch(taskDict: IState['tasks'][0]) {
        const searchText = this.state.searchText.toUpperCase();

        const stringMatches = (value: any) => {
            if (!value)
                return false;

            return value.toString().toUpperCase().indexOf(searchText) >= 0;
        }

        if (this.state.visibleColumns.find(col => stringMatches(taskDict[col as keyof IState['tasks'][0]])))
            return true;

        return false;
    }

    render() {
        const textStyles: CSSProperties = { fontSize: 12, whiteSpace: 'nowrap' };

        return (
            <>
                <Grid container spacing={1} justify='flex-start' alignItems='center'>
                    <Grid item style={filterContainerStyle}>
                        <FormControl >
                            <TextField
                                value={this.state.searchText}
                                variant="outlined"
                                type="text"
                                onChange={(newValue) => this.setState({ searchText: newValue.target.value })}
                                margin="dense"
                                InputProps={{
                                    style: { padding: 0 },
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item style={filterContainerStyle}>
                        <Typography variant='button' style={{ paddingRight: 10 }}>
                            Visible Columns:
                        </Typography>
                        <VSelect
                            style={{ whiteSpace: 'nowrap', minWidth: 300 }}
                            multiple
                            value={this.state.visibleColumns}
                            title='Visible Columns'
                            options={allColumns}
                            getOptionLabel={(option) => `${option}`}
                            onChange={(newValue) => this.setState({ visibleColumns: isEmpty(newValue) ? allColumns : newValue as string[] })}
                        />
                    </Grid>
                    <Grid item style={filterContainerStyle}>
                        <Typography>
                            <Button
                                onClick={() => this.props.callGetWorkflowTable()}>
                                <Icon style={{ marginRight: 5 }} className="fas fa-sync-alt" />
                                Refresh
                            </Button>
                        </Typography>
                    </Grid>

                </Grid>
                <Grid container justify="flex-start">
                    <Grid item xs={12}>

                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {
                                            this.state.visibleColumns.map(col => (
                                                <TableCell key={`head_${col}`} style={{ ...textStyles, width: 100 }}>
                                                    {makeTitle(col)}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.tasks.filter(rec => (!this.state.searchText || this.checkIfMatchesSearch(rec)))
                                            .map(record => (
                                                <TableRow key={`row_${record.taskName}`}>

                                                    {
                                                        this.state.visibleColumns.map(col => (
                                                            <TableCell key={`cell_${record.taskName}_${col}`} style={{ ...textStyles, }}>
                                                                <Typography>
                                                                    {record[col as keyof IState['tasks'][0]] || '-'}
                                                                </Typography>
                                                            </TableCell>
                                                        ))
                                                    }

                                                </TableRow>
                                            ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default WorkflowTable;
