import React from 'react';
import {
    Card, CardHeader, Checkbox,
    Divider, Grid, Icon,
    IconButton, List, ListItem,
    ListItemIcon, ListItemText, makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { arrayIntersection, arrayNot, arrayUnion } from '../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

type Props = {
    style?: { [key: string]: any },
    leftTitle?: string,
    rightTitle?: string,
    leftItems: any[],
    rightItems: any[],
    getItemLabel?: (item: any) => string,
    listItemComponent?: (item: any) => React.ReactNode,
    onChange?: (leftItems: any[], rightItems: any[]) => void,
}

const VTransferList: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const [checked, setChecked] = React.useState<any[]>([]);

    const [left, setLeft] = React.useState(props.leftItems);
    const [right, setRight] = React.useState(props.rightItems);
    React.useEffect(() => { setLeft(props.leftItems) }, [props.leftItems]);
    React.useEffect(() => { setRight(props.rightItems) }, [props.rightItems]);

    const leftChecked = arrayIntersection(checked, left);
    const rightChecked = arrayIntersection(checked, right);

    const handleToggle = (value: any) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: typeof checked) => arrayIntersection(checked, items).length;

    const handleToggleAll = (items: typeof checked) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(arrayNot(checked, items));
        } else {
            setChecked(arrayUnion(checked, items));
        }
    };

    const handleCheckedRight = () => {
        const newLeft = arrayNot(left, leftChecked);
        const newRight = right.concat(leftChecked);

        setRight(newRight);
        setLeft(newLeft);

        setChecked(arrayNot(checked, leftChecked));

        if (props.onChange)
            props.onChange(newLeft, newRight)
    };

    const handleCheckedLeft = () => {
        const newLeft = left.concat(rightChecked);
        const newRight = arrayNot(right, rightChecked);

        setLeft(newLeft);
        setRight(newRight);

        setChecked(arrayNot(checked, rightChecked));

        if (props.onChange)
            props.onChange(newLeft, newRight)
    };

    const customList = (items: any[], title?: string) => (
        <Card style={props.style}>
            {
                title &&
                <React.Fragment>
                    <CardHeader
                        className={classes.cardHeader}
                        avatar={
                            <Checkbox
                                onClick={handleToggleAll(items)}
                                checked={numberOfChecked(items) === items.length && items.length !== 0}
                                indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                                disabled={items.length === 0}
                            />
                        }
                        title={<Typography style={{ fontWeight: 500 }}>{title}</Typography>}
                        subheader={`${numberOfChecked(items)}/${items.length} selected`}
                    />
                    <Divider style={{ margin: "10px 0" }} />
                </React.Fragment>
            }

            <List className={classes.list} dense component="div" role="list">
                {items.map((item, idx) => {
                    return (
                        <ListItem key={`${title}_${idx}`} role="listitem" button onClick={handleToggle(item)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            {
                                props.listItemComponent ?
                                    props.listItemComponent(item)
                                    :
                                    <ListItemText primary={props.getItemLabel ? props.getItemLabel(item) : ""} />
                            }
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    )

    return (
        <Grid container
            justify="center"
            alignItems="center"
            className={classes.root}>
            <Grid item xs={5}>{customList(left, props.leftTitle)}</Grid>
            <Grid item xs={1}>
                <Grid container direction="column" alignItems="center" style={{ padding: 10 }}>
                    <IconButton
                        style={{ border: "1px solid rgba(224, 224, 224, 1)", borderRadius: 0 }}
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}>
                        <Icon className="fas fa-angle-left" />
                    </IconButton>
                    <IconButton
                        style={{ border: "1px solid rgba(224, 224, 224, 1)", borderRadius: 0 }}
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}>
                        <Icon className="fas fa-angle-right" />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={5}>{customList(right, props.rightTitle)}</Grid>
        </Grid>
    )
}

VTransferList.defaultProps = {
    getItemLabel: (item: string) => item,
}

export default VTransferList;