import { Typography, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import VSelect from "../VSelect/VSelect";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconButton: {
            padding: "0 5px 0 0",
        },
        dataLabel: {
            display: "inline-block",
            width: 80,
            fontSize: 12,
            fontWeight: 500,
        },
        dataValueGroup: {
            display: "inline-block",
            fontSize: 12,
            "& > *": {
                marginLeft: theme.spacing(1),
            },
        },
        dataRow: {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
    }),
);

interface IProps {
    columns: { name: string, [key: string]: any }[],
    selectedColumns: { name: string, [key: string]: any }[],
    onAdd?: (currentSelected: { [key: string]: any }) => void,
}

const VDatasetColumnSelector: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    const [selectedColumn, setSelectedColumn] = React.useState<{} | null>(null);

    return (
        <div className={classes.dataRow}>
            <Typography variant="subtitle1" className={classes.dataLabel}>
                Column
            </Typography>
            <div className={classes.dataValueGroup}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <VSelect
                        title="Column to add"
                        options={props.columns}
                        getOptionLabel={(option) => option ? option.name : ""}
                        getOptionDisabled={(option) => !!props.selectedColumns.find(f => f.name === option.name) || (selectedColumn as any)?.name === option.name}
                        value={selectedColumn}
                        onChange={(newValue) => {
                            setSelectedColumn(newValue as any)

                            if (newValue && props.onAdd)
                                props.onAdd(newValue as any);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}



export default VDatasetColumnSelector;