import ReactECharts from 'echarts-for-react';
import { Dataset } from "../../../utils/types";
import { isEmpty } from "lodash";
import * as echarts from 'echarts/core';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { DatasetRow } from './BaseChart';
import { HeatmapChart, HeatmapSeriesOption } from 'echarts/charts';
import BaseTabluarChart from './BaseTabluarChart';

type ECOption = echarts.ComposeOption<GridComponentOption | HeatmapSeriesOption>

echarts.use(
    [GridComponent, HeatmapChart]
);

class CustomHeatmapChart extends BaseTabluarChart {
    chartRef: ReactECharts | null = null;
    chartOptions: ECOption = {};

    static specifyDatapartName(datasetName: string, chartGroup?: string[], firstRow?: { [key: string]: any }) {
        let datapartName = datasetName;

        if (chartGroup && firstRow && !isEmpty(chartGroup) && !isEmpty(firstRow)) {
            datapartName = `${datapartName}|${chartGroup.map(c => firstRow[c]).join('|')}`;
        }

        return datapartName;
    }

    setChartOptions(all_datasets: Dataset[], all_data?: { [key: string]: { data: DatasetRow[], index_column_values: string[] } }) {
        const chartInstance = this.chartRef?.getEchartsInstance();
        chartInstance.showLoading();

        const table = this.prepareTableData(all_datasets, all_data)

        if (!table || !table?.data)
            return;

        const series = [{
            type: 'heatmap',
            data: table.data.flatMap(row => table.columnKeys.map(key => [key, row[table.xAxisName], row[key]])),
            label: {
                show: true
            },
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            },
            itemStyle: {
                borderWidth: 0,
                borderColor: '#fff',
            }
        }]

        const chartOptions = {
            series: series,
            tooltip: {
                position: 'top',
            },
            grid: {
                left: 65,
                right: 70,
                bottom: 60,
                top: 45,
            },
            xAxis: {
                data: table.columnKeys,
                type: 'category',
                position: 'top',
                axisTick: {
                    interval: 0
                },
                splitArea: {
                    show: true
                },
                axisLabel: {
                    rotate: -45
                }
            },
            yAxis: {
                data: table.data.map(d => d[table.xAxisName]),
                type: 'category',
                splitArea: {
                    show: true
                },
                axisTick: {
                    interval: 0
                },
                axisLabel: {
                    rotate: 45
                },
            },
            visualMap: {
                min: table.minValue,
                max: table.maxValue,
                calculable: true,
                dimension: 2,
                orient: 'horizontal',
                itemWidth: 10,
                itemHeight: 70,
                left: 'right',
                bottom: 0,
                inRange: {
                    color: [
                        '#ff0a0a', //red
                        '#ff8989', //light red
                        '#f66', //lighter red
                        '#f2ce02', //orange
                        '#ebff0a', //yellow
                        '#c2f296', //lighter green
                        '#85e62c', //light green
                        '#209c05' //green
                    ]
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    id: 'insideY',
                    yAxisIndex: 0,
                    zoomLock: true,
                    startValue: table.data.length - 15,
                    endValue: table.data.length,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true,
                    brushSelect: false,
                },
                {
                    type: 'slider',
                    yAxisIndex: 0,
                    zoomLock: true,
                    width: 10,
                    right: 10,
                    startValue: table.data.length - 15,
                    endValue: table.data.length,
                    handleSize: 0,
                    showDetail: false,
                    brushSelect: false,
                }
            ],
        };

        chartInstance.clear();
        chartInstance.setOption(this.addApacheEchartsToolbox(chartOptions))

        chartInstance.hideLoading();
    }

    render() {
        return (
            <ReactECharts
                ref={(e) => { this.chartRef = e; }}
                echarts={echarts}
                option={this.chartOptions}
                notMerge={true}
                lazyUpdate={true}
                style={{ width: "auto", ...this.props.style }}
            />
        );
    }
}

export default CustomHeatmapChart;