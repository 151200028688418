import { store } from '../store/store';
import { ShowSpinnerAction, HideSpinnerAction } from '../store/actions';
import { generateUuid } from './common';

export type Spinner = { hide: () => void };

export const createSpinner = (): Spinner => {
    const caller = generateUuid();

    store.dispatch<ShowSpinnerAction>({ type: 'SHOW_SPINNER', payload: { caller } });

    return {
        hide: () => {
            store.dispatch<HideSpinnerAction>({ type: 'HIDE_SPINNER', payload: { caller } });
        }
    };
};
