import React from 'react';
import history from './history';
import { Router, Route, Switch } from 'react-router-dom';
import { IAuthState } from '../store/reducers/authReducer';
import { connect } from 'react-redux';
import Login from '../views/Login/Login';
import { getUser } from '../apis/vitusApi';
import VSpinner from '../components/VSpinner/VSpinner';
import { GlobalStates } from '../store/store';
import AuthManager from './AuthManager';
import NotFound from '../views/NotFound/NotFound';
import Main from '../views/Main/Main';
import VSideBar from '../components/VSideBar/VSideBar';
import { isEmpty } from 'lodash';
import PrivateRoute from './PrivateRoute';
import { ReportParams, ReportView } from './ReportBase';
import { endpointNames } from '../utils/staticInfo';

interface IProps {
    auth: IAuthState
}

class VRouter extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);

        if (!this.props.auth || !this.props.auth.isLoggedIn) {
            getUser();
        }
    }

    reports?: typeof ReportView[];
    getAvaliableReports() {
        if (!this.reports || isEmpty(this.reports)) {
            const reports = AuthManager.getReports();

            this.reports = this.props.auth.reports?.filter(key => {
                if (!reports[key]) {
                    console.log('Unknown report:', key);
                    return false;
                }

                return true;
            })?.map(key => reports[key]) || [];
        }

        return this.reports;
    }

    getFavouriteReports() {
        return this.props.auth.favouriteReports;
    }

    getOwnedCustomReports() {
        if (this.props.auth.ownedCustomReports)
            return Object.keys(this.props.auth.ownedCustomReports).map(k => this.props.auth.ownedCustomReports?.[k]) as ReportParams[];
    }

    getCustomReports() {
        if (this.props.auth.customReports)
            return Object.keys(this.props.auth.customReports).map(k => this.props.auth.customReports?.[k]) as ReportParams[];
    }

    getPrivatePaths() {
        const reports = this.getAvaliableReports();

        return reports?.map(report => {
            const params = report.params;

            const paths = [params.path];

            if (params.cleanPath)
                paths.push(params.cleanPath);

            return (
                <PrivateRoute
                    exact
                    key={params.path}
                    path={paths}
                    component={report} />
            );
        });
    }

    render() {
        if (!this.props.auth.userChecked)
            return <VSpinner show />;

        return (
            <Router history={history}>
                <VSideBar
                    isLoggedIn={this.props.auth.isLoggedIn}
                    reports={this.getAvaliableReports()}
                    favouriteReports={this.getFavouriteReports()}
                    customReports={this.getCustomReports()}
                    ownedCustomReports={this.getOwnedCustomReports()}
                    content={
                        <Switch>
                            <Route path={endpointNames.login} exact component={Login} />
                            <PrivateRoute path="/" exact component={Main} />

                            {this.getPrivatePaths()}

                            <Route path="" exact component={NotFound} />
                        </Switch>
                    }
                />
            </Router>
        );
    }
}

const mapStateToProps = (state: GlobalStates) => {
    return {
        auth: state.auth
    };
};


export default connect(mapStateToProps)(VRouter);