import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { styles } from '../../utils/styles';
import ReactECharts from 'echarts-for-react';
import { isEmpty } from 'lodash';
import { createExcel } from '../../utils/common';

export type TableData = {
    columns: string[],
    rows: { [key: string]: any }[],
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            ...styles.borderedContainer
        }
    }),
);

const addApacheEchartsToolbox = (
    options: { [key: string]: any },
    downloadedFileName: string,
    getTableData?: () => TableData,
    hiddenTools?: { dataView?: boolean, downloadExcel?: boolean, saveAsImage?: boolean }
) => {
    const tableData = getTableData?.();
    const newOptions = options;

    newOptions.toolbox = {
        show: true,
        itemSize: 20,
        z: 1000,
        feature: {
            dataView: {
                show: !isEmpty(tableData) && !hiddenTools?.dataView,
                title: 'Show as Table',
                readOnly: true,
                optionToContent: () => {
                    const table = `
                        <table style="width:auto;text-align:left;border:1px solid rgba(224, 224, 224, 1)">
                            <thead>
                                <tr>
                                    ${tableData?.columns.map(col => `<th style="border:1px solid rgba(224, 224, 224, 1)">${col}</th>`)}
                                </tr>
                            </thead>
                            <tbody>
                                ${tableData?.rows.map(row => `<tr>${tableData?.columns.map(col => `<td style="border:1px solid rgba(224, 224, 224, 1)">${row[col] || ""}</td>`)}</tr>`)}
                            </tbody>
                        </table>
                        `;

                    return table;
                }
            },
            saveAsImage: {
                show: !hiddenTools?.saveAsImage,
                title: 'Save as Image',
                name: downloadedFileName,
                icon: "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86-3 3.87L9 13.14 6 17h12l-3.86-5.14z",
            },
        },
    }


    if (!isEmpty(tableData) && !hiddenTools?.downloadExcel)
        newOptions.toolbox.feature["myDownloadExcel"] = {
            show: !isEmpty(tableData) && !hiddenTools?.downloadExcel,
            title: 'Download Excel',
            icon: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zM211.7 308l50.5-81.8c4.8-8-.9-18.2-10.3-18.2h-4.1c-4.1 0-7.9 2.1-10.1 5.5-31 48.5-36.4 53.5-45.7 74.5-17.2-32.2-8.4-16-45.8-74.5-2.2-3.4-6-5.5-10.1-5.5H132c-9.4 0-15.1 10.3-10.2 18.2L173 308l-59.1 89.5c-5.1 8 .6 18.5 10.1 18.5h3.5c4.1 0 7.9-2.1 10.1-5.5 37.2-58 45.3-62.5 54.4-82.5 31.5 56.7 44.3 67.2 54.4 82.6 2.2 3.4 6 5.4 10 5.4h3.6c9.5 0 15.2-10.4 10.1-18.4L211.7 308z",
            onclick: () => {
                createExcel(
                    downloadedFileName,
                    tableData?.columns,
                    tableData?.rows?.map(row => (tableData?.columns || []).map(col => row[col]))
                )
            }
        }

    return newOptions;
}

export const DefaultChartStyle: React.CSSProperties = {
    height: 600,
    width: '%100'
}

interface IProps {
    report: React.ReactElement<ReactECharts | null>,
    children?: React.ReactNode,
    height?: number | string,
    width?: number | string,
    titleIcon?: React.ReactElement,
    title?: string,
    description?: string,
    toolbox?: {
        chartRef: ReactECharts | null,
        downloadedFileName?: string,
        getTableData?: () => TableData,
        hiddenTools?: { dataView?: boolean, downloadExcel?: boolean, saveAsImage?: boolean },
    },
}

const VChart: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();
    const style = { ...DefaultChartStyle };

    if (props.height)
        style.height = props.height;
    if (props.width)
        style.width = props.width;

    React.useEffect(() => {
        if (props.toolbox) {
            const chartInstance = props.toolbox.chartRef?.getEchartsInstance();

            if (chartInstance) {
                const orgOptions = chartInstance.getOption();
                chartInstance.clear();
                chartInstance.setOption(addApacheEchartsToolbox(
                    orgOptions,
                    props.toolbox.downloadedFileName || props.title || "VitusReport",
                    props.toolbox.getTableData,
                    props.toolbox.hiddenTools
                ));
            }
        }
    }, [props.toolbox, props.title, props.toolbox?.chartRef]);

    return (
        <Grid container
            justify="flex-start"
            alignItems="flex-start"
            direction="row"
            style={style}
            className={classes.container}>
            <Grid item xs={12}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                {props.titleIcon && props.titleIcon}
                {props.title && <Typography variant="h6" style={{ margin: 5 }}>{props.title}</Typography>}
            </Grid>
            <Grid item xs={12}>
                {props.report}
            </Grid>
            {
                props.description &&
                <Grid item xs={12} style={{ padding: "0 10px" }}>
                    <Typography variant="caption" >{props.description}</Typography>
                </Grid>
            }
        </Grid>
    );
}

export default VChart;