import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { GaugeChart, GaugeSeriesOption } from 'echarts/charts';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { getCumulativeGasDemand } from '../../../apis/vitusApi';
import { Grid } from '@material-ui/core';
import { createSpinner } from '../../../utils/spinnerManager';
import { CumulativeGasDemandSeason, ICumulativeGasDemandResponse } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { handleApiError, makeTitle, shortenNumber } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import { ITabProps } from './gasBalanceUtils';

type ECOption = echarts.ComposeOption<GridComponentOption | BarSeriesOption | GaugeSeriesOption>;

echarts.use(
    [BarChart, GaugeChart, GridComponent]
);

interface IProps {
    season: IChartCellProps["season"],
}

interface IState {
    countriesData: CumulativeGasDemandSeason["countries"],
}

class EuropeGasDemandSeason extends React.Component<ITabProps & IProps, IState> {
    state: IState = {
        countriesData: [],
    }

    componentDidMount() {
        if (!this.props.data)
            this.getData();
        else
            this.storeData(this.props.data);
    }

    getData() {
        const spinner = createSpinner();

        getCumulativeGasDemand().then(response => {
            if (response.data.success) {
                this.storeData(response.data.success);
                this.props.onDataChanged(response.data.success);
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    storeData(response: ICumulativeGasDemandResponse["success"]) {
        this.setState({
            countriesData: response.seasons[this.props.season].countries || [],
        });
    }

    render() {
        return (
            <Grid container justify="flex-start" alignItems="flex-end">
                {
                    this.state.countriesData.map(country => {
                        const bar = country.total_data.map(row => ({
                            SeasonType: row.SeasonName,
                            Value: row.Rolling,
                        }));

                        return (
                            <ChartCell
                                season={this.props.season}
                                key={`cell_${country.country_name}`}
                                countryName={country.country_name}
                                barData={bar}
                                gaugeData={country.yearly_difference}
                            />
                        );
                    })
                }
            </Grid>
        )
    }
}

interface IChartCellProps {
    season: "injection" | "withdraw",
    countryName: string,
    barData: { SeasonType: string, Value: number }[],
    gaugeData: number,
}

class ChartCell extends React.Component<IChartCellProps, {}> {
    gaugeChartRef: ReactECharts | null = null;
    barChartRef: ReactECharts | null = null;

    gaugeChartOptions: ECOption = {
    };

    barChartOptions: ECOption = {
        animation: false,
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: function (value: number) {
                    return shortenNumber(value);
                },
            }
        },
    };

    componentDidMount() {
        if (this.props.barData)
            this.updateBarChart();

        if (this.props.gaugeData)
            this.updateGaugeChart();
    }

    componentDidUpdate(prevProps: IChartCellProps) {
        if (this.props.barData && prevProps.barData !== this.props.barData)
            this.updateBarChart();

        if (this.props.gaugeData && prevProps.gaugeData !== this.props.gaugeData)
            this.updateGaugeChart();
    }


    updateBarChart = () => {
        const barChartInstance = this.barChartRef?.getEchartsInstance();
        barChartInstance.showLoading();

        const data = this.props.barData;

        barChartInstance.clear();

        barChartInstance.setOption({
            ...this.barChartOptions,
            series: data.map(d => {
                return {
                    data: [[d.SeasonType, d.Value]],
                    label: {
                        show: true,
                        formatter: shortenNumber(d.Value),
                    },
                    type: 'bar',
                    barWidth: 60,
                    barGap: '-100%',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                };
            }),
        });

        barChartInstance.hideLoading();
    }

    updateGaugeChart = () => {
        const gaugelineChartInstance = this.gaugeChartRef?.getEchartsInstance();
        gaugelineChartInstance.showLoading();

        const value = this.props.gaugeData;

        gaugelineChartInstance.clear();

        const series: ECOption["series"] = [
            {
                type: 'gauge',
                axisTick: {
                    show: false
                },
                progress: {
                    show: true,
                },
                detail: {
                    show: value <= 100,
                    offsetCenter: [0, 0]
                },
                pointer: {
                    show: false,
                },
                data: [
                    {
                        value: value > 100 ? 100 : value
                    }
                ]
            }
        ];

        if (value > 100) {
            series.splice(0, 0, {
                type: 'gauge',
                max: value,
                endAngle: -80,
                axisLine: {
                    lineStyle: {
                        color: [[1, 'red']],
                    }
                },
                axisTick: {
                    show: false,
                },
                pointer: {
                    show: false,
                },
                splitNumber: 1,
                detail: {
                    show: true,
                    offsetCenter: [0, 0],
                    color: 'red',
                },
                data: [
                    {
                        value: value
                    }
                ]
            })
        }

        gaugelineChartInstance.setOption({
            ...this.gaugeChartOptions,
            series: series,
        });

        gaugelineChartInstance.hideLoading();
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={4} >
                    <VChart
                        title={!this.props.countryName ? "" : `${this.props.countryName} ${makeTitle(this.props.season)} Season Comparison (cm)`}
                        height={350}
                        report={
                            <ReactECharts
                                ref={(e) => { this.barChartRef = e; }}
                                echarts={echarts}
                                option={this.barChartOptions}
                                notMerge={true}
                                lazyUpdate={true}
                                style={{ width: '99%' }}
                            />
                        } >
                    </VChart>
                </Grid>
                <Grid item xs={2} >
                    <VChart
                        title={!this.props.countryName ? "" : `${this.props.countryName} Current Year vs Last Year`}
                        height={350}
                        report={
                            <ReactECharts
                                ref={(e) => { this.gaugeChartRef = e; }}
                                echarts={echarts}
                                option={this.gaugeChartOptions}
                                notMerge={true}
                                lazyUpdate={true}
                                style={{ width: '99%' }}
                            />
                        } >
                    </VChart>
                </Grid>
            </React.Fragment>
        )
    }
}

export default EuropeGasDemandSeason;
