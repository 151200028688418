import { store } from '../store/store';
import CryptoJS from 'crypto-js';

export function canEditReport(reportId?: number, reportName?: string) {
    const authState = store.getState().auth;

    if (!reportId)
        return true;

    if (reportName
        && authState.ownedCustomReports
        && Object.keys(authState.ownedCustomReports).find(k => reportName === authState.ownedCustomReports?.[k].name))
        return true;

    return false;
}

export function hashPass(pass: string){
    const hash = CryptoJS.HmacSHA256(pass, process.env.REACT_APP_MILH!);
    return CryptoJS.enc.Base64.stringify(hash);
};
