import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { IReportInfo } from '../../store/reducers/reportInfoReducer';
import { GlobalStates } from '../../store/store';
import VThumbnail from '../../components/VThumbnail/VThumbnail';
import { IAuthState } from '../../store/reducers/authReducer';
import AuthManager from '../../system/AuthManager';
import { isEmpty } from 'lodash';
import { ReportParams } from '../../system/ReportBase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerContainer: {
            border: "1px solid rgba(224, 224, 224, 1)",
            width: "100%",
            textAlign: "left"
        },
    }),
);

interface IProps {
    recentReports: IReportInfo["recentReports"],
    favouriteReports: IAuthState["favouriteReports"],
    customReports: IAuthState["customReports"],
    ownedCustomReports: IAuthState["ownedCustomReports"],
}

const Main: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    const recentReports: IProps["recentReports"] | null = props.recentReports;

    const favouriteReports: IProps["recentReports"] | null = isEmpty(props.favouriteReports) ?
        null
        :
        Object.keys(props.favouriteReports || {})
            .filter(k => AuthManager.reports[k] || props.ownedCustomReports?.[k] || props.customReports?.[k])
            .map(reportKey => {
                let reportParams: ReportParams;

                if (props.ownedCustomReports?.[reportKey])
                    reportParams = props.ownedCustomReports?.[reportKey];
                else if (props.customReports?.[reportKey])
                    reportParams = props.customReports?.[reportKey];
                else
                    reportParams = AuthManager.reports[reportKey].params;

                return { ...reportParams, userName: "" };
            });

    const getThumbnailsRow = (reportsList: IProps["recentReports"], title: string, index: number) => {
        if (!reportsList)
            return null;

        return (
            <Grid container justify="space-evenly" key={`reportRow${title}${index}`}>
                {
                    reportsList.map(report => {
                        return (
                            <Grid item xs={2} key={`thumb_${report.name}`} >
                                <VThumbnail
                                    thumbnail={report.thumbnail}
                                    title={report.name}
                                    path={report.cleanPath || report.path} 
                                    shortName={report.shortName}/>
                            </Grid>
                        );
                    })
                }
                {
                    Array.from(Array(5 - reportsList.length).keys()).map(r => {
                        return (
                            <Grid item xs={2} key={`emptyCell${r}`}>
                            </Grid>
                        )
                    })
                }
            </Grid>
        );
    }

    const getThumbnailsBox = (title: string, reportsList: IProps["recentReports"] | null, rowCount: number = 1) => {
        let visibleRowCount = rowCount;

        if (rowCount < 1)
            visibleRowCount = Math.ceil((reportsList?.length || 0) / 5)

        return (
            <fieldset className={classes.outerContainer}>
                <legend>
                    <Typography gutterBottom variant="h6" style={{ margin: "20" }}>
                        {title}
                    </Typography>
                </legend>
                {reportsList ?
                    Array.from(Array(visibleRowCount).keys()).map((r, idx) => getThumbnailsRow(reportsList.slice(r * 5, (r + 1) * 5), title, idx))
                    :
                    <Grid container justify="space-evenly">
                        <Typography >
                            There are not any recent reports.
                        </Typography>
                    </Grid>
                }
            </fieldset>
        );
    };

    if (favouriteReports)
        return (
            <Grid container>
                {!isEmpty(recentReports) && getThumbnailsBox("Recent Reports", recentReports)}
                {!isEmpty(recentReports) && <Grid style={{ margin: "10px" }}></Grid>}
                {getThumbnailsBox("Favourites", favouriteReports, 0)}
            </Grid>
        );

    return (
        <Grid container>
            {getThumbnailsBox("Recent Reports", recentReports, 2)}
        </Grid>
    );
};

const mapStateToProps = ({ reportInfo, auth }: GlobalStates) => {
    return {
        recentReports: reportInfo.recentReports,
        favouriteReports: auth.favouriteReports,
        customReports: auth.customReports,
        ownedCustomReports: auth.ownedCustomReports,
    };
};

export default connect(mapStateToProps)(Main);
