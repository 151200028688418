import { isEmpty, groupBy, min, max } from "lodash";
import { Dataset } from "../../../utils/types";
import BaseChart, { DatasetRow } from "./BaseChart";


abstract class BaseTabluarChart<P = {}, S = {}> extends BaseChart<P, S> {
    prepareTableData(all_datasets: Dataset[], all_data?: { [key: string]: { data: DatasetRow[], index_column_values: string[] } }) {
        if (!all_data)
            return;

        const dataset = all_datasets?.[0];

        if (isEmpty(dataset))
            return;

        const [xAxisName, yAxisName] = [dataset.x_axis ?? "", dataset.y_axis ?? ""]

        let data: DatasetRow[] = all_data[dataset.alias]?.data
        const all_values = data.map(row => row[yAxisName]);

        const minValue = min(all_values);
        const maxValue = max(all_values);

        let columnKeys = [yAxisName];

        if (dataset.chart_groups?.length) {
            const groupedData = groupBy(data, item => dataset.chart_groups?.map(grKey => item[grKey]).join('|'));

            columnKeys = Object.keys(groupedData);

            const dataDict: { [key: string]: { [key: string]: any } } = {};

            Object.keys(groupedData).forEach(group_key => {
                groupedData[group_key].forEach(row => {
                    if (!dataDict[row[xAxisName]])
                        dataDict[row[xAxisName]] = {};

                    dataDict[row[xAxisName]][group_key] = row[yAxisName];
                })
            })

            data = Object.keys(dataDict).map(row_key => { return { [xAxisName]: row_key, ...dataDict[row_key] } });
        }

        this.setTableData([xAxisName, ...columnKeys], data);

        return { data, columnKeys, xAxisName, minValue, maxValue };
    }
}

export default BaseTabluarChart;