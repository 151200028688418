import { Button, Grid, GridTypeMap, Typography, Tab, Tabs, Tooltip, IconButton, Icon } from '@material-ui/core';
import { getPriceSupplyDemandOptions, getEtlPriceSupplyDemandData } from '../../../apis/vitusApi';
import { IEtlPriceSupplyDemandResponse, IPriceSupplyDemandOptionsResponse, PriceSupplyDemandDataRow } from '../../../apis/vitusApiTypes';
import VBox from '../../../components/VBox/VBox';
import VDatePicker, { VDateTimePicker } from '../../../components/VDatePicker/VDatePicker';
import VPageFilter from '../../../components/VPageFilter/VPageFilter';
import VSelect from '../../../components/VSelect/VSelect';
import { ReportParams, ReportView } from '../../../system/ReportBase';
import AlertManager from '../../../utils/alertManager';
import { createDateObject, createExcelWithMultipleSheets, handleApiError, toLongDateString } from '../../../utils/common';
import messages from '../../../utils/messages';
import { createSpinner } from '../../../utils/spinnerManager';
import ReportViewer from '../../ReportViewer/ReportViewer';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { ScatterChart, ScatterSeriesOption } from 'echarts/charts';
import { inputLabels, PriceType, priceTypes, viewModes, Filter, Options, FilterOptionsDict, Frequency, frequencies } from './psdcTypesAndConstants';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageManager';
import moment from 'moment';
import { sortBy } from 'lodash';
import { TabPanel } from '../../../components/VTabs/VTabs';
import VTable, { TableData } from '../../../components/VTable/VTable';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

type ECOption = echarts.ComposeOption<GridComponentOption | ScatterSeriesOption>

echarts.use(
    [ScatterChart, GridComponent]
);

const sharedScatterOptions = {
    type: 'scatter',
    symbolSize: 7,
    grid: { top: 40, },
}

const filterInputProps: GridTypeMap['props'] = {
    lg: 1, md: 3, sm: 12,
    container: true, justify: "flex-start", alignItems: "flex-start",
};
const filterInputStyle = { style: { minWidth: '14%' } }
const allValueGridProps = { ...filterInputProps, ...filterInputStyle };
const chartGridProps: GridTypeMap['props'] = { md: 6, sm: 12, };

const filterLabelContainerStyle = {
    style: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingRight: 20,
    }
};

const filterLabelStyle = { paddingRight: 10 };

interface IState {
    filterPanelIsOpen: boolean,
    selectedTabIndex: number,
    selectedFilter: Filter,
    activeFilter: Filter,
    selectableOptions?: Options,
    prices?: IEtlPriceSupplyDemandResponse['success']['prices'],
    supplyDemands?: IEtlPriceSupplyDemandResponse['success']['supply_demands'],
    allFilterOptions?: {
        supplyDemandOptions: FilterOptionsDict, // {AreaType: {AreaName: PowerTypes[]}}
        priceOptions: FilterOptionsDict, // {AreaType: {AreaName: Currencies[]}}
    },
    priceTableData?: TableData,
    powerTableData?: TableData,
}

class PriceSupplyDemandComparisonReport extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "PRICE_SUPPLY_DEMAND_COMPARISON",
            name: "Price & Supply-Demand Comparison",
            path: "/psdc",
            thumbnail: ""
        }
    );

    comparisonTimeseriesChartRef: ReactECharts | null = null;
    comparisonTimeseriesChartOptions: ECOption = {};
    comparisonScatterChartRef: ReactECharts | null = null;
    comparisonScatterChartOptions: ECOption = {};

    defaultFilter: Filter = {
        minDate: createDateObject({ dayDiffFromNow: -14, clearTime: true }),
        viewMode: Object.keys(viewModes)[0],
        priceType: PriceType.Price,
        frequency: Frequency.Hourly,
    }
    localStorageFilter = getLocalStorage(PriceSupplyDemandComparisonReport.params.reportKey, "filter", "mainFilter");
    initialFilter: Filter = {
        ...this.defaultFilter,
        ...(this.localStorageFilter || {})
    }

    state: IState = {
        filterPanelIsOpen: true,
        selectedTabIndex: 0,
        selectedFilter: this.initialFilter,
        activeFilter: this.initialFilter,
    }

    componentDidMount() {
        this.getOptions();

        if (this.validateInputs().isValid)
            this.getData()
    }

    getOptions() {
        const spinner = createSpinner();

        getPriceSupplyDemandOptions().then(response => {
            if (response.data.success) {
                const responseData = response.data.success;

                const { priceOptions, supplyDemandOptions } = this.generateOptionsDicts(
                    responseData.price_options,
                    responseData.supply_demand_options
                )

                const selectableOptions = this.getSelectableOptions(this.state.selectedFilter, priceOptions, supplyDemandOptions)

                this.setState({
                    allFilterOptions: { priceOptions, supplyDemandOptions },
                    selectableOptions,
                })
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    generateOptionsDicts(
        priceOptionsArr: IPriceSupplyDemandOptionsResponse['success']['price_options'],
        supplyDemandOptionsArr: IPriceSupplyDemandOptionsResponse['success']['supply_demand_options']
    ) {

        const priceOptions = priceOptionsArr.reduce((map: {
            [key: string]: { [key: string]: string[] }
        }, row) => {
            const previousAreaTypes = map[row.Currency] || {};
            const previousNames = map[row.Currency]?.[row.AreaType] || [];
            map[row.Currency] = { ...previousAreaTypes, [row.AreaType]: [...previousNames, row.AreaName] }
            return map;
        }, {});

        const supplyDemandOptions = supplyDemandOptionsArr.reduce((map: {
            [key: string]: { [key: string]: string[] }
        }, row) => {
            const previousAreaNames = map[row.AreaType] || {};
            const previousPowerTypes = map[row.AreaType]?.[row.AreaName] || [];
            map[row.AreaType] = { ...previousAreaNames, [row.AreaName]: [...previousPowerTypes, row.PowerTypeName] }
            return map;
        }, {});

        return { priceOptions, supplyDemandOptions }
    }

    getFilters() {
        if (!this.state.allFilterOptions || !this.state.selectableOptions)
            return null;

        const options = this.state.selectableOptions;

        return (
            <VPageFilter showHide={true}
                show={this.state.filterPanelIsOpen}
                onShowChanged={(show) => this.setState({ filterPanelIsOpen: show })}
                getActiveFilter={() => {
                    const { minDate, maxDate, ...rest } = this.state.activeFilter;

                    const clearFilters: { [key: string]: any } = {};

                    if (minDate)
                        clearFilters[inputLabels["minDate"]] = toLongDateString(this.state.activeFilter.minDate);

                    if (maxDate)
                        clearFilters[inputLabels["maxDate"]] = toLongDateString(this.state.activeFilter.maxDate);

                    Object.entries(rest).forEach(
                        ([key, value]) => clearFilters[inputLabels[key]] = value
                    );

                    return clearFilters;
                }}>
                <Grid container justify='flex-start'>
                    <VBox title="Dates" style={{
                        width: '100%',
                        whiteSpace: 'nowrap'
                    }}>
                        <Grid container>
                            {this.getSharedFilters()}
                        </Grid>
                    </VBox>

                    <VBox title="Prices" style={{
                        width: '100%',
                        whiteSpace: 'nowrap'
                    }}>
                        <Grid container>
                            {this.getPriceFilters(options)}
                        </Grid>
                    </VBox>


                    <VBox title="Supply-Demand" style={{
                        width: '100%',
                        whiteSpace: 'nowrap'
                    }}>
                        <Grid container>
                            {this.getPowerSupplyFilters(options)}
                        </Grid>
                    </VBox>


                    <Grid item sm={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-end">
                        <Button
                            style={{ height: "40" }}
                            variant="contained"
                            onClick={() => this.clearFilters()}>
                            Clear
                        </Button>
                        <Button
                            style={{ height: "40" }}
                            variant="contained"
                            onClick={() => this.getData()}>
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </VPageFilter>
        )
    }

    clearFilters() {
        this.setState({ selectedFilter: { ...this.defaultFilter } as Filter });
    }

    getData() {
        const { isValid, message } = this.validateInputs();

        if (!isValid) {
            AlertManager.showError(message); //TODO: message
            return;
        }

        const spinner = createSpinner();

        const filter = this.state.selectedFilter;
        if (!filter)
            return;

        getEtlPriceSupplyDemandData(
            this.clientFilterToServerFilter(filter)
        ).then(response => {
            if (response.data.success) {
                const prices = response.data.success['prices'];
                const supplyDemands = response.data.success['supply_demands'];
                const priceTableData = this.generateTableData(prices, filter.frequency);
                const powerTableData = this.generateTableData(supplyDemands, filter.frequency);

                this.setState({
                    prices,
                    supplyDemands,
                    priceTableData,
                    powerTableData,
                    activeFilter: { ...filter },
                }, () => {
                    this.updateCharts(filter, prices, supplyDemands);
                    this.updateLocalStorageFilter();
                });
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });

    }

    updateLocalStorageFilter(newActiveFilter?: Filter) {
        if (!newActiveFilter)
            newActiveFilter = this.state.activeFilter;

        if (!newActiveFilter)
            return;

        const { minDate, maxDate, ...rest } = newActiveFilter;
        setLocalStorage(PriceSupplyDemandComparisonReport.params.reportKey, "filter", "mainFilter", rest);
    }

    clientFilterToServerFilter(filter: Filter) {
        return {
            min_date: filter.minDate && moment(filter.minDate).format('yyyy/MM/DD HH:mm'),
            max_date: filter.maxDate && moment(filter.maxDate).format('yyyy/MM/DD HH:mm'),
            currency: filter.currency,
            price_type: filter.priceType,
            frequency: filter.frequency,
            price_area_type: filter.priceAreaType,
            price_area_name: filter.priceAreaName,
            source_price_area_type: filter.sourcePriceAreaType,
            source_price_area_name: filter.sourcePriceAreaName,
            sink_price_area_type: filter.sinkPriceAreaType,
            sink_price_area_name: filter.sinkPriceAreaName,
            power_type: filter.powerType,
            power_area_type: filter.powerAreaType,
            power_area_name: filter.powerAreaName,
        }
    }

    getDirectionName(priceType: PriceType, priceAreaName?: string, sourcePriceAreaName?: string, sinkPriceAreaName?: string) {
        if (priceType === PriceType.Price)
            return priceAreaName || "";
        return `${sourcePriceAreaName} to ${sinkPriceAreaName}`;
    }

    updateCharts(
        filter?: Filter,
        prices?: IState['prices'],
        supplyDemands?: IState['supplyDemands']
    ) {

        if (!filter || !prices || !supplyDemands)
            return;

        const priceType = filter.priceType;
        const powerType = filter.powerType;
        const direction = this.getDirectionName(filter.priceType, filter.priceAreaName, filter.sourcePriceAreaName, filter.sinkPriceAreaName);
        const powerAreaName = filter.powerAreaName;
        const currency = filter.currency;

        if (!priceType || !powerAreaName || !powerType || !currency)
            return;


        this.updateScatterChart(
            priceType,
            powerType,
            direction,
            powerAreaName,
            currency,
            prices,
            supplyDemands)

        this.updateTimeseriesChart(
            priceType,
            powerType,
            direction,
            powerAreaName,
            prices,
            supplyDemands)
    }

    updateScatterChart(
        priceType: PriceType,
        powerType: string,
        direction: string,
        powerAreaName: string,
        currency: string,
        prices: IEtlPriceSupplyDemandResponse['success']['prices'],
        supplyDemands: IEtlPriceSupplyDemandResponse['success']['supply_demands'],
    ) {
        const chartInstance = this.comparisonScatterChartRef?.getEchartsInstance();

        if (chartInstance) {
            chartInstance.showLoading();
            chartInstance.clear();
        }

        const priceKey = 'Price';
        const supplyDemandKey = 'SupplyDemand';

        const valuesMatcher: {
            [key: string]: { [priceKey]?: string, SupplyDemand?: string }
        } = prices.reduce((map: {
            [key: string]: { [priceKey]?: string, SupplyDemand?: string }
        }, row) => {
            map[row.DateInfo] = { [priceKey]: row.Value?.toString() };
            return map;
        }, {})

        supplyDemands.forEach(row => {
            if (!valuesMatcher[row.DateInfo])
                valuesMatcher[row.DateInfo] = { [supplyDemandKey]: row.Value.toString() };
            else
                valuesMatcher[row.DateInfo][supplyDemandKey] = row.Value.toString();
        })

        const allDates = sortBy(Object.keys(valuesMatcher))

        const powerName = `${powerAreaName} ${powerType}`
        const priceName = `${direction} ${priceType}`

        let options: ECOption = {
            ...this.comparisonTimeseriesChartOptions,
            legend: {},
            xAxis: {
                type: 'value',
                name: powerType,
                nameLocation: 'middle',
                nameGap: 25
            },
            yAxis: {
                type: 'value',
                name: priceType,
            },
            series: [
                {
                    type: sharedScatterOptions.type,
                    symbolSize: sharedScatterOptions.symbolSize,
                    name: `[${priceName}] and [${powerName}]`,
                    data: allDates.map(date_cet => [
                        valuesMatcher[date_cet][supplyDemandKey] || "0",
                        valuesMatcher[date_cet][priceKey] || "0",
                        date_cet
                    ]),
                }
            ],
            tooltip: {
                confine: true,
                formatter: (params: any) => {
                    const date_cet: string = params.data[2];
                    const priceStr = valuesMatcher[date_cet][priceKey] ? `<strong>${valuesMatcher[date_cet][priceKey]} ${currency}</strong>` : 'N/A'
                    const powerStr = valuesMatcher[date_cet][supplyDemandKey] ? `<strong>${valuesMatcher[date_cet][supplyDemandKey]}</strong>` : 'N/A'

                    return `${inputLabels["date"]}: <strong>${date_cet}</strong>
                            <br />${priceName}: ${priceStr}
                            <br />${powerName}: ${powerStr}`
                }
            },
            dataZoom: [{}],
            grid: {
                top: sharedScatterOptions.grid.top,
                bottom: 85,
            },
        } as ECOption;

        this.comparisonScatterChartOptions = options;

        if (chartInstance) {
            chartInstance.setOption(options);
            chartInstance.hideLoading();
        }
    }

    updateTimeseriesChart(
        priceType: PriceType,
        powerType: string,
        direction: string,
        powerAreaName: string,
        prices: IEtlPriceSupplyDemandResponse['success']['prices'],
        supplyDemands: IEtlPriceSupplyDemandResponse['success']['supply_demands'],
    ) {
        const chartInstance = this.comparisonTimeseriesChartRef?.getEchartsInstance();

        if (chartInstance) {
            chartInstance.showLoading();
            chartInstance.clear();
        }

        let options: ECOption = {
            xAxis: { type: 'category' },
            tooltip: { trigger: 'axis' },
            legend: {},
            grid: sharedScatterOptions.grid,
            dataZoom: [{}],
        };

        const viewMode = this.state.selectedFilter.viewMode;

        switch (viewMode) {
            case "SeperateCharts":
                options = {
                    ...options,
                    ...this.getSeperateChartsOptions(priceType,
                        direction,
                        powerAreaName,
                        powerType,
                        prices,
                        supplyDemands)
                } as ECOption;

                break;
            case "SingleChartSeperateY":
                options = {
                    ...options,
                    ...this.getSingleChartSeperateYOptions(priceType,
                        direction,
                        powerAreaName,
                        powerType,
                        prices,
                        supplyDemands)
                } as ECOption;

                break;
            case "SingleChartSingleY":
                options = {
                    ...options,
                    ...this.getSingleChartSingleYOptions(priceType,
                        direction,
                        powerAreaName,
                        powerType,
                        prices,
                        supplyDemands)
                } as ECOption;

                break;
        }

        this.comparisonTimeseriesChartOptions = options;

        if (chartInstance) {
            chartInstance.setOption(options);
            chartInstance.hideLoading();
        }
    }

    getSingleChartSeperateYOptions(
        priceType: PriceType,
        direction: string,
        powerAreaName: string,
        powerType: string,
        prices: IEtlPriceSupplyDemandResponse['success']['prices'],
        supplyDemands: IEtlPriceSupplyDemandResponse['success']['supply_demands']
    ) {
        return {
            yAxis: [
                {
                    type: 'value',
                    name: priceType,
                    axisLine: { show: true },
                    alignTicks: true,
                },
                {
                    type: 'value',
                    name: 'Supply/Demand',
                    position: 'right',
                    axisLine: { show: true },
                    alignTicks: true,
                }
            ],
            series: [
                {
                    type: sharedScatterOptions.type,
                    symbolSize: sharedScatterOptions.symbolSize,
                    name: `${direction} ${priceType}`,
                    data: prices.map(d => [d.DateInfo, d.Value]),
                },
                {
                    type: sharedScatterOptions.type,
                    symbolSize: sharedScatterOptions.symbolSize,
                    yAxisIndex: 1,
                    name: `${powerAreaName} ${powerType}`,
                    data: supplyDemands.map(d => [d.DateInfo, d.Value]),
                }
            ]
        }
    }

    getSingleChartSingleYOptions(
        priceType: PriceType,
        direction: string,
        powerAreaName: string,
        powerType: string,
        prices: IEtlPriceSupplyDemandResponse['success']['prices'],
        supplyDemands: IEtlPriceSupplyDemandResponse['success']['supply_demands']
    ) {
        return {
            yAxis: {
                type: 'value',
                axisLine: { show: true },
            },
            series: [
                {
                    type: sharedScatterOptions.type,
                    symbolSize: sharedScatterOptions.symbolSize,
                    name: `${direction} ${priceType}`,
                    data: prices.map(d => [d.DateInfo, d.Value]),
                },
                {
                    type: sharedScatterOptions.type,
                    symbolSize: sharedScatterOptions.symbolSize,
                    name: `${powerAreaName} ${powerType}`,
                    data: supplyDemands.map(d => [d.DateInfo, d.Value]),
                }
            ]
        }
    }

    getSeperateChartsOptions(
        priceType: PriceType,
        direction: string,
        powerAreaName: string,
        powerType: string,
        prices: IEtlPriceSupplyDemandResponse['success']['prices'],
        supplyDemands: IEtlPriceSupplyDemandResponse['success']['supply_demands']
    ) {
        return {
            dataZoom: undefined,
            grid: [
                { left: '7%', width: '40%' },
                { right: '7%', width: '40%' },
            ],
            xAxis: [
                {
                    gridIndex: 0,
                    type: 'category'
                },
                {
                    gridIndex: 1,
                    type: 'category'
                }
            ],
            yAxis: [
                {
                    gridIndex: 0,
                    type: 'value',
                    name: priceType,
                    axisLine: { show: true },
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    name: 'Supply/Demand',
                    position: 'right',
                    axisLine: { show: true },
                }
            ],
            series: [
                {
                    type: sharedScatterOptions.type,
                    symbolSize: sharedScatterOptions.symbolSize,
                    name: `${direction} ${priceType}`,
                    data: prices.map(d => [d.DateInfo, d.Value]),
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                },
                {
                    type: sharedScatterOptions.type,
                    symbolSize: sharedScatterOptions.symbolSize,
                    name: `${powerAreaName} ${powerType}`,
                    data: supplyDemands.map(d => [d.DateInfo, d.Value]),
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                }
            ]
        }
    }

    validateInputs() {
        const filter = this.state.selectedFilter;

        if (!filter)
            return { isValid: false, message: "All inputs are required." }

        if (!filter?.['minDate']) // maxDate is not required
            return this.makeFailedResult(inputLabels['minDate']);

        if (!filter?.['priceType'])
            return this.makeFailedResult(inputLabels['priceType']);

        if (!filter?.['frequency'])
            return this.makeFailedResult(inputLabels['frequency']);

        if (!filter?.['currency'])
            return this.makeFailedResult(inputLabels['currency']);

        if (filter['priceType'] === PriceType.Price) {
            if ((!filter?.['priceAreaType'] || !filter?.['priceAreaName']))
                return this.makeFailedResult("", `${inputLabels['priceAreaType']} and ${inputLabels['priceAreaType']} are required for ${PriceType.Price}`);
        }
        else if ((!filter?.['sourcePriceAreaType']
            || !filter?.['sourcePriceAreaName']
            || !filter?.['sinkPriceAreaType']
            || !filter?.['sinkPriceAreaName'])) {
            return this.makeFailedResult("", `${inputLabels['sourcePriceAreaType']}, ${inputLabels['sourcePriceAreaName']}, ${inputLabels['sinkPriceAreaType']}, ${inputLabels['sinkPriceAreaName']} are required for ${filter['priceType']}`);
        }

        if (!filter?.['powerType'])
            return this.makeFailedResult(inputLabels['powerType']);

        if (!filter?.['powerAreaType'])
            return this.makeFailedResult(inputLabels['powerAreaType']);

        if (!filter?.['powerAreaName'])
            return this.makeFailedResult(inputLabels['powerAreaName']);

        return { isValid: true, message: "" }
    }

    makeFailedResult(paramName?: string, message?: string) {
        return {
            isValid: false,
            message: message ? message : `${paramName} is required.`
        }
    }

    getSelectableOptions(
        selectedFilter?: Filter,
        priceOptions?: FilterOptionsDict,
        supplyDemandOptions?: FilterOptionsDict,
    ): Options {
        if (!priceOptions)
            priceOptions = this.state.allFilterOptions?.priceOptions || {};

        if (!supplyDemandOptions)
            supplyDemandOptions = this.state.allFilterOptions?.supplyDemandOptions || {};

        const currencies = Object.keys(priceOptions);

        const priceAreaTypeOptions = priceOptions[selectedFilter?.currency || currencies[0]];
        const priceAreaTypes = Object.keys(priceAreaTypeOptions);

        const priceAreaNames = priceAreaTypeOptions[selectedFilter?.priceAreaType || priceAreaTypes[0]];

        const sourcePriceAreaNames = priceAreaTypeOptions[selectedFilter?.sourcePriceAreaType || priceAreaTypes[0]];

        const sinkPriceAreaNames = priceAreaTypeOptions[selectedFilter?.sinkPriceAreaType || priceAreaTypes[0]];

        const powerAreaTypes = Object.keys(supplyDemandOptions);
        const selectedPowerAreaType = selectedFilter?.powerAreaType || powerAreaTypes[0];

        const powerAreaNameOptions = supplyDemandOptions[selectedPowerAreaType];
        const powerAreaNames = Object.keys(powerAreaNameOptions);
        const selectedPowerAreaName = selectedFilter?.powerAreaName || powerAreaNames[0];

        const powerTypes = supplyDemandOptions[selectedPowerAreaType][selectedPowerAreaName];


        return {
            currencies,
            priceAreaTypes,
            priceAreaNames,
            sourcePriceAreaNames,
            sinkPriceAreaNames,
            powerAreaTypes,
            powerAreaNames,
            powerTypes,
        }
    }

    getSharedFilters() {
        return (
            <>
                {
                    this.getDateFilters()
                }
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['frequency']}
                        value={this.state.selectedFilter?.frequency}
                        getOptionLabel={(option) => `${option}`}
                        options={frequencies}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({ frequency: newValue as Frequency })
                        }}
                    />
                </Grid>
            </>
        )
    }

    getDateFilters() {
        const generateDatePicker = (name: string,
            label: string,
            minDate?: ParsableDate,
            maxDate?: ParsableDate,
            value?: ParsableDate) => {
            switch (this.state.selectedFilter.frequency) {
                case Frequency.Hourly:
                    return (
                        <VDateTimePicker
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            label={label}
                            minDate={minDate || undefined}
                            maxDate={maxDate || undefined}
                            value={value || null}
                            onChange={(date) => {
                                this.setState({
                                    selectedFilter: { ...this.state.selectedFilter, [name]: date?.toDate() }
                                })
                            }}
                        />
                    )
                case Frequency.Daily:
                    return (
                        <VDatePicker
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            label={label}
                            minDate={minDate || undefined}
                            maxDate={maxDate || undefined}
                            value={value || null}
                            onChange={(date) => {
                                this.setState({
                                    selectedFilter: { ...this.state.selectedFilter, [name]: date?.toDate() }
                                })
                            }}
                        />
                    )
                default:
                    return null;

            }
        }

        return (
            <>
                <Grid item {...allValueGridProps}>
                    {
                        generateDatePicker('minDate',
                            inputLabels['minDate'],
                            null,
                            this.state.selectedFilter?.maxDate,
                            this.state.selectedFilter?.minDate)
                    }
                </Grid>
                <Grid item {...allValueGridProps}>
                    {
                        generateDatePicker('maxDate',
                            inputLabels['maxDate'],
                            this.state.selectedFilter?.minDate,
                            null,
                            this.state.selectedFilter?.maxDate)
                    }
                </Grid>
            </>
        )
    }

    getPriceFilters(options: Options) {
        const priceType = this.state.selectedFilter.priceType;

        return (
            <>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['priceType']}
                        value={this.state.selectedFilter?.priceType}
                        getOptionLabel={(option) => `${option}`}
                        options={priceTypes}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({ priceType: newValue as PriceType })
                        }}
                    />
                </Grid>
                {priceType === "Price" ?
                    this.getPriceFilter(options)
                    :
                    this.getFlowSpreadFilter(options)
                }
            </>
        )
    }

    getPriceFilter(options: Options) {
        return (
            <>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['currency']}
                        value={this.state.selectedFilter?.currency}
                        getOptionLabel={(option) => `${option}`}
                        options={options.currencies}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({
                                    currency: newValue as PriceType,
                                    priceAreaType: undefined,
                                    priceAreaName: undefined,
                                })
                        }}
                    />
                </Grid>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['priceAreaType']}
                        value={this.state.selectedFilter?.priceAreaType}
                        getOptionLabel={(option) => `${option}`}
                        options={options.priceAreaTypes}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({
                                    priceAreaType: newValue.toString(),
                                    priceAreaName: undefined,
                                })
                        }}
                    />
                </Grid>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['priceAreaName']}
                        value={this.state.selectedFilter?.priceAreaName}
                        getOptionLabel={(option) => `${option}`}
                        options={options.priceAreaNames}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({ priceAreaName: newValue.toString() })
                        }}
                    />
                </Grid>
            </>
        )
    }

    getFlowSpreadFilter(options: Options) {
        return (
            <>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['currency']}
                        value={this.state.selectedFilter?.currency}
                        getOptionLabel={(option) => `${option}`}
                        options={options.currencies}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({
                                    currency: newValue as PriceType,
                                    sourcePriceAreaType: undefined,
                                    sourcePriceAreaName: undefined,
                                    sinkPriceAreaType: undefined,
                                    sinkPriceAreaName: undefined,
                                })
                        }}
                    />
                </Grid>
                <Grid item {...filterLabelContainerStyle}>
                    <Typography variant='button' style={filterLabelStyle}>
                        Source:
                    </Typography>
                </Grid>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['sourcePriceAreaType']}
                        value={this.state.selectedFilter?.sourcePriceAreaType}
                        getOptionLabel={(option) => `${option}`}
                        options={options.priceAreaTypes}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({
                                    sourcePriceAreaType: newValue.toString(),
                                    sourcePriceAreaName: undefined,
                                })
                        }}
                    />
                </Grid>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['sourcePriceAreaName']}
                        value={this.state.selectedFilter?.sourcePriceAreaName}
                        getOptionLabel={(option) => `${option}`}
                        options={options.sourcePriceAreaNames}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({ sourcePriceAreaName: newValue.toString() })
                        }}
                    />
                </Grid>
                <Grid item {...filterLabelContainerStyle}>
                    <Typography variant='button' style={filterLabelStyle}>
                        Sink:
                    </Typography>
                </Grid>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['sinkPriceAreaType']}
                        value={this.state.selectedFilter?.sinkPriceAreaType}
                        getOptionLabel={(option) => `${option}`}
                        options={options.priceAreaTypes}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({
                                    sinkPriceAreaType: newValue.toString(),
                                    sinkPriceAreaName: undefined,
                                })
                        }}
                    />
                </Grid>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['sinkPriceAreaName']}
                        value={this.state.selectedFilter?.sinkPriceAreaName}
                        getOptionLabel={(option) => `${option}`}
                        options={options.sinkPriceAreaNames}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({ sinkPriceAreaName: newValue.toString() })
                        }}
                    />
                </Grid>
            </>
        )
    }

    getPowerSupplyFilters(options: Options) {
        return (
            <>
                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['powerAreaType']}
                        value={this.state.selectedFilter?.powerAreaType}
                        getOptionLabel={(option) => `${option}`}
                        options={options.powerAreaTypes}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({
                                    powerAreaType: newValue.toString(),
                                    powerAreaName: undefined,
                                    powerType: undefined,
                                })
                        }}
                    />
                </Grid>

                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['powerAreaName']}
                        value={this.state.selectedFilter?.powerAreaName}
                        getOptionLabel={(option) => `${option}`}
                        options={options.powerAreaNames}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({
                                    powerAreaName: newValue.toString(),
                                    powerType: undefined,
                                })
                        }}
                    />
                </Grid>

                <Grid item {...allValueGridProps}>
                    <VSelect
                        disableClearable
                        title={inputLabels['powerType']}
                        value={this.state.selectedFilter?.powerType}
                        getOptionLabel={(option) => `${option}`}
                        options={options.powerTypes}
                        onChange={(newValue) => {
                            if (newValue)
                                this.updateSelectedFilter({ powerType: newValue.toString() })
                        }}
                    />
                </Grid>
            </>
        )
    }

    updateSelectedFilter(newValues: Partial<Filter>) {
        const selectedFilter = this.state.selectedFilter || {};

        const mergedFilter = { ...selectedFilter, ...newValues };
        const viewModeChanged = mergedFilter?.viewMode !== selectedFilter?.viewMode;

        const selectableOptions = this.getSelectableOptions(mergedFilter)

        if (!viewModeChanged) {
            this.setState({
                selectedFilter: mergedFilter,
                selectableOptions,
            });
        }
        else {
            const activeFilter = this.state.activeFilter || {};
            const newActiveFilter = { ...activeFilter, ...newValues };

            this.setState({
                selectedFilter: mergedFilter,
                activeFilter: newActiveFilter,
                selectableOptions
            }, () => {
                this.updateCharts(newActiveFilter, this.state.prices, this.state.supplyDemands);
                this.updateLocalStorageFilter(newActiveFilter);
            });
        }
    }

    getResultsView() {
        const tabStyle = { minWidth: 100, height: '24px', minHeight: '24px' };
        const tabPanelStyle = { padding: 0 };

        const activeFilter = this.state.activeFilter;
        const priceTableData = this.state.priceTableData;
        const powerTableData = this.state.powerTableData;

        return (
            <VBox style={{ padding: "4px", margin: "0" }}>
                <Grid container justify="flex-start">
                    <Grid item xs={12} >
                        {priceTableData && powerTableData &&
                            this.getFileDownloader(activeFilter, priceTableData, powerTableData)}
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs value={this.state.selectedTabIndex}
                            style={tabStyle}
                            onChange={(_, value) => this.setState({ selectedTabIndex: value })} >
                            <Tab key='tab_charts' label='Charts' style={tabStyle} />
                            <Tab key={`tab_${activeFilter.priceType}`} style={tabStyle}
                                label={`${activeFilter.priceType} Table`} />
                            <Tab key={`tab_${activeFilter.powerType}`} style={tabStyle}
                                label={`${activeFilter.powerType} Table`} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={tabPanelStyle} index={0} key='tab_content_charts'>
                            {this.getCharts()}
                        </TabPanel>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={tabPanelStyle} index={1} key='tab_content_table_price'>
                            {priceTableData && this.getTable(priceTableData)}
                        </TabPanel>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={tabPanelStyle} index={2} key='tab_content_table_power'>
                            {powerTableData && this.getTable(powerTableData)}
                        </TabPanel>
                    </Grid>
                </Grid>
            </VBox>
        )
    }

    flattenTableData(tableData: TableData) {
        return tableData.data.map(row => {
            return tableData.columns.map(col => row[col])
        });
    }

    getFileDownloader(filter: Filter, priceTableData: TableData, powerTableData: TableData) {
        const priceExcelData = this.flattenTableData(priceTableData);
        const powerExcelData = this.flattenTableData(powerTableData);

        const priceName = `${this.getDirectionName(filter.priceType, filter.priceAreaName, filter.sourcePriceAreaName, filter.sinkPriceAreaName)} ${filter.priceType}`
        const powerName = `${filter.powerAreaName} ${filter.powerType}`

        return (
            <div style={{ display: "block", height: '100%', position: 'relative' }}>
                <div style={{
                    position: "absolute",
                    display: "inline-block",
                    right: 0,
                    top: 0,
                }}>
                    <Tooltip title="Download Excel">
                        <IconButton size="small" style={{ zIndex: 1000, marginRight: 5 }} onClick={() =>
                            createExcelWithMultipleSheets(
                                "Price & Power Data",
                                [
                                    {
                                        sheetName: priceName,
                                        headers: priceTableData.columns,
                                        values: priceExcelData,
                                    },
                                    {
                                        sheetName: powerName,
                                        headers: powerTableData.columns,
                                        values: powerExcelData,
                                    }
                                ])
                        }>
                            <Icon className="far fa-file-excel" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div >
        );
    }

    getCharts() {
        return (
            <Grid container>
                <Grid container item {...chartGridProps}>
                    <Grid item xs={12} style={{ padding: '10px' }}>
                        <ReactECharts
                            ref={(e) => { this.comparisonScatterChartRef = e; }}
                            echarts={echarts}
                            option={this.comparisonScatterChartOptions}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{ width: '99%' }}
                        />
                    </Grid>
                </Grid>
                <Grid container item {...chartGridProps}>
                    <Grid item xs={12} style={{ padding: '10px' }}>
                        <ReactECharts
                            ref={(e) => { this.comparisonTimeseriesChartRef = e; }}
                            echarts={echarts}
                            option={this.comparisonTimeseriesChartOptions}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{ width: '99%' }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: '10px', height: '70px' }}>
                        <VSelect
                            disableClearable
                            title={inputLabels['viewMode']}
                            value={this.state.selectedFilter.viewMode}
                            getOptionLabel={(option) => viewModes[`${option}`]}
                            options={Object.keys(viewModes)}
                            onChange={(newValue) => {
                                if (newValue)
                                    this.updateSelectedFilter({ viewMode: newValue.toString() })
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    generateTableData(data: PriceSupplyDemandDataRow[], frequency: Frequency): TableData {
        const dailyGroups: { [key: string]: { [key: string]: any } } = {};
        const allHours = new Set<string>();

        switch (frequency) {
            case Frequency.Hourly:
                data?.forEach(row => {
                    const [date, hour] = row.DateInfo.split(' ');

                    allHours.add(hour)

                    if (!dailyGroups[date])
                        dailyGroups[date] = { [hour]: row.Value };
                    else
                        dailyGroups[date][hour] = row.Value;
                })
                break;
            case Frequency.Daily:
                const valueColumnHeader = 'Value';
                allHours.add(valueColumnHeader)

                data?.forEach(row => {
                    dailyGroups[row.DateInfo] = { [valueColumnHeader]: row.Value };
                })

                break;
            default:
                throw Error("Not implemented");
        }


        return {
            columns: ["Date", ...sortBy(Array.from(allHours.values()))],
            data: Object.keys(dailyGroups).map(date => ({ Date: date, ...dailyGroups[date] })),
        };
    }

    getTable(tableData: TableData) {

        return (
            <VTable
                makeFirstColumnsSticky
                tableData={tableData}
                containerStyle={{
                    maxWidth: 'calc(100vw - 30px)',
                    height: this.state.filterPanelIsOpen ? 'calc(100vh - 500px)' : 'calc(100vh - 200px)',
                    overflow: 'auto',
                }}
            />
        )
    }

    render() {
        return (
            <ReportViewer
                {...PriceSupplyDemandComparisonReport.params}>
                <Grid container justify="flex-start"
                    alignItems="flex-start"
                    spacing={1}>
                    <Grid item xs={12}>
                        {
                            this.getFilters()
                        }
                    </Grid>
                    {
                        (this.state.prices && this.state.supplyDemands)
                        && <Grid item xs={12}>
                            {
                                this.getResultsView()
                            }
                        </Grid>
                    }
                </Grid>
            </ReportViewer>
        )
    }
}

export default PriceSupplyDemandComparisonReport;