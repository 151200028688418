import { ReportParams, ReportView } from "../../system/ReportBase";
import ReportViewer from "../ReportViewer/ReportViewer";
import { generateUuid, handleApiError } from '../../utils/common';
import { getDynamicDatasetsMetadata, getDynamicDatasetsData, getCustomChart, saveChart } from '../../apis/vitusApi';
import { createSpinner, Spinner } from "../../utils/spinnerManager";
import AlertManager from "../../utils/alertManager";
import messages from '../../utils/messages';
import { Breadcrumbs, Button, FormControl, Grid, Icon, Input, InputLabel, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import VSelect from "../../components/VSelect/VSelect";
import VDatasetCard from "../../components/VDatasetCard/VDatasetCard";
import VCollapse from "../../components/VCollapse/VCollapse";
import { Dataset, DatasetMetaData } from "../../utils/types";
import { isEmpty } from "lodash";
import { AxiosError, AxiosResponse } from "axios";
import { ICustomChartResponse } from "../../apis/vitusApiTypes";
import { mainMenuItems } from "../../components/VSideBar/VSideBar";
import CustomChartsList from "../CustomChartsList/CustomChartsList";
import ChartAdapter from "./CustomChartAdapters/ChartAdapter";
import BaseChart, { chartTypes, DatasetRow } from "./CustomChartAdapters/BaseChart";

interface IState {
    datasetsMetaData: { [key: string]: DatasetMetaData },
    selectedDatasets: Dataset[],
    removedDatasetIds: number[],
    selectedDataset: Dataset | null,
    aggregations: string[],
    chart_id?: number,
    chart_name?: string,
    allDatasetData: { [key: string]: { data: DatasetRow[], index_column_values: string[] } },
}

interface IProps {
    match: { params: { id?: number } }
}

class CustomChart extends ReportView<IProps, IState> {
    chartRef: BaseChart | null = null;

    static params: ReportParams = new ReportParams(
        {
            reportKey: "CUSTOM_CHART",
            name: "Custom Chart",
            cleanPath: "/customChart",
            path: "/customChart/:id?",
            thumbnail: ""
        }
    );

    state: IState = {
        datasetsMetaData: {},
        selectedDataset: null,
        aggregations: [],
        selectedDatasets: [],
        removedDatasetIds: [],
        chart_name: "",
        allDatasetData: {},
    }

    componentDidMount() {
        let chartSpinner: Spinner;
        let storedChartRequest: Promise<AxiosResponse<ICustomChartResponse>> | null = null;

        const chart_id = this.props.match.params.id;

        if (chart_id) {
            chartSpinner = createSpinner();
            storedChartRequest = getCustomChart({ chart_id });
        }

        const metaSpinner = createSpinner();

        getDynamicDatasetsMetadata().then(async response => {
            if (response.data.success) {
                const datasets: IState["datasetsMetaData"] = {};
                response.data.success.datasets.forEach(ds => datasets[ds.Name] = ds);

                this.setState({
                    datasetsMetaData: datasets,
                    aggregations: response.data.success.aggregations
                });

                if (storedChartRequest) {
                    try {
                        const storedChartResponse = await storedChartRequest;

                        if (storedChartResponse.data.success) {
                            const storedChart = storedChartResponse.data.success.custom_chart;

                            this.setState({
                                chart_id: storedChart.id,
                                chart_name: storedChart.name,
                                selectedDatasets: storedChart.datasets
                            });
                        }
                        else if (storedChartResponse.data.error) {
                            AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                        }
                        else {
                            AlertManager.showError("Chart not found."); //TODO: message
                        }
                    } catch (error) {
                        handleApiError(error as AxiosError);
                    } finally {
                        chartSpinner.hide()
                    };

                }
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            metaSpinner.hide()
        });
    }

    addNewDataSet() {
        if (!this.state.selectedDataset)
            return;

        const sameDatasetOrder = this.state.selectedDatasets.filter(s => s.name === this.state.selectedDataset?.name).length + 1;
        const newDataset = { ...this.state.selectedDataset, id: generateUuid(), alias: `${this.state.selectedDataset?.name} ${sameDatasetOrder}` };

        this.setState({ selectedDatasets: [...this.state.selectedDatasets, newDataset] });
    }

    removeDataSet(datasetId: string) {
        let removedDatasetIds;

        if (+datasetId)
            removedDatasetIds = [...this.state.removedDatasetIds, +datasetId]
        else
            removedDatasetIds = this.state.removedDatasetIds;

        this.setState({
            removedDatasetIds,
            selectedDatasets: this.state.selectedDatasets.filter(s => s.id !== datasetId)
        });
    }

    validateChart() {
        const errorMessages: string[] = [];

        if (!this.state.chart_name) {
            AlertManager.showError("Please specify Chart Name.");
            return false;
        }

        if (!this.state.selectedDatasets?.length) {
            AlertManager.showError("Please add at least one dataset.");
            return false;
        }

        const datasetError = ChartAdapter.checkErrors(this.state.selectedDatasets);

        if (datasetError) {
            AlertManager.showError(datasetError);
            return false;
        }

        this.state.selectedDatasets.forEach(dataset => {

            const name = dataset.alias || dataset.name;
            const missingFields: string[] = [];

            if (!dataset.chart_type)
                missingFields.push("Chart Type");
            if (!dataset.x_axis)
                missingFields.push("X Axis");
            if (!dataset.y_axis)
                missingFields.push("Y Axis");

            if (missingFields.length > 0) {
                errorMessages.push(`${name}: Please fill required fields: ${missingFields.join(' ,')}.`);
                return;
            }

            if (!isEmpty(dataset.group_by?.groups) && isEmpty(dataset.group_by?.aggregations)) {
                errorMessages.push(`${name}: Group Columns and Aggregations cannot be specified separately, please either select both or select none.`);
                return;
            }

            if (!isEmpty(dataset.group_by?.groups)
                && !dataset.group_by?.groups.find(g => g === dataset.x_axis)) {
                errorMessages.push(`${name}: If data groups are specified X Axis column must be added to the groups or result will not be consistent.`);
                return;
            }

            if (!isEmpty(dataset.group_by?.groups)
                && dataset.chart_groups?.find(cgr => !dataset.group_by?.groups.find(dgr => dgr === cgr))) {
                errorMessages.push(`${name}: Chart groups must also be selected on data groups.`);
                return;
            }

            if (!isEmpty(dataset.group_by?.aggregations)
                && !dataset.group_by?.aggregations[dataset.y_axis || ""]) {
                errorMessages.push(`${name}: If aggregations are specified Y Axis column must be added to the aggregations or result will not be consistent.`);
                return;
            }

            const y_column = this.state.datasetsMetaData[dataset.name].Columns[dataset.y_axis || "not selected"];

            if (dataset.chart_type === "pie" && y_column?.type !== "number") {
                errorMessages.push(`${name}: X Axis of Pie Chart must be a column with type of number.`);
                return;
            }
        });

        if (errorMessages.length > 0) {
            AlertManager.showError(errorMessages.join("\n"));
            return false;
        }

        return true;
    }

    callSaveChart(asNew: boolean = false) {
        if (!this.validateChart())
            return;

        const spinner = createSpinner();

        saveChart({
            chart: {
                id: asNew ? undefined : this.state.chart_id,
                name: this.state.chart_name,
                datasets: this.state.selectedDatasets,
                removedDatasetIds: this.state.removedDatasetIds,
            }
        }).then(response => {
            if (response.data.success) {
                const result = response.data.success.result;

                if (result) {
                    if (this.state.removedDatasetIds.length)
                        this.setState({ removedDatasetIds: [] });

                    AlertManager.showSuccess("Chart saved successfully."); //TODO: message
                } else {
                    AlertManager.showError("Chart could not be saved."); //TODO: message
                }
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    updateData() {
        if (!this.validateChart())
            return;

        this.state.selectedDatasets.forEach(dataset => {
            const spinner = createSpinner();

            getDynamicDatasetsData(
                {
                    name: dataset.name as string,
                    filter: dataset.filter,
                    group_by: dataset.group_by,
                    index_column: dataset.x_axis,
                    value_column: dataset.y_axis,
                    chart_groups: dataset.chart_groups,
                })
                .then(response => {
                    if (response.data.success) {
                        const newData = { ...this.state.allDatasetData };
                        newData[dataset.alias] = { data: response.data.success.data, index_column_values: response.data.success.index_column_values };

                        this.setState({ allDatasetData: newData }, () => this.chartRef?.setChartOptions(this.state.selectedDatasets, this.state.allDatasetData));
                    }

                    if (response.data.error) {
                        AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                    }
                }).catch(error => {
                    handleApiError(error);
                }).finally(() => {
                    spinner.hide()
                });
        })
    }



    render() {
        return (
            <ReportViewer {...CustomChart.params}>
                <Grid container spacing={3} justify="flex-start">
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" to={mainMenuItems.home.route}>
                                Home
                            </Link>
                            <Link color="inherit" to={CustomChartsList.params.path}>
                                Custom Chart List
                            </Link>
                            <Typography color="textPrimary">Custom Chart</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12}>
                        <VCollapse
                            title="Chart Details"
                            defaultShow={true}
                            getSummary={() => `Chart Name: ${this.state.chart_name}, Datasets: ${this.state.selectedDatasets.map(s => s.alias || s.name).join(', ')}`}>
                            <Grid container
                                justify="flex-start"
                                alignContent="flex-start">
                                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                    <Typography variant="subtitle1" style={{ display: "inline-block", width: 80, fontSize: 12, fontWeight: 500 }}>
                                        Chart Name
                                    </Typography>
                                    <div style={{ display: "inline-block", fontSize: 12 }}>
                                        <FormControl style={{ marginLeft: 8 }}>
                                            <InputLabel htmlFor="chart_name" shrink>Name</InputLabel>
                                            <Input
                                                id="chart_name"
                                                value={this.state.chart_name}
                                                type="text"
                                                onChange={(newValue) => this.setState({ chart_name: newValue.target.value })}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                    <Typography variant="subtitle1" style={{ display: "inline-block", width: 80, fontSize: 12, fontWeight: 500 }}>
                                        New Dataset
                                    </Typography>
                                    <VSelect
                                        title="Dataset"
                                        options={Object.keys(this.state.datasetsMetaData).map(ds => this.state.datasetsMetaData[ds])}
                                        value={this.state.datasetsMetaData[this.state.selectedDataset?.name || "not selected"]}
                                        getOptionLabel={(option) => option.Name as string}
                                        onChange={(newValue) => {
                                            if (!newValue)
                                                return;

                                            const meta = newValue as DatasetMetaData;

                                            this.setState({
                                                selectedDataset: { id: meta.Id || "", name: meta.Name, alias: meta.Alias }
                                            })
                                        }}
                                    />
                                    <Button
                                        style={{ height: "40" }}
                                        variant="contained"
                                        onClick={() => this.addNewDataSet()}>
                                        Add DataSet
                                    </Button>
                                </Grid>
                                <Grid container item xs={1} justify="flex-end" style={{ display: 'flex' }}>
                                    <Button
                                        color="primary"
                                        style={{ height: "40" }}
                                        variant="contained"
                                        onClick={() => this.callSaveChart(true)}>
                                        Save As New Chart
                                    </Button>
                                </Grid>
                                <Grid container item xs={1} justify="flex-end" style={{ display: 'flex' }}>
                                    <Button
                                        color="primary"
                                        style={{ height: "40" }}
                                        variant="contained"
                                        onClick={() => this.callSaveChart()}>
                                        Save Chart
                                    </Button>
                                </Grid>
                            </Grid>
                            <hr />
                            {
                                this.state.selectedDatasets?.length ?
                                    this.state.selectedDatasets.map((dataset, idx) => {
                                        const datasetMetadata = this.state.datasetsMetaData[dataset.name];

                                        return (
                                            <Grid item xs={12} key={`${dataset.id}_${idx}`}>
                                                <VDatasetCard
                                                    id={dataset.id}
                                                    name={dataset.name}
                                                    alias={dataset.alias}
                                                    columns={datasetMetadata.Columns}
                                                    onDelete={(datasetId) => this.removeDataSet(datasetId)}
                                                    options={datasetMetadata.Options}
                                                    dataset={dataset}
                                                    chartTypes={Object.keys(chartTypes)}
                                                    aggregations={this.state.aggregations}
                                                    onDatasetChanged={(updatedDataset) => {
                                                        const newDatasets = [...this.state.selectedDatasets];
                                                        const updatedDatasetIndex = newDatasets.indexOf(dataset);

                                                        newDatasets[updatedDatasetIndex] = {
                                                            ...dataset,
                                                            ...updatedDataset,
                                                        };

                                                        this.setState({ selectedDatasets: newDatasets })
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    })
                                    :
                                    <Typography variant="subtitle1">
                                        Please select at least one dataset.
                                    </Typography>
                            }
                        </VCollapse>
                    </Grid>
                    <Grid item xs={12}>
                        <VCollapse
                            title="Chart"
                            defaultShow={true}>
                            <Grid container justify="space-between" spacing={5}>
                                <Grid item xs={11}>
                                    {
                                        ChartAdapter.generateChart(
                                            this.state.selectedDatasets,
                                            {
                                                ref: (e: BaseChart) => { this.chartRef = (e as BaseChart); },
                                                title: this.state.chart_name || "Chart",
                                            }
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        style={{ height: "40" }}
                                        startIcon={<Icon className="fas fa-redo" />}
                                        onClick={() => this.updateData()}>
                                        Refresh
                                    </Button>
                                </Grid>
                            </Grid>
                        </VCollapse>
                    </Grid>
                </Grid>
            </ReportViewer >
        );
    }
}


export default CustomChart;