import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Icon, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import { deleteCacheRecord, getCacheDashboard, getCacheRecordDetail, warmUpAllOperations, warmUpSingleOperation } from "../../../apis/vitusApi";
import { ReportView, ReportParams } from "../../../system/ReportBase";
import AlertManager from "../../../utils/alertManager";
import { handleApiError, ViewTypes } from "../../../utils/common";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import ReportViewer from "../../ReportViewer/ReportViewer";
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlayArrow from '@material-ui/icons/PlayArrow';

interface IState {
    cacheRecords: string[],
    warmUpFunctions: { name: string, isLoading: boolean }[],
    recordDetails: { [key: string]: { timeToLive: number, value: any, valueStr: string, isLoading: boolean } },
    showDetail?: any,
    showConfirmDelete?: string,
    warmUpAllStatus: { askConfirm: boolean, isLoading: boolean },
    searchCache: string,
    searchWarmup: string,
}

const textStyles = {
    fontSize: 12,
}

class CacheDashboard extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            viewType: ViewTypes.Administration,
            reportKey: "CACHE_DASHBOARD",
            name: "Cache Dashboard",
            path: "/cacheDashboard",
            thumbnail: ""
        }
    );

    state = {
        cacheRecords: [] as IState["cacheRecords"],
        warmUpFunctions: [] as unknown as IState["warmUpFunctions"],
        recordDetails: {} as IState["recordDetails"],
        showDetail: undefined,
        showConfirmDelete: "",
        warmUpAllStatus: { askConfirm: false, isLoading: false },
        searchCache: "",
        searchWarmup: "",
    }

    componentDidMount() {
        this.callGetCacheDashboard();
    }

    callGetCacheDashboard() {
        const spinner = createSpinner();

        getCacheDashboard().then(response => {
            if (response.data.success) {
                this.setState({
                    cacheRecords: response.data.success.cache_records,
                    warmUpFunctions: response.data.success.warm_up_functions.map(func => { return { name: func, isLoading: false } }),
                })
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    getRecordDetails(key: string) {
        this.setState({
            recordDetails: {
                ...this.state.recordDetails,
                [key]: { timeToLive: 0, value: undefined, valueStr: "", isLoading: true },
            }
        })

        getCacheRecordDetail({ key }).then(response => {
            if (response.data.success) {
                const recordDetail = {
                    timeToLive: response.data.success.time_to_live,
                    value: response.data.success.value,
                    valueStr: typeof response.data.success.value === "string" ? response.data.success.value : JSON.stringify(response.data.success.value),
                    isLoading: false,
                }

                this.setState({
                    recordDetails: {
                        ...this.state.recordDetails,
                        [key]: recordDetail,
                    }
                })
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                this.setState({
                    recordDetails: {
                        ...this.state.recordDetails,
                        [key]: { ...this.state.recordDetails[key], isLoading: false },
                    }
                })
            }
        }).catch(error => {
            handleApiError(error);
            this.setState({
                recordDetails: {
                    ...this.state.recordDetails,
                    [key]: { ...this.state.recordDetails[key], isLoading: false },
                }
            })
        });
    }

    prettifyRecordValue(value?: any) {
        if (!value)
            return "";

        return JSON.stringify(value, null, 2);
    }

    callDeleteCacheRecord(keys: string[]) {
        const recordDetails = { ...this.state.recordDetails };
        keys.forEach(key => {
            recordDetails[key] = { ...recordDetails[key], isLoading: true }
        })

        this.setState({
            showConfirmDelete: "",
            recordDetails,
        });

        deleteCacheRecord({ keys }).then(response => {
            if (response.data.success && response.data.success.result) {
                keys.forEach(key => {
                    recordDetails[key] = { timeToLive: 0, value: undefined, valueStr: "", isLoading: false }
                })

                this.setState({
                    cacheRecords: this.state.cacheRecords.filter(r => !keys.find(k => k === r)),
                    recordDetails: { ...recordDetails }
                });

                AlertManager.showSuccess("Cache record deleted successfully."); //TODO: message
            } else {
                AlertManager.showError("Cache record could not be deleted."); //TODO: message
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        });
    }

    callWarmUpAllOperations() {
        this.setState({ warmUpAllStatus: { askConfirm: false, isLoading: true } });

        warmUpAllOperations().then(response => {
            if (response.data.success && response.data.success.result) {
                AlertManager.showSuccess("Warmed up successfully."); //TODO: message
            } else {
                AlertManager.showError("Warm up failed."); //TODO: message
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            this.setState({ warmUpAllStatus: { askConfirm: false, isLoading: false } });
        });
    }

    callWarmUpSingleOperation(functionName: string) {
        const warmUpFunctions = [...this.state.warmUpFunctions];
        warmUpFunctions[warmUpFunctions.findIndex(f => f.name === functionName)].isLoading = true;

        this.setState({ warmUpFunctions });

        warmUpSingleOperation({ function_name: functionName }).then(response => {
            if (response.data.success && response.data.success.result) {
                AlertManager.showSuccess(`${functionName} ran successfully.`); //TODO: message
            } else {
                AlertManager.showError(`${functionName} failed.`); //TODO: message
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            const changedIndex = warmUpFunctions.findIndex(f => f.name === functionName);
            if (changedIndex < 0)
                return;

            warmUpFunctions[changedIndex].isLoading = false;
            this.setState({ warmUpFunctions });
        });
    }

    render() {
        return (
            <ReportViewer {...CacheDashboard.params}>
                <Grid container spacing={3} justify="flex-start">
                    <Grid container item xs={12} justify="flex-end" style={{ height: 0 }}>
                        <Button variant="contained" style={{ marginTop: -20 }}
                            onClick={() => this.callGetCacheDashboard()}>
                            <Icon style={{ marginRight: 5 }} className="fas fa-sync-alt" />
                            Refresh
                        </Button>
                    </Grid>
                    <Grid container item xs={12}>
                        <TableContainer component={Paper} title="Cache Records">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} style={{ ...textStyles }}>
                                            Cache Records
                                        </TableCell>
                                        <TableCell colSpan={2} style={{ ...textStyles, }}>
                                            <FormControl style={{ float: "right" }}>
                                                <TextField
                                                    value={this.state.searchCache}
                                                    variant="outlined"
                                                    type="text"
                                                    onChange={(newValue) => this.setState({ searchCache: newValue.target.value })}
                                                    margin="dense"
                                                    InputProps={{
                                                        style: { padding: 0 },
                                                        startAdornment: (
                                                            <InputAdornment position="end">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ ...textStyles, width: 400 }}>
                                            Key
                                        </TableCell>
                                        <TableCell style={{ ...textStyles, width: 200 }}>
                                            Time to Live (Minutes)
                                        </TableCell>
                                        <TableCell style={textStyles}>
                                            Value
                                        </TableCell>
                                        <TableCell style={textStyles}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.cacheRecords.filter(rec => (!this.state.searchCache
                                            || rec.toLowerCase().indexOf(this.state.searchCache.toLowerCase()) >= 0))
                                            .map(record => {
                                                return (
                                                    <TableRow key={`record_${record}`}>
                                                        <TableCell>
                                                            <Typography>
                                                                {record}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography>
                                                                {
                                                                    (this.state.recordDetails?.[record]?.timeToLive > 0 ?
                                                                        ((this.state.recordDetails?.[record]?.timeToLive || 0) / 60).toFixed(2)
                                                                        : this.state.recordDetails?.[record]?.timeToLive)
                                                                    || "N/A"
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography>
                                                                {
                                                                    this.state.recordDetails?.[record]?.valueStr ?
                                                                        <React.Fragment>
                                                                            {this.state.recordDetails?.[record]?.valueStr?.slice(0, 150) || ""}{(this.state.recordDetails?.[record]?.valueStr?.length || 0) > 150 ?
                                                                                <IconButton size="small" onClick={() => this.setState({
                                                                                    showDetail: this.state.recordDetails?.[record]?.value
                                                                                })} >
                                                                                    ...
                                                                                    <SearchIcon />
                                                                                </IconButton>
                                                                                :
                                                                                ""
                                                                            }
                                                                        </React.Fragment>
                                                                        : "N/A"
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell style={{ width: 20, whiteSpace: "nowrap" }}>
                                                            {
                                                                this.state.recordDetails?.[record]?.isLoading ?
                                                                    <div style={{ float: "right" }}>
                                                                        <CircularProgress disableShrink size={20} style={{ padding: 3, }} />
                                                                    </div>
                                                                    :
                                                                    <React.Fragment>
                                                                        <Tooltip title={<Typography>Delete record</Typography>}>
                                                                            <IconButton size="small" onClick={() => this.setState({ showConfirmDelete: record })} >
                                                                                <ClearOutlinedIcon color="error" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title={<Typography>Get record details</Typography>}>
                                                                            <IconButton size="small" onClick={() => this.getRecordDetails(record)} >
                                                                                <DownloadIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </React.Fragment>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container item xs={12}>
                        <TableContainer component={Paper} title="Cache Records">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ ...textStyles }}>
                                            Warm Up Functions
                                        </TableCell>
                                        <TableCell colSpan={2} style={{ ...textStyles, }}>
                                            <FormControl style={{ float: "right" }}>
                                                <TextField
                                                    value={this.state.searchWarmup}
                                                    variant="outlined"
                                                    type="text"
                                                    onChange={(newValue) => this.setState({ searchWarmup: newValue.target.value })}
                                                    margin="dense"
                                                    InputProps={{
                                                        style: { padding: 0 },
                                                        startAdornment: (
                                                            <InputAdornment position="end">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ ...textStyles }}>
                                            Name
                                        </TableCell>
                                        <TableCell style={{ ...textStyles }}>
                                        </TableCell>
                                        <TableCell style={{ ...textStyles, width: 20, whiteSpace: "nowrap" }}>
                                            {
                                                this.state.warmUpAllStatus.isLoading ?
                                                    <div style={{ float: "right" }}>
                                                        <CircularProgress disableShrink style={{ padding: 3, }} />
                                                    </div>
                                                    :
                                                    <Tooltip title={<Typography>Run All Warm Up Functions</Typography>}>
                                                        <Typography>
                                                            <Button variant="outlined"
                                                                onClick={() => this.setState({ warmUpAllStatus: { askConfirm: true, isLoading: false } })}>
                                                                <PlayArrow style={{ color: "green" }} />
                                                                Run All
                                                            </Button>
                                                        </Typography>
                                                    </Tooltip>
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.warmUpFunctions.filter(rec => (!this.state.searchWarmup
                                            || rec.name.toLowerCase().indexOf(this.state.searchWarmup.toLowerCase()) >= 0))
                                            .map(func => {
                                                const name = func.name;

                                                return (
                                                    <TableRow key={`warmup_${name}`}>
                                                        <TableCell>
                                                            <Typography>
                                                                {name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell style={{ ...textStyles }}>
                                                        </TableCell>
                                                        <TableCell style={{ width: 20, whiteSpace: "nowrap", float: "right", marginRight: 20 }}>
                                                            {
                                                                this.state.warmUpAllStatus.isLoading || func.isLoading ?
                                                                    <div style={{ float: "right" }}>
                                                                        <CircularProgress disableShrink size={20} style={{ padding: 3, }} />
                                                                    </div>
                                                                    :
                                                                    <Tooltip title={<Typography>Run warm up function</Typography>}>
                                                                        <IconButton size="small" onClick={() => this.callWarmUpSingleOperation(name)} >
                                                                            <PlayArrow style={{ color: "green" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {
                        this.state.showDetail &&
                        <Dialog onClose={() => this.setState({ showDetail: "" })} open={!!this.state.showDetail}>
                            <DialogTitle>Record Detail</DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    {this.prettifyRecordValue(this.state.showDetail)}
                                </Typography>
                            </DialogContent>
                        </Dialog>
                    }
                    {
                        this.state.showConfirmDelete &&
                        <Dialog onClose={() => this.setState({ showConfirmDelete: "" })} open={!!this.state.showConfirmDelete}>
                            <DialogTitle>Confirm deleting</DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    Are you sure you want to delete <span style={{ fontWeight: 500 }}>{this.state.showConfirmDelete}</span>?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => this.setState({ showConfirmDelete: "" })}>
                                    Cancel
                                </Button>
                                <Button autoFocus onClick={() => this.callDeleteCacheRecord([this.state.showConfirmDelete])} color="secondary">
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                    {
                        this.state.warmUpAllStatus.askConfirm &&
                        <Dialog onClose={() => this.setState({ warmUpAllStatus: { askConfirm: false, isLoading: false } })}
                            open={this.state.warmUpAllStatus.askConfirm}>
                            <DialogTitle>Confirm warm up</DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    Are you sure you want to run all warm up operations? This may take a few minutes.
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => this.setState({ warmUpAllStatus: { askConfirm: false, isLoading: false } })}>
                                    Cancel
                                </Button>
                                <Button autoFocus onClick={() => this.callWarmUpAllOperations()} color="secondary">
                                    Warm Up
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </Grid>
            </ReportViewer >
        );
    }
}

export default CacheDashboard;