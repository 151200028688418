import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

// TODO: may be useful for pdf exporting:
// chartRef?.getEchartsInstance()?.getRenderedCanvas()
// Note: canvas renderer removed from pages because removing it did not break the page

const THUMBNAIL_DIR = "reportThumbnails";

const useStyles = makeStyles({
    root: {
        width: 200,
        height: 160,
        margin: 10,
        textAlign: 'center',
    },
    media: {
        width: 'auto',
        height: 130,
        paddingTop: '40%',
        backgroundSize: 'contain',
    },
    content: {
        padding: 0,
    },
});

interface IProps {
    title: string,
    path: string,
    thumbnail: string,
    shortName?: string,
}

const VThumbnail: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    return (
        <Link to={props.path} style={{ textDecoration: 'none' }}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardContent className={classes.content}>
                        <Typography gutterBottom variant="h5" style={{ textDecoration: 'underline' }}>
                            {props.title}
                        </Typography>
                    </CardContent>
                    {props.thumbnail ?
                        <CardMedia
                            className={classes.media}
                            image={`${THUMBNAIL_DIR}/${props.thumbnail}`}
                        />
                        :
                        <Typography variant="h1">
                            {props.shortName || props.title.split(" ").filter(c => c !== '&').slice(0, 3).map(w => w.slice(0, 1))}
                        </Typography>
                    }
                </CardActionArea>
            </Card>
        </Link>
    );
}

export default VThumbnail;