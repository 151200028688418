import { Tooltip, Typography } from '@material-ui/core';
import { max, uniq } from 'lodash';
import React from 'react';
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup,
    Line,
    Marker,
} from "react-simple-maps"
import { getCountryCoordinate } from '../../utils/geo';

/* 
sources:
https://www.react-simple-maps.io/examples/
https://github.com/zcreativelabs/react-simple-maps
https://www.npmjs.com/package/react-simple-maps
https://www.react-simple-maps.io/docs/line/
*/

const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json"

type LineData = { from: string, to: string, value?: any };

interface IProps {
    lines?: LineData[],
    countryColors?: { [key: string]: string },
}

interface IState {
    countryTooltip: string,
    lineTooltip?: LineData,
    tooltipPosition?: { x?: number, y?: number },
    markers?: string[],
}

class WorldMapChart extends React.Component<IProps, IState> {
    state: IState = {
        countryTooltip: "",
    }

    specifyMarkers() {
        return uniq(this.props.lines?.flatMap(line => [line.from, line.to]) || []);
    }

    componentDidMount() {
        this.setState({ markers: this.specifyMarkers() });
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.lines && prevProps.lines !== this.props.lines)
            this.setState({ markers: this.specifyMarkers() });
    }

    render() {
        let maxLine = 0

        if (!isNaN(this.props.lines?.[0]?.value))
            maxLine = max(this.props.lines?.map(l => l.value));


        return (
            <Tooltip
                open={!!this.state.countryTooltip || !!this.state.lineTooltip}
                onMouseMove={e => this.setState({ tooltipPosition: { x: e.pageX, y: e.pageY } })}
                PopperProps={{
                    anchorEl: {
                        clientHeight: 0,
                        clientWidth: 0,
                        getBoundingClientRect: () => ({
                            top: this.state.tooltipPosition?.y || 0,
                            left: this.state.tooltipPosition?.x || 0,
                            right: this.state.tooltipPosition?.x || 0,
                            bottom: this.state.tooltipPosition?.y || 0,
                            width: 0,
                            height: 0,
                            x: 0,
                            y: 0,
                            toJSON: () => ""
                        })
                    }
                }}
                title={
                    <Typography>
                        {!!this.state.lineTooltip ? (
                            <React.Fragment>
                                <span>From: {this.state.lineTooltip.from}</span>
                                <br />
                                <span>To: {this.state.lineTooltip.to}</span>
                                <br />
                                {(this.state.lineTooltip.value !== undefined) && <span>Value: {this.state.lineTooltip.value}</span>}
                            </React.Fragment>
                        )
                            :
                            (!!this.state.countryTooltip ? this.state.countryTooltip : "")}
                    </Typography>
                }>
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "block",
                    verticalAlign: "middle",
                    overflow: "hidden",
                    padding: 10,
                }}>
                    <ComposableMap
                        projection="geoMercator">
                        <ZoomableGroup>
                            <Geographies
                                geography={geoUrl}
                                fill="#D6D6DA"
                                stroke="#FFFFFF">
                                {({ geographies }) => {
                                    return geographies.map(geo => {
                                        return (
                                            <Geography
                                                key={geo.rsmKey}
                                                geography={geo}
                                                onMouseEnter={() => {
                                                    this.setState({ countryTooltip: `${geo.properties.NAME}` });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ countryTooltip: "" });
                                                }}
                                                style={{
                                                    default: {
                                                        fill: this.props.countryColors?.[geo.properties.NAME] || "#D6D6DA",
                                                        outline: "none"
                                                    },
                                                    hover: {
                                                        fill: "orange",
                                                        outline: "none"
                                                    },
                                                    pressed: {
                                                        fill: "#E42",
                                                        outline: "none"
                                                    }
                                                }}
                                            />
                                        )
                                    })
                                }
                                }
                            </Geographies>
                            {
                                this.props.lines &&
                                this.props.lines.map((line, idx) => {
                                    const fromCoord = getCountryCoordinate(line.from);
                                    const toCoord = getCountryCoordinate(line.to);

                                    return (
                                        <React.Fragment key={`line_${line.from}_${line.to}_${idx}`}>
                                            <Line
                                                onMouseEnter={() => {
                                                    this.setState({ lineTooltip: line });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ lineTooltip: undefined });
                                                }}
                                                key={`line_${line.from}_${line.to}`}
                                                from={[fromCoord.longitude, fromCoord.latitude]}
                                                to={[toCoord.longitude, toCoord.latitude]}
                                                stroke="black"
                                                strokeWidth={(maxLine && (line.value || line.value === 0)) ? 5 / maxLine * line.value : 1}
                                                strokeLinecap="round"
                                            />
                                        </React.Fragment>

                                    )
                                })
                            }
                            {
                                this.state.markers &&
                                this.state.markers.map((marker, idx) => {
                                    const coord = getCountryCoordinate(marker);

                                    return (
                                        <Marker key={`marker_${marker}_${idx}`} coordinates={[coord.longitude, coord.latitude]}>
                                            <Tooltip title={<Typography>{marker}</Typography>}>
                                                <circle r={3} fill="#F00" stroke="#fff" strokeWidth={1} />
                                            </Tooltip>
                                        </Marker>
                                    );
                                })
                            }
                        </ZoomableGroup>
                    </ComposableMap>
                </div>
            </Tooltip>
        );
    }
}

export default WorldMapChart;