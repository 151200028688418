import _ from 'lodash';
import { LineSeriesOption } from 'echarts/charts';
import { GridComponentOption } from 'echarts/components';
import { months, shortenNumber } from "../../../utils/common";
import * as echarts from 'echarts/core';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

export interface ITabProps {
    data?: any,
    onDataChanged: (data: any) => void,
}

export const rangeLineColor = "gray";

export const rangeLineOptions = {
    lineStyle: {
        type: 'dotted',
        width: 2,
        color: rangeLineColor,
    },
};

export const colors = ["red", "#CCCC00", "green", "#ffa600", "#003f5c", "#444e86",
    "#a4c400", "#008a00", "#00aba9", "#1ba1e2", "#6a00ff",
    "#aa00ff", "#f472d0", "#d80073", "#a20025", "#fa6800", "#f0a30a", "#111",
    "#e3c800", "#6d8764", "#647687", "#76608a", "#a0522d"];

export const isRange = (col: string) => { return ["MinHistorical", "MaxHistorical"].includes(col) };

export const defaultLineRangeChartOptions: ECOption = {
    animation: false,
    xAxis: {
        type: 'category',
        axisLabel: {
            interval: 30,
            formatter: (value: any) => {
                const month = months[+value.split('_')[0] - 1]
                return month;
            }
        }
    },
    yAxis: {
        type: 'value',
        scale: true,
        axisLabel: {
            formatter: shortenNumber,
        }
    },
    tooltip: {
        trigger: 'axis',
        confine: true,
        order: 'valueDesc',
        axisPointer: {
            type: "shadow",
        },
        formatter: (params: any) => {
            const tooltip = `
                <div>
                    <span>${params[0].data.day} ${params[0].data.month}</span><br />

                    ${_(params.filter((p: any) => !!p.value[p.seriesName]))
                    .filter((p: any) => p?.value?.[p?.seriesName])
                    .sortBy(p => p.seriesName)
                    .reverse()
                    .map((p: any) => (`
                    <span style="background-color: ${p.color}; height: 10px; width: 10px; border-radius: 50%; display: inline-block;"></span>
                    <span>${p.seriesName}<strong style="margin-left:20px; float:right;">${shortenNumber(p.value[p.seriesName].toFixed(2))}</strong></span>
                    `)).join('<br />')}
                </div>
                `

            return tooltip;
        },
    },
    series: [{
        data: [],
        type: 'line'
    }],
    dataZoom: [{}],
}