import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import React from 'react';

interface IProps {
    fullWidth?: boolean,
    open: boolean,
    setOpen: (open: boolean) => void,
    title?: string,
    content?: React.ReactNode,
    actions?: React.ReactNode[],
}

export const VSimpleDialog: React.FC<IProps> = (props: IProps) => {

    return (
        <Dialog open={props.open}
            fullWidth={props.fullWidth}
            onClose={() => props.setOpen(false)}>
            {props.title && <DialogTitle style={{ padding: 10 }}>{props.title}</DialogTitle>}
            {props.content &&
                <DialogContent>
                    {props.content}
                </DialogContent>
            }
            {props.actions &&
                <DialogActions>
                    {
                        props.actions.map(act => act)
                    }
                </DialogActions>
            }
        </Dialog>
    );
}
