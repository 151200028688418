import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { MapChart, MapSeriesOption } from 'echarts/charts';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import { SankeyChart, SankeySeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { getBalanceNetFlows } from '../../../apis/vitusApi';
import _, { isEmpty, uniq } from 'lodash';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { createSpinner } from '../../../utils/spinnerManager';
import { IBalanceNetFlowsResponse } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { handleApiError, makeTitle } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import WorldMapChart from '../../../charts/WorldMapChart/WorldMapChart';
import { styles } from '../../../utils/styles';
import { colors, ITabProps } from './gasBalanceUtils';

type ECOption = echarts.ComposeOption<GridComponentOption | SankeySeriesOption | MapSeriesOption | BarSeriesOption>;

echarts.use(
    [SankeyChart, MapChart, BarChart, GridComponent]
);

interface IProps {
    season: "injection" | "withdraw",
}

interface IState {
    mapData: IBalanceNetFlowsResponse["success"]["seasonal_gas_flows"]["withdraw"]["cross_country_gas_flows"],
    barData: IBalanceNetFlowsResponse["success"]["seasonal_gas_flows"]["withdraw"]["seasonal_gas_flows_by_country"],
    unit: string,
    showLoading: boolean,
}

class GasFlows extends React.Component<ITabProps & IProps, IState> {
    sankeyChartRef: ReactECharts | null = null;
    barChartRef: ReactECharts | null = null;

    sankeyChartOptions: ECOption = {

    }

    barChartOptions: ECOption = {
        animation: false,
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
        },
        grid: {
            top: 5,
            right: 200,
        },
    };

    state: IState = {
        barData: [],
        mapData: [],
        unit: "n/a",
        showLoading: false,
    }

    componentDidMount() {
        // TODO: commented until flow data performance is improved
        // if (!this.props.data)
        //     this.getData();
        // else
        //     this.storeData(this.props.data);

        if (this.props.data)
            this.storeData(this.props.data);
        else if (!this.state.showLoading)
            this.setState({ showLoading: true });
    }

    componentDidUpdate(prevProps: GasFlows["props"]) {
        if (this.props.data && prevProps.data !== this.props.data)
            this.storeData(this.props.data);
        else if (!this.props.data && !this.state.showLoading)
            this.setState({ showLoading: true });
    }

    getData() {
        const spinner = createSpinner();

        getBalanceNetFlows().then(response => {
            if (response.data.success) {
                this.storeData(response.data.success);
                this.props.onDataChanged(response.data.success);
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    storeData(response: IBalanceNetFlowsResponse["success"]) {
        const currentSeasonData = response.seasonal_gas_flows[this.props.season];
        const mapData = currentSeasonData?.cross_country_gas_flows || [];

        this.setAllCountryColors(uniq(mapData.flatMap(d => [d.SourceCountry, d.SinkCountry])))

        this.setState({
            barData: currentSeasonData?.seasonal_gas_flows_by_country || [],
            mapData: mapData,
            unit: response.seasonal_gas_flows?.unit || "n/a",
            showLoading: false,
        }, this.updateCharts);
    }

    updateCharts() {
        this.updateSankey();
        this.updateBarChart();
    }

    updateBarChart = () => {
        const barChartInstance = this.barChartRef?.getEchartsInstance();
        barChartInstance.showLoading();

        const data = this.state.barData;

        barChartInstance.clear();

        const series = _(data)
            .groupBy("SourceCountry")
            .map((value, countryName) => {
                return {
                    type: 'bar',
                    name: countryName,
                    stack: 'total',
                    data: value.map(d => [d.Value, d.Season,]),
                    label: {
                        show: true
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {
                        color: this.getCountryColor(countryName),
                    }
                };
            })
            .value();

        barChartInstance.setOption({
            ...this.barChartOptions,
            yAxis: {
                type: 'category',
                data: _(data.map(d => d.Season)).uniq().sortBy().value().reverse(),
            },
            series: series,
            legend: {
                right: 0,
                orient: 'vertical',
                type: 'scroll',
                data: series.map(s => s.name),
            },
        });

        barChartInstance.hideLoading();
    }

    countryColorMap: { [key: string]: string } = {};
    setAllCountryColors(countries: string[]) {
        this.countryColorMap = {};

        countries.forEach(country => {
            this.countryColorMap[country] = colors[Object.keys(this.countryColorMap).length % colors.length];
        })
    }

    getCountryColor(country: string) {
        if (this.countryColorMap[country])
            return this.countryColorMap[country];

        const nextColorIdx = Object.keys(this.countryColorMap).length;

        this.countryColorMap[country] = colors[nextColorIdx % colors.length];

        return this.countryColorMap[country];
    }

    updateSankey = () => {
        const sankeyInstance = this.sankeyChartRef?.getEchartsInstance();
        sankeyInstance.showLoading();

        const data = this.state.mapData;

        const allCountries = uniq(data.flatMap(d => [d.SourceCountry, d.SinkCountry]));

        sankeyInstance.clear();
        sankeyInstance.setOption({
            ...this.sankeyChartOptions,
            series: {
                type: 'sankey',
                layout: 'none',
                emphasis: {
                    focus: 'adjacency'
                },
                data: allCountries.map(countryName => ({
                    name: countryName,
                    itemStyle: {
                        color: this.getCountryColor(countryName),
                    }
                })),
                links: data.map(d => ({
                    source: d.SourceCountry,
                    target: d.SinkCountry,
                    value: d.Value,
                }))
            },
        });

        sankeyInstance.hideLoading();
    }

    render() {
        return (
            <Grid container justify="flex-start" alignItems="flex-start">
                {
                    this.state.showLoading &&
                    <div style={{
                        position: "absolute",
                        display: "inline-block",
                        left: "50%",
                        top: "40%",
                        textAlign: 'center',
                    }}>
                        <CircularProgress style={{}} color="inherit" />
                        <Typography>Getting flow data</Typography>
                    </div>
                }
                {
                    !isEmpty(this.state.mapData) &&
                    <Grid item xs={4} >
                        <VChart
                            title={`Europe Current ${makeTitle(this.props.season)} Season Period Entry Gas Flow (${this.state.unit})`}
                            height={750}
                            report={
                                <ReactECharts
                                    ref={(e) => { this.sankeyChartRef = e; }}
                                    echarts={echarts}
                                    option={this.sankeyChartOptions}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ width: '99%', height: 700 }}
                                />
                            } >
                        </VChart>
                    </Grid>
                }
                <Grid item xs={8} >
                    {
                        !isEmpty(this.state.barData) &&
                        <Grid item xs={12} >
                            <VChart
                                title={`${makeTitle(this.props.season)} Season Period Total Entry Gas Flow Amounts by Country (${this.state.unit})`}
                                height={250}
                                report={
                                    <ReactECharts
                                        ref={(e) => { this.barChartRef = e; }}
                                        echarts={echarts}
                                        option={this.barChartOptions}
                                        notMerge={true}
                                        lazyUpdate={true}
                                        style={{ width: '99%', height: 250 }}
                                    />
                                } >
                            </VChart>
                        </Grid>
                    }
                    {
                        !isEmpty(this.state.mapData) &&
                        <Grid container item xs={12} style={{ ...styles.borderedContainer }} >
                            <Grid item xs={12}
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start">
                                <Typography variant="h6" style={{ margin: 5 }}>
                                    Europe Current {makeTitle(this.props.season)} Season Period Entry Gas Flow Map
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ height: 465 }}>
                                <WorldMapChart
                                    countryColors={this.countryColorMap}
                                    lines={this.state.mapData.map(point => ({ from: point.SourceCountry, to: point.SinkCountry, value: point.Value }))}
                                />
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid >
        );
    }
}

export default GasFlows;