import { Breadcrumbs, Button, FormControl, Grid, Icon, IconButton, Input, InputLabel, TextField, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { ReportParams, ReportView } from "../../../system/ReportBase";
import { handleApiError, ViewTypes } from "../../../utils/common";
import ReportViewer from "../../ReportViewer/ReportViewer";
import { DatasetMetaData, DataSetOptionUpdater, DataSourceTypes } from "../../../utils/types";
import { generateDataSourceInfo, getDataSourceType, getDataSourceValue } from "../../../utils/dynamicReportUtils";
import { getBaseDatasetOptions, getDataset, saveDataset } from "../../../apis/vitusApi";
import AlertManager from "../../../utils/alertManager";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import VSelect from "../../../components/VSelect/VSelect";
import VBox from '../../../components/VBox/VBox';
import { isEmpty, orderBy } from "lodash";
import { mainMenuItems } from "../../../components/VSideBar/VSideBar";
import DatasetList from "../DatasetList/DatasetList";

interface IState {
    dataset: DatasetMetaData,
    columnDataTypes: string[],
    dataSourceTypes: string[],
    newColumn: { name: string, type: string },
    selectedDataSourceType?: DataSourceTypes,
    removedDatasourceIds: number[],
    optionUpdater: DataSetOptionUpdater,
}

interface IProps {
    match: { params: { id?: number } }
}

class CreateDataset extends ReportView<IProps, IState> {
    static params: ReportParams = new ReportParams(
        {
            viewType: ViewTypes.Administration,
            reportKey: "CREATE_DATASET",
            name: "Create Dataset",
            cleanPath: "/createDataset",
            path: "/createDataset/:id?",
            thumbnail: ""
        }
    );

    state: IState = {
        dataset: { Name: "", Alias: "", Columns: {}, DataSources: [] },
        columnDataTypes: [],
        dataSourceTypes: [],
        newColumn: { name: "", type: "" },
        removedDatasourceIds: [],
        optionUpdater: { SourceInfo: { query: "" } },
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.match.params.id !== this.props.match.params.id)
            window.location.reload();
    }

    componentDidMount() {
        const spinner = createSpinner();

        getBaseDatasetOptions().then(response => {
            if (response.data.success) {
                this.setState({
                    columnDataTypes: response.data.success.column_data_types,
                    dataSourceTypes: response.data.success.data_source_types,
                    selectedDataSourceType: getDataSourceType(response.data.success.data_source_types[0]),
                });
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });

        if (this.props.match.params.id) {
            const dsSpinner = createSpinner();

            getDataset({ dataset_id: this.props.match.params.id }).then(response => {
                if (response.data.success) {
                    this.setState({
                        dataset: response.data.success.dataset,
                        optionUpdater: response.data.success?.option_updater || this.state.optionUpdater,
                    });
                }

                if (response.data.error) {
                    AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                }
            }).catch(error => {
                handleApiError(error);
            }).finally(() => {
                dsSpinner.hide()
            });
        }
    }

    updateSourceInfo = (idx: number, newValue: string) => {
        const dataSources = [...(this.state.dataset.DataSources || [])];

        dataSources[idx].SourceInfo = generateDataSourceInfo(dataSources[idx].SourceType, this.parseUserJson(newValue));

        this.setState({
            dataset: {
                ...this.state.dataset,
                DataSources: dataSources
            }
        })
    }

    updateOptionUpdater = (newValue: string) => {
        this.setState({
            optionUpdater: { ...this.state.optionUpdater, SourceInfo: { query: this.parseUserJson(newValue) } }
        })
    }

    removeSource = (idx: number) => {
        const dataSources = [...(this.state.dataset.DataSources || [])];

        const removedElement = dataSources.splice(idx, 1)[0];

        const removedDatasourceIds = [...this.state.removedDatasourceIds];

        if (removedElement.Id)
            removedDatasourceIds.push(removedElement.Id);

        this.setState({
            dataset: {
                ...this.state.dataset,
                DataSources: dataSources,
            },
            removedDatasourceIds
        })
    }

    addNewColumn = () => {
        if (!this.state.newColumn.name) {
            AlertManager.showError("Specify column name"); //TODO: message
            return;
        }
        if (!this.state.newColumn.type) {
            AlertManager.showError("Specify column type"); //TODO: message
            return;
        }
        if (this.state.dataset.Columns[this.state.newColumn.name]) {
            AlertManager.showError(this.state.newColumn.name + " already added"); //TODO: message
            return;
        }

        this.setState({
            dataset: {
                ...this.state.dataset,
                Columns: { ...this.state.dataset.Columns, [this.state.newColumn.name]: this.state.newColumn },
            }
        });
    }

    validateDataset() {
        if (!this.state.dataset.Name) {
            AlertManager.showError("Please specify Dataset Name.");
            return false;
        }
        if (isEmpty(this.state.dataset.Columns)) {
            AlertManager.showError("Please specify Columns.");
            return false;
        }
        if (isEmpty(this.state.dataset.DataSources)) {
            AlertManager.showError("Please specify DataSources.");
            return false;
        }
        if (!this.state.optionUpdater?.SourceInfo?.query) {
            AlertManager.showError("Please specify Option Updater.");
            return false;
        }

        return true;
    }

    callSaveDataset() {
        if (!this.validateDataset())
            return;

        const spinner = createSpinner();

        saveDataset({
            dataset: this.state.dataset,
            removedDatasourceIds: this.state.removedDatasourceIds,
            option_updater: this.state.optionUpdater,
        }).then(response => {
            if (response.data.success) {
                const result = response.data.success.result;

                if (result) {
                    this.setState({ removedDatasourceIds: [], dataset: response.data.success.dataset, optionUpdater: response.data.success.option_updater });

                    AlertManager.showSuccess("Dataset saved successfully."); //TODO: message
                } else {
                    AlertManager.showError("Dataset could not be saved."); //TODO: message
                }
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    parseUserJson(jsonStr: string) {
        return jsonStr
    }

    render() {
        return (
            <ReportViewer {...CreateDataset.params}>
                <Grid container spacing={3} justify="flex-start">
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" to={mainMenuItems.home.route}>
                                Home
                            </Link>
                            <Link color="inherit" to={DatasetList.params.path}>
                                Dataset List
                            </Link>
                            <Typography color="textPrimary">Create Dataset</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0 }}>
                        <Typography variant={this.state.dataset.UserChartCount ? "h6" : "subtitle1"} style={{ color: this.state.dataset.UserChartCount ? "red" : "default", fontWeight: 500 }}>
                            This dataset is used in {this.state.dataset.UserChartCount} charts!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0 }}>
                        <Typography variant="subtitle1" style={{ color: "red", fontWeight: 500 }}>
                            WARNING: When a dataset is saved, all of its sources will become inactive and charts using the dataset will not work correctly! Inactivated datasets must be activated manually. There is no way to create and directly use a dataset from this screen.
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <Typography variant="subtitle1" style={{ display: "inline-block", width: 80, fontSize: 12, fontWeight: 500 }}>
                            Dataset Name
                        </Typography>
                        <div style={{ display: "inline-block", fontSize: 12 }}>
                            <FormControl style={{ marginLeft: 8 }}>
                                <Input
                                    id="chart_name"
                                    value={this.state.dataset?.Name}
                                    type="text"
                                    onChange={(newValue) => this.setState({ dataset: { ...this.state.dataset, Name: newValue.target.value } })}
                                />
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <Typography variant="subtitle1" style={{ display: "inline-block", width: 80, fontSize: 12, fontWeight: 500 }}>
                            Columns

                            <div style={{ display: "inline-block", fontSize: 12, marginRight: 8 }}>
                                <FormControl style={{ marginLeft: 8, verticalAlign: 'middle' }}>
                                    <InputLabel htmlFor="column_name" shrink>Column Name</InputLabel>
                                    <Input
                                        id="column_name"
                                        value={this.state.newColumn.name}
                                        type="text"
                                        onChange={(newValue) => this.setState({ newColumn: { ...this.state.newColumn, name: newValue.target.value } })}
                                    />
                                </FormControl>
                            </div>

                            <VSelect
                                width="110px"
                                title="Column type"
                                options={this.state.columnDataTypes}
                                getOptionLabel={(option) => "" + option}
                                value={this.state.newColumn.type}
                                style={{ verticalAlign: 'middle' }}
                                onChange={(newValue) => {
                                    const newType = newValue ? newValue as string : "";
                                    const newColumn = { ...this.state.newColumn, type: newType };

                                    this.setState({ newColumn });
                                }}
                            />

                            <IconButton onClick={this.addNewColumn}>
                                <Icon className="fas fa-plus" />
                            </IconButton>
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <Typography variant="subtitle1" style={{ display: "inline-block", width: 80, fontSize: 12, fontWeight: 500 }}>
                            Sources

                            <VSelect
                                width="110px"
                                title="Column type"
                                style={{ verticalAlign: 'middle', marginLeft: 8 }}
                                options={this.state.dataSourceTypes}
                                getOptionLabel={(option) => "" + option}
                                value={this.state.selectedDataSourceType}
                                onChange={(newValue) => {
                                    const newType = newValue ? newValue as string : "";

                                    this.setState({ selectedDataSourceType: getDataSourceType(newType) });
                                }}
                            />

                            <IconButton onClick={() => {
                                if (!this.state.selectedDataSourceType) {
                                    AlertManager.showError("Specify source type"); //TODO: message
                                    return;
                                }

                                this.setState({
                                    dataset: {
                                        ...this.state.dataset,
                                        DataSources: [
                                            ...(this.state.dataset.DataSources || []),
                                            {
                                                SourceType: this.state.selectedDataSourceType,
                                                SourceInfo: generateDataSourceInfo(this.state.selectedDataSourceType),
                                            }
                                        ],
                                    }
                                });
                            }}>
                                <Icon className="fas fa-plus" />
                            </IconButton>
                        </Typography>
                    </Grid>
                    <Grid container item xs={3} justify="flex-end"
                        style={{ height: 0 }}>
                        <Button variant="contained"
                            onClick={() => this.callSaveDataset()}>
                            <Icon style={{ marginRight: 5 }} className="fas fa-check" />
                            Save Dataset
                        </Button>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', width: '100%', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        {
                            !isEmpty(this.state.dataset.Columns) &&
                            <VBox title="Columns" style={{ width: '100%' }}>
                                <Grid container>
                                    {
                                        Object.keys(this.state.dataset.Columns).map(key => {
                                            const column = this.state.dataset.Columns[key];

                                            return (
                                                <Grid item xs={3}
                                                    key={`${column.name}_row`}
                                                    style={{ position: 'relative', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        whiteSpace: 'nowrap',
                                                    }}>

                                                        <div style={{ position: "absolute", left: 0 }}>
                                                            <IconButton style={{ padding: 3 }}
                                                                onClick={() => {
                                                                    const newColumns: DatasetMetaData["Columns"] = {};

                                                                    Object.keys(this.state.dataset.Columns)
                                                                        .filter(c => c !== column.name)
                                                                        .forEach(c => {
                                                                            newColumns[c] = this.state.dataset.Columns[c];
                                                                        });

                                                                    this.setState({ dataset: { ...this.state.dataset, Columns: newColumns, } });
                                                                }}>
                                                                <Icon className="fas fa-times" />
                                                            </IconButton>
                                                        </div>

                                                        <Typography variant="subtitle1" style={{
                                                            display: "inline-block",
                                                            width: 80,
                                                            fontSize: 12,
                                                            fontWeight: 500,
                                                            marginLeft: 20,
                                                        }}>
                                                            {column.name}
                                                        </Typography>
                                                        <div style={{
                                                            display: "inline-block",
                                                            fontSize: 12,
                                                        }}>
                                                            <div style={{ display: "flex", flexDirection: "row", marginLeft: 8 }}>
                                                                <VSelect
                                                                    title="Column type"
                                                                    options={this.state.columnDataTypes}
                                                                    getOptionLabel={(option) => "" + option}
                                                                    value={column.type}
                                                                    onChange={(newValue) => {
                                                                        const newType = newValue ? newValue as string : "";
                                                                        const newColumn = { ...column, type: newType };

                                                                        this.setState({
                                                                            dataset: {
                                                                                ...this.state.dataset,
                                                                                Columns: { ...this.state.dataset.Columns, [newColumn.name]: newColumn },
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </VBox>
                        }
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', width: '100%', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        {
                            !isEmpty(this.state.dataset.DataSources) &&
                            <VBox title="Sources" style={{ width: '100%' }}>
                                <Grid container item xs={12} style={{ display: 'flex', width: '100%', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                    {
                                        orderBy(this.state.dataset.DataSources, item => item.IsActive, ["desc"]).map((source, idx) => {
                                            const htmlKey = `${source.SourceType} ${source.Id || idx}`;

                                            return (
                                                <Grid item xs={4} key={htmlKey} style={{ position: 'relative', wordWrap: "break-word" }}>
                                                    <VBox title={`${source.SourceType} ${source.Id || "[NEW]"}`}>
                                                        {
                                                            !!source.IsActive &&
                                                            <div style={{ position: "absolute", right: 0, top: 0 }}>
                                                                <IconButton style={{ margin: 10, padding: 10 }} onClick={() => this.removeSource(idx)}>
                                                                    <Icon className="fas fa-times" />
                                                                </IconButton>
                                                            </div>
                                                        }
                                                        <div style={{ position: "absolute", padding: 10, margin: 10, left: 0, top: 0 }}>
                                                            {
                                                                source.IsActive ?
                                                                    <Typography variant="subtitle1"
                                                                        style={{ color: "green", fontSize: 12, fontWeight: 500 }}>
                                                                        <Icon fontSize="small" className="fas fa-check" />
                                                                        Active
                                                                    </Typography>
                                                                    :
                                                                    <Typography variant="subtitle1"
                                                                        style={{ alignItems: "center", fontSize: 12, color: "red", fontWeight: 500 }}>
                                                                        <Icon fontSize="small" className="fas fa-times" />
                                                                        Inactive
                                                                    </Typography>
                                                            }
                                                        </div>
                                                        <FormControl style={{ width: "100%" }}>
                                                            <TextField
                                                                id={`${htmlKey}_input`}
                                                                style={{ margin: 10, width: 'auto', maxWidth: 540 }}
                                                                multiline
                                                                placeholder="Placeholder"
                                                                label={Object.keys(source.SourceInfo)[0]}
                                                                defaultValue={getDataSourceValue(source)}
                                                                variant="outlined"
                                                                onChange={(newValue) => this.updateSourceInfo(idx, newValue.target.value)}
                                                            />
                                                        </FormControl>
                                                    </VBox>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </VBox>
                        }
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', width: '100%', alignItems: 'center', whiteSpace: 'nowrap' }}>
                        <VBox title="Option Updater" style={{ width: '100%' }}>
                            <FormControl style={{ width: "100%" }}>
                                <TextField
                                    id="option_updater_input"
                                    style={{ margin: 10, width: 'auto', maxWidth: 540 }}
                                    multiline
                                    placeholder="Placeholder"
                                    label="Option Updater"
                                    defaultValue={this.state.optionUpdater?.SourceInfo?.query}
                                    variant="outlined"
                                    onChange={(newValue) => this.updateOptionUpdater(newValue.target.value)}
                                />
                            </FormControl>
                        </VBox>
                    </Grid>
                </Grid>

            </ReportViewer >
        );
    }
}

export default CreateDataset;