import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Card, CardContent, FormControl, Grid, Icon, IconButton, Input, InputLabel, Typography } from '@material-ui/core';
import VSelect from '../VSelect/VSelect';
import { DataSetColumn, DataSetFilter, Dataset } from '../../utils/types';
import VDatasetFilterItem from './VDatasetFilterItem';
import VDatasetGroupItem from './VDatasetGroupItem';
import VBox from '../VBox/VBox';
import VDatasetColumnSelector from './VDatasetColumnSelector';
import { isEmpty } from 'lodash';
import { generateUuid } from '../../utils/common';

const DEFAULT_AGGREGATION = "First";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            margin: '3px',
        },
        header: {
            width: '20em'
        },
        details: {
            display: 'flex',
            padding: theme.spacing(1),
            width: "100%",
        },
        delete: {
            padding: "0 5px 0 0",
        },
        dataFilterItem: {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
        dataLabel: {
            display: "inline-block",
            width: 80,
            fontSize: 12,
            fontWeight: 500,
        },
        dataValueGroup: {
            display: "inline-block",
            fontSize: 12,
            "& > *": {
                marginLeft: theme.spacing(1),
            },
        },
        outerContainer: {
            border: "1px solid rgba(224, 224, 224, 1)",
            width: "70%",
            textAlign: "left"
        },
    }),
);

interface IProps {
    id: string,
    name: string,
    alias: string,
    columns: { [key: string]: { name: string, [key: string]: any } },
    onDelete?: (datasetId: string) => void,
    chartTypes: string[],
    dataset: Dataset,
    options?: { [key: string]: string }[],
    static_limits?: DataSetFilter["static_limits"],
    onDatasetChanged: (newValues: Dataset) => void,
    aggregations: string[],
}

const VDatasetCard: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    const [filters, setFilters] = React.useState<DataSetFilter["filter"]>(props.dataset.filter);

    const [groupColumns, setGroupColumns] = React.useState<{ groups: string[], aggregations: { [column_name: string]: string } }>(
        props.dataset.group_by || { groups: [], aggregations: {} });

    const [chartGroups, setChartGroups] = React.useState<DataSetFilter["chart_groups"]>(props.dataset.chart_groups);

    const addGrouping = (columnToAdd: string) => {
        const newGroupColumns = {
            groups: [
                ...groupColumns.groups,
                columnToAdd
            ],
            aggregations: Object.keys(groupColumns.aggregations)
                .filter(a => a !== columnToAdd)
                .reduce((map: { [column_name: string]: string }, column) => {
                    map[column] = groupColumns.aggregations[column];
                    return map;
                }, {}),
        };

        setGroupColumns(newGroupColumns)

        props.onDatasetChanged({ ...props.dataset, group_by: newGroupColumns });
    };

    const addAggregation = (columnToAdd: string) => {
        const newGroupColumns = {
            groups: groupColumns.groups.filter(a => a !== columnToAdd),
            aggregations: {
                ...groupColumns.aggregations,
                [columnToAdd]: DEFAULT_AGGREGATION
            }
        };

        setGroupColumns(newGroupColumns)

        props.onDatasetChanged({ ...props.dataset, group_by: newGroupColumns });
    };

    const onAggregationChanged = (columnName: string, aggregation: string) => {
        const newGroupColumns = { ...groupColumns };

        newGroupColumns.aggregations[columnName] = aggregation;

        setGroupColumns(newGroupColumns)

        props.onDatasetChanged({ ...props.dataset, group_by: newGroupColumns });
    };

    const chartOptions = props.chartTypes.map(chartType => chartType.split(' ').map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(' '));

    const id = generateUuid();
    const columnsArr = Object.keys(props.columns).map(c => props.columns[c]);

    return (
        <Card className={classes.root}>
            <div className={classes.header}>
                <CardContent>
                    <Typography component="h6" variant="h6">
                        {
                            props.onDelete &&
                            <IconButton className={classes.delete} onClick={() => { if (props.onDelete) props.onDelete(props.id) }}>
                                <Icon className="fas fa-times" />
                            </IconButton>
                        }
                        {props.alias}
                    </Typography>
                    {props.alias !== props.name &&
                        <Typography component="h6" variant="h6">
                            ({props.name})
                        </Typography>}
                </CardContent>
            </div>
            <div className={classes.details}>
                <Grid container spacing={0} justify="flex-start" alignItems="stretch">
                    <Grid item xs={12} md={4} >
                        <VBox title="Design Chart" height="100%">
                            <Grid item xs={12} className={classes.dataFilterItem}>
                                <Typography variant="subtitle1" className={classes.dataLabel}>
                                    Dataset Alias
                                </Typography>
                                <div className={classes.dataValueGroup}>
                                    <FormControl>
                                        <InputLabel htmlFor={`alias_${id}`} shrink>Alias</InputLabel>
                                        <Input
                                            id={`alias_${id}`}
                                            value={props.alias}
                                            type="text"
                                            onChange={(newValue) => props.onDatasetChanged({ ...props.dataset, alias: newValue.target.value })}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} className={classes.dataFilterItem}>
                                <Typography variant="subtitle1" className={classes.dataLabel}>
                                    Chart Type
                                </Typography>
                                <div className={classes.dataValueGroup}>
                                    <VSelect
                                        disableClearable
                                        title="Chart Type"
                                        value={props.dataset.chart_type}
                                        getOptionLabel={(option) => `${option}`.split(' ').map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(' ')}
                                        options={chartOptions}
                                        onChange={(newValue) => props.onDatasetChanged({ ...props.dataset, chart_type: newValue as string })}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} className={classes.dataFilterItem}>
                                <Typography variant="subtitle1" className={classes.dataLabel}>
                                    X Axis
                                </Typography>
                                <div className={classes.dataValueGroup}>
                                    <VSelect
                                        title="X Axis"
                                        value={props.columns[props.dataset.x_axis || "not selected"]}
                                        options={columnsArr}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(newValue) => props.onDatasetChanged(
                                            {
                                                ...props.dataset,
                                                x_axis: (newValue as { name: string })?.name
                                            })
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} className={classes.dataFilterItem}>
                                <Typography variant="subtitle1" className={classes.dataLabel}>
                                    Y Axis
                                </Typography>
                                <div className={classes.dataValueGroup}>
                                    <VSelect
                                        title="Y Axis"
                                        value={props.columns[props.dataset.y_axis || "not selected"]}
                                        options={columnsArr}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(newValue) => props.onDatasetChanged(
                                            {
                                                ...props.dataset,
                                                y_axis: (newValue as { name: string })?.name
                                            })
                                        }
                                    />
                                </div>
                            </Grid>
                            <VBox title="Chart Groups">
                                <VDatasetColumnSelector
                                    columns={columnsArr}
                                    selectedColumns={chartGroups?.map(g => ({ name: g })) || []}
                                    onAdd={(currentSelected) => {
                                        const newChartGroups = chartGroups ? [...chartGroups, currentSelected.name] : [currentSelected.name];
                                        setChartGroups(newChartGroups)

                                        props.onDatasetChanged({ ...props.dataset, chart_groups: newChartGroups });
                                    }}
                                />
                                {
                                    chartGroups?.map((cell, idx) => {
                                        return (
                                            <Grid item xs={12} key={`${cell}_${idx}`}>
                                                <VDatasetGroupItem
                                                    isSelected={true}
                                                    cellName={cell}
                                                    onDelete={(columnToDelete) => {
                                                        const newGroupColumns = chartGroups.filter(a => a !== columnToDelete);

                                                        setChartGroups(newGroupColumns)

                                                        props.onDatasetChanged({ ...props.dataset, chart_groups: newGroupColumns });
                                                    }}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </VBox>
                        </VBox>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <VBox title="Filter Data" height="100%">
                            <Grid item xs={12} className={classes.dataFilterItem}>
                                <VDatasetColumnSelector
                                    columns={columnsArr}
                                    selectedColumns={filters ? Object.keys(filters).map(k => filters[k]) : []}
                                    onAdd={(currentSelected) => {
                                        const newFilters = { ...filters, [currentSelected.name]: currentSelected };

                                        setFilters(newFilters)
                                    }}
                                />
                            </Grid>
                            {
                                (!filters || isEmpty(filters)) ?
                                    <Typography variant="subtitle1">
                                        Please add filters.
                                    </Typography>
                                    :
                                    Object.keys(filters).map(key => {
                                        const cell = filters[key];
                                        return (
                                            <Grid item xs={12} key={`filter_${cell.name}`}>
                                                <VDatasetFilterItem
                                                    cellDetails={cell as DataSetColumn}
                                                    dataset={props.dataset}
                                                    options={props.options}
                                                    static_limits={props.static_limits}
                                                    onDataSetChanged={(newFilter) => {
                                                        setFilters(newFilter);

                                                        const updatedDataset = { ...props.dataset, filter: { ...props.dataset.filter, ...newFilter } }

                                                        props.onDatasetChanged(updatedDataset)
                                                    }}
                                                    onDelete={() => {
                                                        const newFilters = Object.keys(filters).filter(c => c !== cell.name)
                                                            .reduce((map: DataSetFilter["filter"], column) => {
                                                                if (!map) map = {};
                                                                map[column] = filters[column];
                                                                return map;
                                                            }, {});

                                                        setFilters(newFilters)

                                                        props.onDatasetChanged({ ...props.dataset, filter: newFilters });
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    })
                            }
                        </VBox>
                    </Grid>
                    <Grid item xs={12} md={4} >
                        <VBox title="Manage Data" height="100%">
                            <VBox title="Group Columns">
                                <VDatasetColumnSelector
                                    columns={columnsArr.filter(c => !Object.keys(groupColumns.aggregations).find(a => a === c.name))}
                                    selectedColumns={groupColumns.groups.map(g => ({ name: g }))}
                                    onAdd={(currentSelected) => {
                                        addGrouping(currentSelected.name)
                                    }}
                                />
                                {
                                    groupColumns.groups.map((cell, idx) => {
                                        return (
                                            <Grid item xs={12} key={`${cell}_${idx}`}>
                                                <VDatasetGroupItem
                                                    isSelected={true}
                                                    defaultOption={DEFAULT_AGGREGATION}
                                                    aggregations={props.aggregations}
                                                    cellName={cell}
                                                    onAggregationChanged={onAggregationChanged}
                                                    onDelete={(columnToDelete) => {
                                                        const newGroupColumns = {
                                                            groups: groupColumns.groups.filter(a => a !== columnToDelete),
                                                            aggregations: groupColumns.aggregations,
                                                        };

                                                        setGroupColumns(newGroupColumns)

                                                        props.onDatasetChanged({ ...props.dataset, group_by: newGroupColumns });
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    })
                                }
                            </VBox>
                            <VBox title="Aggregations">
                                <VDatasetColumnSelector
                                    columns={columnsArr.filter(c => !groupColumns.groups.find(g => g === c.name))}
                                    selectedColumns={Object.keys(groupColumns.aggregations).map(g => ({ name: g }))}
                                    onAdd={(currentSelected) => {
                                        addAggregation(currentSelected.name)
                                    }}
                                />

                                {
                                    Object.keys(groupColumns.aggregations).map((column_name, idx) => {
                                        return (
                                            <Grid item xs={12} key={`${column_name}_${idx}`}>
                                                <VDatasetGroupItem
                                                    isSelected={false}
                                                    defaultOption={groupColumns.aggregations[column_name] || DEFAULT_AGGREGATION}
                                                    aggregations={props.aggregations}
                                                    cellName={column_name}
                                                    onAggregationChanged={onAggregationChanged}
                                                    onDelete={(columnToDelete) => {
                                                        const newGroupColumns = {
                                                            groups: groupColumns.groups,
                                                            aggregations: Object.keys(groupColumns.aggregations)
                                                                .filter(a => a !== columnToDelete)
                                                                .reduce((map: { [column_name: string]: string }, column) => {
                                                                    map[column] = groupColumns.aggregations[column];
                                                                    return map;
                                                                }, {}),
                                                        };

                                                        setGroupColumns(newGroupColumns)

                                                        props.onDatasetChanged({ ...props.dataset, group_by: newGroupColumns });
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    })
                                }
                            </VBox>
                        </VBox>
                    </Grid>
                </Grid>
            </div>
        </Card>
    )
}

export default VDatasetCard;
