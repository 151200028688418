import { Icon, IconButton, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { CSSProperties } from 'react';

interface IProps {
    minutes: number,
    label: string,
    timerEvent?: () => void,
    lastRefresh?: Date,
    lastRefreshResult?: boolean,
}

interface IState {
    time: { minutes: number, seconds: number },
    seconds: number,
    paused: boolean,
    startedFor?: Date,
}

class VTimer extends React.Component<IProps, IState> {
    timer: number | null = null;

    constructor(props: IProps, state: IState) {
        super(props);
        this.state = {
            time: { minutes: 0, seconds: 0 },
            seconds: 0,
            paused: false,
        }

        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs: number) {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return {
            minutes,
            seconds
        };
    }

    componentDidMount() {
        this.startTimer()
    }

    componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (!this.state.paused && prevProps.lastRefresh !== this.state.startedFor) {
            this.restartTimer();
        }
    }

    startTimer() {
        if (this.timer || this.timer === 0)
            return;

        const seconds = this.state.seconds ? this.state.seconds : this.props.minutes * 60;

        this.setState({
            seconds,
            time: this.secondsToTime(seconds),
            startedFor: this.props.lastRefresh
        }, () => {
            if (this.state.seconds > 0 && this.timer === null) {
                this.timer = +setInterval(this.countDown, 1000);
            }
        });
    }

    restartTimer() {
        if (this.timer && this.timer !== 0) {
            clearInterval(this.timer);
            this.timer = null;
        }

        this.setState({ seconds: 0 }, () => this.startTimer());
    }

    countDown() {
        if (!this.state)
            return;

        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            if (this.props.timerEvent)
                this.props.timerEvent();

            this.restartTimer();
        }
    }

    onPauseChanged() {
        const newPaused = !this.state.paused;

        if (newPaused) {
            if (this.timer && this.timer !== 0) {
                clearInterval(this.timer)
                this.timer = null;
            }
        }
        else {
            this.startTimer()
        }

        this.setState({ paused: newPaused });
    }

    render() {
        const textStyle: CSSProperties = { fontWeight: 900, whiteSpace: "nowrap", alignContent: "center" };

        let resultMessage: string = "";
        let resultColor: string = "black";

        if (this.props.lastRefreshResult !== undefined) {
            if (this.props.lastRefreshResult) {
                resultMessage = " (Successful) at";
                resultColor = "green";
            }
            else {
                resultMessage = " (Failed) at"
                resultColor = "red";
            }
        }

        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {
                    this.props.lastRefresh &&
                    <Typography variant="subtitle1"
                        style={{...textStyle, color:resultColor}}>
                        Last Refresh{resultMessage}: {moment(this.props.lastRefresh).format('DD.MM.yyyy HH:mm:ss')}
                    </Typography>
                }
                <Typography variant="subtitle1"
                    style={{ ...textStyle, paddingLeft: 15 }}>
                    {this.props.label}: <span style={{ color: this.state.paused ? "red" : "green" }}>
                        {this.state.time.minutes.toString().padStart(2, '0')}: {this.state.time.seconds.toString().padStart(2, '0')}
                    </span>
                </Typography>
                <IconButton onClick={() => this.onPauseChanged()}>
                    {
                        this.state.paused ?
                            <Icon className="far fa-play-circle" />
                            :
                            <Icon className="far fa-pause-circle" />
                    }
                </IconButton>
            </div >
        )
    }
}

export default VTimer;
