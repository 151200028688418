import './Login.css';

import React, { SyntheticEvent } from 'react';
import { login } from '../../apis/vitusApi';
import { connect } from 'react-redux';
import { setUser } from '../../store/actions';
import { IAuthState } from '../../store/reducers/authReducer';
import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { GlobalStates } from '../../store/store';
import { createSpinner } from '../../utils/spinnerManager';
import { Button, Container, Grid, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { ClassNameMap, ClassKeyOfStyles, } from '@material-ui/styles';
import { handleApiError } from '../../utils/common';
import { endpointNames } from '../../utils/staticInfo';
import history from '../../system/history';
import { hashPass } from '../../utils/authUtils';

const styles = (theme: Theme) => ({
    container: {
        padding: theme.spacing(3),
    }
});

interface IProps {
    auth: IAuthState,
    classes?: ClassNameMap<ClassKeyOfStyles<typeof styles>>
}

interface IState {
    showError: boolean,
    isLoading: boolean,
}

class Login extends React.Component<IProps & RouteComponentProps<
    { [key: string]: string | undefined },
    { [key: string]: string | undefined },
    { from: string }>,
    IState> {
    userName = React.createRef<HTMLInputElement>();
    password = React.createRef<HTMLInputElement>();

    state = { showError: false, isLoading: false }

    onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!this.userName.current || !this.password.current)
            return;

        this.setState({ isLoading: true });

        this.onLogin(this.userName.current.value, hashPass(this.password.current.value));
    }

    showError() {
        this.setState({ showError: true, isLoading: false });

        if (this.password.current)
            this.password.current.value = "";
    }

    onLogin(username: string, password: string) {
        this.setState({ showError: false });

        const spinner = createSpinner();

        login({
            username: username,
            password: password
        }).then(response => {
            if (response.data.data?.toLowerCase() === "true") {
                history.go(0);
            }
            else {
                spinner.hide();
                this.showError();
            }
        }, error => {
            handleApiError(error);
            spinner.hide();
            this.showError();
        });
    }

    clearErrorMessage = () => {
        if (this.state.showError)
            this.setState({ showError: false });
    }

    render() {
        if (this.props.auth.isLoggedIn) {
            const { from } = this.props.location.state || { from: { pathname: endpointNames.main } };

            return (
                <Redirect to={from} />
            );
        }

        return (
            <Container className={this.props.classes?.container || ""} maxWidth="xs">
                <form onSubmit={this.onSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                        autoFocus
                                        inputRef={this.userName}
                                        onChange={this.clearErrorMessage}
                                        label="Username"
                                        name="username"
                                        size="small"
                                        variant="outlined" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        inputRef={this.password}
                                        onChange={this.clearErrorMessage}
                                        label="Password"
                                        name="password"
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained">
                                Log in
                            </Button>
                        </Grid>
                        {this.state.showError &&
                            <Grid item xs={12}>
                                <Typography color="error"
                                    variant="subtitle1"
                                    gutterBottom>
                                    Login failed.
                                </Typography>
                            </Grid>}
                    </Grid>
                </form>
            </Container>
        );
    }
}

const mapStateToProps = (state: GlobalStates) => {
    return {
        auth: state.auth
    };
};

export default withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, { setUser })(Login)
);
