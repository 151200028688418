import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import { SankeyChart, SankeySeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { getEuLng } from '../../../apis/vitusApi';
import _, { uniq } from 'lodash';
import { Grid } from '@material-ui/core';
import { createSpinner } from '../../../utils/spinnerManager';
import { ILngFlowsResponse, LngFlowSeason } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { handleApiError, makeTitle, shortenNumber } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import { ITabProps } from './gasBalanceUtils';

type ECOption = echarts.ComposeOption<GridComponentOption | SankeySeriesOption | BarSeriesOption>;

echarts.use(
    [SankeyChart, BarChart, GridComponent]
);

interface IProps {
    season: "injection" | "withdraw",
}

interface IState {
    sankeyData: LngFlowSeason["cross_country_lng"],
    barSeasonData: LngFlowSeason["seasonal_lng_by_country"],
    barData: ILngFlowsResponse["success"]["total_lng_flows"],
}

class LngFlows extends React.Component<ITabProps & IProps, IState> {
    sankeyChartRef: ReactECharts | null = null;
    barChartRef: ReactECharts | null = null;
    barSeasonChartRef: ReactECharts | null = null;

    sankeyChartOptions: ECOption = {

    }

    barChartOptions: ECOption = {
        animation: false,
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
        },
        grid: {
            top: 5,
            right: 200,
        },
    };

    state: IState = {
        sankeyData: [],
        barData: [],
        barSeasonData: [],
    }

    componentDidMount() {
        if (!this.props.data)
            this.getData();
        else
            this.storeData(this.props.data);
    }

    getData() {
        const spinner = createSpinner();

        getEuLng().then(response => {
            if (response.data.success) {
                this.storeData(response.data.success);
                this.props.onDataChanged(response.data.success);
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    storeData(response: ILngFlowsResponse["success"]) {
        const seasonalData = response.seasonal_lng_flows[this.props.season];

        this.setState({
            sankeyData: seasonalData.cross_country_lng || [],
            barSeasonData: seasonalData.seasonal_lng_by_country || [],
            barData: response.total_lng_flows || [],
        }, this.updateCharts);
    }

    updateCharts() {
        this.updateSankey();
        this.updateBarCharts();
    }

    updateBarCharts = () => {
        const barChartInstance = this.barChartRef?.getEchartsInstance();
        barChartInstance.showLoading();

        const data = this.state.barData;

        barChartInstance.clear();

        const series = _(data)
            .groupBy("SourceCountry")
            .map((value, countryName) => {
                return {
                    type: 'bar',
                    name: countryName,
                    stack: 'total',
                    data: value.map(d => [d.Value, d.Year.toString()]),
                    label: {
                        show: true,
                        formatter: (params: { data: any[] }) => shortenNumber(params.data[0]),
                    },
                    emphasis: {
                        focus: 'series'
                    },
                };
            })
            .value();

        barChartInstance.setOption({
            ...this.barChartOptions,
            yAxis: {
                type: 'category',
                data: _(data.map(d => d.Year.toString())).uniq().sortBy().value().reverse(),
            },
            series: series,
            legend: {
                right: 0,
                orient: 'vertical',
                type: 'scroll',
                data: series.map(s => s.name),
            },
        });

        barChartInstance.hideLoading();

        const barSeasonChartInstance = this.barSeasonChartRef?.getEchartsInstance();
        barSeasonChartInstance.showLoading();

        const seasonData = this.state.barSeasonData;

        barSeasonChartInstance.clear();

        const seasonSeries = _(seasonData)
            .groupBy("SourceCountry")
            .map((value, countryName) => {
                return {
                    type: 'bar',
                    name: countryName,
                    stack: 'total',
                    data: value.map(d => [d.Value, d.Season]),
                    label: {
                        show: true,
                        formatter: (params: { data: any[] }) => shortenNumber(params.data[0]),
                    },
                    emphasis: {
                        focus: 'series'
                    },
                };
            })
            .value();

        barSeasonChartInstance.setOption({
            ...this.barChartOptions,
            yAxis: {
                type: 'category',
                data: _(seasonData.map(d => d.Season)).uniq().sortBy().value().reverse(),
            },
            series: seasonSeries,
            legend: {
                right: 0,
                orient: 'vertical',
                type: 'scroll',
                data: seasonSeries.map(s => s.name),
            },
        });

        barSeasonChartInstance.hideLoading();
    }

    updateSankey = () => {
        const sankeyInstance = this.sankeyChartRef?.getEchartsInstance();
        sankeyInstance.showLoading();

        const data = this.state.sankeyData;

        const allCountries = uniq(data.flatMap(d => [d.SourceCountry, d.SinkCountry]));

        sankeyInstance.clear();
        sankeyInstance.setOption({
            ...this.sankeyChartOptions,
            series: {
                type: 'sankey',
                layout: 'none',
                emphasis: {
                    focus: 'adjacency'
                },
                data: allCountries.map(c => ({ name: c })),
                links: data.map(d => ({
                    source: d.SourceCountry,
                    target: d.SinkCountry,
                    value: d.Value,
                }))
            },
        });

        sankeyInstance.hideLoading();
    }

    render() {
        return (
            <Grid container justify="flex-start" alignItems="flex-start">
                {
                    this.state.sankeyData &&
                    <Grid item xs={4} >
                        <VChart
                            title={`Current ${makeTitle(this.props.season)} Season LNG Flows`}
                            height={750}
                            report={
                                <ReactECharts
                                    ref={(e) => { this.sankeyChartRef = e; }}
                                    echarts={echarts}
                                    option={this.sankeyChartOptions}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ width: '99%', height: 700 }}
                                />
                            } >
                        </VChart>
                    </Grid>
                }
                <Grid item xs={8} >
                    {
                        this.state.barSeasonData &&
                        <Grid item xs={12} >
                            <VChart
                                title={`${makeTitle(this.props.season)} Season Period LNG Flow Amounts by Country`}
                                height={375}
                                report={
                                    <ReactECharts
                                        ref={(e) => { this.barSeasonChartRef = e; }}
                                        echarts={echarts}
                                        option={this.barChartOptions}
                                        notMerge={true}
                                        lazyUpdate={true}
                                        style={{ width: '99%', height: 375 }}
                                    />
                                } >
                            </VChart>
                        </Grid>
                    }
                    {
                        this.state.barData &&
                        <Grid item xs={12} >
                            <VChart
                                title="LNG Flow Amounts by Country"
                                height={375}
                                report={
                                    <ReactECharts
                                        ref={(e) => { this.barChartRef = e; }}
                                        echarts={echarts}
                                        option={this.barChartOptions}
                                        notMerge={true}
                                        lazyUpdate={true}
                                        style={{ width: '99%', height: 375 }}
                                    />
                                } >
                            </VChart>
                        </Grid>
                    }
                </Grid>
            </Grid >
        );
    }
}

export default LngFlows;