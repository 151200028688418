import { combineReducers, createStore } from 'redux';
import { authReducer, IAuthState } from './reducers/authReducer';
import { IGlobalSettings, globalSettingsReducer } from './reducers/globalSettingsReducer';
import { IReportInfo, reportInfoReducer } from './reducers/reportInfoReducer';

export type GlobalStates = {
    auth: IAuthState,
    settings: IGlobalSettings,
    reportInfo: IReportInfo
}

export const store = createStore(
    combineReducers<GlobalStates>({
        auth: authReducer,
        settings: globalSettingsReducer,
        reportInfo: reportInfoReducer,
    })
);
