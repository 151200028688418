import { IAuthState } from './reducers/authReducer';
import { IGlobalSettings } from './reducers/globalSettingsReducer';

export type SetUserAction = {
    type: 'SET_USER',
    payload: IAuthState,
}

export const setUser = (user: IAuthState): SetUserAction => ({
    type: 'SET_USER',
    payload: user
});

export type SetUserPreferencesAction = {
    type: 'SET_USER_PREF',
    payload: IGlobalSettings,
}

export const setUserPreferences = (preferences: IGlobalSettings): SetUserPreferencesAction => ({
    type: 'SET_USER_PREF',
    payload: preferences
});

export type ShowSpinnerAction = {
    type: 'SHOW_SPINNER',
    payload: { caller: string },
}

export const showSpinner = (caller: string): ShowSpinnerAction => ({
    type: 'SHOW_SPINNER',
    payload: { caller },
});

export type HideSpinnerAction = {
    type: 'HIDE_SPINNER',
    payload: { caller: string },
}

export const hideSpinner = (caller: string): HideSpinnerAction => ({
    type: 'HIDE_SPINNER',
    payload: { caller },
});

export type UpdateRecentReportsAction = {
    type: 'UPDATE_RECENT',
    payload: { name: string, path: string, thumbnail: string, reportKey: string, userName: string, cleanPath?: string, shortName?: string },
}

export const updateRecentReports = (report: UpdateRecentReportsAction["payload"]): UpdateRecentReportsAction => {
    return {
        type: 'UPDATE_RECENT',
        payload: report,
    }
};

export type InitializeRecentReportsAction = {
    type: 'INITIALIZE_RECENT',
    payload: UpdateRecentReportsAction["payload"][],
}

export const initializeRecentReports = (reports: InitializeRecentReportsAction["payload"]) => {
    /*
    TODO: test after other reports implemented:
    1.login with user authorized to reports X, Y, Z
    2.logout
    3.login with user authorized to reports X, Y
    4.check if report auth is violated

    TODO: better: give reports code names not to store as clear text.
    */

    return {
        type: 'INITIALIZE_RECENT' as InitializeRecentReportsAction["type"],
        payload: reports,
    }
}