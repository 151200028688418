import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormControl, Icon, IconButton, Input, InputLabel, Typography } from '@material-ui/core';
import VDatePicker, { FloatingDate, isFloating } from '../VDatePicker/VDatePicker';
import VSelect from '../VSelect/VSelect';
import { DataSetColumn, DataSetFilter, Dataset } from '../../utils/types';
import { shortStringToDate, toDateString } from '../../utils/common';
import { isArray, isEmpty, isObject, uniq } from 'lodash';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IFilterProps {
    cellDetails: DataSetColumn,
    onDelete: () => void,
    onDataSetChanged: (newFilter: Dataset["filter"]) => void,
    dataset: Dataset,
    options?: { [key: string]: string }[],
    static_limits?: { [key: string]: { min: any, max: any } },
}

type FilterKey = "" | "min" | "max";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        delete: {
            padding: "0 5px 0 0",
        },
        dataFilterItem: {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
        filterLabel: {
            display: "inline-block",
            width: 100,
            fontSize: 12,
            fontWeight: 500,
        },
        dataValueGroup: {
            display: "inline-block",
            fontSize: 12,
            "& > *": {
                marginLeft: theme.spacing(1),
            },
        },
    }),
);

const staticLimits: DataSetFilter["static_limits"] = {
    Day: { min: 1, max: 31 },
    Month: { min: 1, max: 12 },
    Hour: { min: 0, max: 24 },
}

const VDatasetFilterItem: React.FC<IFilterProps> = (props: IFilterProps) => {
    const classes = useStyles();

    const cellName = props.cellDetails.name;
    let filter = null;

    const handleFilterChange = (prefix: FilterKey, newValue: any) => {
        const filter = props.dataset.filter ?? {};

        if (!prefix) {
            if (isArray(newValue))
                filter[cellName] = { name: cellName, type: props.cellDetails.type, value: newValue.map(v => v.name) };
            else if (isObject(newValue))
                filter[cellName] = { name: cellName, type: props.cellDetails.type, ...newValue };
            else
                filter[cellName] = { name: cellName, type: props.cellDetails.type, value: newValue };
        }
        else {
            if (!filter[cellName])
                filter[cellName] = { name: cellName, type: props.cellDetails.type };

            filter[cellName][prefix] = newValue;
        }

        filter[cellName].type = props.cellDetails.type;

        props.onDataSetChanged(filter)
    }

    const getDateFilter = (dateFormat: string) => {
        let minValue: Date | FloatingDate;
        let maxValue: Date | FloatingDate;
        const dateObj: { min?: any, max?: any } = {};

        if (isFloating(props.dataset?.filter?.[cellName]?.["min"])) {
            minValue = props.dataset?.filter?.[cellName]?.["min"];
            dateObj.min = minValue;
        } else {
            minValue = shortStringToDate(props.dataset?.filter?.[cellName]?.["min"]) ?? new Date();
            dateObj.min = toDateString(dateFormat, minValue);
        }

        if (isFloating(props.dataset?.filter?.[cellName]?.["max"])) {
            maxValue = props.dataset?.filter?.[cellName]?.["max"];
            dateObj.max = maxValue;
        } else {
            maxValue = shortStringToDate(props.dataset?.filter?.[cellName]?.["max"]) ?? new Date();
            dateObj.max = toDateString(dateFormat, maxValue);
        }

        initializers.push(() =>
            handleFilterChange("", dateObj));

        const dateChangeHandler = (prefix: "min" | "max", newValue: MaterialUiPickersDate | null | FloatingDate, format: string, floatingDatesSelected?: boolean) => {
            if (floatingDatesSelected)
                handleFilterChange(prefix, newValue)
            else
                handleFilterChange(prefix, toDateString(format, (newValue as MaterialUiPickersDate)?.toDate()))
        };

        return (
            <div className={classes.dataValueGroup}>
                <VDatePicker
                    allowFloatingDates
                    variant="inline"
                    format={dateFormat}
                    margin="normal"
                    label="Min"
                    value={minValue}
                    onCustomChange={(date, floatingDatesSelected) => dateChangeHandler("min", date, dateFormat, floatingDatesSelected)}
                />
                <VDatePicker
                    allowFloatingDates
                    variant="inline"
                    format={dateFormat}
                    margin="normal"
                    label="Max"
                    value={maxValue}
                    onCustomChange={(date, floatingDatesSelected) => dateChangeHandler("max", date, dateFormat, floatingDatesSelected)}
                />
            </div>
        )

    }

    let optionNames: (string | number)[] = [];

    if (props.options?.length) {
        const availableOptions = !isEmpty(props.dataset?.filter) ?
            props.options.filter(option => {
                if (!props.dataset?.filter)
                    return false;

                return Object.keys(props.dataset?.filter)
                    .map(key =>
                        key === cellName // filter is current column
                        || isEmpty(props.dataset?.filter?.[key].value) // filter is empty
                        || option[key] === props.dataset?.filter?.[key].value // filter matchs the option
                        || (isArray(props.dataset?.filter?.[key].value)
                            && props.dataset?.filter?.[key].value.includes(option[key]))) // filter is an array and contains the option
                    .every(b => b); // all filters are satisfied
            })
            :
            props.options; // no filter selected, all options are available

        optionNames = uniq(availableOptions
            .map(row => row?.[cellName])
            .filter(cell => cell));
    }

    const options = optionNames.map(o => { return { name: o.toString(), title: o.toString() } })

    const initializers: (() => void)[] = [];

    switch (props.cellDetails.type) {
        case "Number":
            const minNValue = props.dataset?.filter?.[cellName]?.["min"] !== undefined ?
                props.dataset?.filter?.[cellName]?.["min"] : "";
            const maxNValue = props.dataset?.filter?.[cellName]?.["max"] !== undefined ?
                props.dataset?.filter?.[cellName]?.["max"] : "";

            const staticMin = props.static_limits?.[cellName]?.min !== undefined ?
                props.static_limits?.[cellName]?.min : staticLimits?.[cellName]?.min;

            const staticMax = props.static_limits?.[cellName]?.max !== undefined ?
                props.static_limits?.[cellName]?.max : staticLimits?.[cellName]?.max;

            const inputProps: { [key: string]: any } = {};

            if (staticMin !== undefined)
                inputProps.min = staticMin;

            if (staticMax !== undefined)
                inputProps.max = staticMax;

            filter = (
                <div className={classes.dataValueGroup}>
                    <FormControl style={{ width: 50 }}>
                        <InputLabel htmlFor="min_number_filter" shrink>Min</InputLabel>
                        <Input
                            id="min_number_filter"
                            value={minNValue}
                            inputProps={inputProps}
                            type="number"
                            onChange={(newValue) => {
                                let value = +newValue.target.value;

                                if (staticMin !== undefined && value < staticMin) value = staticMin;
                                if (staticMax !== undefined && value > staticMax) value = staticMax;

                                handleFilterChange("min", value)
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: 50 }}>
                        <InputLabel htmlFor="max_number_filter" shrink>Max</InputLabel>
                        <Input
                            id="max_number_filter"
                            value={maxNValue}
                            inputProps={inputProps}
                            type="number"
                            onChange={(newValue) => {
                                let value = +newValue.target.value;

                                if (staticMin !== undefined && value < staticMin) value = staticMin;
                                if (staticMax !== undefined && value > staticMax) value = staticMax;

                                handleFilterChange("max", value)
                            }}
                        />
                    </FormControl>
                </div>
            )

            break;
        case "String":
            const value = props.dataset?.filter?.[cellName]?.["value"] || "";

            filter = (
                <div className={classes.dataValueGroup}>
                    <VSelect
                        multiple
                        value={!value ? [] : value.map((v: string) => { return { name: v, title: v } })}
                        title={cellName}
                        options={options}
                        getOptionLabel={(option) => option.name}
                        onChange={(newValue) => handleFilterChange("", newValue)}
                    />
                </div>
            )

            break;
        case "Date":
            filter = getDateFilter("DD/MM/yyyy");
            break;
        case "DateTime":
            filter = getDateFilter("DD/MM/yyyy HH:mm");
            break;
        default:
            throw Error('Unmapped data type');
    }

    React.useEffect(() => {
        initializers.forEach(h => h());
        // hide warning about dependency of initializers because initializers is not added to hook dependencies because it is meant to run only on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.dataFilterItem}>
            <Typography variant="subtitle1" className={classes.filterLabel}>
                {props.onDelete &&
                    <IconButton className={classes.delete} onClick={() => { if (props.onDelete) props.onDelete() }}>
                        <Icon className="fas fa-times" />
                    </IconButton>}
                {cellName}:
            </Typography>
            {filter}
        </div>
    )
}

export default VDatasetFilterItem;