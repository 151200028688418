import { CSSProperties } from 'react';

export const styles = {
    borderedContainer: {
        border: "1px solid rgba(224, 224, 224, 1)",
    }
}


export const stickyColumnStyle: CSSProperties = {
    position: 'sticky',
    left: 0,
    background: 'white'
};


export const stickyColumnWithHeaderStyle: { [key: string]: CSSProperties } = {
    header: {
        ...stickyColumnStyle,
        zIndex: 1001
    },
    cell: {
        ...stickyColumnStyle,
        zIndex: 1000
    }
};