import React from 'react';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { IForecastReportDataResponse } from '../../../apis/vitusApiTypes';
import { sortBy } from 'lodash';
import { stickyColumnStyle } from '../../../utils/styles';

interface IState {
    shownRowCount: number,
    tableTooltip?: { datetime: string, forecastDatetime: string, value: string },
    tooltipPosition?: { x?: number, y?: number },
}

interface IProps {
    forecasts: IForecastReportDataResponse["success"]["forecasts"],
    defaultInitialRowCount: number,
}

class FRTabularView extends React.Component<IProps, IState> {

    state: IState = {
        shownRowCount: 0,
    }

    static getColumns(forecasts: IForecastReportDataResponse["success"]["forecasts"], ignoreDatetime: boolean = false) {
        const cols = forecasts?.length ? sortBy(Object.keys(forecasts[0])).filter(c => c !== 'DateTime') : [];

        if (ignoreDatetime)
            return cols;

        return ['DateTime', ...cols]
    }

    initializeComponent() {
        this.setState({
            shownRowCount: this.props.defaultInitialRowCount > this.props.forecasts.length ? this.props.forecasts.length
                :
                this.props.defaultInitialRowCount
        })
    }

    componentDidMount() {
        this.initializeComponent();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.forecasts !== this.props.forecasts)
            this.initializeComponent();
    }


    tableInProcess: boolean = false;
    tableCache: React.ReactElement | null = null;
    tableOwner: IProps["forecasts"] | null = null;
    renderedRowCount: IState["shownRowCount"] | null = null;
    getTable(): React.ReactElement {
        if (this.tableCache
            && this.tableOwner
            && this.tableOwner === this.props.forecasts
            && this.renderedRowCount === this.state.shownRowCount)
            return this.tableCache;

        this.tableInProcess = true;

        const columns: string[] = FRTabularView.getColumns(this.props.forecasts, true);

        this.tableOwner = this.props.forecasts;
        this.renderedRowCount = this.state.shownRowCount;

        this.tableCache = (
            <Paper style={{ width: '100%', height: '100%', overflow: 'scroll' }}
                onScroll={(e) => {
                    if (this.tableInProcess || (this.renderedRowCount || 0) >= this.props.forecasts.length)
                        return;

                    const element = e.currentTarget;
                    if (element.scrollHeight - element.scrollTop < element.clientHeight * 1.5)
                        this.setState({ shownRowCount: this.state.shownRowCount + this.props.defaultInitialRowCount })
                }}>
                <TableContainer style={{ width: '100%', overflow: 'unset' }}>
                    <Table stickyHeader size="small" style={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ ...stickyColumnStyle, width: 130, minWidth: 130, padding: 6, zIndex: 1001 }} key="th_DateTime">
                                    DateTime
                                </TableCell>
                                {
                                    columns.map(column => (
                                        <TableCell key={`tc_${column}`} style={{ width: 130, minWidth: 130, padding: 6 }} >
                                            {column}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.props.forecasts.slice(0, this.state.shownRowCount).map(row => {
                                    return (
                                        <TableRow key={`tr_${row.DateTime}`}>
                                            <TableCell style={{ ...stickyColumnStyle, zIndex: 1000 }} key={`tc_${row.DateTime}`}>
                                                {row.DateTime}
                                            </TableCell>

                                            {columns.map((column) => {
                                                return (
                                                    <TableCell
                                                        key={`tc_${row.DateTime}_${column}`}
                                                        style={{ padding: 6 }}
                                                        onMouseEnter={() => {
                                                            this.setState({ tableTooltip: { datetime: row.DateTime, forecastDatetime: column, value: row[column]?.toLocaleString() } });
                                                        }}
                                                        onMouseLeave={() => {
                                                            this.setState({ tableTooltip: undefined });
                                                        }}
                                                    >
                                                        {row[column]?.toLocaleString()}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <div
                    style={{ ...stickyColumnStyle, height: 30, width: '100%', textAlign: 'center' }}>
                    <Typography>
                        {this.renderedRowCount < this.props.forecasts.length ? this.renderedRowCount : this.props.forecasts.length} of {this.props.forecasts.length} rows are listed
                    </Typography>
                    {
                        this.renderedRowCount < this.props.forecasts.length &&
                        <CircularProgress disableShrink size={10} style={{ marginLeft: 10, }} />
                    }
                </div>

            </Paper >
        )

        this.tableInProcess = false;

        return this.tableCache;
    }


    render(): React.ReactNode {
        return (
            <Paper
                style={{
                    maxWidth: 'calc(100vw - 250px)',
                    height: 'calc(100vh - 310px)',
                    overflow: 'auto',
                    marginTop: 40,
                }}>
                <Tooltip
                    open={!!this.state.tableTooltip}
                    onMouseMove={e => this.setState({ tooltipPosition: { x: e.pageX, y: e.pageY } })}
                    PopperProps={{
                        anchorEl: {
                            clientHeight: 0,
                            clientWidth: 0,
                            getBoundingClientRect: () => ({
                                top: this.state.tooltipPosition?.y || 0,
                                left: this.state.tooltipPosition?.x || 0,
                                right: this.state.tooltipPosition?.x || 0,
                                bottom: this.state.tooltipPosition?.y || 0,
                                width: 0,
                                height: 0,
                                x: 0,
                                y: 0,
                                toJSON: () => ""
                            })
                        }
                    }}
                    title={
                        <Typography>
                            <span>Value: {this.state.tableTooltip?.value}</span>
                            <br />
                            <span>DateTime: {this.state.tableTooltip?.datetime}</span>
                            <br />
                            <span>Forecast: {this.state.tableTooltip?.forecastDatetime}</span>
                        </Typography>
                    }>
                    {
                        this.getTable()
                    }
                </Tooltip>
            </Paper>
        )
    }
}

export default FRTabularView;