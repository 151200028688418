import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalStates } from '../../store/store';
import { IGlobalSettings } from '../../store/reducers/globalSettingsReducer';
import VSpinner from './VSpinner';

const VGlobalSpinner: React.FC<{}> = () => {
    const spinnerCalls = useSelector<GlobalStates, IGlobalSettings["spinnerCalls"]>(state => state.settings.spinnerCalls) || {};

    const show = (Object.keys(spinnerCalls).map(k => spinnerCalls[k])).some(v => v);

    return <VSpinner show={show} />;
};

export default VGlobalSpinner;
