import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "react-resizable/css/styles.css"

import React from 'react';
import VRouter from './system/VRouter';
import { IAuthState } from './store/reducers/authReducer';
import { connect } from 'react-redux';
import { GlobalStates } from './store/store';
import { IGlobalSettings } from './store/reducers/globalSettingsReducer';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import VGlobalSpinner from './components/VSpinner/VGlobalSpinner';
import { VAlerts } from './components/VAlerts/VAlerts';
import AlertManager from './utils/alertManager';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { IReportInfo } from './store/reducers/reportInfoReducer';

// To prevend strict mode warning:
// import { createMuiTheme } from '@material-ui/core';

interface IProps {
  auth: IAuthState,
  settings: IGlobalSettings,
  reportInfo: IReportInfo,
}

const App: React.FC<IProps> = (props: IProps) => {
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        // TODO: font size based on different screen sizes
        typography: {
          fontSize: 10,
        },
        palette: {
          type: props.settings.theme,
        },
        overrides: {
          MuiButton: {
            contained: {
              maxHeight: 35,
              margin: 5,
            }
          },
          MuiInput: {
            root: {
              // width: 150,
              margin: '0 0 8px 0',
            },
          },
        }
      }),
    [props.settings.theme],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <VAlerts setShowMessage={AlertManager.setShowMessage}>
        <VRouter />
        <VGlobalSpinner />
      </VAlerts>
    </ThemeProvider>
  );
}


const mapStateToProps = (state: GlobalStates) => {
  return {
    auth: state.auth,
    settings: state.settings,
    reportInfo: state.reportInfo,
  };
};

export default connect(mapStateToProps)(App);
