import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React from 'react';

interface IProps {
    height?: string,
    title?: string,
    children: React.ReactNode,
    style?: { [key: string]: any },
    headerVariant?: Variant,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerContainer: {
            border: "1px solid rgba(224, 224, 224, 1)",
            display: "block",
            textAlign: "left",
            paddingLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            width: "auto",
        },
    }),
);

const VBox: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    return (
        <fieldset
            className={classes.outerContainer}
            style={{ height: props.height || "auto", ...(props.style || {}) }}>
            {
                props.title &&
                <legend>
                    <Typography gutterBottom variant={props.headerVariant || "h6"} style={{ margin: "20" }}>
                        {props.title}
                    </Typography>
                </legend>
            }
            {props.children}
        </fieldset >
    );
}

export default VBox;