import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, Radio, RadioGroup, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { getReportsAndPermissions, saveReportPermissions } from "../../../apis/vitusApi";
import VTransferList from "../../../components/VTransferList/VTransferList";
import { VSimpleDialog } from "../../../dialogs/VSimpleDialog/VSimpleDialog";
import { ReportView, ReportParams } from "../../../system/ReportBase";
import AlertManager from "../../../utils/alertManager";
import { handleApiError, ViewTypes } from "../../../utils/common";
import { ReportDBObject, ReportGroupDBObject } from "../../../utils/types";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import ReportViewer from "../../ReportViewer/ReportViewer";

interface IState {
    reports: ReportDBObject[],
    groups: { [key: number]: ReportGroupDBObject },
    selectedReport?: ReportDBObject,
    showGroup?: ReportGroupDBObject,
    isFormDirty: boolean,
}

class ReportPermissions extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            viewType: ViewTypes.Administration,
            reportKey: "REPORT_PERMISSIONS",
            name: "Report Permissions",
            path: "/reportPermissions",
            thumbnail: ""
        }
    );

    state: IState = {
        groups: {},
        reports: [],
        isFormDirty: false,
    }

    callSavePermissions() {
        if (!this.state.selectedReport)
            return;

        const spinner = createSpinner();

        saveReportPermissions({
            report: this.state.selectedReport
        }).then(response => {
            if (response.data.success) {
                const result = response.data.success.result;

                if (result) {
                    if (this.state.selectedReport) {
                        const newReports = [...this.state.reports];
                        const updatedReportIdx = newReports.findIndex(r=> r.Id === this.state.selectedReport?.Id);

                        newReports[updatedReportIdx] = this.state.selectedReport;

                        this.setState({ reports: newReports, isFormDirty: false });
                    }

                    AlertManager.showSuccess("Report permissions saved successfully."); //TODO: message
                } else {
                    AlertManager.showError("Report permissions could not be saved."); //TODO: message
                }
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    componentDidMount() {
        const spinner = createSpinner();

        getReportsAndPermissions().then(response => {
            if (response.data.success) {
                this.setState(
                    {
                        reports: response.data.success.reports,
                        selectedReport: response.data.success.reports?.[0],
                        groups: response.data.success.groups
                            .reduce((dict: IState["groups"], group: ReportGroupDBObject) => {
                                dict[group.Id] = group; return dict;
                            }, {}),
                    });

            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    selectedReportChanged(event: React.ChangeEvent<HTMLInputElement>, value: string) {
        this.setState({ selectedReport: this.state.reports.find(r => r.Id === +value) });
    }

    discardChanges() {
        this.setState({
            isFormDirty: false,
            selectedReport: this.state.reports.find(r => r.Id === this.state.selectedReport?.Id)
        })
    }

    render() {
        const boxStyle: React.CSSProperties = {
            height: "calc(50vh - 120px)",
            overflowX: "hidden",
            overflowY: "scroll",
            border: "1px solid rgba(224, 224, 224, 1)",
            padding: 10,
            width: "100%",
        };

        return (
            <ReportViewer {...ReportPermissions.params}>
                <Grid container justify="space-between">
                    <Grid item xs={2}>
                        <Box style={boxStyle}>
                            <FormControl component="fieldset" disabled={this.state.isFormDirty}>
                                <FormLabel component="legend">Reports</FormLabel>
                                <RadioGroup
                                    name="reports"
                                    value={this.state.selectedReport?.Id || null}
                                    onChange={(event, value) => this.selectedReportChanged(event, value)}>
                                    {
                                        this.state.reports.map(report => {
                                            const label = (
                                                <FormControlLabel
                                                    key={`report_${report.Id}`}
                                                    value={report.Id}
                                                    control={<Radio />}
                                                    label={report.Name} />
                                            );

                                            if (this.state.isFormDirty)
                                                return (
                                                    <Tooltip
                                                        key={`report_tooltip_${report.Id}`}
                                                        title={<Typography>Discard or save changes to change report</Typography>}>
                                                        {label}
                                                    </Tooltip>
                                                );

                                            return label;
                                        })
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <VTransferList
                            leftTitle="Authorized Groups"
                            rightTitle="Unauthorized Groups"
                            leftItems={this.state.selectedReport?.Groups?.map(groupId => this.state.groups[groupId]) || []}
                            rightItems={Object.keys(this.state.groups).filter(id => !this.state.selectedReport?.Groups?.find(sgId => +id === +sgId))
                                .map(groupId => this.state.groups[+groupId]) || []}
                            style={boxStyle}
                            getItemLabel={(group: ReportGroupDBObject) => group.Name}
                            onChange={(left) => {
                                this.setState({
                                    isFormDirty: true,
                                    selectedReport: {
                                        ...(this.state.selectedReport as ReportDBObject),
                                        Groups: left.map((l: ReportGroupDBObject) => l.Id),
                                    }
                                })
                            }}
                            listItemComponent={(group: ReportGroupDBObject) => (
                                <React.Fragment>
                                    <ListItemText primary={group.Name} />
                                    <ListItemIcon>
                                        <Tooltip title={<Typography>Show users</Typography>}>
                                            <IconButton onClick={(e) => {
                                                this.setState({ showGroup: group })
                                                e.stopPropagation();
                                            }}>
                                                <Icon style={{ fontSize: 16 }} className="fas fa-info-circle" />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemIcon>
                                </React.Fragment>
                            )}
                        />
                    </Grid>
                    <Grid container item xs={12} justify="flex-end">
                        <Button variant="contained"
                            style={{ margin: "10px 20px 0 0" }}
                            onClick={() => this.discardChanges()}>
                            Discard Changes
                        </Button>
                        <Button variant="contained" color="primary"
                            style={{ margin: "10px 50px 0 0" }}
                            onClick={() => this.callSavePermissions()}>
                            Save
                        </Button>
                    </Grid>

                    <VSimpleDialog
                        fullWidth
                        open={!!this.state.showGroup}
                        setOpen={(open) => { if (!open) this.setState({ showGroup: undefined }) }}
                        title={`${this.state.showGroup?.Name} Users`}
                        content={(
                            <List component="div" >
                                {
                                    this.state.showGroup?.Users?.map(user => (
                                        <ListItem key={user} dense style={{ padding: 0 }}>
                                            <Icon style={{ width: 20, fontSize: 10 }} className="fas fa-circle" />
                                            <ListItemText primary={<Typography>{user}</Typography>} />
                                        </ListItem>
                                    ))
                                }
                            </List>)}
                        actions={[
                            <Button key="close_button" onClick={() => this.setState({ showGroup: undefined })} color="primary">
                                Close
                            </Button>
                        ]}
                    />
                </Grid>
            </ReportViewer>
        );
    }
}

export default ReportPermissions;