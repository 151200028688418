import ReportViewer from "../ReportViewer/ReportViewer";
import { ReportParams, ReportView } from "../../system/ReportBase";
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided, DraggableLocation } from 'react-beautiful-dnd';
import { Button, FormControl, Grid, Icon, IconButton, TextField, Typography } from "@material-ui/core";
import { CSSProperties } from "react";
import VCustomChart from "../../components/VCustomChart/VCustomChart";
import { getCustomCharts, getCustomReport, getDynamicChartData, saveReport } from "../../apis/vitusApi";
import { createSpinner } from "../../utils/spinnerManager";
import AlertManager from "../../utils/alertManager";
import { handleApiError } from "../../utils/common";
import { CustomChartObject, CustomReportChartObject, CustomReportObject } from "../../utils/types";
import messages from "../../utils/messages";
import history from '../../system/history';
import { isEmpty } from "lodash";
import { DatasetRow } from "../CustomChart/CustomChartAdapters/BaseChart";
import { canEditReport } from "../../utils/authUtils";

// Drag and drop tool:
// https://github.com/atlassian/react-beautiful-dnd

export type ChartData = { refresed_at: Date, allDatasetsData: { [key: string]: { data: DatasetRow[], index_column_values: string[] } } };

interface IState {
    allCharts: CustomChartObject[],
    allChartData: { [key: string]: ChartData }, // { [chart name]: chart data }
    report: CustomReportObject,
    editModeOpen: boolean,
}

enum PageMode {
    Read = "read", // not used in code and defined for clarification. control is made as edit means edit and undefined means read.
    Edit = "edit",
}

interface IProps {
    match: { params: { id?: number, mode?: PageMode } }
}

const reorder = (list: (CustomChartObject | CustomReportChartObject)[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source: (CustomChartObject | CustomReportChartObject)[], destination: CustomReportChartObject[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
    const sourceClone = Array.from(source) || [];
    const destClone = Array.from(destination) || [];
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    const added =
        (removed as CustomReportChartObject)?.chart ?
            { ...removed, rowNumber: +droppableDestination.droppableId }
            :
            { chart: removed, rowNumber: +droppableDestination.droppableId, asTable: false };

    destClone.splice(droppableDestination.index, 0, added as CustomReportChartObject);

    return { newSource: sourceClone, newDestination: destClone, movedChart: added };
};

export const CustomReportParams: ReportParams = new ReportParams(
    {
        reportKey: "CUSTOM_REPORT",
        name: "Custom Report",
        cleanPath: "/customReport",
        path: "/customReport/:id?/:mode?",
        thumbnail: "customReport.png"
    }
);

class CustomReport extends ReportView<IProps, IState> {
    static params: ReportParams = CustomReportParams;

    report_id?: number = undefined;
    columnCount = 3;

    state: IState = {
        allCharts: [],
        allChartData: {},
        report: new CustomReportObject(),
        editModeOpen: !this.props.match.params.id || this.props.match.params.mode === PageMode.Edit,
    }

    constructor(props: IProps | Readonly<IProps>) {
        super(props);

        this.report_id = this.props.match.params.id;
    }

    isEditing() {
        return this.state.editModeOpen && canEditReport(this.report_id, this.state.report.name);
    }

    editReport() {
        history.push({ pathname: `${history.location.pathname}/${PageMode.Edit}` })
        this.prepare_report(true);
    }

    cancelEditReport() {
        const pathname = history.location.pathname.slice(0, history.location.pathname.lastIndexOf('/'))
        history.push({ pathname })
        this.setState({ editModeOpen: false });
    }

    prepare_report(openEditMode: boolean) {
        if (openEditMode !== this.state.editModeOpen)
            this.setState({ editModeOpen: openEditMode });

        if (openEditMode && !this.state.allCharts.length && canEditReport(this.report_id, this.state.report.name)) {
            const spinner = createSpinner();

            getCustomCharts().then(response => {
                if (response.data.success) {
                    this.setState({
                        allCharts: response.data.success.custom_charts
                    })
                }

                if (response.data.error) {
                    AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                }
            }).catch(error => {
                handleApiError(error);
            }).finally(() => {
                spinner.hide()
            });
        }

        if (this.report_id && (!this.state.report.id || this.report_id.toString() !== this.state.report.id.toString())) {
            const spinner = createSpinner();

            getCustomReport({ report_id: this.report_id }).then(response => {
                if (response.data.success) {
                    this.setState({ report: response.data.success.custom_report },
                        () => {
                            // TODO: try to get all data with single request when report is known
                            this.state.report.chartRows.forEach(row => row.forEach(chartReport => this.getChartData(chartReport.chart)))
                        });
                }

                if (response.data.error) {
                    AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                }
            }).catch(error => {
                this.setState({ editModeOpen: false });
                handleApiError(error);
            }).finally(() => {
                spinner.hide()
            });
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.match.params.id !== this.props.match.params.id)
            window.location.reload();
    }

    componentDidMount() {
        const openEditMode = !this.props.match.params.id || this.props.match.params.mode === PageMode.Edit;
        this.prepare_report(openEditMode);
    }

    getList = (id: string): (CustomChartObject | CustomReportChartObject)[] => {
        if (id === "charts")
            return this.state.allCharts;

        return this.state.report.chartRows[+id];
    };

    updateSelectedCharts(selectedChartRows?: CustomReportChartObject[][], movedChart?: CustomChartObject, removedChartIds?: number[]) {
        if (!selectedChartRows)
            return;

        if (movedChart)
            this.getChartData(movedChart);

        const newReport = {
            ...this.state.report,
            chartRows: selectedChartRows,
        }

        if (removedChartIds?.length)
            newReport.removedChartIds = [...(this.state.report.removedChartIds || []), ...removedChartIds]

        this.setState({ report: newReport });
    }

    getChartData(chart: CustomChartObject) {
        if (!chart.id)
            return;

        getDynamicChartData({ chart_id: chart.id })
            .then(response => {
                if (response.data.success) {
                    const chartData = response.data.success.chart_data;

                    this.setState({
                        allChartData: {
                            ...this.state.allChartData,
                            [chart.name || ""]: { refresed_at: new Date(), allDatasetsData: chartData }
                        }
                    });
                }

                if (response.data.error) {
                    AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                }
            }).catch(error => {
                handleApiError(error);
            });
    }

    onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        let selectedChartRows, newChart;

        if (source.droppableId === destination.droppableId) {
            const charts = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId !== 'charts') {
                selectedChartRows = [...this.state.report.chartRows];
                selectedChartRows[+source.droppableId] = charts as CustomReportChartObject[];
            }
        } else {

            const { newSource, newDestination, movedChart } = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId) as CustomReportChartObject[],
                source,
                destination
            );

            if (source.droppableId === 'charts') {
                newChart = movedChart.chart;
            }

            selectedChartRows = [...this.state.report.chartRows];

            if (source.droppableId === 'charts') {
                selectedChartRows[+destination.droppableId] = newDestination;
            } else if (destination.droppableId === 'charts') {
                selectedChartRows[+source.droppableId] = newSource as CustomReportChartObject[];
            }
            else {
                selectedChartRows[+source.droppableId] = newSource as CustomReportChartObject[];
                selectedChartRows[+destination.droppableId] = newDestination;
            }
        }

        this.updateSelectedCharts(selectedChartRows, newChart as CustomChartObject);
    }

    grid = 8;
    getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? 'lightblue' : 'lightgrey',
        padding: this.grid,
        minWidth: 250,
        display: 'inline-block',
    });

    getSelectedListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? 'lightblue' : 'lightgrey',
        display: 'flex',
        padding: this.grid,
        marginBottom: this.grid,
        overflow: 'auto',
        minHeight: 300 + 2 * this.grid,
        position: 'relative',
    } as CSSProperties);

    getItemStyle = (isDragging: boolean, draggableStyle?: { [key: string]: any }): { [key: string]: any } => ({
        userSelect: 'none',
        padding: this.grid * 2,
        margin: `0 0 ${this.grid}px 0`,

        background: isDragging ? 'lightgreen' : 'grey',

        ...draggableStyle
    });

    getSelectedItemStyle = (isDragging: boolean, draggableStyle?: { [key: string]: any }): { [key: string]: any } => ({
        ...this.getItemStyle(isDragging, draggableStyle),

        margin: `0 ${this.grid}px 0 0`,
        minWidth: 400,
        minHeight: 400,
        position: 'relative',
        background: isDragging ? 'lightgreen' : 'white',

        ...draggableStyle
    });

    removeSelectedChart(droppableId: string, chartIdx: number) {
        const selectedChartRows = [...this.state.report.chartRows];

        const removedChart = selectedChartRows[+droppableId].splice(chartIdx, 1);

        if (removedChart[0]?.id)
            this.updateSelectedCharts(selectedChartRows, undefined, [removedChart[0].id]);
        else
            this.updateSelectedCharts(selectedChartRows);
    }

    removeSelectedRow(droppableId: string) {
        const selectedChartRows = this.state.report.chartRows.filter((c, rowId) => rowId !== +droppableId);
        const removedIds = this.state.report.chartRows[+droppableId].map(c => c.id).filter(c => c) as number[];

        this.updateSelectedCharts(selectedChartRows, undefined, removedIds);
    }

    getChart(item: CustomReportChartObject, rowIdx: number, chartIdx: number, droppableId: string) {
        const chart = (
            <VCustomChart
                reportChart={item}
                chartData={this.state.allChartData[item.chart.name ?? ""]}
                onChartUpdated={(updatedChart) => {
                    const chartRows = [...this.state.report.chartRows];
                    chartRows[rowIdx][chartIdx] = updatedChart;
                    this.setState({ report: { ...this.state.report, chartRows } });
                }}
            />
        );

        if (!this.isEditing())
            return (
                <div key={`${item.chart.name}_${item.rowNumber}_${chartIdx}`} style={this.getSelectedItemStyle(false, {})}>
                    {chart}
                </div>
            );

        return (
            <Draggable
                key={`${item.chart.name}_${item.rowNumber}_${chartIdx}`}
                draggableId={`${item.chart.name}_${item.rowNumber}_${chartIdx}`}
                index={chartIdx}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef} {...provided.draggableProps}
                        style={this.getSelectedItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}>
                        {
                            this.isEditing() &&
                            <div className="MuiIconButton-root"
                                style={{ position: "absolute", left: 0, top: 0, cursor: "move", padding: 3 }}
                                {...provided.dragHandleProps}>
                                <Icon className="fas fa-arrows-alt" />
                            </div>
                        }
                        {
                            chart
                        }
                        {
                            this.isEditing() &&
                            <div style={{ position: "absolute", right: 0, top: 0 }}>
                                <IconButton style={{ padding: 3 }} onClick={() => this.removeSelectedChart(droppableId, chartIdx)}>
                                    <Icon className="fas fa-times" />
                                </IconButton>
                            </div>
                        }
                    </div>
                )}
            </Draggable>
        )
    }

    createDroppableRow(rowIdx: number, items: CustomReportChartObject[]) {
        const droppableId = "" + rowIdx;

        return (
            <Droppable key={droppableId} droppableId={droppableId} direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={this.getSelectedListStyle(snapshot.isDraggingOver)}>

                        {this.isEditing() &&
                            <div style={{ position: "absolute", right: 0, top: 0 }}>
                                <IconButton onClick={() => this.removeSelectedRow(droppableId)}>
                                    <Icon className="fas fa-times" />
                                </IconButton>
                            </div>}

                        {!items?.length ?
                            <Typography>
                                No charts selected
                            </Typography>
                            :
                            items?.map((item, chartIdx) => this.getChart(item, rowIdx, chartIdx, droppableId))}
                        {provided.placeholder}
                    </div>
                )
                }
            </Droppable>
        )
    }

    onTitleChanged(newTitle: string) {
        this.setState({ report: { ...this.state.report, name: newTitle } });
    }

    validateReport() {
        if (!this.state.report.name) {
            AlertManager.showError("Please specify Report Name.");
            return false;
        }

        if (!this.state.report.chartRows.find(row => row.length)) {
            AlertManager.showError("Please specify al least 1 Chart.");
            return false;
        }

        return true;
    }

    callSaveReport() {
        if (!this.validateReport())
            return;

        const spinner = createSpinner();

        const reportToSave = this.state.report;

        saveReport({
            report: {
                id: reportToSave.id,
                name: reportToSave.name,
                removedChartIds: reportToSave.removedChartIds,
                chartRows: reportToSave.chartRows.map(row => {
                    return row.map(c => {
                        return {
                            ...c,
                            datasets: [], // not needed
                        };
                    });
                }),
            }
        }).then(response => {
            if (response.data.success) {
                const result = response.data.success.result;

                if (result) {
                    if (!isEmpty(this.state.report.removedChartIds))
                        this.setState({ report: { ...this.state.report, removedChartIds: [] } });

                    AlertManager.showSuccess("Report saved successfully."); //TODO: message
                } else {
                    AlertManager.showError("Report could not be saved."); //TODO: message
                }
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    render() {
        if (this.report_id && !this.state.report.name)
            return null;

        const params = {
            ...CustomReport.params,
        }

        if (this.report_id) {
            params.name = this.state.report.name || "";
            params.reportKey = params.name.replaceAll(" ", "_").toUpperCase();
            params.path = `${CustomReport.params.cleanPath}/${this.report_id}`;
            params.cleanPath = params.path;
        }

        return (
            <ReportViewer
                dontChangeView
                reportKey={params.reportKey}
                name={params.name}
                cleanPath={params.cleanPath}
                path={params.path}
                thumbnail={params.thumbnail}
            >
                <div className="reportWrapper" style={{ width: "auto", height: "auto" }}>
                    <Grid container spacing={3}>
                        {
                            this.isEditing() ?
                                <FormControl>
                                    <TextField
                                        id="report_name"
                                        variant="outlined"
                                        label="Report Name"
                                        size="small"
                                        value={this.state.report.name}
                                        type="text"
                                        onChange={(newValue) => this.onTitleChanged(newValue.target.value)}
                                    />
                                </FormControl>
                                :
                                <Typography gutterBottom variant="h6">
                                    {this.state.report.name || CustomReport.params.name}
                                </Typography>
                        }
                        <Grid item xs={12}>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Grid container>
                                    {
                                        !this.isEditing() ?
                                            (canEditReport(this.report_id, this.state.report.name) &&
                                                <>
                                                    <Grid container item xs={12} justify="flex-end"
                                                        style={{ height: 0 }}>
                                                        <Button variant="contained"
                                                            style={{ marginTop: -20 }}
                                                            onClick={() => this.editReport()}>
                                                            Edit
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={2}></Grid>
                                                </>)
                                            :
                                            <>
                                                <Grid container item xs={12} justify="flex-end"
                                                    style={{ height: 0 }}>
                                                    <Button variant="contained"
                                                        style={{ marginTop: -20 }}
                                                        onClick={() => this.updateSelectedCharts([...this.state.report.chartRows, []])}>
                                                        <Icon style={{ marginRight: 5 }} className="fas fa-plus" />
                                                        Add Row
                                                    </Button>
                                                    <Button variant="contained"
                                                        style={{ marginTop: -20 }}
                                                        onClick={() => this.callSaveReport()}>
                                                        <Icon style={{ marginRight: 5 }} className="fas fa-check" />
                                                        Save Report
                                                    </Button>
                                                    {this.report_id &&
                                                        <Button variant="contained"
                                                            style={{ marginTop: -20 }}
                                                            onClick={() => this.cancelEditReport()}>
                                                            <Icon style={{ marginRight: 5 }} className="fas fa-check" />
                                                            Cancel Edit
                                                        </Button>
                                                    }
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <Typography variant="h5">
                                                        Charts
                                                    </Typography>
                                                    <Droppable droppableId="charts" isDropDisabled>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                style={this.getListStyle(snapshot.isDraggingOver)}>
                                                                {this.state.allCharts.map((item, index) => (
                                                                    <Draggable
                                                                        key={`original_${item.name}_${index}`}
                                                                        draggableId={`original_${item.name}_${index}`}
                                                                        index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={this.getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}>
                                                                                {item.name}
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </Grid>
                                            </>
                                    }
                                    <Grid item xs={this.isEditing() ? 10 : 12}>
                                        {this.isEditing() ?
                                            <Typography variant="h5">
                                                Report View
                                            </Typography>
                                            :
                                            <Typography>
                                                <span>&#8203;</span>
                                            </Typography>
                                        }
                                        {
                                            this.state.report.chartRows.map((row, rowId) =>
                                                this.createDroppableRow(rowId, row)
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </DragDropContext>
                        </Grid>
                    </Grid>
                </div>


            </ReportViewer>
        );
    }
}

export default CustomReport;