import { Paper, Chip, createStyles, makeStyles, Theme } from "@material-ui/core";
import { isEmpty } from "lodash";
import React from "react";

interface ChipData {
    key: number;
    label: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: theme.spacing(0.5),
            margin: 0,
            overflow: "hide"
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    }),
);

interface IProps {
    elements: string[],
    onDelete?: (newElements: string[], removedElement: string) => void,
    style?: React.CSSProperties,
    defaultText?: string,
}

const VChipArray: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();
    const [chipData, setChipData] = React.useState<ChipData[]>([]);

    const handleDelete = (chipToDelete: ChipData) => () => {
        const newChips = chipData.filter(c => c.key !== chipToDelete.key);
        setChipData(newChips);

        if (props.onDelete)
            props.onDelete(newChips.map(c => c.label), chipToDelete.label);
    };

    React.useEffect(() => {
        setChipData(props.elements.map((e, idx) => { return { key: idx, label: e } }));
    }, [props.elements]);

    return (
        <Paper variant="outlined" component="ul" className={classes.root}
            style={{ width: "100%", height: "100%", ...(props.style || {}) }}>
            {
                (props.defaultText && isEmpty(chipData)) ?
                    <li>
                        {props.defaultText}
                    </li>
                    :
                    chipData.map((data) => {
                        let icon;

                        return (
                            <li key={data.key}>
                                <Chip
                                    icon={icon}
                                    label={data.label}
                                    onDelete={handleDelete(data)}
                                    className={classes.chip}
                                />
                            </li>
                        );
                    })}
        </Paper>
    );
}

export default VChipArray;