import { isEmpty } from "lodash";
import React from "react";
import { createExcel } from "../../../utils/common";
import { Dataset } from "../../../utils/types";

export type ChartType = "line" | "bar" | "scatter" | "heatmap" | "table";

export const chartTypes: { [key in ChartType]: string } = {
    line: "line",
    bar: "bar",
    scatter: "scatter",
    heatmap: "heatmap",
    table: "table",
}

export type DatasetRow = { [key: string]: any };

interface IProps {
    ref: React.LegacyRef<BaseChart>,
    style?: { [key: string]: any },
    title: string,
}

abstract class BaseChart<P = {}, S = {}> extends React.Component<IProps & P, S> {
    chartRef: any;
    chartOptions: any;
    tableData?: { columns: string[], rows: { [key: string]: any }[] } = undefined;

    addApacheEchartsToolbox(
        options: { [key: string]: any },
        config: {
            hideDataView?: boolean,
            hideDownloadExcel?: boolean,
            hideSaveAsImage?: boolean,
            customSaveAsImage?: () => void,
        } = {
                hideDataView: false,
                hideDownloadExcel: false,
                hideSaveAsImage: false,
            }
    ) {
        const newOptions: typeof options = {
            ...options,
            toolbox: {
                show: true,
                itemSize: 20,
                z: 1000,
                feature: {
                    dataView: {
                        show: !isEmpty(this.tableData) && !config?.hideDataView,
                        title: 'Show as Table',
                        readOnly: true,
                        optionToContent: () => {
                            const table = `
                            <table style="width:auto;text-align:left;border:1px solid rgba(224, 224, 224, 1)">
                                <thead>
                                    <tr>
                                        ${this.tableData?.columns.map(col => `<th style="border:1px solid rgba(224, 224, 224, 1)">${col}</th>`)}
                                    </tr>
                                </thead>
                                <tbody>
                                    ${this.tableData?.rows.map(row => `<tr>${this.tableData?.columns.map(col => `<td style="border:1px solid rgba(224, 224, 224, 1)">${row[col] || ""}</td>`)}</tr>`)}
                                </tbody>
                            </table>
                            `;

                            return table;
                        }
                    },
                    saveAsImage: {
                        show: !config?.hideSaveAsImage && !config?.customSaveAsImage,
                        title: 'Save as Image',
                        name: this.props.title,
                        icon: "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86-3 3.87L9 13.14 6 17h12l-3.86-5.14z",
                    },
                },
            }
        }

        if (!config?.hideSaveAsImage && config?.customSaveAsImage)
            newOptions.toolbox.feature["mySaveImage"] = {
                show: !config?.hideSaveAsImage && config?.customSaveAsImage,
                title: 'Save as Image',
                icon: "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86-3 3.87L9 13.14 6 17h12l-3.86-5.14z",
                onclick: () => {
                    config.customSaveAsImage?.();
                }
            }

        if (!isEmpty(this.tableData) && !config?.hideDownloadExcel)
            newOptions.toolbox.feature["myDownloadExcel"] = {
                show: !isEmpty(this.tableData) && !config?.hideDownloadExcel,
                title: 'Download Excel',
                icon: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zM211.7 308l50.5-81.8c4.8-8-.9-18.2-10.3-18.2h-4.1c-4.1 0-7.9 2.1-10.1 5.5-31 48.5-36.4 53.5-45.7 74.5-17.2-32.2-8.4-16-45.8-74.5-2.2-3.4-6-5.5-10.1-5.5H132c-9.4 0-15.1 10.3-10.2 18.2L173 308l-59.1 89.5c-5.1 8 .6 18.5 10.1 18.5h3.5c4.1 0 7.9-2.1 10.1-5.5 37.2-58 45.3-62.5 54.4-82.5 31.5 56.7 44.3 67.2 54.4 82.6 2.2 3.4 6 5.4 10 5.4h3.6c9.5 0 15.2-10.4 10.1-18.4L211.7 308z",
                onclick: () => {
                    createExcel(
                        this.props.title,
                        this.tableData?.columns,
                        this.tableData?.rows?.map(row => (this.tableData?.columns || []).map(col => row[col]))
                    )
                }
            }

        return newOptions;
    }

    /**
     * Sets tableData variable to be used when data will be viewes as plain table
     */
    setTableData(columns: string[], rows: { [key: string]: any }[]) {
        this.tableData = { columns, rows };
    }

    abstract setChartOptions(all_datasets: Dataset[], all_data?: { [key: string]: { data: DatasetRow[], index_column_values: string[] } }): void;

    abstract render(): React.ReactElement;
}

export default BaseChart;