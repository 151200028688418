import { IReportInfo } from "../store/reducers/reportInfoReducer";
import { simpleHash } from "./common";

const generateKey = (ownerPage: string, ownerElement: string, key: string) => {
    return `${ownerPage}_${ownerElement}_${key}`;
}

export const setLocalStorage = (viewName: string, elementName: string, key: string, value: any) => {
    const storage_key = generateKey(viewName, elementName, key);
    localStorage.setItem(storage_key, JSON.stringify(value));
    return key;
}

export const getLocalStorage: any = (viewName: string, elementName: string, key: string) => {
    const storage_key = generateKey(viewName, elementName, key);
    const value = localStorage.getItem(storage_key);
    return value ? JSON.parse(value) : value;
}

const recentReportParams = {
    viewName: "reportInfoReducer",
    elementName: "recentReports",
}

let _userHash: string;
const userHash = (userName: string) => {
    if (!_userHash)
        _userHash = simpleHash(userName);
    return _userHash;
}

export const setRecentReports = (userName: string, recentReports: IReportInfo["recentReports"]) => {
    setLocalStorage(recentReportParams.viewName, recentReportParams.elementName, userHash(userName), recentReports);
}

export const getRecentReports = (userName: string) => {
    return getLocalStorage(recentReportParams.viewName, recentReportParams.elementName, userHash(userName));
}
