import React from "react";
import { CustomReportChartObject } from "../../utils/types";
import { max } from "lodash";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { ChartData } from "../../views/CustomReport/CustomReport";
import { toLongDateString } from "../../utils/common";
import { Resizable, ResizeCallbackData } from 'react-resizable';
import ChartAdapter from "../../views/CustomChart/CustomChartAdapters/ChartAdapter";
import BaseChart from "../../views/CustomChart/CustomChartAdapters/BaseChart";

const defaultCssProperities = {
    height: 380,
    width: 380,
}

interface IProps {
    reportChart: CustomReportChartObject,
    chartData?: ChartData,
    onChartUpdated: (updatedChart: CustomReportChartObject) => void,
}

interface IState {
    selectedTableTabIndex: number,
}

class VCustomChart extends React.Component<IProps, IState> {
    chartRef: BaseChart | null = null;

    state: IState = {
        selectedTableTabIndex: 0,
    }

    componentDidMount() {
        if (this.props.chartData)
            this.chartRef?.setChartOptions(this.props.reportChart.chart.datasets, this.props.chartData?.allDatasetsData);
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.chartData !== this.props.chartData || prevProps.reportChart !== this.props.reportChart)
            this.chartRef?.setChartOptions(this.props.reportChart.chart.datasets, this.props.chartData?.allDatasetsData);
    }

    getDefaultChartHeight() {
        return (this.props.reportChart?.cssProperities?.height || defaultCssProperities.height) - 80;
    }

    getChart() {
        return ChartAdapter.generateChart(
            this.props.reportChart.chart.datasets,
            {
                ref: (e: BaseChart) => { this.chartRef = (e as BaseChart); },
                style: { height: this.getDefaultChartHeight() },
                title: this.props.reportChart.chart.name || "Chart",
            }
        );
    }

    onResize = (event: React.SyntheticEvent, data: ResizeCallbackData) => {
        this.props.onChartUpdated({
            ...this.props.reportChart,
            cssProperities: {
                ...this.props.reportChart.cssProperities,
                width: data.size.width,
                height: data.size.height,
            }
        });
    };

    render() {
        const refresedAt = !this.props.chartData ?
            "N/A"
            :
            toLongDateString(max(Object.keys(this.props.chartData).map(k => this.props.chartData?.refresed_at)))

        const height = this.props.reportChart?.cssProperities?.height || defaultCssProperities.height;
        const width = this.props.reportChart?.cssProperities?.width || defaultCssProperities.width;

        return (
            <div>
                <Resizable
                    height={height}
                    width={width}
                    onResize={this.onResize}
                    minConstraints={[defaultCssProperities.height, defaultCssProperities.width]}>
                    <div style={{ height, width, ...this.props.reportChart.cssProperities }}>
                        <Grid container style={{ display: "inline-block" }}>
                            <Grid item xs={12}>
                                <Typography component="h6" variant="h6">
                                    {this.props.reportChart.chart.name}
                                </Typography>
                            </Grid>
                            <Grid container justify="space-between" item xs={12} style={{ alignItems: "center" }}>
                                <Grid item xs={6}>
                                    <Typography>
                                        Refreshed at: {refresedAt}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ height: this.getDefaultChartHeight() }}>
                                <div style={{ display: "block", height: '100%', position: 'relative' }}>
                                    {!this.props.chartData &&
                                        <div style={{
                                            position: "absolute",
                                            display: "inline-block",
                                            left: "42%",
                                            top: "40%"
                                        }}>
                                            <CircularProgress style={{}} color="inherit" />
                                        </div>
                                    }
                                    {
                                        this.getChart()
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Resizable>
            </div>
        )
    }
}

export default VCustomChart;