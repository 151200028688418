import { Button } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetUserPreferencesAction } from '../../store/actions';
import { IGlobalSettings } from '../../store/reducers/globalSettingsReducer';
import { GlobalStates } from '../../store/store';

const VThemeSelector: React.FC = () => {
    const currentTheme = useSelector<GlobalStates, IGlobalSettings["theme"]>(state => state.settings.theme);
    const dispatch = useDispatch();

    const changeTheme = () => {
        let newTheme: typeof currentTheme = "dark";

        if (currentTheme === "dark")
            newTheme = "light";

        dispatch<SetUserPreferencesAction>({ type: 'SET_USER_PREF', payload: { theme: newTheme } });
    };

    return (
        <Button onClick={changeTheme} style={{ fontSize: '1.5rem', color: 'white' }}>
            {
                currentTheme === "dark" ?
                    <i className="fas fa-sun"></i>
                    :
                    <i className="fas fa-moon"></i>
            }
        </Button>
    );
}

export default VThemeSelector;