import { makeStyles, Theme, createStyles, Grid, Icon, IconButton, Typography, Card } from '@material-ui/core';
import React from 'react';
import { styles } from "../../utils/styles";

interface IProps {
    defaultShow?: boolean,
    children: React.ReactNode,
    getSummary?: () => string,
    title?: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerContainer: {
            ...styles.borderedContainer,
            width: "100%"
        },
        innerContainer: {
            textAlign: "center",
        },
        summary: {
            margin: "0 10px",
        },
        childrenArea: {
            // display: 'flex',
            margin: '3px',
            width: "100%",
            padding: theme.spacing(1),
        }
    }),
);

const VCollapse: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    const [show, setShow] = React.useState(!!props.defaultShow);

    return (
        <Grid container
            justify="flex-start"
            className={classes.outerContainer}>
            <Grid item xs={11} container alignItems="center"
                className={classes.innerContainer}
                onClick={() => setShow(!show)}
                style={{ cursor: "pointer" }}>
                <Typography variant="caption" className={classes.summary}>
                    <i className="fas fa-file-alt" /> {props.title ? `${props.title}:` : ""} {props.getSummary ? props.getSummary() : ""}
                </Typography>
            </Grid >
            <Grid item xs={1} container justify="flex-end">
                <IconButton
                    style={{ maxHeight: "48px", maxWidth: "48px" }}
                    onClick={() => setShow(!show)}>
                    {show ?
                        <Icon className="fas fa-chevron-up" />
                        :
                        <Icon className="fas fa-chevron-down" />}
                </IconButton>
            </Grid>
            {show &&
                <Grid item
                    xs={12}
                    alignItems="flex-start"
                    spacing={0}
                    container>
                    <Card className={classes.childrenArea}>
                        {props.children}
                    </Card>
                </Grid>
            }
        </Grid >
    )
}

export default VCollapse