import { Grid, List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import { getGasStorageReport } from "../../../apis/vitusApi";
import { ILongTermEuropeGasBalanceResponse } from "../../../apis/vitusApiTypes";
import { ReportView, ReportParams } from "../../../system/ReportBase";
import AlertManager from "../../../utils/alertManager";
import { handleApiError } from "../../../utils/common";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import ReportViewer from "../../ReportViewer/ReportViewer";
import Exhibit from "./Exhibit";

interface IState {
    data?: ILongTermEuropeGasBalanceResponse["success"]["exhibits"],
}

class LongTermEuropeGasBalanceReport extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "GAS_STORAGE_REPORT",
            name: "Long Term Europe Gas Balance Report",
            path: "/longTermEuropeGasBalanceReport",
            thumbnail: ""
        }
    );

    state: IState = {

    }

    componentDidMount() {
        this.getData()
    }

    getData(){
        const spinner = createSpinner();

        getGasStorageReport().then(response => {
            if (response.data.success)
                this.storeData(response.data.success.exhibits)

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    storeData(response: ILongTermEuropeGasBalanceResponse["success"]["exhibits"]) {
        this.setState({ data: response })
    }

    getCharts() {
        if (!this.state.data)
            return null;

        const data = this.state.data;

        return (
            <>
                <Grid item xs={1} container>
                    <div style={{ position: "fixed", left: "5px", top: '80px', zIndex: 1000, opacity: 1, }}>
                        <List dense component="nav" style={{ width: 165, maxWidth: 165 }}
                            subheader={
                                <ListSubheader component="div"
                                    style={{ lineHeight: 'inherit', paddingTop: 10 }}>
                                    Exhibits
                                </ListSubheader>
                            }>
                            {
                                data.map((exhibit, idx) => {
                                    const title = exhibit.title || `Exhibit ${idx}`;
                                    return (
                                        <ListItem key={`link_${exhibit.title}`}
                                            button
                                            component="a"
                                            href={`#${exhibit.title}`}
                                            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
                                            <ListItemText primary={title} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </div>
                </Grid>
                <Grid item xs={11} container spacing={1}
                    style={{ overflow: 'scroll', height: 'calc(100vh - 84px)', width: '100%' }}>
                    {
                        data.map((exhibit, idx) => {
                            return (
                                <Grid id={`${exhibit.title}`} item xs={6} key={`exhibit_${idx}`}>
                                    <Exhibit data={exhibit} exhibitNumber={idx} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </>
        )

    }

    render() {
        return (
            <ReportViewer onRefresh={() => this.getData()} {...LongTermEuropeGasBalanceReport.params}>
                <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                    {
                        this.getCharts()
                    }
                </Grid>
            </ReportViewer>
        )
    }
}

export default LongTermEuropeGasBalanceReport;