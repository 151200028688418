import { Button, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { isEmpty, sortBy } from "lodash";
import { getFtrAnalysesData, getFtrAnalysesPnlOptionsAndPortfolio } from "../../../apis/vitusApi";
import { FtrDataTypes, FTRDirection, IFtrAnalysesPnlOptionsResponse, IFtrAnalysesPnLResponse } from "../../../apis/vitusApiTypes";
import VHeatMap, { Column, getColumnKey, getColumnLabel, IHeatMapProps } from "../../../charts/VHeatMap/VHeatMap";
import ExcelDownloadButton from "../../../components/ExcelDownloadButton/ExcelDownloadButton";
import VChipArray from "../../../components/VChipArray/VChipArray";
import { VDateTimePicker } from "../../../components/VDatePicker/VDatePicker";
import VPageFilter from "../../../components/VPageFilter/VPageFilter";
import VSelect from "../../../components/VSelect/VSelect";
import { ReportParams, ReportView } from "../../../system/ReportBase";
import AlertManager from "../../../utils/alertManager";
import { handleApiError, toLongDateString } from "../../../utils/common";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import { styles } from "../../../utils/styles";
import ReportViewer from "../../ReportViewer/ReportViewer";
import { TabPanel } from '../../../components/VTabs/VTabs';

const staticDetailedColumns = [{ key: 'DateTimeCET', style: { minWidth: 95 } }, { key: 'Total' }];

const hourColumns = [{ key: 'DateCET', label: 'Date/Hour' }, ...Array.from({ length: 24 }, (_, key) => key).map(k => k.toString()), 'Total'];
const monthColumns = [{ key: 'Year', label: 'Year/Month' }, ...Array.from({ length: 12 }, (_, key) => key).map(k => (k + 1).toString()), 'Total'];

type MemoizeParams = {
    cache: React.ReactElement | null,
    ownersMap: { current: () => any, used: any }[] | null,
    inprocessFlag: Boolean,
    elementCreator: () => React.ReactElement | null,
}

interface IFilter {
    data_type: FtrDataTypes,
    min_date_time?: Date,
    max_date_time?: Date,
    directions: FTRDirection[],
}

interface IState {
    detailedRows: IFtrAnalysesPnLResponse['success']['hourly_details'],
    hourlySummaryRows: IFtrAnalysesPnLResponse['success']['hourly_summary'],
    monthlySummaryRows: IFtrAnalysesPnLResponse['success']['monthly_summary'],
    directionOptions: { [key: string]: FTRDirection },
    columns: IHeatMapProps["columns"],
    filterIsHidden: boolean,
    selectedFilter: IFilter,
    activeFilter: IFilter,
    roundValues: IFtrAnalysesPnlOptionsResponse['success']['round_values'],
    selectedTabIndex: number,
}

class FtrAnalysesPnLReport extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "FTR_ANALYSES_PNL_REPORT",
            name: "Ftr Analyses PnL",
            path: "/ftrAnalysesPnLPnl",
            thumbnail: "",
            shortName: 'FTR'
        }
    );

    state: IState = {
        detailedRows: [],
        hourlySummaryRows: [],
        monthlySummaryRows: [],
        columns: [],
        directionOptions: {},
        filterIsHidden: false,
        selectedFilter: { directions: [], data_type: FtrDataTypes.Flow },
        activeFilter: { directions: [], data_type: FtrDataTypes.Flow },
        roundValues: {},
        selectedTabIndex: 0,
    }

    componentDidMount() {
        const optionsSpinner = createSpinner();

        getFtrAnalysesPnlOptionsAndPortfolio().then(response => {
            if (response.data.success) {
                const selectedFilter = {
                    data_type: response.data.success.saved_filter.data_type,
                    min_date_time: response.data.success.saved_filter?.min_date_time,
                    max_date_time: response.data.success.saved_filter?.max_date_time,
                    directions: response.data.success.saved_filter?.direction_list?.map(direction => ({
                        SourceCountryId: direction.source_country_id,
                        SinkCountryId: direction.sink_country_id,
                        SourceAreaId: direction.source_area_id,
                        SinkAreaId: direction.sink_area_id,
                        Direction: direction.direction
                    })) || [],
                }

                this.setState({
                    selectedFilter,
                    activeFilter: selectedFilter,
                    roundValues: response.data.success.round_values,
                    directionOptions: response.data.success.options
                        .reduce((map: { [key: string]: FTRDirection }, directionDetail) => {
                            map[directionDetail.Direction] = directionDetail;
                            return map;
                        }, {})
                })

                this.storeData(response.data.success, selectedFilter)
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            optionsSpinner.hide()
        });
    }

    sortDirections(directions: Column[]) {
        return sortBy(directions, col => getColumnLabel(col));
    }

    storeData(originalData: IFtrAnalysesPnLResponse['success'], filter: IState['activeFilter']) {
        if (!originalData)
            return;

        let columns: string[];

        if (!isEmpty(originalData.hourly_details))
            columns = Object.keys(originalData.hourly_details[0]).filter(col => !staticDetailedColumns
                .find(staticCol => staticCol['key'] === col));
        else
            columns = []

        this.setState({
            detailedRows: originalData.hourly_details,
            hourlySummaryRows: originalData.hourly_summary,
            monthlySummaryRows: originalData.monthly_summary,
            columns: [...staticDetailedColumns, ...this.sortDirections(Array.from(columns.values()))],
            activeFilter: filter
        })
    }

    getData(filter: IState["activeFilter"]) {
        if (!filter.data_type || !filter.min_date_time || !filter.max_date_time) {
            AlertManager.showWarning("Data Type and dates are required."); //TODO: message
            return;
        }

        const spinner = createSpinner();

        getFtrAnalysesData({
            data_type: filter.data_type,
            min_date_time: filter.min_date_time,
            max_date_time: filter.max_date_time,
            direction_list: filter.directions.map(direction => ({
                source_country_id: direction.SourceCountryId,
                sink_country_id: direction.SinkCountryId,
                source_area_id: direction.SourceAreaId,
                sink_area_id: direction.SinkAreaId,
                direction: direction.Direction,
            }))
        }).then(response => {
            if (response.data.success)
                this.storeData(response.data.success, filter)

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    directionSelectionChanged(items: any) {
        if (!items)
            return;

        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                directions: items
            }
        });
    }

    getFilter() {

        return (
            <VPageFilter showHide
                show={!this.state.filterIsHidden}
                onShowChanged={(show) => this.setState({ filterIsHidden: !show })}
                getActiveFilter={() => ({
                    ...this.state.activeFilter,
                    directions: this.state.activeFilter.directions.map(dir => dir.Direction),
                    min_date_time: !this.state.activeFilter.min_date_time ? "-" : toLongDateString(this.state.activeFilter.min_date_time),
                    max_date_time: !this.state.activeFilter.max_date_time ? "-" : toLongDateString(this.state.activeFilter.max_date_time),
                })}>
                <Grid item xs={2} style={{ minWidth: '14%' }}>
                    <VSelect
                        disableClearable
                        title="Data Type"
                        value={this.state.selectedFilter.data_type}
                        getOptionLabel={(option) => `${option}`}
                        options={Object.keys(FtrDataTypes).filter((item) => { return isNaN(Number(item)); })}
                        onChange={(newValue) => this.setState({
                            selectedFilter: {
                                ...this.state.selectedFilter,
                                data_type: newValue as FtrDataTypes
                            }
                        })}
                    />
                </Grid>
                <Grid item xs={2} style={{ minWidth: '14%' }}>
                    <VDateTimePicker
                        variant="inline"
                        format="DD/MM/yyyy"
                        margin="normal"
                        label="Min Date (CET)"
                        maxDate={this.state.selectedFilter.max_date_time}
                        value={this.state.selectedFilter.min_date_time || null}
                        onChange={(date) => {
                            this.setState({
                                selectedFilter: { ...this.state.selectedFilter, min_date_time: date?.toDate() }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={2} style={{ minWidth: '14%' }}>
                    <VDateTimePicker
                        variant="inline"
                        format="DD/MM/yyyy"
                        margin="normal"
                        label="Max Date (CET)"
                        minDate={this.state.selectedFilter.min_date_time}
                        value={this.state.selectedFilter.max_date_time || null}
                        onChange={(date) => {
                            this.setState({
                                selectedFilter: { ...this.state.selectedFilter, max_date_time: date?.toDate() }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <VSelect
                        multiple
                        width="200px"
                        value={this.state.selectedFilter.directions}
                        title='Directions'
                        options={Object.keys(this.state.directionOptions).map(key => this.state.directionOptions[key])}
                        sortBy={(row) => row.Direction}
                        getOptionLabel={(option) => option.Direction}
                        onChange={(newValue) => this.directionSelectionChanged(newValue)}
                        renderTags={false}
                    />
                </Grid>
                <Grid container item xs={5}>
                    <VChipArray
                        style={{ minHeight: 50 }}
                        defaultText="All Directions"
                        elements={sortBy(this.state.selectedFilter.directions, d => d.Direction).map(direction => direction.Direction)}
                        onDelete={((_, removedElement) => this.setState({
                            selectedFilter: {
                                ...this.state.selectedFilter,
                                directions: this.state.selectedFilter.directions.filter(d => d.Direction !== removedElement)
                            }
                        }))}
                    />
                </Grid>
                <Grid container item xs={12} justify="flex-end">
                    <Button variant="contained" onClick={() => this.getData(this.state.selectedFilter)}>
                        Apply
                    </Button>
                </Grid>
            </VPageFilter >
        )
    }

    houryDetailedHeatMapMomizer: MemoizeParams = {
        inprocessFlag: false,
        cache: null,
        ownersMap: [
            { current: () => this.state.detailedRows, used: null },
            { current: () => this.state.columns, used: null }
        ],
        elementCreator: () => {
            const columns = this.state.columns;
            const rows = this.state.detailedRows;
            const directionCount = columns.length - staticDetailedColumns.length;

            return this.generateHeatMapTabContent(columns, rows,
                `FTR Analyses for ${directionCount ?? "All"} Directions`);
        },
    }

    hourlySummaryHeatMapMomizer: MemoizeParams = {
        inprocessFlag: false,
        cache: null,
        ownersMap: [
            { current: () => this.state.hourlySummaryRows, used: null }
        ],
        elementCreator: () => {
            const rows = this.state.hourlySummaryRows;
            const directionCount = this.state.columns.length - staticDetailedColumns.length;

            return this.generateHeatMapTabContent(hourColumns, rows,
                `FTR Analyses Hourly Summary for ${directionCount ?? "All"} Directions`);
        }
    }

    monthlySummaryHeatMapMomizer: MemoizeParams = {
        inprocessFlag: false,
        cache: null,
        ownersMap: [
            { current: () => this.state.monthlySummaryRows, used: null }
        ],
        elementCreator: () => {
            const rows = this.state.monthlySummaryRows;
            const directionCount = this.state.columns.length - staticDetailedColumns.length;

            return this.generateHeatMapTabContent(monthColumns, rows,
                `FTR Analyses Monthly Summary for ${directionCount ?? "All"} Directions`);
        }
    }

    generateHeatMapTabContent(columns: IHeatMapProps["columns"], rows: IHeatMapProps["rows"], title: string) {
        if (isEmpty(rows))
            return <Typography>Nothing to show</Typography>

        return (
            <>
                <ExcelDownloadButton
                    title={title}
                    sheets={[{
                        columns: columns.map(col => getColumnLabel(col)),
                        rows: rows.map(row => columns.map(c => row?.[getColumnKey(c)])),
                    }]}
                />
                <VHeatMap style={{
                    height: 520,
                    maxWidth: 'calc(100vw - 60px)',
                }}
                    stickyHeader stickyFirstColumn
                    columns={columns} rows={rows} />
            </>
        )
    }

    getMemoizedHeatmap = (params: MemoizeParams) => {
        if (params.cache
            && params.ownersMap
            && params.ownersMap.every(owner => owner.current() === owner.used))
            return params.cache;

        params.inprocessFlag = true;

        params.cache = params.elementCreator();

        params.ownersMap?.forEach(owner => {
            owner.used = owner.current();
        })

        params.inprocessFlag = false;

        return params.cache;
    }

    render() {
        return (
            <ReportViewer {...FtrAnalysesPnLReport.params} onRefresh={() => this.getData(this.state.activeFilter)}>
                <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
                    <Grid item xs={12}>
                        {this.getFilter()}
                    </Grid>
                    <Grid item xs={12} style={{ maxWidth: '100%', ...styles.borderedContainer, margin: 4 }}>
                        <Tabs value={this.state.selectedTabIndex}
                            onChange={(_, value) => this.setState({ selectedTabIndex: value })} >
                            <Tab key='tab_hourly_summary'
                                label='Hourly Summary'
                                style={{ minWidth: 100 }}
                            />
                            <Tab key='tab_monthly_summary'
                                label='Monthly Summary'
                                style={{ minWidth: 100 }}
                            />
                            <Tab key='tab_hourly_details'
                                label='Direction Details'
                                style={{ minWidth: 100 }}
                            />
                        </Tabs>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={{ padding: 0 }}
                            index={0}
                            key='tab_hourly_summary'>
                            {
                                this.getMemoizedHeatmap(this.hourlySummaryHeatMapMomizer)
                            }
                        </TabPanel>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={{ padding: 0 }}
                            index={1}
                            key='tab_monthly_summary'>
                            {
                                this.getMemoizedHeatmap(this.monthlySummaryHeatMapMomizer)
                            }
                        </TabPanel>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={{ padding: 0 }}
                            index={2}
                            key='tab_hourly_details'>
                            {
                                this.getMemoizedHeatmap(this.houryDetailedHeatMapMomizer)
                            }
                        </TabPanel>
                    </Grid>
                </Grid>
            </ReportViewer >
        )
    }
}

export default FtrAnalysesPnLReport;
