import { callSetFavouriteReport } from '../apis/vitusApi';
import { SetUserAction } from '../store/actions';
import { store } from '../store/store';
import { ReportParams } from '../system/ReportBase';
import { CustomReportParams } from '../views/CustomReport/CustomReport';
import AlertManager from './alertManager';
import { handleApiError, ViewTypes } from './common';


export const prepareFavouriteReports = (favourite_reports?: string[]) => {
    return favourite_reports?.reduce(
        (dict: { [key: string]: boolean }, reportKey: string) => {
            dict[reportKey] = true; return dict;
        }, {})
}

export const setFavouriteReport = (title: string, reportKey: string, isFavourite: boolean) => {
    if (isFavourite)
        AlertManager.showInfo(`Adding ${title} to favourites.`);
    else
        AlertManager.showInfo(`Removing ${title} from favourites.`);

    callSetFavouriteReport({ report_key: reportKey, is_favourite: isFavourite })
        .then(response => {
            if (response.data.success) {
                store.dispatch<SetUserAction>({
                    type: 'SET_USER', payload: {
                        ...store.getState().auth,
                        favouriteReports: prepareFavouriteReports(response.data.success.favourite_reports)
                    }
                });

                if (isFavourite)
                    AlertManager.showSuccess(`${title} was succesfully added to favourites.`);
                else
                    AlertManager.showSuccess(`${title} was successfullt removed from favourites.`);
            }
            else if (response.data.error) {
                AlertManager.showError(`Update favourites failed for ${title}`);
            }
        }, error => {
            handleApiError(error);
        });
}

export const prepareCustomReports = (rawCustomReports?: { Id: string, Name: string }[]) => {
    return rawCustomReports?.reduce((dict: { [key: string]: ReportParams }, r: { Id: string, Name: string }) => {
        const reportKey = r.Name.replaceAll(' ', '_').toUpperCase();

        dict[reportKey] = {
            viewType: ViewTypes.CustomReport,
            reportKey,
            name: r.Name,
            path: `${CustomReportParams.cleanPath}/${r.Id}`,
            thumbnail: CustomReportParams.thumbnail,
        }; return dict;
    }, {})
}