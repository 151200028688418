import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps, KeyboardDateTimePickerProps, KeyboardTimePicker } from "@material-ui/pickers";
import { PickerView, ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import { AppBar, Chip, FormControl, Grid, Icon, IconButton, Input, InputAdornment, InputLabel, Popover, Typography } from "@material-ui/core";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import VSelect, { checkedCheckboxIcon, uncheckedCheckboxIcon } from '../VSelect/VSelect';
import { toDateString, toShortDateString, shortDateFormat, generateUuid } from '../../utils/common';
import Event from '@material-ui/icons/Event';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        datePicker: {
            width: 130,
            display: "inline-block",
            margin: 0,
        },
        toolbar: {
            width: "auto",
            height: "auto",
            padding: 0,
        },
        customButtonsContainer: {
            maxWidth: 310,
            padding: 0,
        },
        floatingCustomButtonsContainer: {
            maxWidth: 320,
            padding: 0,
        },
        chip: {
            margin: theme.spacing(0.8),
            fontWeight: 600,
            fontSize: 12,
        },
        floatingDateOuterContainer: {
            width: "auto",
            height: "auto", padding: 12,
            whiteSpace: "nowrap",
            verticalAlign: "top",
            display: "inline-block",
            "& > *": {
                margin: theme.spacing(0.8),
                display: "inline-block",
            }
        },
        floatingDateInnerContainer: {
            verticalAlign: "top",
        },
    }),
);

function getDateByDiff(diff: number) {
    const customDate = new Date();
    customDate.setDate(new Date().getDate() - diff);
    customDate.setHours(0, 0, 0, 0);
    return moment(customDate);
}

const customDates: { [key: string]: () => Moment } = {
    "D": () => {
        return getDateByDiff(0);
    },
    "D-1": () => {
        return getDateByDiff(1);
    },
    "D-2": () => {
        return getDateByDiff(2);
    },
}

type CustomToolbarComponentProps = {
    setDate: (newDate: MaterialUiPickersDate | FloatingDate) => void,
    allowFloatingDates?: boolean
    floatingDatesSelected?: boolean
    setFloatingDatesSelected?: (floatingDatesSelected: boolean) => void
    setOpenView?: (view: PickerView) => void
    isLandscape?: boolean
    date?: MaterialUiPickersDate,
    setIsOpen: (isOpen: boolean) => void,
}

const CustomToolbar: React.FC<CustomToolbarComponentProps> = (props: CustomToolbarComponentProps) => {
    const handleChangeViewClick = (view: PickerView) => () => {
        if (props.setOpenView)
            props.setOpenView(view);
    }

    const classes = useStyles();

    return (
        <PickerToolbar className={classes.toolbar}
            isLandscape={props.isLandscape ?? true}>
            <Grid container className={classes.customButtonsContainer}>
                {props.allowFloatingDates &&
                    <Grid item xs={12}>
                        <Chip
                            className={classes.chip}
                            label="Floating Dates"
                            onClick={() => {
                                if (props.setFloatingDatesSelected)
                                    props.setFloatingDatesSelected(!props.floatingDatesSelected);

                            }}
                            avatar={props.floatingDatesSelected ? checkedCheckboxIcon : uncheckedCheckboxIcon}
                        />
                    </Grid>
                }
                {!props.floatingDatesSelected &&
                    <Grid item xs={12}>
                        <Chip
                            className={classes.chip}
                            label={props.date?.format("DD MMM") || "-"}
                            onClick={handleChangeViewClick("date")}
                        />
                        <Chip
                            className={classes.chip}
                            label={props.date?.format("YYYY") || "-"}
                            onClick={handleChangeViewClick("year")}
                        />

                        {
                            Object.keys(customDates).map((k: string) => {
                                return (
                                    <Chip
                                        key={k}
                                        className={classes.chip}
                                        label={k}
                                        onClick={() => {
                                            props.setDate(customDates[k]());
                                            props.setIsOpen(false);
                                        }}
                                    />
                                )
                            })
                        }
                    </Grid>
                }
            </Grid>
        </PickerToolbar>
    );

}

export type CustomKeyboardDatePickerProps = Omit<KeyboardDatePickerProps, 'onChange'> & {
    onChange?: (date: MaterialUiPickersDate | null, value?: string | null) => void
    onCustomChange?: (date: MaterialUiPickersDate | null | FloatingDate, floatingDatesSelected?: boolean) => void;
    allowFloatingDates?: boolean,
}

export type FloatingDate = {
    selectedDateReference: "Today" | "First Day of Month" | "First Day of Year",
    selectedOperation: '+' | '-',
    selectedValueForFloating: number,
}

const dateReferences: FloatingDate["selectedDateReference"][] = ["Today", "First Day of Month", "First Day of Year"];
const operations: FloatingDate["selectedOperation"][] = ["-", "+"];

const operators: { [key in FloatingDate["selectedOperation"]]: (val1: Date, val2: number) => void } = {
    "+": (val1: Date, val2: number) => val1.setDate(val1.getDate() + val2),
    "-": (val1: Date, val2: number) => val1.setDate(val1.getDate() - val2),
}

const defaultFloatingDate: FloatingDate = {
    selectedDateReference: dateReferences[0],
    selectedOperation: operations[0],
    selectedValueForFloating: 0,
}

export function isFloating(value?: any) {
    if ((value as FloatingDate)?.selectedDateReference !== undefined)
        return true;

    return false;
}

export function calculateFloatingDate(value: FloatingDate) {
    const referenceDate = new Date();

    if (value.selectedDateReference !== "Today")
        referenceDate.setDate(1);

    if (value.selectedDateReference === "First Day of Year")
        referenceDate.setMonth(0);

    operators[value.selectedOperation](referenceDate, value.selectedValueForFloating);

    return toShortDateString(referenceDate);
}

const VDatePicker: React.FC<CustomKeyboardDatePickerProps> = (props: CustomKeyboardDatePickerProps) => {
    const classes = useStyles();
    const anchorRef = React.useRef<HTMLInputElement>(null);

    const elementId = generateUuid();

    const floatingDatesSelectedInitial = isFloating(props.value);

    const initialValues: FloatingDate = floatingDatesSelectedInitial ? ((props.value as FloatingDate) || defaultFloatingDate) : defaultFloatingDate;

    const [value, setValue] = React.useState(props.value);
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedDateReference, setSelectedDateReference] = React.useState<FloatingDate["selectedDateReference"]>(initialValues.selectedDateReference);
    const [selectedOperation, setSelectedOperation] = React.useState<FloatingDate["selectedOperation"]>(initialValues.selectedOperation);
    const [selectedValueForFloating, setSelectedValueForFloating] = React.useState<number>(initialValues.selectedValueForFloating);

    const [floatingDatesSelected, setFloatingDatesSelected] = React.useState<boolean>(floatingDatesSelectedInitial);

    const updateDate = (newDate: MaterialUiPickersDate | FloatingDate) => {
        setValue(newDate);

        if (props.onChange && (newDate as MaterialUiPickersDate)) {
            props.onChange(newDate as MaterialUiPickersDate);
            setIsOpen(false);
        }

        if (props.onCustomChange && (newDate as FloatingDate))
            props.onCustomChange(newDate, floatingDatesSelected);
    }

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    React.useEffect(() => {
        if (floatingDatesSelected)
            updateDate(defaultFloatingDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floatingDatesSelected]);

    const customToolbar = (toobarProps?: ToolbarComponentProps) => (
        <CustomToolbar
            {...(toobarProps || {})}
            setIsOpen={setIsOpen}
            allowFloatingDates={!!props?.allowFloatingDates}
            setDate={updateDate}
            floatingDatesSelected={floatingDatesSelected}
            setFloatingDatesSelected={setFloatingDatesSelected}
        />
    )

    const simpleDatePicker = (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                {...props}
                onChange={(newDate) => {
                    const newDateMoment = pickerDateToMoment(value, newDate)

                    setValue(newDateMoment);
                    props.onChange?.(newDateMoment as MaterialUiPickersDate);
                    setIsOpen(false);
                }}
                className={classes.datePicker}
                variant={props.allowFloatingDates ? "static" : "inline"}
                openTo="date"
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                value={value}
                autoOk
                ToolbarComponent={(props) => customToolbar(props)}
            />
        </MuiPickersUtilsProvider>
    )

    if (!props.allowFloatingDates)
        return simpleDatePicker;

    return (
        <>
            <InputLabel htmlFor={`input_${elementId}`} shrink>{props.label}</InputLabel>
            <Input
                ref={anchorRef}
                id={`input_${elementId}`}
                value={floatingDatesSelected ?
                    `${selectedDateReference} ${selectedOperation} ${selectedValueForFloating}`
                    :
                    toDateString(props.format || shortDateFormat, value as Date)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={() => setIsOpen(true)} >
                            <Event />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <br />
            <Popover
                open={isOpen}
                anchorEl={anchorRef.current}
                onClose={() => setIsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                {
                    floatingDatesSelected ?
                        <div className={classes.floatingCustomButtonsContainer}>
                            <AppBar position="static">
                                {customToolbar()}
                            </AppBar>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={classes.floatingDateOuterContainer}>
                                        <div className={classes.floatingDateInnerContainer}>
                                            <VSelect
                                                width={150}
                                                title="Referance Value"
                                                disableClearable
                                                value={selectedDateReference}
                                                options={dateReferences}
                                                getOptionLabel={(option) => `${option}`}
                                                onChange={(newValue) => {
                                                    const newSelection: FloatingDate = {
                                                        selectedDateReference: newValue as FloatingDate["selectedDateReference"],
                                                        selectedOperation,
                                                        selectedValueForFloating
                                                    };

                                                    updateDate(newSelection);
                                                    setSelectedDateReference(newSelection.selectedDateReference)
                                                }}
                                            />
                                        </div>
                                        <div className={classes.floatingDateInnerContainer}>
                                            <VSelect
                                                width={65}
                                                largeText
                                                title="Operation"
                                                disableClearable
                                                value={selectedOperation}
                                                options={operations}
                                                getOptionLabel={(option) => `${option}`}
                                                onChange={(newValue) => {
                                                    const newSelection: FloatingDate = {
                                                        selectedDateReference: selectedDateReference,
                                                        selectedOperation: newValue as FloatingDate["selectedOperation"],
                                                        selectedValueForFloating
                                                    };

                                                    updateDate(newSelection);
                                                    setSelectedOperation(newSelection.selectedOperation)
                                                }}
                                            />
                                        </div>
                                        <div className={classes.floatingDateInnerContainer}>
                                            <FormControl style={{ width: 50 }}>
                                                <InputLabel htmlFor={`num_input_${elementId}`} shrink>Day Count</InputLabel>
                                                <Input
                                                    id={`num_input_${elementId}`}
                                                    value={selectedValueForFloating}
                                                    type="number"
                                                    onChange={(newValue) => {
                                                        const newSelection: FloatingDate = {
                                                            selectedDateReference: selectedDateReference,
                                                            selectedOperation,
                                                            selectedValueForFloating: +newValue.target.value
                                                        };

                                                        updateDate(newSelection);
                                                        setSelectedValueForFloating(newSelection.selectedValueForFloating)
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.floatingDateOuterContainer}>
                                        <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                                            Result: ({`${selectedDateReference} ${selectedOperation} ${selectedValueForFloating}`} days)
                                        </Typography>
                                        <br />
                                        <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                                            Current Value: {calculateFloatingDate({ selectedDateReference: selectedDateReference, selectedOperation, selectedValueForFloating })}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        simpleDatePicker
                }
            </Popover>
            <br />
        </>
    )
}

function pickerDateToMoment(previousDate: ParsableDate, newDate: MaterialUiPickersDate) {
    if (newDate) {
        if (previousDate) {
            const mv = moment(previousDate);
            newDate.set({ hour: mv.hour(), minute: mv.minute(), second: 0, millisecond: 0 })
        }
        else {
            newDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        }
    }

    return newDate;
}

export const VDateTimePicker = (props: KeyboardDateTimePickerProps) => {
    const [dateValue, setDateValue] = React.useState(props.value);

    React.useEffect(() => {
        setDateValue(props.value);
    }, [props.value]);

    const { views, openTo, ...rest } = props;

    const dateChanged = (date: MaterialUiPickersDate) => {
        if (date?.isValid() || !date) {
            setDateValue(date);
            props.onChange?.(date as MaterialUiPickersDate);
        }
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        }}>
            <VDatePicker
                {...rest}
                variant="inline"
                format="DD/MM/yyyy"
                style={{ width: 110 }}
                margin={props.margin}
                label={props.label}
                value={dateValue}
                onChange={(date) => {
                    dateChanged(pickerDateToMoment(dateValue, date));
                }}
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardTimePicker
                    autoOk
                    ampm={false}
                    format='HH:mm'
                    style={{ width: 78, marginLeft: 3 }}
                    label="Time"
                    minutesStep={10}
                    placeholder=""
                    value={dateValue}
                    onChange={date => {
                        if (date)
                            if (dateValue) {
                                const mv = moment(dateValue);
                                date.set({ year: mv.year(), month: mv.month(), date: mv.date(), second: 0, millisecond: 0 })
                            }

                        dateChanged(date);
                    }}
                />
            </MuiPickersUtilsProvider>
            <Typography>
                <IconButton
                    style={{ padding: "3px 3px 0 3px" }}
                    onClick={() => dateChanged(null)}>
                    <Icon fontSize="small" className="fas fa-times" />
                </IconButton>
            </Typography>
        </div>
    )
}

export default VDatePicker;
