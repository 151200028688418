import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { logout } from '../../apis/vitusApi';
import { IAuthState } from '../../store/reducers/authReducer';
import { GlobalStates } from '../../store/store';
import { createSpinner } from '../../utils/spinnerManager';
import VThemeSelector from '../VThemeSelector/VThemeSelector';

const VUserInfo: React.FC = () => {
    const auth = useSelector<GlobalStates, IAuthState>(state => state.auth);

    if (!auth.isLoggedIn)
        return null;

    const onLogout = () => {
        const spinner = createSpinner();

        logout().finally(() => {
            spinner.hide();
        });
    }

    return (
        <div style={{ whiteSpace: 'nowrap' }}>
            <VThemeSelector />
            <Button onClick={() => false} color="inherit">
                <i className="far fa-user-circle" style={{ paddingRight: "5px" }} />
                {auth.userName}
            </Button>
            <Button onClick={onLogout} color="inherit">
                <i className="fas fa-sign-out-alt" style={{ paddingRight: "5px" }} />
                Log out
            </Button>
        </div>)
};

export default VUserInfo;