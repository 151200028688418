import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React from 'react';

interface IProps {
    title?: string,
    defaultValue?: string,
    options: { value: string, title: string }[],
    onChange?: (selectedValue: string) => void
}

const VRadioButtonGroup: React.FC<IProps> = (props: IProps) => {
    const [selected, setSelected] = React.useState(props.defaultValue || props.options[0].value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setSelected(value);

        if (props.onChange)
            props.onChange(value);
    };

    return (
        <div style={{ height: 42.56, margin: 8 }}>
            <FormControl component="fieldset">
                {props.title &&
                    <FormLabel component="legend">{props.title}</FormLabel>
                }
                <RadioGroup row value={selected} onChange={handleChange}>
                    {
                        props.options.map(o => {
                            return (
                                <FormControlLabel
                                    key={o.value}
                                    value={o.value}
                                    control={<Radio />}
                                    label={o.title} />
                            );
                        })
                    }
                </RadioGroup>
            </FormControl>
        </div>
    );
};

export default VRadioButtonGroup;