import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { HeatmapChart, HeatmapSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { getEuropeGasDemandBalance } from '../../../apis/vitusApi';
import _, { isEmpty, max, min } from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import { createSpinner } from '../../../utils/spinnerManager';
import { IEuropeGasDemandBalanceResponse } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { handleApiError, monthNames, shortenNumber } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import { ITabProps, rangeLineOptions } from './gasBalanceUtils';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption | HeatmapSeriesOption>;

echarts.use(
    [LineChart, HeatmapChart, GridComponent]
);

interface IState {
    rangeData: IEuropeGasDemandBalanceResponse["success"]["range"],
    heatmapData: IEuropeGasDemandBalanceResponse["success"]["heatmap"],
    yearlyAverages: IEuropeGasDemandBalanceResponse["success"]["yearly_averages"],
    pageReady: boolean,
    includedYears: string[],
}

class EuropeGasDemand extends React.Component<ITabProps, IState> {
    lineRangeChartRef: ReactECharts | null = null;
    heatmapChartRef: ReactECharts | null = null;

    lineRangeChartOptions: ECOption = {
        color: ['black', 'black', 'blue', 'green', 'red', 'orange'],
        animation: false,
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
            scale: true,
            axisLabel: {
                formatter: shortenNumber,
            }
        },
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'valueDesc'
        },
        series: [{
            data: [],
            type: 'line'
        }],
        dataZoom: [{}],
    }

    heatMapOptions: ECOption = {
        animation: false,
        tooltip: {
            position: 'top',
        },
        xAxis: {
            type: 'category',
            position: 'top',
            axisTick: {
                interval: 0
            },
            splitArea: {
                show: true
            },
            axisLabel: {
                rotate: -45
            }
        },
        yAxis: {
            type: 'category',
            splitArea: {
                show: true
            },
            axisTick: {
                interval: 0
            },
            axisLabel: {
                interval: 0
            },
        }
    };

    state: IState = {
        rangeData: [],
        heatmapData: [],
        yearlyAverages: [],
        includedYears: [],
        pageReady: false,
    }

    componentDidMount() {
        if (!this.props.data)
            this.getData();
        else
            this.storeData(this.props.data);
    }

    getData() {
        const spinner = createSpinner();

        getEuropeGasDemandBalance().then(response => {
            if (response.data.success) {
                this.storeData(response.data.success);
                this.props.onDataChanged(response.data.success);
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            this.setState({ pageReady: true });
            spinner.hide()
        });
    }

    storeData(response: IEuropeGasDemandBalanceResponse["success"]) {
        this.setState({
            rangeData: response.range || [],
            heatmapData: response.heatmap || [],
            yearlyAverages: response.yearly_averages || [],
            includedYears: response.included_years || [],
        }, this.updateCharts);
    }

    updateCharts() {
        if (!this.renderCharts())
            return;

        this.updateHeatMap();
        this.updateLineChart();
    }

    updateLineChart = () => {
        const lineChartInstance = this.lineRangeChartRef?.getEchartsInstance();
        lineChartInstance.showLoading();

        const data = this.state.rangeData;
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 5;
        const maxYear = currentYear - 1;

        const allLegend = "Rolling " + ((minYear && maxYear) ? (`${minYear}-${maxYear}`) : "All")

        const series = [
            {
                name: 'Range Min',
                type: 'line',
                data: data.map(d => [d.DateTitle, d.MinHistorical]),
                ...rangeLineOptions,
            },
            {
                name: 'Range Max',
                type: 'line',
                data: data.map(d => [d.DateTitle, d.MaxHistorical]),
                ...rangeLineOptions,
            },
            {
                type: 'line',
                lineStyle: {
                    type: 'dashed',
                },
                name: allLegend,
                data: data.map(d => [d.DateTitle, d.RollingAll])
            },
            {
                type: 'line',
                lineStyle: {
                    type: 'dotted',
                },
                name: "Last Year",
                data: data.map(d => [d.DateTitle, d.LastYear])
            },
            ...this.state.includedYears.map(year => ({
                type: 'line',
                name: year,
                data: data.map(d => [d.DateTitle, d[year]]),
            })),
        ];

        lineChartInstance.clear();

        lineChartInstance.setOption({
            ...this.lineRangeChartOptions,
            series: series,
            legend: {
                type: 'scroll',
                data: series.map(s => s.name),
            }
        });

        lineChartInstance.hideLoading();
    }

    updateHeatMap = () => {
        const heatMapInstance = this.heatmapChartRef?.getEchartsInstance();
        heatMapInstance.showLoading();

        const data = this.state.heatmapData;
        const values = data.map(d => d.Value);

        heatMapInstance.clear();
        heatMapInstance.setOption({
            ...this.heatMapOptions,
            xAxis: [{
                ...this.heatMapOptions.xAxis,
                data: _(data.map(d => d.Year.toString())).uniq().sortBy().value()
            },
            {
                type: "category",
                name: "Average",
                nameLocation: 'start',
                position: 'bottom',
                offset: 10,
                splitArea: {
                    show: true
                },
                axisLabel: {
                    fontWeight: "bold",
                    interval: 0,
                },
                data: this.state.yearlyAverages.map(v => v.toLocaleString()),
            }],
            yAxis: {
                ...this.heatMapOptions.yAxis,
                data: _(data.map(d => d.Month)).uniq().sortBy(i => -monthNames[i]).value()
            },
            series: [{
                type: 'heatmap',
                data: data.map(d => [d.Year.toString(), d.Month, d.Value]),
                label: {
                    show: true,
                    formatter: (params: { data: any[][] }) => (params.data[2].toLocaleString())
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                itemStyle: {
                    borderWidth: 3,
                    borderColor: '#fff',
                }
            }],
            visualMap: {
                min: min(values),
                max: max(values),
                calculable: true,
                orient: 'horizontal',
                itemWidth: 10,
                itemHeight: 70,
                inRange: {
                    color: ['#208E0E', '#9EB09B', '#DA4848']
                }
            },
        });

        heatMapInstance.hideLoading();
    }

    renderCharts() {
        return this.state.rangeData && !isEmpty(this.state.rangeData);
    }

    render() {
        return (
            <Grid container spacing={3} justify="flex-start" alignItems="flex-end">
                {
                    this.renderCharts() &&
                    <Grid item xs={12} >
                        <VChart
                            height={350}
                            title="Average of 7 Day Rolling Europe Demand Comparison (cm)"
                            toolbox={{
                                chartRef: this.lineRangeChartRef,
                                downloadedFileName: "",
                                getTableData: () => (
                                    {
                                        columns: ["DateTitle", "RollingAll", "LastYear", "MinHistorical", "MaxHistorical", ...this.state.includedYears],
                                        rows: this.state.rangeData
                                    }),
                            }}
                            report={
                                <ReactECharts
                                    ref={(e) => { this.lineRangeChartRef = e; }}
                                    echarts={echarts}
                                    option={this.lineRangeChartOptions}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ width: '99%' }}
                                />
                            } >
                        </VChart>
                    </Grid>
                }
                {
                    this.renderCharts() &&
                    <React.Fragment>
                        <Grid item xs={8}>
                            <VChart
                                height={400}
                                title="Average of 7 Day Rolling Europe Demand (cm)"
                                report={
                                    <ReactECharts
                                        ref={(e) => { this.heatmapChartRef = e; }}
                                        echarts={echarts}
                                        option={this.heatMapOptions}
                                        notMerge={true}
                                        lazyUpdate={true}
                                        style={{ width: '99%', height: 350 }}
                                    />
                                } />
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <span style={{ fontWeight: 500 }}>Countries included:</span>
                                <span>Austria, Belgium, Bulgaria, Crotia, Czech Republic, Denmark,
                                    France, Germany, Hungary, Italy, Luxembourg, Netherlands, Poland,
                                    Portugal, Romania, Slovakia, Slovenia, Spain, United Kingdom
                                </span>
                                <br />
                                <span style={{ fontWeight: 500 }}>Unit:</span>
                                <span>Cubic Meter (CM)</span>
                            </div>
                        </Grid>
                    </React.Fragment>
                }
                {
                    this.state.pageReady && !this.renderCharts() &&
                    <Grid item>
                        <Typography>
                            Data not found!
                        </Typography>
                    </Grid>
                }
            </Grid>
        );
    }
}

export default EuropeGasDemand;