export const endpointNames = {
    // system end points:
    main: '/', // needed for redirection
    login: '/login',
    logout: '/logout',
    get_user: '/get_user',

    // business end points:
    getRealizedPowerSupplyDemand: '/getRealizedPowerSupplyDemand',
    setFavouriteReport: '/setFavouriteReport',
    getRealizedWithForecastPSD: '/getRealizedWithForecastPSD',
    getPSDStaticData: '/getPSDStaticData',
    getDynamicDatasetsMetadata: '/getDynamicDatasetsMetadata',
    getDynamicDatasetsData: '/getDynamicDatasetsData',
    getCustomCharts: '/getCustomCharts',
    getCustomChart: '/getCustomChart',
    saveChart: '/saveChart',
    saveReport: '/saveReport',
    getCustomReport: '/getCustomReport',
    getDynamicChartData: '/getDynamicChartData',
    getBaseDatasetOptions: '/getBaseDatasetOptions',
    saveDataset: '/saveDataset',
    getDataset: '/getDataset',
    getAllDatasets: '/getAllDatasets',
    getReportsAndPermissions: '/getReportsAndPermissions',
    saveReportPermissions: '/saveReportPermissions',
    getCacheDashboard: '/getCacheDashboard',
    getCacheRecordDetail: '/getCacheRecordDetail',
    deleteCacheRecord: '/deleteCacheRecord',
    warmUpAllOperations: '/warmUpAllOperations',
    warmUpSingleOperation: '/warmUpSingleOperation',
    getUsersAndRoles: '/getUsersAndRoles',
    getUserDetails: '/getUserDetails',
    getEuropeGasDemandBalance: '/getEuropeGasDemandBalance',
    getCumulativeGasDemand: '/getCumulativeGasDemand',
    getEuropeGasDemandInjectionSeason: '/getEuropeGasDemandInjectionSeason',
    getGasBurn: '/getGasBurn',
    getGasFlowsInjection: '/getGasFlowsInjection',
    getYoYChanges: '/getYoYChanges',
    getBalanceStorages: '/getBalanceStorages',
    getEUProduction: '/getEUProduction',
    getBalanceNetFlows: '/getBalanceNetFlows',
    getEUGasStorage: '/getEUGasStorage',
    getEuLng: '/getEULNG',
    getForecastReportParams: '/getForecastReportParams',
    getForecastReportData: '/getForecastReportData',
    getEUMonthlyReport: '/getEUMonthlyReport',
    saveEUMonthlyPredictions: '/saveEUMonthlyPredictions',
    deleteEUMonthlyPrediction: '/deleteEUMonthlyPrediction',
    getGasStorageReport: '/getGasStorageReport',
    saveUser: '/saveUser',
    createUser: '/createUser',
    deleteUser: '/deleteUser',
    getAllGasBalanceReport: '/getAllGasBalanceReport',
    getWorkflowTasks: '/getWorkflowTasks',
    getWorkflowTimeTable: '/getWorkflowTimeTable',
    getPriceSupplyDemandOptions: '/getPriceSupplyDemandOptions',
    getEtlPriceSupplyDemandData: '/getEtlPriceSupplyDemandData',
    getFtrAnalysesData: '/getFtrAnalysesData',
    getFtrAnalysesPnlOptionsAndPortfolio: '/getFtrAnalysesPnlOptionsAndPortfolio',
    getContractOptions: '/getContractOptions',
    getContractPricesData: '/getContractPricesData',
}
