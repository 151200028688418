import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Icon, IconButton, Typography } from '@material-ui/core';
import VSelect from '../VSelect/VSelect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        delete: {
            padding: "0 5px 0 0",
        },
        dataFilterItem: {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
        filterLabel: {
            display: "inline-block",
            width: 100,
            fontSize: 12,
            fontWeight: 500,
        },
        dataValueGroup: {
            display: "inline-block",
            fontSize: 12,
            "& > *": {
                marginLeft: theme.spacing(1),
            },
        },
    }),
);

interface IGroupProps {
    cellName: string,
    onDelete: (columnName: string) => void,
    isSelected?: boolean,
    aggregations?: string[],
    onAggregationChanged?: (columnName: string, aggregation: string) => void,
    defaultOption?: string,
}

const VDatasetGroupItem: React.FC<IGroupProps> = (props: IGroupProps) => {
    const classes = useStyles();

    const cellName = props.cellName;

    const [selectedAggregation, setSelectedAggregation] = React.useState<{} | null>({ name: props.defaultOption, value: props.defaultOption });

    return (
        <div className={classes.dataFilterItem}>
            <Typography variant="subtitle1" className={classes.filterLabel}>
                <IconButton className={classes.delete} onClick={() => { if (props.onDelete) props.onDelete(cellName) }}>
                    <Icon className="fas fa-times" />
                </IconButton>
                {cellName}
            </Typography>
            <div className={classes.dataValueGroup}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {
                        !props.isSelected &&
                        <VSelect
                            title=""
                            disableClearable
                            options={!props.aggregations ? [] : props.aggregations.map(a => { return { name: a, value: a } })}
                            getOptionLabel={(option) => option.name}
                            value={selectedAggregation}
                            onChange={(newSelectedValue: {} | null) => {
                                setSelectedAggregation(newSelectedValue)

                                if (newSelectedValue && props.onAggregationChanged)
                                    props.onAggregationChanged(cellName, (newSelectedValue as { name: string }).name as string);
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default VDatasetGroupItem;