export function isMidnight(dateString: string) {
    return dateString.endsWith("00:00");
}

export function getMidnightForDay(dateString: string) {
    return `${getDate(dateString)} 00:00`
}

export function getDate(dateString: string) {
    return dateString.split(" ")[0];
}
