import './MonthlyEuropeSummary.css';
import ReportViewer from '../../ReportViewer/ReportViewer';
import { ReportParams, ReportView } from '../../../system/ReportBase';
import {
    Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton,
    List, ListItem, ListItemText, Paper, Typography, ListSubheader
} from '@material-ui/core';
import { deleteEUMonthlyPrediction, getEUMonthlyReport, saveEUMonthlyPredictions } from '../../../apis/vitusApi';
import { createSpinner } from '../../../utils/spinnerManager';
import messages from '../../../utils/messages';
import AlertManager from '../../../utils/alertManager';
import { handleApiError, makeTitle, monthNames, months } from '../../../utils/common';
import { IMonthlyEuropeSummaryResponse, ISaveEUMonthlyPredictionsRequest, ISaveEUMonthlyPredictionsResponse, MonthlyEuropeSummaryType, MonthlyEuropeSummaryTypes } from '../../../apis/vitusApiTypes';
import { isEmpty, mean, sortBy, sum } from 'lodash';
import { DataGrid, GridCellParams, GridColumns, GridEditCellPropsParams, GridRowParams } from '@material-ui/data-grid';
import React from 'react';
import { styles } from '../../../utils/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Injection: 4 <= month < 11
const allMonths = Array.from(Array(12).keys()).map(m => m + 1);
const injectionMonths = allMonths.filter(m => m >= 4 && m < 11);
const winterMonths = allMonths.filter(m => !injectionMonths.includes(m));

const tableTypes: { [realizedTableName: string]: string } = {
    'average': 'average_forecast',
    'total': 'total_forecast',
}

type YearlyColumns = { [year: number]: { [month: number]: number } };

type MonthFilter = {
    sectionKey: string, location: string, tableKey: string, year: number, month: number
}

const operationMap: { [operation: string]: (collection: (number | undefined)[]) => number } = {
    'average': mean,
    'total': sum,
}

const legendItems = {
    editedCell: { title: "Edited Cell", class: "edited-cell" },
    manuelforecastCell: { title: "Saved Prediction", class: "manuel-forecast-cell" },
};

const customRowClasses: { [key: string]: { id: string, className: string } } = {
    overall: { id: "overall", className: "overall-row" },
    overallInjection: { id: "overallInjection", className: "overall-injection-row" },
    overallWinter: { id: "overallWinter", className: "overall-winter-row" },
    overallWinterYear: { id: "overallWinterYear", className: "overall-winter-years-row" },
}

type SummaryRow = { Year: number, Month: number, Value: number };

type SectionData = {
    [location: string]: {
        [tableKey: string]: {
            [year: number]: {
                [month: number]: number
            }
        }
    }
}

type SummaryData = {
    [dataKey in MonthlyEuropeSummaryType]: SectionData
}

interface IState {
    pageReady: boolean,
    selectedSection: string,
    data?: SummaryData,
    editedValues: SummaryData,
    savedPredictions: SummaryData,
    showConfirmSave?: boolean,
    showConfirmDiscard?: boolean,
    showReviewModal?: boolean,
    cellToDelete?: { data_type: string, value_type: string, location: string, location_type: string, year: number, month: number, value: number },
    locationDetails: {
        [location: string]: {
            locationType: string,
            descriptions: {
                [dataKey in MonthlyEuropeSummaryType]: string
            }
        }
    },
    cellDetail: { info?: any[] },
    hidePredictions: boolean,
    expandedSection: string,
}

class MonthlyEuropeSummary extends ReportView<{}, IState> {
    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth() + 1; //+1 because javascript months start from 0

    static params: ReportParams = new ReportParams(
        {
            reportKey: "MONTHLY_EUROPE_SUMMARY",
            name: "Monthly Europe Summary",
            path: "/MonthlyEuropeSummary",
            thumbnail: ""
        }
    );

    state: IState = {
        pageReady: false,
        selectedSection: "",
        editedValues: {},
        savedPredictions: {},
        locationDetails: {},
        cellDetail: {},
        hidePredictions: false,
        expandedSection: "",
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const spinner = createSpinner();

        getEUMonthlyReport().then(response => {
            if (response.data.success)
                this.storeData(response.data.success)

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            this.setState({ pageReady: true });
            spinner.hide()
        });
    }

    transformSavedValues(tableTypes: string[], sectionKey: string, location: string, sourceTables: { [tableKey: string]: SummaryRow[] }, targetTables: SummaryData) {
        targetTables[sectionKey][location] = {};

        tableTypes.forEach(tableKey => {
            if (!sourceTables[tableKey])
                return;

            targetTables[sectionKey][location][tableKey] = {};

            sourceTables[tableKey].forEach(row => {
                if (!targetTables[sectionKey][location][tableKey][row.Year])
                    targetTables[sectionKey][location][tableKey][row.Year] = {}

                targetTables[sectionKey][location][tableKey][row.Year][row.Month] = row.Value
            })
        })

        if (isEmpty(targetTables[sectionKey][location]))
            delete targetTables[sectionKey][location];
    }

    storeData(data: IMonthlyEuropeSummaryResponse["success"]) {
        const { transformedData, locationDetails, savedPredictions } = this.transformTablesToDicts(data);

        this.setState({ data: transformedData, locationDetails: locationDetails, savedPredictions: savedPredictions })
    }

    private transformTablesToDicts(data: IMonthlyEuropeSummaryResponse["success"]) {
        const transformedData: IState["data"] = {};
        const savedPredictions: IState["data"] = {};

        const locationDetails: IState["locationDetails"] = {};

        Object.keys(data).forEach((sectionKey: MonthlyEuropeSummaryType) => {
            transformedData[sectionKey] = {};
            savedPredictions[sectionKey] = {};

            if (!data[sectionKey] || isEmpty(data[sectionKey]))
                return;

            data[sectionKey].data.forEach(locationData => {
                const { location, tables, location_type, description } = locationData;

                locationDetails[location] = {
                    locationType: location_type,
                    descriptions: { ...(locationDetails[location]?.descriptions || {}), [sectionKey]: description || "" }
                };

                this.transformSavedValues(Object.keys(tableTypes), sectionKey, location, tables, transformedData);
                this.transformSavedValues(Object.values(tableTypes), sectionKey, location, tables, savedPredictions);
            });

            if (isEmpty(savedPredictions[sectionKey]))
                delete savedPredictions[sectionKey];
        });
        return { transformedData, locationDetails, savedPredictions };
    }

    getSectionList() {
        return (
            <List dense component="nav" style={{ width: 165, maxWidth: 165 }}
                subheader={
                    <ListSubheader component="div" style={{ lineHeight: 'inherit', paddingTop: 10 }}>
                        Report Sections
                    </ListSubheader>
                }>
                {
                    MonthlyEuropeSummaryTypes.map(sectionKey => {
                        const isSubListOpen = this.state.expandedSection === sectionKey;

                        return (
                            <React.Fragment key={`link_${sectionKey}`}>
                                <ListItem
                                    button
                                    component="a"
                                    href={`#${sectionKey}`}
                                    onClick={() => {
                                        if (isSubListOpen)
                                            this.setState({ expandedSection: '' })
                                        else
                                            this.setState({ expandedSection: sectionKey })
                                        return true;
                                    }}>
                                    <ListItemText primary={makeTitle(sectionKey)} />
                                    <IconButton size="small">
                                        {isSubListOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </ListItem>
                                {
                                    (this.state.data && !isEmpty(this.state.data)) &&
                                    <Collapse in={isSubListOpen} component="li" unmountOnExit >
                                        <List dense component="div" disablePadding style={{ width: 'fit-content', paddingLeft: 10 }}>
                                            {Object.keys(this.state.data?.[sectionKey] || {}).map(location => {
                                                return (
                                                    <ListItem key={`link_${sectionKey}_${location}`}
                                                        button
                                                        component="a"
                                                        href={`#${sectionKey}_${location}`}
                                                        onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
                                                        <ListItemText primary={makeTitle(location)} />
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </Collapse>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </List>
        )
    }

    getSections(data: SummaryData, editedValues: SummaryData, savedPredictions: SummaryData) {
        const sections = MonthlyEuropeSummaryTypes.map(sectionKey => {
            return this.getTables(sectionKey, data?.[sectionKey], editedValues?.[sectionKey], savedPredictions?.[sectionKey])
        })

        return (
            <Grid item xs={12} alignItems="flex-start" container
                style={{ marginTop: 35, overflow: 'scroll', height: 'calc(100vh - 127px)' }}>
                {sections.filter(e => e).map((e: any, idx) => {
                    e.key = `row_${idx}`

                    return e;
                })}
            </Grid>
        )
    }

    getOverall(operationName: string,
        data: YearlyColumns,
        editedValues?: YearlyColumns,
        savedPredictions?: YearlyColumns
    ) {
        const overall: { [key: string]: string } = {};

        this.getYears(data, savedPredictions).forEach(year => {
            const yearNum = +year;

            const yearlyValues: number[] = allMonths.map(month => {
                const value = this.chooseValueToShow({
                    editedValue: editedValues?.[yearNum]?.[+month],
                    savedPrediction: savedPredictions?.[yearNum]?.[+month],
                    realizedValue: data[yearNum]?.[+month]
                });

                return value;
            }).filter(v => v !== undefined) as number[];

            if (yearlyValues.length && operationMap[operationName])
                overall[yearNum] = operationMap[operationName](yearlyValues).toFixed(1)
            else
                overall[yearNum] = "-";
        })

        return overall;
    }

    getWinterSeason(operationName: string,
        data: YearlyColumns,
        editedValues?: YearlyColumns,
        savedPredictions?: YearlyColumns
    ) {
        const overall: { [year: string]: string } = {};

        this.getYears(data, savedPredictions).forEach(year => {
            const yearNum = +year;

            const yearlyValues: number[] = winterMonths.map(month => {
                let yearToUse: number;

                if (month > 10)
                    yearToUse = yearNum;
                else
                    yearToUse = yearNum + 1;

                const value = this.chooseValueToShow({
                    editedValue: editedValues?.[yearToUse]?.[+month],
                    savedPrediction: savedPredictions?.[yearToUse]?.[+month],
                    realizedValue: data[yearToUse]?.[+month]
                });

                return value;
            }).filter(v => v !== undefined) as number[];

            if (yearlyValues.length && operationMap[operationName])
                overall[yearNum] = operationMap[operationName](yearlyValues).toFixed(1);
            else
                overall[yearNum] = "-";
        })

        return overall;
    }

    getInjectionSeason(operationName: string,
        data: YearlyColumns,
        editedValues?: YearlyColumns,
        savedPredictions?: YearlyColumns) {
        const overall: { [year: string]: string } = {};

        this.getYears(data, savedPredictions).forEach(year => {
            const yearNum = +year;

            const yearlyValues = injectionMonths.map(month => {
                return this.chooseValueToShow({
                    editedValue: editedValues?.[yearNum]?.[+month],
                    savedPrediction: savedPredictions?.[yearNum]?.[+month],
                    realizedValue: data[yearNum]?.[+month]
                })
            }).filter(v => v !== undefined) as number[];

            if (yearlyValues.length && operationMap[operationName])
                overall[yearNum] = operationMap[operationName](yearlyValues).toFixed(1);
            else
                overall[yearNum] = "-";
        })

        return overall;
    }

    getRowClassName(params: GridRowParams) {
        if (customRowClasses[params.id])
            return customRowClasses[params.id].className;

        if (+params.id % 2 === 0)
            return "even-row";
        return "odd-row";
    }

    isCellEditable(params: GridCellParams) {
        const monthId = monthNames[params.row["month"]];

        if (monthId === undefined)
            return false;

        const year = +params.field;

        if (year > this.currentYear || (year === this.currentYear && (monthId + 1) >= this.currentMonth))
            return true;

        return false;
    }

    forceRenderTable(sectionKey: string, location: string, tableKey: string) {
        this.cachedObjects[this.cacheKeyIdentities[this.getCacheIdentityKey(sectionKey, location, tableKey)]] = undefined;
    }

    getCacheIdentityKey(sectionKey: string, location: string, tableKey: string) {
        return `${sectionKey}_${location}_${tableKey}`
    }

    cacheKeyIdentities: { [key: string]: string } = {};
    cachedObjects: { [key: string]: any } = {};
    getCachedTable(sectionKey: string, location: string, tableKey: string, data: YearlyColumns, editedValues?: YearlyColumns, savedPredictions?: YearlyColumns) {
        const params_key = JSON.stringify({ sectionKey, location, tableKey, data, editedValues, savedPredictions, hidePredictions: this.state.hidePredictions });

        const cachedObjects = this.cachedObjects;
        if (cachedObjects[params_key]) {
            return cachedObjects[params_key];
        }

        const newObject = this.getTable(sectionKey, location, tableKey, data, editedValues, savedPredictions);
        cachedObjects[params_key] = newObject;
        this.cacheKeyIdentities[this.getCacheIdentityKey(sectionKey, location, tableKey)] = params_key;

        return newObject;
    }

    setDeleteCell(params: MonthFilter) {
        this.setState({
            cellToDelete: {
                data_type: params.sectionKey,
                value_type: params.tableKey,
                location: params.location,
                location_type: this.state.locationDetails[params.location].locationType,
                year: params.year,
                month: params.month,
                value: this.getSavedPredictionValue(params) || -1
            }
        })
    }

    confirmDeleteCell() {
        if (!this.state.cellToDelete) {
            AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            return;
        }

        const cellToDelete = { ...this.state.cellToDelete };
        this.cancelDeleteCell();

        const params: MonthFilter = {
            sectionKey: cellToDelete.data_type,
            location: cellToDelete.location,
            tableKey: cellToDelete.value_type,
            year: cellToDelete.year,
            month: cellToDelete.month
        };

        const editedValue = this.getEditedValue(params);

        if (editedValue !== undefined) {
            const editedValues = { ...this.state.editedValues };
            this.deleteWithBranchCleaning(editedValues, params)
            this.setState({ editedValues });

            AlertManager.showSuccess("Unsaved Prediction cleaned successfully."); //TODO: message
            return;
        }

        const spinner = createSpinner();

        deleteEUMonthlyPrediction(cellToDelete).then(response => {
            if (response.data.success) {
                const updatedPredictions = response.data.success.predictions;

                if (!isEmpty(response.data.success)) {
                    const { savedPredictions } = this.transformTablesToDicts(updatedPredictions);
                    this.setState({ savedPredictions });

                    AlertManager.showSuccess("Prediction deleted successfully."); //TODO: message
                } else {
                    AlertManager.showError("Prediction could not be deleted."); //TODO: message
                }
            }

            if (response.data.error) {
                this.handleEditError(response.data.error)
            }

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });;
    }

    cancelDeleteCell() {
        this.setState({ cellToDelete: undefined });
    }

    chooseValueToShow(
        values: {
            realizedValue?: number, editedValue?: number, savedPrediction?: number
        }) {
        const { realizedValue, editedValue, savedPrediction } = values;

        if (!this.state.hidePredictions) {
            if (editedValue || editedValue === 0)
                return editedValue;
            else if (savedPrediction || savedPrediction === 0)
                return +(+savedPrediction).toFixed(2);
            else if (realizedValue || realizedValue === 0)
                return realizedValue;
            else
                return undefined;
        }
        else {
            return (realizedValue || realizedValue === 0) ? realizedValue : undefined;
        }
    }

    getYears(data: YearlyColumns, savedPredictions?: YearlyColumns, includeNextYear?: boolean) {
        const currenyYear = new Date().getFullYear();

        return sortBy(Object.keys({
            ...data,
            ...(savedPredictions || {}),
            ...(includeNextYear ? { [currenyYear + 1]: "", [currenyYear + 2]: "" } : {})
        })).map(year => +year);
    }

    getTable(sectionKey: string, location: string, tableKey: string, data: YearlyColumns, editedValues?: YearlyColumns, savedPredictions?: YearlyColumns) {
        const years = this.getYears(data, savedPredictions, true);

        const overall = this.getOverall(tableKey, data, editedValues, savedPredictions);
        const overallOfInjectionSeason = this.getInjectionSeason(tableKey, data, editedValues, savedPredictions);
        const overallOfWinterSeason = this.getWinterSeason(tableKey, data, editedValues, savedPredictions);

        overall["month"] = `Overall ${makeTitle(tableKey)}`;
        overallOfInjectionSeason["month"] = `${makeTitle(tableKey)} of Injection Season`;
        overallOfWinterSeason["month"] = `${makeTitle(tableKey)} of Winter Season`;

        //table width defined on css => 820, first column defined below => 150
        const dataColumnWidth = (820 - 152) / years.length;

        const columns: GridColumns = [
            {
                field: 'month',
                headerName: makeTitle(tableKey),
                width: 150,
                sortable: false,
                headerClassName: "header-row",
            },
            ...years.map(year => ({
                field: year.toString(),
                headerName: year.toString(),
                width: dataColumnWidth,
                sortable: false,
                headerClassName: "header-row",
                editable: true,
                renderCell: (params: GridCellParams) => {
                    if (params.value !== "-"
                        && !this.state.hidePredictions
                        && this.checkIfPredictionExists(sectionKey, location, tableKey, params))
                        return (
                            <div style={{ position: 'relative', width: '100%' }}>
                                <span>
                                    {params.value}
                                    <IconButton
                                        style={{ right: 0, position: "absolute", padding: 0 }}
                                        onClick={() => {
                                            this.setDeleteCell({ sectionKey, tableKey, location, year: +params.field, month: +params.id })
                                        }}>
                                        <Icon className="fas fa-times" />
                                    </IconButton>
                                </span >
                            </div>
                        );
                    return params.value;
                }
            }))
        ]

        const rows = months.map((month, monthId) => {
            const monthNum = monthId + 1;

            const row: { [key: string]: number | string } = { id: monthNum, month };

            years.forEach(year => {

                const editedValue = editedValues?.[year]?.[monthNum];
                const savedPrediction = savedPredictions?.[year]?.[monthNum];
                const realizedValue = data[year]?.[monthNum];

                const value = this.chooseValueToShow({
                    editedValue,
                    savedPrediction,
                    realizedValue
                });

                row[year.toString()] = value === undefined ? "-" : value;
            })

            return row;
        })

        overall["id"] = customRowClasses.overall.id;
        overall["month"] = `Overall ${makeTitle(tableKey)}`;
        overallOfInjectionSeason["id"] = customRowClasses.overallInjection.id;
        overallOfInjectionSeason["month"] = `${makeTitle(tableKey)} of Injection Season`;
        overallOfWinterSeason["id"] = customRowClasses.overallWinter.id;
        overallOfWinterSeason["month"] = `${makeTitle(tableKey)} of Winter Season`;

        const winterYears: { [key: string]: string } = {
            id: customRowClasses.overallWinterYear.id,
            month: ""
        }
        years.forEach(year => {
            const yearPostfix = year % 100;

            winterYears[year.toString()] = `${yearPostfix}/${yearPostfix + 1}`;
        })

        rows.push(overall)
        rows.push(overallOfInjectionSeason)
        rows.push(winterYears)
        rows.push(overallOfWinterSeason)

        return (
            <Grid alignItems="flex-start" key={`table_head_${sectionKey}_${location}_${tableKey}`} item xs={6} container >
                <Grid id={sectionKey} item xs={12} style={{ padding: "8px 8px 0 8px" }}>
                    <Typography id={`${sectionKey}_${location}`} variant="h6"
                        style={{ margin: "0 5px", scrollMarginTop: "60px" }}>
                        {location} {makeTitle(sectionKey)} {makeTitle(tableKey)}
                    </Typography>
                </Grid>
                <Grid item key={`table_${sectionKey}_${location}_${tableKey}`}
                    style={{ padding: "0 8px 8px 8px" }}
                    xs={12} >
                    <div className="monthly-europe-summary-table">
                        <DataGrid
                            onEditCellChangeCommitted={(params: GridEditCellPropsParams, _) => this.onEditCellChangeCommitted(sectionKey, location, tableKey, params)}
                            density='compact'
                            disableColumnMenu
                            rowHeight={25}
                            hideFooter
                            rows={rows}
                            getRowClassName={(params) => this.getRowClassName(params)}
                            getCellClassName={(params) => this.getCellClassName(sectionKey, location, tableKey, params)}
                            isCellEditable={(params) => this.isCellEditable(params)}
                            columns={columns}
                            onCellEnter={(p, e) => this.showCellDetail(sectionKey, location, tableKey, p, e)}
                        />
                    </div>
                    {
                        <Typography variant="caption">
                            {this.state.locationDetails[location].descriptions[sectionKey]}
                        </Typography>
                    }
                </Grid>
            </Grid >
        );
    }

    showCellDetail(sectionKey: string, location: string, tableKey: string, params: GridCellParams, event: React.MouseEvent<Element, MouseEvent>) {
        const year = +params.field;

        if (!year)
            return;

        const month = +params.id;

        const realizedValue = this.getRealizedValue({ sectionKey, location, tableKey, year, month });
        const editedValue = this.getEditedValue({ sectionKey, location, tableKey, year, month });
        const savedPrediction = this.getSavedPredictionValue({ sectionKey, location, tableKey, year, month });

        const detail = [
            <span><strong>Data Type:</strong>{makeTitle(sectionKey)}</span>,
            <span><strong>Value Type:</strong>{makeTitle(tableKey)}</span>,
            <span><strong>Month:</strong>{params.row.month}</span>,
            <span><strong>Year:</strong>{year}</span>,
        ]

        if (realizedValue !== undefined)
            detail.push(<span><strong>Realized Value:</strong>{realizedValue}</span>);
        else {
            detail.push(<span><strong>Realized Value:</strong>-</span>);
        }

        if (savedPrediction !== undefined) detail.push(<span><strong>Prediction:</strong>{(+savedPrediction).toFixed(4)}</span>);
        if (editedValue !== undefined) detail.push(<span><strong>Prediction:</strong>{editedValue}<strong>[UNSAVED]</strong></span>);

        this.setState({ cellDetail: { info: detail } })
    }

    getTables(sectionKey: string, sectionData?: SectionData, editedSectionData?: SectionData, savedPredictionsSectionData?: SectionData): React.ReactElement[] | null {
        if (!sectionData)
            return null;

        const locationTables: React.ReactElement[] = [];

        Object.keys(sectionData).forEach(location => {
            const locationDetails = sectionData[location];

            Object.keys(tableTypes).forEach((tableKey: string) => {
                const data = locationDetails[tableKey];
                const editedValues = editedSectionData?.[location]?.[tableKey];
                const savedPredictions = savedPredictionsSectionData?.[location]?.[tableTypes[tableKey]];

                locationTables.push(this.getCachedTable(sectionKey, location, tableKey, data, editedValues, savedPredictions))
            });
        });

        return locationTables;
    }

    getSummaryValue(data: SummaryData | undefined, params: MonthFilter) {
        return data?.[params.sectionKey]?.[params.location]?.[params.tableKey]?.[params.year]?.[params.month];
    }

    getEditedValue(params: MonthFilter) {
        return this.getSummaryValue(this.state.editedValues, params);
    }

    getRealizedValue(params: MonthFilter) {
        return this.getSummaryValue(this.state.data, params);
    }

    getSavedPredictionValue(params: MonthFilter) {
        params.tableKey = tableTypes[params.tableKey]
        return this.getSummaryValue(this.state.savedPredictions, params);
    }

    checkIfPredictionExists(sectionKey: string, location: string, tableKey: string, params: GridCellParams) {
        const year = +params.field;
        const month = monthNames[params.row["month"]] + 1;

        const editedValue = this.getEditedValue({ sectionKey, location, tableKey, year, month });

        if (editedValue || editedValue === 0)
            return true;

        const savedPrediction = this.getSavedPredictionValue({ sectionKey, location, tableKey, year, month });

        if (savedPrediction || savedPrediction === 0)
            return true;

        return false;
    }

    getCellClassName(sectionKey: string, location: string, tableKey: string, params: GridCellParams) {
        if (!this.isCellEditable(params) || this.state.hidePredictions)
            return "";

        const year = +params.field;
        const month = monthNames[params.row["month"]] + 1;

        const editedValue = this.getEditedValue({ sectionKey, location, tableKey, year, month });

        if (editedValue || editedValue === 0)
            return legendItems.editedCell.class;

        const savedPrediction = this.getSavedPredictionValue({ sectionKey, location, tableKey, year, month });

        if (savedPrediction || savedPrediction === 0)
            return legendItems.manuelforecastCell.class;

        return "";
    }

    deleteWithBranchCleaning(listToUpdate: SummaryData, params: MonthFilter) {
        const { sectionKey, location, tableKey, year, month } = params;

        delete listToUpdate[sectionKey][location][tableKey][year][month];

        if (isEmpty(listToUpdate[sectionKey][location][tableKey][year]))
            delete listToUpdate[sectionKey][location][tableKey][year];

        if (isEmpty(listToUpdate[sectionKey][location][tableKey]))
            delete listToUpdate[sectionKey][location][tableKey];

        if (isEmpty(listToUpdate[sectionKey][location]))
            delete listToUpdate[sectionKey][location];

        if (isEmpty(listToUpdate[sectionKey]))
            delete listToUpdate[sectionKey];
    }

    onEditCellChangeCommitted(sectionKey: string, location: string, tableKey: string, params: GridEditCellPropsParams) {
        const value = params.props.value;

        if ((!value && value !== 0) || value === '-')
            return;

        const valueType = tableKey;
        const year = +params.field;
        const month = +params.id;

        const tempPreviousValue = this.chooseValueToShow({
            editedValue: this.getEditedValue({ sectionKey, location, tableKey: valueType, year, month }),
            savedPrediction: this.getSavedPredictionValue({ sectionKey, location, tableKey: valueType, year, month }),
            realizedValue: this.getRealizedValue({ sectionKey, location, tableKey: valueType, year, month })
        });

        const previousValue = tempPreviousValue === undefined ? "-" : tempPreviousValue;

        if (!isNaN(+value) && +value === +previousValue) {
            this.forceRenderTable(sectionKey, location, tableKey);
            return;
        }

        const newEditedValues = { ...this.state.editedValues };
        const oldValue = this.getSummaryValue(newEditedValues, { sectionKey, location, tableKey, year, month });

        if (value || value === 0) {
            if (!newEditedValues[sectionKey])
                newEditedValues[sectionKey] = {};

            if (!newEditedValues[sectionKey][location])
                newEditedValues[sectionKey][location] = {}

            if (!newEditedValues[sectionKey][location][valueType])
                newEditedValues[sectionKey][location][valueType] = {}

            if (!newEditedValues[sectionKey][location][tableKey][year])
                newEditedValues[sectionKey][location][tableKey][year] = {}

            if (isNaN(+value)) {
                AlertManager.showError(`"${value}" is not a valid number.`);
                newEditedValues[sectionKey][location][tableKey][year][month] = 0;
                this.forceRenderTable(sectionKey, location, tableKey);
            }
            else {
                newEditedValues[sectionKey][location][tableKey][year][month] = +value;
            }
        } else if (oldValue || oldValue === 0) {
            this.deleteWithBranchCleaning(newEditedValues, { sectionKey, location, tableKey, year, month })
        }

        this.setState({ editedValues: newEditedValues });
    }

    preparePredictionsToSave() {
        const predictions: ISaveEUMonthlyPredictionsRequest["predictions"] = {};

        const editedValues = this.state.editedValues;
        const locationDetails = this.state.locationDetails;

        Object.keys(this.state.editedValues).forEach(dataType => {
            predictions[dataType] = { data: [] };

            Object.keys(editedValues[dataType]).forEach(location => {
                const tables = editedValues[dataType][location];
                const locationData: { location: string, location_type: string, tables: { [tableKey: string]: SummaryRow[] } } = {
                    location,
                    location_type: locationDetails[location].locationType,
                    tables: {},
                };

                Object.keys(tables).forEach(tableKey => {
                    const predictionValues: SummaryRow[] = [];

                    Object.keys(tables[tableKey]).forEach(year => {
                        Object.keys(tables[tableKey][+year]).forEach(month => {
                            predictionValues.push({
                                Year: +year,
                                Month: +month,
                                Value: tables[tableKey][+year][+month]
                            })
                        })
                    })
                    locationData.tables[tableTypes[tableKey]] = predictionValues;
                })

                predictions[dataType].data.push(locationData);
            });
        });

        return predictions;
    }

    validateEditedValues() {
        const editedValues = this.state.editedValues;
        const invalidLocations: string[] = [];

        Object.keys(editedValues).forEach(sectionKey => {
            Object.keys(editedValues[sectionKey]).forEach(location => {
                const locationData = editedValues[sectionKey][location];

                if (Object.keys(locationData).length <= 1)
                    return;

                const editedKeys: { [year: number]: { [month: number]: boolean } } = {};

                Object.keys(tableTypes).forEach(tableKey => {
                    Object.keys(locationData[tableKey]).forEach(y => {
                        const year = +y;

                        Object.keys(locationData[tableKey][+year]).forEach(m => {
                            const month = +m;

                            if (!editedKeys[year]) {
                                editedKeys[year] = { [month]: true }
                            }
                            else if (editedKeys[year][month]) {
                                invalidLocations.push(`${location}/${makeTitle(sectionKey)}`);
                            }
                            else {
                                editedKeys[year][month] = true
                            }

                        })
                    })
                })
            });
        });

        if (!isEmpty(invalidLocations)) {
            AlertManager.showError(`${Object.keys(tableTypes).map(t => makeTitle(t)).join(' and ')} depend on each other and should not be updated 
            at the same time for a single location: ${invalidLocations.join(', ')}`); //TODO: message
            return false;
        }

        return true;
    }

    handleEditError(error: ISaveEUMonthlyPredictionsResponse["error"]) {
        if (error?.default?.InvalidData)
            AlertManager.showError("Calculated locations can not be updated manually."); //TODO: message
        else
            AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED);
    }

    saveEditedValues() {
        this.setState({ showConfirmSave: false })

        if (!this.validateEditedValues())
            return;

        const predictions = this.preparePredictionsToSave();

        const spinner = createSpinner();

        saveEUMonthlyPredictions({ predictions }).then(response => {
            if (response.data.success) {
                const data = response.data.success.predictions;

                if (!isEmpty(response.data.success)) {
                    const { savedPredictions } = this.transformTablesToDicts(data);
                    this.setState({ savedPredictions, editedValues: {} });

                    AlertManager.showSuccess("Predictions saved successfully."); //TODO: message
                } else {
                    AlertManager.showError("Predictions could not be saved."); //TODO: message
                }
            }

            if (response.data.error) {
                this.handleEditError(response.data.error)
            }

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    discardEditedValues() {
        this.setState({ editedValues: {}, showConfirmDiscard: false })
    }

    getReviewDetails(title: string, reviedData: SummaryData) {
        return (
            <>
                <Typography variant="h6" style={{ margin: 5 }}>
                    {title}
                </Typography>
                {
                    isEmpty(reviedData) ?
                        <Typography>Nothing here</Typography>
                        :
                        <Paper variant="outlined" component="ul">
                            {
                                Object.keys(reviedData).map(sectionKey => {
                                    const sectionPredictions = reviedData[sectionKey];

                                    return (
                                        <li key={`section_li_${sectionKey}`}>
                                            <Typography>{makeTitle(sectionKey)}</Typography>
                                            <ul>
                                                {
                                                    (!sectionPredictions || isEmpty(sectionPredictions)) ?
                                                        <li key={`value_li_${sectionKey}`}>Nothing here</li>
                                                        :
                                                        Object.keys(sectionPredictions).filter(location => !isEmpty(sectionPredictions[location])).map(location => {
                                                            const locationPredictions = sectionPredictions[location];

                                                            return Object.keys(locationPredictions).flatMap(valueType => {
                                                                const valuePredictions = locationPredictions[valueType];

                                                                return (
                                                                    <li key={`value_li_${sectionKey}_${valueType}`}>
                                                                        <Typography>{makeTitle(location)} {makeTitle(valueType)}</Typography>
                                                                        {
                                                                            <table>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Year</th>
                                                                                        <th>Month</th>
                                                                                        <th>Prediction</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        Object.keys(valuePredictions).map(year => {
                                                                                            const yearPredictions = valuePredictions[+year];

                                                                                            return Object.keys(yearPredictions).map(month => {
                                                                                                return (
                                                                                                    <tr key={`month_li_${sectionKey}_${valueType}_${year}_${month}`}>
                                                                                                        <td>
                                                                                                            <Typography>{year}</Typography>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Typography>{months[+month - 1]}</Typography>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <Typography>{yearPredictions[+month]}</Typography>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        }
                                                                    </li>)
                                                            })
                                                        })
                                                }
                                            </ul>
                                        </li>
                                    )
                                })
                            }
                        </Paper>
                }
            </>
        )
    }

    getReviewModal() {
        return (
            <Dialog onClose={() => this.setState({ showReviewModal: false })} open={!!this.state.showReviewModal}>
                <DialogTitle>Review Predictions</DialogTitle>
                <DialogContent dividers>
                    {
                        this.getReviewDetails('New (Unsaved) Predictions', this.state.editedValues)
                    }
                    {
                        this.getReviewDetails('Saved Predictions', this.state.savedPredictions)
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => this.setState({ showReviewModal: false })}>
                        Close
                    </Button>
                    <Button disabled={isEmpty(this.state.editedValues)} variant="outlined"
                        onClick={() => this.setState({ showConfirmSave: true })}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog >
        )
    }

    render() {
        return (
            <ReportViewer hideTopButton onRefresh={() => this.getData()} {...MonthlyEuropeSummary.params}>
                <Grid alignItems="flex-start" container spacing={2}>
                    <Grid item xs={1} container>
                        <div style={{ position: "fixed", left: "5px", top: '80px', zIndex: 1000, opacity: 1, }}>
                            <Grid container>
                                <Grid item xs={12} >
                                    {this.getSectionList()}
                                </Grid>
                                <Grid item xs={12} >
                                    {this.state.cellDetail.info?.length &&
                                        <div style={{ ...styles.borderedContainer, fontSize: 14, width: 165 }}>
                                            <>
                                                <Typography style={{ margin: 2, fontWeight: 600, width: 'fit-content' }}>Details:</Typography>
                                                {this.state.cellDetail.info?.map((detailItem, idx) => (
                                                    <Typography key={`detail_item_${idx}`} style={{ margin: 2, width: 'fit-content' }}>
                                                        {detailItem}
                                                    </Typography>))}
                                            </>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {
                        this.state.pageReady &&
                        <Grid alignItems="flex-start" spacing={2} item xs={11} container>
                            <Grid item xs={12}
                                style={{ position: 'fixed', top: 77, padding: "8px 8px 0 8px", display: "flex", zIndex: 9999 }}>
                                <Button style={{ marginLeft: 10 }} disabled={isEmpty(this.state.editedValues)} variant="outlined"
                                    onClick={() => this.setState({ showConfirmSave: true })}>
                                    Save
                                </Button>
                                <Button style={{ marginLeft: 10 }} disabled={isEmpty(this.state.editedValues)} variant="outlined" color="secondary"
                                    onClick={() => this.setState({ showConfirmDiscard: true })}>
                                    Discard
                                </Button>
                                <Button style={{ marginLeft: 10 }} disabled={isEmpty(this.state.editedValues) && isEmpty(this.state.savedPredictions)}
                                    variant="outlined"
                                    onClick={() => this.setState({ showReviewModal: true })}>
                                    Review Predictions
                                </Button>
                                <div className="monthly-europe-summary-table"
                                    style={{
                                        width: "auto",
                                        height: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: 10,
                                    }}>
                                    {
                                        Object.values(legendItems).map(legendItem => {
                                            return (
                                                <div style={{ marginRight: 5, display: "flex" }}
                                                    key={`legend_${legendItem.class}`}>
                                                    <div className={legendItem.class}
                                                        style={{
                                                            width: 15,
                                                            height: 15,
                                                            marginRight: 2,
                                                            display: 'inline-block',
                                                            border: "1px solid rgba(224, 224, 224, 1)"
                                                        }}>
                                                    </div>
                                                    <Typography>{legendItem.title}</Typography>
                                                </div>
                                            )
                                        })
                                    }
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.hidePredictions}
                                                onChange={() => this.setState({ hidePredictions: !this.state.hidePredictions })}
                                                color="primary"
                                            />
                                        }
                                        label="Hide Predictions"
                                    />
                                </div>
                            </Grid>
                            {this.state.data && this.getSections(this.state.data, this.state.editedValues, this.state.savedPredictions)}
                        </Grid>
                    }
                    {
                        this.state.showConfirmSave &&
                        <Dialog onClose={() => this.setState({ showConfirmSave: false })} open={!!this.state.showConfirmSave}>
                            <DialogTitle>Confirm Save</DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    Are you sure you want to save all changes?
                                </Typography>
                                {
                                    this.getReviewDetails('New Predictions', this.state.editedValues)
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => this.setState({ showConfirmSave: false })}>
                                    Cancel
                                </Button>
                                <Button autoFocus onClick={() => this.saveEditedValues()}>
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                    {
                        this.state.showConfirmDiscard &&
                        <Dialog onClose={() => this.setState({ showConfirmDiscard: false })} open={!!this.state.showConfirmDiscard}>
                            <DialogTitle>Confirm Discard</DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    Are you sure you want to discard all changes?
                                </Typography>
                                {
                                    this.getReviewDetails('New Predictions', this.state.editedValues)
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => this.setState({ showConfirmDiscard: false })}>
                                    Cancel
                                </Button>
                                <Button autoFocus onClick={() => this.discardEditedValues()}>
                                    Discard
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                    {
                        (this.state.cellToDelete && !isEmpty(this.state.cellToDelete)) &&
                        <Dialog onClose={() => this.cancelDeleteCell()} open={!isEmpty(this.state.cellToDelete)}>
                            <DialogTitle>Confirm Delete</DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    Are you sure you want to delete cell:
                                </Typography>
                                <Typography>
                                    <strong>Data Type:</strong> {makeTitle(this.state.cellToDelete.data_type)}
                                </Typography>
                                <Typography>
                                    <strong>Value Type:</strong> {makeTitle(this.state.cellToDelete.value_type)}
                                </Typography>
                                <Typography>
                                    <strong>Location:</strong> {makeTitle(this.state.cellToDelete.location)}
                                </Typography>
                                <Typography>
                                    <strong>Year:</strong> {this.state.cellToDelete.year}
                                </Typography>
                                <Typography>
                                    <strong>Month:</strong> {months[this.state.cellToDelete.month - 1]}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => this.cancelDeleteCell()}>
                                    Cancel
                                </Button>
                                <Button autoFocus color='secondary' onClick={() => this.confirmDeleteCell()}>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }

                    {
                        this.state.showReviewModal &&
                        this.getReviewModal()
                    }
                </Grid>
            </ReportViewer >
        )
    }
}

export default MonthlyEuropeSummary;