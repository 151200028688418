import {
    CustomChartObject,
    CustomReportObject,
    DatasetMetaData,
    DataSetOptionUpdater,
    ReportDBObject,
    ReportGroupDBObject,
} from "../utils/types";
import { User } from "../utils/types";

export interface IBaseVitusResponse {
    success: {},
    error: {},
}

export interface ILoginRequest {
    username: string,
    password: string,
}

export interface ILoginResponse {
    data: string
}

export interface IGetUserResponse {
    data: {
        csrf: string,
        roles: string[],
        reports: string[],
        favourite_reports?: string[],
        custom_reports?: { Id: string, Name: string }[],
        owned_custom_reports?: { Id: string, Name: string }[],
        user_id: string,
        user_name: string,
    }
}

export interface IPowerSupplyDemandRequest {
    years: string[],
    countries?: string[],
    fuel_types?: string[],
    months?: string[],
    days?: string[],
}

export interface IPowerSupplyDemandResponse extends IBaseVitusResponse {
    success: {
        total_row_count: number,
        min_value: number,
        max_value: number,
        countries: string[],
        fuel_types: string[],
        group_keys: string[],
        original_data: { [key: string]: any }[],
    },
    error: {},
}

export interface ISetFavouriteRequest {
    report_key: string,
    is_favourite: boolean,
}

export interface ISetFavouriteResponse extends IBaseVitusResponse {
    success: {
        favourite_reports: string[],
    },
}

export interface IDailyWithForecastPSDRequest {
    period?: string,
    countries?: string[],
    fuel_type?: string,
    source?: string,
    data_type?: string,
    operation?: string,
}

export interface IDailyWithForecastPSDResponse extends IBaseVitusResponse {
    success: {
        source: string,
        country_values: { [key: string]: any }[],
        total_values: { [key: string]: any }[],
        latest_forecasts: { [key: string]: any }[],
    },
    error: {},
}

export interface IPSDSTaticDataResponse extends IBaseVitusResponse {
    success: {
        prod_countries: string[],
        cons_countries: string[],
        europe_countries: { [key: string]: string[] },
        prod_sources: string[],
        cons_sources: string[],
    },
    error: {},
}

export interface IDynamicDatasetsMetadataResponse extends IBaseVitusResponse {
    success: {
        datasets: DatasetMetaData[],
        aggregations: string[],
    },
    error: {},
}

export interface IDynamicDatasetsDataRequest {
    name: string,
    filter?: { [key: string]: any },
    index_column?: string,
    value_column?: string,
    group_by?: { groups: string[], aggregations: { [column_name: string]: string } },
    chart_groups?: string[],
}

export interface IDynamicDatasetsDataResponse extends IBaseVitusResponse {
    success: {
        data: {}[],
        index_column_values: string[],
    },
    error: {},
}

export interface ICustomChartListResponse extends IBaseVitusResponse {
    success: {
        custom_charts: CustomChartObject[],
    },
    error: {},
}

export interface ICustomChartResponse extends IBaseVitusResponse {
    success: {
        custom_chart: CustomChartObject,
    },
    error: {},
}

export interface ICustomChartRequest {
    chart_id: number,
}

export interface ISaveCustomChartRequest {
    chart: CustomChartObject,
}

export interface IGenericSaveObjectResponse extends IBaseVitusResponse {
    success: {
        result: boolean,
    },
    error: {},
}

export interface ISaveCustomReportRequest {
    report: CustomReportObject,
}

export interface ICustomReportRequest {
    report_id: number,
}

export interface ICustomReportResponse extends IBaseVitusResponse {
    success: {
        custom_report: CustomReportObject,
    },
    error: {},
}

export interface IChartDataResponse extends IBaseVitusResponse {
    success: {
        chart_data: {
            [key: string]: // dataset alias
            {
                data: {}[],
                index_column_values: string[]
            }
        },
    },
    error: {},
}

export interface IBaseDatasetOptionsResponse extends IBaseVitusResponse {
    success: {
        column_data_types: string[],
        data_source_types: string[],
    },
    error: {},
}

export interface ISaveDatasetRequest {
    dataset: DatasetMetaData,
    removedDatasourceIds: number[],
    option_updater: DataSetOptionUpdater,
}

export interface IDatasetRequest {
    dataset_id: number,
}

export interface IDatasetResponse extends IBaseVitusResponse {
    success: {
        dataset: DatasetMetaData,
        option_updater: DataSetOptionUpdater,
    },
    error: {},
}

export interface ISaveDatasetResponse extends IBaseVitusResponse {
    success: {
        result: boolean,
        dataset: DatasetMetaData,
        option_updater: DataSetOptionUpdater,
    },
    error: {},
}

export interface IMultiDatasetResponse extends IBaseVitusResponse {
    success: {
        datasets: DatasetMetaData[],
    },
    error: {},
}

export interface IReportsAndPermissionsResponse extends IBaseVitusResponse {
    success: {
        reports: ReportDBObject[],
        groups: ReportGroupDBObject[],
    },
    error: {},
}

export interface IReportPermissionsRequest {
    report: ReportDBObject,
}

export interface ICacheDashboardResponse extends IBaseVitusResponse {
    success: {
        cache_records: string[],
        warm_up_functions: string[],
    },
    error: {},
}

export interface ICacheRecordDetailResponse extends IBaseVitusResponse {
    success: {
        time_to_live: number,
        value: any,
    },
    error: {},
}

export interface ICacheRecordDetailsRequest {
    key: string,
}

export interface IDeleteCacheRecordRequest {
    keys: string[],
}

export interface IWarmUpSingleOperationRequest {
    function_name: string,
}

export interface IUsersAndRolesResponse extends IBaseVitusResponse {
    success: {
        roles: string[],
        report_groups: { Id: number, Name: string }[],
        users_and_roles: User[],
    },
    error: {},
}

export interface IEuropeGasDemandBalanceResponse extends IBaseVitusResponse {
    success: {
        range: {
            DateTitle: string,
            MinHistorical: number,
            MaxHistorical: number,
            RollingAll: number,
            LastYear: number,
            [year: string]: number | string,
        }[],
        heatmap: {
            Month: string,
            MonthTitle: string,
            Value: number,
            Year: number,
        }[],
        yearly_averages: number[],
        included_years: string[],
    },
    error: {},
}

export interface IUserDetailRequest {
    user_id: number,
}

export interface IUserDetailResponse extends IBaseVitusResponse {
    success: {
        user: User,
    },
    error: {},
}

export type CumulativeGasDemandSeason = {
    countries: {
        country_name: string,
        daily_data: CumulativeGasDemandDailyRow[],
        total_data: CumulativeGasDemandDailyTotalRow[],
        yearly_difference: number,
    }[]
};
export type CumulativeGasDemandDailyTotalRow = {
    SeasonName: string,
    Rolling: number
};
export type CumulativeGasDemandDailyRow = {
    DateTitle: string,
    Cumulative: number,
    SeasonName: string,
};
export interface ICumulativeGasDemandResponse extends IBaseVitusResponse {
    success: {
        seasons: {
            injection: CumulativeGasDemandSeason,
            withdraw: CumulativeGasDemandSeason,
        }
    },
    error: {},
}

export interface IForecastReportParamsResponse extends IBaseVitusResponse {
    success: {
        params: {
            CountryId: number,
            CountryName: string,
            ForecastType: string,
            FuelType: string,
            FuelTypeId: number,
            Source: string,
            TimeZone: string,
        }[],
    },
    error: {},
}

export interface IEuropeGasDemandInjectionSeasonResponse extends IBaseVitusResponse {
    success: {
        countries: {
            country_name: string,
            bar: {
                SeasonType: string,
                Value: number,
            }[],
            gauge: {
                CurrentYear: number,
                LastYear: number,
            },
        }[],
    },
    error: {},
}

export interface IGasFlowsInjectionResponse extends IBaseVitusResponse {
    success: {
        sankey: {
            SinkCountry: string,
            SourceCountry: string,
            Value: number,
        }[],
        bar: {
            SeasonType: string,
            SourceCountry: string,
            Value: number,
        }[],
        map: {
            SinkCountry: string,
            SourceCountry: string,
            Value: number,
        }[],
    },
    error: {},
}

export type YoyChangeRow = {
    Value: number,
    Season: string,
    ChangePercentage: number,
    Change: number,
}

export type YoyChangesByDataType = {
    unit: string,
    description: string,
    withdraw: YoyChangeRow[],
    injection: YoyChangeRow[],
}

export interface IYoyChangesResponse extends IBaseVitusResponse {
    success: {
        net_balance: {
            withdraw: { Season: string, Value: number }[],
            injection: { Season: string, Value: number }[],
        },
        production: YoyChangesByDataType,
        demand: YoyChangesByDataType,
        gas_flow: YoyChangesByDataType,
        lng: YoyChangesByDataType,
    },
    error: {},
}

export type NetFlowSeason = {
    cross_country_gas_flows: {
        SinkCountry: string,
        SourceCountry: string,
        Value: number,
    }[],
    seasonal_gas_flows_by_country: {
        Season: string,
        SourceCountry: string,
        Value: number,
    }[],
};

export interface IBaseBalanceRangeResponse extends IBaseVitusResponse {
    success: {
        countries: {
            country_name: string,
            data: {
                Month: number,
                Day: number,
                [key: string]: string | number,
            }[],
        }[],
    },
    error: {},
}

export interface IBalanceStorageResponse extends IBaseVitusResponse {
    success: {
        storage: IBaseBalanceRangeResponse["success"],
        storage_change: IBaseBalanceRangeResponse["success"],
    },
    error: {},
}


export interface IBalanceNetFlowsResponse extends IBaseVitusResponse {
    success: {
        net_flows: {
            countries: {
                country_code: string,
                country_name: string,
                description: string,
                data: IBaseBalanceRangeResponse["success"]["countries"][0]["data"],
            }[],
        }
        seasonal_gas_flows: {
            unit: string,
            injection: NetFlowSeason,
            withdraw: NetFlowSeason,
        }
    },
    error: {},
}

export type LngFlowSeason = {
    cross_country_lng: {
        SinkCountry: string,
        SourceCountry: string,
        Value: number,
    }[],
    seasonal_lng_by_country: {
        SourceCountry: string,
        Value: number,
        Season: string,
    }[],
}

export interface ILngFlowsResponse extends IBaseVitusResponse {
    success: {
        total_lng_flows: {
            SourceCountry: string,
            Value: number,
            Year: number,
        }[],
        seasonal_lng_flows: {
            unit: string,
            injection: LngFlowSeason,
            withdraw: LngFlowSeason,
        }
    },
    error: {},
}

export interface IForecastReportDataRequest {
    source: string,
    country: number,
    fuel_type: number,
    forecast_type: string,
    min_forecast_date?: string,
    max_forecast_date?: string,
    min_data_date?: string,
    max_data_date?: string,
}

export interface IForecastReportRow {
    DateTime: string,
    [forecastDate: string]: number | string,
}

export interface IForecastReportDataResponse extends IBaseVitusResponse {
    success: {
        forecasts: IForecastReportRow[],
        used_dates?: {
            ForecastedDateTime: string,
            WindForecastedDateTime?: string,
            SolarForecastedDateTime?: string,
            ForecastedDateTimeCount?: number,
            WindForecastedDateTimeCount?: number,
            SolarForecastedDateTimeCount?: number,
        }[],
    },
    error: {},
}

export const MonthlyEuropeSummaryTypes = ['demand', 'production', 'gas_flows', 'lng_entries', 'power_burn', 'baseload']
export type MonthlyEuropeSummaryType = typeof MonthlyEuropeSummaryTypes[number];

export interface IMonthlyEuropeSummaryResponse extends IBaseVitusResponse {
    success: {
        [key in MonthlyEuropeSummaryType]: {
            data: {
                location: string,
                location_type: string,
                description?: string,
                tables: {
                    [tableKey: string]: {
                        Month: number,
                        Value: number,
                        Year: number
                    }[]
                }
            }[]
        }
    },
    error: {},
}

export interface ISaveEUMonthlyPredictionsRequest {
    predictions: IMonthlyEuropeSummaryResponse["success"],
}

export interface IDeleteEUMonthlyPredictionRequest {
    data_type: string,
    value_type: string,
    location: string,
    location_type: string,
    year: number,
    month: number,
    value: number,
}

export interface ISaveEUMonthlyPredictionsResponse extends IBaseVitusResponse {
    success: {
        predictions: IMonthlyEuropeSummaryResponse["success"]
    },
    error: {
        [key: string]: any
    },
}

export interface LongTermEuropeGasBalanceData {
    description: string,
    title: string,
    data: {
        Year: number,
        Month: number,
        Day: number,
        Value: number,
    }[]
}

export interface ILongTermEuropeGasBalanceResponse extends IBaseVitusResponse {
    success: {
        exhibits: LongTermEuropeGasBalanceData[]
    },
    error: {},
}

export interface ISaveUserRequest {
    old_user: User,
    updated_user: User,
}

export interface ICreateUserRequest {
    new_user: User,
}

export interface ISaveUserResponse extends IBaseVitusResponse {
    success: {
        user: User
    },
    error: {},
}

export interface IDeleteUserRequest {
    user_id: number,
    user_name: string,
}

export interface IGasBalanceReportResponse extends IBaseVitusResponse {
    success: {
        [dataKey: string]: {},
        europe_gas_demand: IEuropeGasDemandBalanceResponse["success"],
        cumulative_gas_demand: ICumulativeGasDemandResponse["success"],
        gas_burn: IBaseBalanceRangeResponse["success"],
        eu_production: IBaseBalanceRangeResponse["success"],
        eu_gas_storage: IBalanceStorageResponse["success"],
        lng_flows: ILngFlowsResponse["success"],
        yoy_changes: IYoyChangesResponse["success"],
    },
    error: {

    },
}

export interface IWorkflowTasksResponse extends IBaseVitusResponse {
    success: {
        tasks: {
            taskName: string,
            status: string,
            lastSuccessDate: string,
            startDate: string,
            endDate: string,
            nextRunDate: string,
            preConditionTaskList: string,
            preConditionMinute: string,
            nextTaskList: string,
            cronSchedule: string,
            startScript: string,
            script_server: string,
            retryCount: string,
            isActive: string,
        }[],
    },
    error: {},
}

export interface IWorkflowTimeTableResponse extends IBaseVitusResponse {
    success: {
        logs: {
            id: number,
            taskName: string,
            status: string,
            startDate: string,
            endDate: string,
            duration: number,
            script_server: string,
            cDate: string,
            uDate: string,
            source: string,
        }[],
    },
    error: {
        [key: string]: any
    },
    warn: {
        message: {
            InvalidRequest: string
        }
    },
}

export interface IWorkflowTimeTableRequest {
    min_start_date?: Date,
    max_start_date?: Date,
    min_end_date?: Date,
    max_end_date?: Date,
    search_task_name?: string,
    status?: string[],
    servers?: string[],
    min_duration?: number,
    max_duration?: number,
    log_sources?: string[],
}

export interface IPriceSupplyDemandOptionsResponse extends IBaseVitusResponse {
    success: {
        price_options: {
            AreaType: string,
            AreaName: string,
            Currency: string
        }[],
        supply_demand_options: {
            AreaType: string,
            AreaName: string,
            PowerTypeName: string
        }[],
    },
    error: {},
}

export interface IEtlPriceSupplyDemandRequest {
    min_date?: string,
    max_date?: string,
    currency?: string,
    price_type?: string,
    frequency?: string,
    price_area_type?: string,
    price_area_name?: string,
    source_price_area_type?: string,
    source_price_area_name?: string,
    sink_price_area_type?: string,
    sink_price_area_name?: string,
    power_type?: string,
    power_area_type?: string,
    power_area_name?: string,
}

export type PriceSupplyDemandDataRow = {
    DateInfo: string,
    Value: number,
}

export interface IEtlPriceSupplyDemandResponse extends IBaseVitusResponse {
    success: {
        prices: PriceSupplyDemandDataRow[],
        supply_demands: PriceSupplyDemandDataRow[],
    },
    error: {

    },
}

export enum FtrDataTypes {
    Flow = 'Flow',
    Spread = 'Spread',
    // TotalPnl = 'TotalPnl',
}

export interface IFtrAnalysesPnLRequest {
    data_type: FtrDataTypes,
    min_date_time: Date,
    max_date_time: Date,
    direction_list?: {
        source_country_id: number,
        sink_country_id: number,
        source_area_id: number,
        sink_area_id: number,
        direction: string,
    }[],
}

export interface FtrAnalysesPnLRow {
    DateTimeCET: string,
    [direction: string]: null | number | string,
}

export interface IFtrAnalysesPnLResponse extends IBaseVitusResponse {
    success: {
        hourly_details: FtrAnalysesPnLRow[],
        hourly_summary: { DateCET: string, [key: string]: null | number | string }[],
        monthly_summary: { Year: string, [key: string]: null | number | string }[],
    },
    error: {

    },
}

export type FTRDirection = {
    SourceCountryId: number,
    SinkCountryId: number,
    SourceAreaId: number,
    SinkAreaId: number,
    Direction: string
}

export interface IFtrAnalysesPnlOptionsResponse extends IBaseVitusResponse {
    success: {
        options: FTRDirection[],
        hourly_details: IFtrAnalysesPnLResponse['success']['hourly_details'],
        hourly_summary: IFtrAnalysesPnLResponse['success']['hourly_summary'],
        saved_filter: IFtrAnalysesPnLRequest,
        round_values: { [key: string]: number },
        monthly_summary: IFtrAnalysesPnLResponse['success']['monthly_summary'],
    },
    error: {

    },
}

export type Contract = {
    ContractName: string,
    Source: string,
}

export interface IContractOptionsResponse extends IBaseVitusResponse {
    success: {
        options: Contract[],
        saved_filter: IContractPricesRequest,
        contract_prices: IContractPricesResponse['success']['contract_prices'],
        sources: string[],
    },
    error: {

    },
}

export interface IContractOptionsRequest {
    min_date_time?: Date,
    max_date_time?: Date,
}

export interface IContractPricesRequest {
    min_date_time: Date,
    max_date_time?: Date,
    min_contract_year: number,
    max_contract_year: number,
    min_contract_month: number,
    max_contract_month: number,
    sources_to_show?: string[],
    maturity_type: string,
    contract_quarter: number,
    contract_year: number,
    contracts: {
        contract_name: string,
        source: string,
    }[],
}

export interface IContractPricesResponse extends IBaseVitusResponse {
    success: {
        contract_prices: {
            Datetime: string,
            Average: number,
            [key: string]: null | number | string
        }[],
    },
    error: {

    },
}