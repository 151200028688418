export type User = {
    Id: number,
    UserName: string,
    Password: string,
    EmailAddress: string,
    Name: string,
    Title: string,
    PhoneNumber: string,
    CreatedBy: string,
    UpdatedBy: string,
    CDate: Date,
    UDate: Date,
    Roles: string[],
    ReportGroups: number[],
}

export enum DataSourceTypes {
    Etl = 'Etl',
    MySql = 'MySql',
}

export type DataSource = {
    Id?: number,
    SourceType: DataSourceTypes,
    SourceInfo: { path?: string, query?: string },
    IsActive?: boolean,
}

export type DatasetMetaData = {
    // mapped from server
    Id?: string,
    Name: string,
    Alias: string,
    Options?: { [key: string]: string }[],
    Columns: { [key: string]: { name: string, type: string } },
    DataSources?: DataSource[],
    UserChartCount?: number,
}

export type DataSetOptionUpdater = {
    Id?: number,
    DataSetId?: number,
    SourceInfo: { query: string }
}

export type DataSetColumn = {
    name: string,
    type: string,
}

export type DataSetFilter = {
    chart_type?: string,
    alias: string,
    options?: { [key: string]: string }[],
    x_axis_column?: DataSetColumn,
    y_axis_column?: DataSetColumn,
    filter?: {
        [columnName: string]: {
            name: string,
            type: string,
            min?: any,
            max?: any,
            value?: any
        },
    },
    group_by?: { groups: string[], aggregations: { [column_name: string]: string } },
    chart_groups?: string[],
    static_limits?: { [key: string]: { min: any, max: any } },
}

export type Dataset = {
    id: string,
    name: string,
    alias: string,
    chart_type?: string,
    x_axis?: string,
    y_axis?: string,
    chart_groups?: string[],
    filter?: {
        [key: string]: {
            name: string,
            type: string,
            min?: any,
            max?: any,
            value?: any,
        }
    },
    group_by?: {
        groups: string[],
        aggregations: { [key: string]: string }
    }
}

export class CustomChartObject {
    id?: number;
    name?: string;
    datasets: Dataset[];
    removedDatasetIds: number[];

    constructor(id?: number, name?: string, datasets?: Dataset[]) {
        this.id = id;
        this.name = name;
        this.datasets = datasets || [];
        this.removedDatasetIds = [];
    }
}

export class CustomReportChartObject {
    chart: CustomChartObject;
    id?: number;
    rowNumber: number;
    order?: number;
    cssProperities?: { [key: string]: any };

    constructor() {
        this.chart = new CustomChartObject();
        this.rowNumber = 0;
    }
}

export class ReportGroupDBObject {
    Id: number;
    Name: string;
    Users?: string[];

    constructor(Id: number, Name: string, Users?: string[]) {
        this.Id = Id;
        this.Name = Name;
        this.Users = Users;
    }
}

export class ReportDBObject {
    Id: number;
    Name: string;
    Groups?: number[];
    OwnerUserId?: number;

    constructor(Id: number, Name: string, OwnerUserId?: number, Groups?: number[]) {
        this.Id = Id;
        this.Name = Name;
        this.Groups = Groups;
        this.OwnerUserId = OwnerUserId;
    }
}

export class CustomReportObject {
    id?: number;
    name?: string;
    chartRows: CustomReportChartObject[][];
    removedChartIds: number[];

    constructor(id?: CustomReportObject["id"], name?: CustomReportObject["name"], chartRows?: CustomReportObject["chartRows"]) {
        this.id = id;
        this.name = name || "";
        this.chartRows = chartRows || [[]];
        this.removedChartIds = [];
    }
}
