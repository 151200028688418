import React from 'react';
import { IForecastReportDataResponse, IForecastReportRow } from '../../../apis/vitusApiTypes';
import { Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { stickyColumnStyle } from '../../../utils/styles';
import moment from 'moment';
import { max } from 'lodash';
import { createDateObject, round } from '../../../utils/common';

interface IRealTimeDay {
    Day: string,
    AnchorForecast?: string,
    Values: {
        [hour: number]: { anchor?: number, latest?: number }
    }
}

interface IState {
    realTimeTable: IRealTimeDay[],
    hideIfNoAnchor: boolean,
    showTomorrowEvenIfNoAnchor: boolean,
}

interface IProps {
    rawForecasts: IForecastReportDataResponse["success"]["forecasts"],

}

const anchorHour = 11;


class FRRealTimeForecastTable extends React.Component<IProps, IState> {
    state: IState = {
        realTimeTable: [],
        hideIfNoAnchor: true,
        showTomorrowEvenIfNoAnchor: true,
    }

    componentDidMount() {
        this.generateRealTimeTable();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.rawForecasts !== this.props.rawForecasts)
            this.generateRealTimeTable();
    }

    getForecastDates(forecastRow: IForecastReportRow) {
        return Object.keys(forecastRow).filter(f => f !== 'DateTime' && (forecastRow[f] || forecastRow[f] === 0))
            .map(forecastDate => moment(forecastDate, 'yyyy-MM-DD HH:mm:ss').toDate());
    }

    findLatestForecast(forecastRow: IForecastReportRow) {
        const forecastDates = this.getForecastDates(forecastRow);
        return max(forecastDates);
    }

    initializeDay(forecastRow: IForecastReportRow): IRealTimeDay {
        const dateTime = moment(forecastRow.DateTime, 'yyyy-MM-DD HH:mm').toDate();
        const anchorBoundary = new Date(dateTime);
        anchorBoundary.setDate(anchorBoundary.getDate() - 1);
        anchorBoundary.setHours(anchorHour);

        const forecastDates = this.getForecastDates(forecastRow);
        const maxForecastDate = max(forecastDates);

        const day = moment(dateTime).format("yyyy-MM-DD");

        let anchorForecast = undefined;
        let anchorValue = undefined;

        if (maxForecastDate && maxForecastDate >= anchorBoundary) {
            // anchor is ready

            const anchorCandidates = forecastDates.filter(forecast => forecast < anchorBoundary);
            const anchorDate = anchorCandidates[anchorCandidates.length - 1];

            anchorForecast = moment(anchorDate).format('yyyy-MM-DD HH:mm:ss');
            anchorValue = +forecastRow[anchorForecast];
        }

        return {
            Day: day,
            AnchorForecast: anchorForecast,
            Values: {
                [dateTime.getHours()]: {
                    anchor: anchorValue,
                    latest: +forecastRow[moment(maxForecastDate).format('yyyy-MM-DD HH:mm:ss')]
                }
            }
        }
    }

    generateRealTimeTable() {
        const rawForecasts = this.props.rawForecasts;

        const realTimeTable: { [day: string]: IRealTimeDay } = {};

        rawForecasts.forEach(rawForecast => {
            const day = moment(rawForecast.DateTime).format("yyyy-MM-DD");

            if (!realTimeTable[day])
                realTimeTable[day] = this.initializeDay(rawForecast);

            const dateTime = moment(rawForecast.DateTime, 'yyyy-MM-DD HH:mm').toDate();

            const maxForecastDate = this.findLatestForecast(rawForecast);

            let anchorValue: number | undefined = +rawForecast[realTimeTable[day].AnchorForecast || "n/a"];
            anchorValue = isNaN(anchorValue) ? undefined : anchorValue;

            realTimeTable[day].Values[dateTime.getHours()] = {
                anchor: anchorValue,
                latest: +rawForecast[moment(maxForecastDate).format('yyyy-MM-DD HH:mm:ss')],
            }
        });

        this.setState({ realTimeTable: Object.values(realTimeTable) })
    }

    getTable(): React.ReactElement | null {
        if (!this.state.realTimeTable?.length)
            return <Typography style={{ padding: 20, fontWeight: 900 }}>Not available</Typography>;

        const columnsStyle = { ...stickyColumnStyle, width: 50, minWidth: 80, padding: 3, zIndex: 1001 };

        let rowsToShow = this.state.realTimeTable;

        if (this.state.hideIfNoAnchor) {
            if (this.state.showTomorrowEvenIfNoAnchor) {
                const tomorrow = moment(createDateObject({ dayDiffFromNow: 1, clearTime: false })).format("yyyy-MM-DD");
                rowsToShow = rowsToShow.filter(row => row.AnchorForecast || row.Day === tomorrow)
            }
            else {
                rowsToShow = rowsToShow.filter(row => row.AnchorForecast)
            }
        }

        if (!rowsToShow?.length)
            return <Typography style={{ padding: 20, fontWeight: 900 }}>Nothing to show</Typography>;

        let table = (
            <TableContainer style={{ width: '100%', overflow: 'unset' }}>
                <Table stickyHeader size="small" style={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ ...columnsStyle, textAlign: 'right', borderRightStyle: "solid", borderWidth: "thin" }} >
                                Date
                            </TableCell>
                            {
                                rowsToShow.map(dayInfo => {

                                    return (
                                        <TableCell colSpan={3}
                                            style={{ borderLeftStyle: "solid", borderRightStyle: "solid", borderWidth: "thin" }}
                                            key={`th_day_main_head_${dayInfo.Day}`} >
                                            {dayInfo.Day}
                                        </TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ ...columnsStyle, textAlign: 'right', borderRightStyle: "solid", borderWidth: "thin" }} key="th_Hour">
                                Hour
                            </TableCell>
                            {
                                rowsToShow.map(dayInfo => {

                                    return (
                                        <React.Fragment key={`th_day_sub_head_${dayInfo.Day}`}>
                                            <TableCell style={{ borderLeftStyle: "solid", borderWidth: "thin" }}>
                                                <span>Anchor:</span> <span style={{ whiteSpace: 'nowrap' }}>{dayInfo.AnchorForecast}</span>
                                            </TableCell>
                                            <TableCell >
                                                Latest
                                            </TableCell>
                                            <TableCell style={{ borderRightStyle: "solid", borderWidth: "thin" }}>
                                                Difference
                                            </TableCell>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Array.from(Array(24).keys()).map(hour => {
                                return (
                                    <TableRow key={`tr_hour_${hour}`}>
                                        <TableCell
                                            key={`tc_hour_${hour}`}
                                            style={{ ...columnsStyle, textAlign: 'right', borderRightStyle: "solid", borderWidth: "thin" }}>
                                            {hour.toString().padStart(2, '0')}:00
                                        </TableCell>

                                        {
                                            rowsToShow.map(dayInfo => {
                                                const anchorExists = dayInfo.Values[hour]?.anchor && dayInfo.Values[hour].anchor !== 0;
                                                const latestExists = dayInfo.Values[hour]?.latest && dayInfo.Values[hour].latest !== 0;

                                                const anchorValue = anchorExists ? round(dayInfo.Values[hour].anchor || 0, 3) : "-";
                                                const latestValue = latestExists ? round(dayInfo.Values[hour]?.latest || 0, 3) : "-";

                                                const differenceExist = anchorExists && latestExists;
                                                const difference = differenceExist ?
                                                    round((dayInfo.Values[hour].latest ?? 0) - (dayInfo.Values[hour].anchor ?? 0), 3)
                                                    : "-";

                                                const differenceStyle = differenceExist ? { backgroundColor: (+difference > 0 ? "#D9F5C5" : "#F5C5C5") } : {};

                                                return (
                                                    <React.Fragment key={`th_day_sub_value_${dayInfo.Day}`}>
                                                        <TableCell style={{ borderLeftStyle: "solid", borderWidth: "thin" }}>
                                                            {anchorValue}
                                                        </TableCell>
                                                        <TableCell >
                                                            {latestValue}
                                                        </TableCell>
                                                        <TableCell style={{ borderRightStyle: "solid", borderWidth: "thin", ...differenceStyle }}>
                                                            {difference}
                                                        </TableCell>
                                                    </React.Fragment>
                                                );
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )

        return table;
    }

    getChecks() {
        const hideIfNoAnchor = this.state.hideIfNoAnchor;
        const showTomorrowEvenIfNoAnchor = this.state.showTomorrowEvenIfNoAnchor;

        return (
            <React.Fragment>
                <FormControlLabel
                    style={{ paddingLeft: 20 }}
                    control={
                        <Checkbox
                            checked={hideIfNoAnchor}
                            onChange={() => {
                                this.setState({ hideIfNoAnchor: !hideIfNoAnchor })
                            }}
                            color="primary"
                        />
                    }
                    label="Hide if no anchor"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showTomorrowEvenIfNoAnchor}
                            onChange={() => {
                                this.setState({ showTomorrowEvenIfNoAnchor: !showTomorrowEvenIfNoAnchor })
                            }}
                            color="primary"
                        />
                    }
                    label="Show tomorrow even if no anchor"
                />
            </React.Fragment>
        );
    }

    render(): React.ReactNode {
        return (
            <Paper
                style={{
                    maxWidth: 'calc(100vw - 250px)',
                    height: 'calc(100vh - 150px)',
                    overflow: 'auto',
                }}>
                {
                    this.getChecks()
                }
                {
                    this.getTable()
                }
            </Paper>
        )
    }
}

export default FRRealTimeForecastTable;
