import React, { CSSProperties } from 'react';
import { FormControl, Grid, Input, InputLabel, Typography } from '@material-ui/core';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { isEmpty, sortBy } from 'lodash';
import VSelect from '../../../components/VSelect/VSelect';
import { round } from '../../../utils/common';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

echarts.use(
    [LineChart, GridComponent]
);

export interface IContractPriceChartProps {
    dateColumn: string,
    valueColumns?: string[],
    rows?: { [key: string]: any }[],
    columnLabels: { [key: string]: string; },
    chartConfig: ChartConfig,
    updateChartConfig: (newConfig: ChartConfig) => void,
}

export enum ValueType {
    Simple = 'Simple',
    Difference = 'Difference',
    Ratio = 'Ratio',
}

const ValueInfo: { [key in ValueType]: { label: string, calculator: (x: number, y: number) => (number | null) } } = {
    [ValueType.Simple]: { label: 'NoOp', calculator: (x, y) => 0 },
    [ValueType.Difference]: { label: '-', calculator: (x, y) => x - y },
    [ValueType.Ratio]: { label: '/', calculator: (x, y) => y === 0 ? null : x / y },
}

export const valueTypes = Object.keys(ValueType).filter((item) => {
    return isNaN(Number(item));
})

export type ChartConfig = {
    valueType: ValueType,
    factor1: number,
    contract1?: string,
    factor2: number,
    contract2?: string,
}

interface IState {
    rowGroups: { [contractName: string]: { [dateValue: string]: number } },
}

class ContractPriceChart extends React.Component<IContractPriceChartProps, IState> {
    lineChartRef: ReactECharts | null = null;
    defaultChartOptions: ECOption = {
        animation: false,
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
            scale: true,
        },
        series: [{
            data: [],
            type: 'line'
        }],
    };

    state: IState = {
        rowGroups: {},
    }

    componentDidMount() {
        this.prepareData();
    }

    componentDidUpdate(prevProps: IContractPriceChartProps) {
        if (prevProps.rows !== this.props.rows || prevProps.valueColumns !== this.props.valueColumns) {
            this.prepareData();
        }
    }

    prepareData() {
        const rows = this.props.rows;
        const columns = this.props.valueColumns;

        if (!rows || !columns)
            return;

        const rowGroups: IState["rowGroups"] = {};
        columns.forEach(column => rowGroups[column] = {})

        rows.forEach(row => {
            columns.forEach(column => {
                const dateValue = row[this.props.dateColumn];
                rowGroups[column][dateValue] = row[column]
            })
        })

        this.setState({ rowGroups }, () => this.updateChart());
    }

    generateSeries(rowGroups: IState["rowGroups"], chartConfig: ChartConfig) {
        if (chartConfig.valueType === ValueType.Simple)
            return this.generateSimpleSeries(rowGroups)

        return this.generateAggregatedSeries(rowGroups);
    }

    getContractLabel(contractName: string) {
        return this.props.columnLabels[contractName] ?? contractName;
    }

    generateSimpleSeries(rowGroups: IState["rowGroups"]) {
        return {
            series: Object.keys(rowGroups).map(rowGroup => ({
                type: 'line',
                name: this.getContractLabel(rowGroup),
                symbol: 'none',
                data: sortBy(Object.keys(rowGroups[rowGroup]).map(dateValue => [dateValue, rowGroups[rowGroup][dateValue]]
                ), row => row[0]),
            }))
        };
    }

    padZero(value: string) {
        return ('0' + value).slice(-2);
    }

    aggregator(factor1: number, contractValue1: number, factor2: number, contractValue2: number) {
        if (contractValue1 === null || contractValue2 === null)
            return null;

        const calculator = ValueInfo[this.props.chartConfig.valueType].calculator;
        const result = calculator(factor1 * contractValue1, factor2 * contractValue2);

        if (!result)
            return result;

        return round(result, 2)
    }

    generateAggregatedSeries(rowGroups: IState["rowGroups"]) {
        const { factor1, contract1, factor2, contract2 } = this.props.chartConfig;

        if (!contract1 || !contract2)
            return [];

        const contractsWithNoData: string[] = [];

        if (isEmpty(rowGroups[contract1]))
            contractsWithNoData.push(contract1)
        if (isEmpty(rowGroups[contract2]))
            contractsWithNoData.push(contract2)


        if (!isEmpty(contractsWithNoData))
            return {
                graphic: [
                    {
                        type: 'text',
                        z: 100,
                        left: 'center',
                        top: 'middle',
                        style: {
                            fill: '#333',
                            width: 220,
                            overflow: 'break',
                            text: `${contractsWithNoData.join(', ')} data is not listed.`, //TODO: message
                            font: '14px Microsoft YaHei'
                        }
                    }
                ],
            };

        const contractValues1 = rowGroups[contract1];
        const contractValues2 = rowGroups[contract2];

        const aggregatedValues = sortBy(Object.keys(contractValues1).map(dateValue => {
            return [dateValue, this.aggregator(factor1, contractValues1[dateValue], factor2, contractValues2[dateValue])]
        }), row => row[0]);

        return {
            series: {
                type: 'line',
                name: `(${factor1} x ${this.getContractLabel(contract1)}) - (${factor2} x ${this.getContractLabel(contract2)})`,
                symbol: 'none',
                data: aggregatedValues
            }
        };
    }

    updateChart() {
        const rowGroups = this.state.rowGroups;
        const { valueType, contract1, contract2 } = this.props.chartConfig

        if (!rowGroups // no rows
            || !valueType // no value type
            || (valueType !== ValueType.Simple && (!contract1 || !contract2))) {
            return;
        }

        const chartInstance = this.lineChartRef?.getEchartsInstance();
        chartInstance.showLoading();
        chartInstance.clear();

        const chartInfo = this.generateSeries(rowGroups, this.props.chartConfig);

        chartInstance.setOption({
            ...this.defaultChartOptions,
            ...chartInfo,
            tooltip: { trigger: 'axis', },
            legend: {}
        });

        chartInstance.hideLoading();
    }

    updateChartConfig(newValues: Partial<ChartConfig>) {
        this.props.updateChartConfig({ ...this.props.chartConfig, ...newValues });
    }

    render(): React.ReactNode {
        const operatorStyle: CSSProperties = { whiteSpace: 'nowrap', padding: '6px 5px 10px 5px' };
        const operatorVariant = 'h5';
        const factorStyle: CSSProperties = { width: 45 };
        const fontSize = '0.9rem';

        return (
            <Grid container spacing={1} style={{ padding: '10px 0', }}>
                <Grid item container spacing={0} justify="space-between" alignItems='flex-end' style={{ padding: 10, marginLeft: 70 }}>
                    <Grid item xs={2}>
                        <VSelect
                            style={{ maxWidth: '110px' }}
                            disableClearable
                            title="Value Type"
                            value={this.props.chartConfig.valueType}
                            getOptionLabel={(option) => `${option}`}
                            options={valueTypes}
                            onChange={(newValue) => {
                                if (newValue)
                                    this.updateChartConfig({ valueType: newValue as ValueType })
                            }}
                        />
                    </Grid>
                    {
                        this.props.chartConfig.valueType !== ValueType.Simple &&
                        <>
                            <Grid item xs={10}
                                style={{
                                    display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end',
                                    justifyContent: 'space-evenly', flexBasis: '60%'
                                }}>
                                <Typography variant={operatorVariant} style={operatorStyle}>
                                    (
                                </Typography>
                                <FormControl style={factorStyle}>
                                    <InputLabel htmlFor="factor1_input" shrink>Factor 1</InputLabel>
                                    <Input
                                        style={{ fontSize }}
                                        id="factor1_input"
                                        value={this.props.chartConfig.factor1}
                                        type="number"
                                        onWheel={event => { event.preventDefault(); }}
                                        onChange={(newValue) => {
                                            if (newValue)
                                                this.updateChartConfig({ factor1: +newValue.target.value })
                                        }}
                                    />
                                </FormControl>
                                <Typography variant={operatorVariant} style={operatorStyle}>
                                    x
                                </Typography>
                                <VSelect
                                    style={{ maxWidth: '130px' }}
                                    disableClearable
                                    title="Contract 1"
                                    value={this.props.chartConfig.contract1}
                                    getOptionLabel={(option) => this.getContractLabel(option.toString())}
                                    options={Object.keys(this.state.rowGroups)}
                                    onChange={(newValue) => {
                                        if (newValue)
                                            this.updateChartConfig({ contract1: newValue.toString() })
                                    }}
                                />
                                <Typography variant={operatorVariant} style={operatorStyle}>
                                    )
                                </Typography>
                                <Typography variant={operatorVariant} style={operatorStyle}>
                                    {ValueInfo[this.props.chartConfig.valueType].label}
                                </Typography>
                                <Typography variant={operatorVariant} style={operatorStyle}>
                                    (
                                </Typography>
                                <FormControl style={factorStyle}>
                                    <InputLabel htmlFor="factor2_input" shrink>Factor 2</InputLabel>
                                    <Input
                                        style={{ fontSize }}
                                        id="factor2_input"
                                        value={this.props.chartConfig.factor2}
                                        type="number"
                                        onWheel={event => { event.preventDefault(); }}
                                        onChange={(newValue) => {
                                            if (newValue)
                                                this.updateChartConfig({ factor2: +newValue.target.value })
                                        }}
                                    />
                                </FormControl>
                                <Typography variant={operatorVariant} style={operatorStyle}>
                                    x
                                </Typography>
                                <VSelect
                                    style={{ maxWidth: '130px' }}
                                    disableClearable
                                    title="Contract 2"
                                    value={this.props.chartConfig.contract2}
                                    getOptionLabel={(option) => this.getContractLabel(option.toString())}
                                    options={Object.keys(this.state.rowGroups)}
                                    onChange={(newValue) => {
                                        if (newValue)
                                            this.updateChartConfig({ contract2: newValue.toString() })
                                    }}
                                />
                                <Typography variant={operatorVariant} style={operatorStyle}>
                                    )
                                </Typography>
                            </Grid>
                        </>
                    }
                </Grid>
                <Grid item xs={12} style={{ padding: '10px' }}>
                    <ReactECharts
                        ref={(e) => { this.lineChartRef = e; }}
                        echarts={echarts}
                        option={this.defaultChartOptions}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{ width: '99%' }}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default ContractPriceChart;