import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { createExcel, createExcelWithMultipleSheets } from "../../utils/common";

function ExcelDownloadButton(props: {
    title: string,
    sheets: {
        name?: string,
        columns: string[],
        rows: any[][],
    }[],
}) {

    return (
        <div style={{ display: "block", height: '100%', position: 'relative' }}>
            <div style={{
                position: "absolute",
                display: "inline-block",
                right: 0,
                top: 0,
            }}>
                <Tooltip title="Download Excel">
                    <IconButton onClick={() => {
                        props.sheets.length === 1 ?
                            createExcel(
                                props.title,
                                props.sheets[0].columns,
                                props.sheets[0].rows
                            )
                            :
                            createExcelWithMultipleSheets(
                                props.title,
                                props.sheets.map(table => ({
                                    sheetName: table.name,
                                    headers: table.columns,
                                    values: table.rows,
                                }))
                            )
                    }}>
                        <Icon className="far fa-file-excel" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}

export default ExcelDownloadButton;