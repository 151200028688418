import { ReportParams, ReportView } from "../../../system/ReportBase";
import ReportViewer from "../../ReportViewer/ReportViewer";
import VPageFilter from "../../../components/VPageFilter/VPageFilter";
import { Button, FormControl, Grid, Input, InputLabel, Tab, Tabs, Typography } from "@material-ui/core";
import VDatePicker from "../../../components/VDatePicker/VDatePicker";
import VSelect from "../../../components/VSelect/VSelect";
import { Contract, IContractPricesResponse } from "../../../apis/vitusApiTypes";
import { createSpinner } from "../../../utils/spinnerManager";
import { createDateObject, handleApiError, makeTitle, shortMonthNames, toDateString, toShortDateString } from "../../../utils/common";
import { dateFormat, getContractOptions, getContractPricesData } from "../../../apis/vitusApi";
import AlertManager from "../../../utils/alertManager";
import messages from "../../../utils/messages";
import VChipArray from "../../../components/VChipArray/VChipArray";
import { isEmpty, sortBy } from "lodash";
import { TabPanel } from '../../../components/VTabs/VTabs';
import { getMemoizedElement, MemoizeParams } from "../../../utils/memoizer";
import { styles } from "../../../utils/styles";
import VHeatMap, { getColumnKey, getColumnLabel, IHeatMapProps } from "../../../charts/VHeatMap/VHeatMap";
import ExcelDownloadButton from "../../../components/ExcelDownloadButton/ExcelDownloadButton";
import ContractPriceChart, { ChartConfig, ValueType } from "./ContractPriceChart";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStorageManager";


const maturityTypes: { [key: string]: { key: string, label: string } } = {
    month: { key: 'M', label: "Month" },
    quarter: { key: 'Q', label: "Quarter" },
    year: { key: 'Y', label: "Year" },
}

interface IFilter {
    min_date_time?: Date,
    max_date_time?: Date,
    min_contract_year: number,
    max_contract_year: number,
    min_contract_month: number,
    max_contract_month: number,
    maturity_type: { key: string, label: string },
    contract_quarter: number,
    contract_year: number,
    contracts: Contract[],
}

const defaultChartValues = {
    valueType: ValueType.Simple,
    factor1: 1,
    factor2: 1,
};

interface IState {
    filterIsHidden: boolean,
    selectedFilter: IFilter,
    activeFilter: IFilter,
    contractOptions: { [key: string]: Contract },
    selectedTabIndex: number,
    monthlyDetails?: {
        columns: string[],
        rows: IContractPricesResponse['success']['contract_prices']
    },
    chartConfig1: ChartConfig,
    chartConfig2: ChartConfig,
    sourcesToShow: string[],
    sources: string[],
}

type ColumnConfig = { visible: boolean, label: string, order: string, isValue: boolean };

const monthlyDetailsCustomColumns: { [key: string]: ColumnConfig } = {
    'DateTime': { visible: true, label: 'DateTime', order: '1', isValue: false },
    'Average': { visible: true, label: 'Average', order: 'zzz', isValue: true },
};

const defaultFilter: IFilter = {
    contracts: [],
    maturity_type: maturityTypes.month,
    min_contract_month: 1,
    min_contract_year: (new Date()).getFullYear(),
    max_contract_month: 1,
    max_contract_year: (new Date()).getFullYear(),
    contract_quarter: 1,
    contract_year: (new Date()).getFullYear(),
}

class ContractPrices extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "CONTRACT_PRICE_REPORT",
            name: "Contract Prices",
            path: "/contractPrices",
            thumbnail: "",
        }
    );

    state: IState = {
        filterIsHidden: false,
        selectedFilter: { ...defaultFilter },
        activeFilter: { ...defaultFilter },
        contractOptions: {},
        selectedTabIndex: 0,
        chartConfig1: { ...defaultChartValues },
        chartConfig2: { ...defaultChartValues },
        sourcesToShow: [],
        sources: [],
    };

    getLocalStorageDateTag() {
        return toShortDateString(new Date());
    }

    getDateTimeFilter() {
        const storedValues = getLocalStorage(ContractPrices.params.reportKey, "filter", "mainFilter");
        const storedDates = storedValues?.[this.getLocalStorageDateTag()];

        if (storedDates && !isEmpty(storedDates))
            return storedDates;

        return {
            min_date_time: createDateObject({ dayDiffFromNow: -90, clearTime: true }),
            max_date_time: createDateObject({ dayDiffFromNow: 1, clearTime: true }),
        }
    }

    componentDidMount() {
        const optionsSpinner = createSpinner();

        const { min_date_time, max_date_time } = this.getDateTimeFilter();

        getContractOptions({ min_date_time, max_date_time, }).then(response => {
            if (response.data.success) {
                const savedFilter = response.data.success.saved_filter;

                const selectedFilter = {
                    min_date_time,
                    max_date_time,
                    min_contract_year: savedFilter?.min_contract_year,
                    max_contract_year: savedFilter?.max_contract_year,
                    min_contract_month: savedFilter?.min_contract_month,
                    max_contract_month: savedFilter?.max_contract_month,
                    contract_quarter: savedFilter?.contract_quarter,
                    contract_year: savedFilter?.contract_year,
                    maturity_type: Object.values(maturityTypes).find(m => m.key === savedFilter?.maturity_type) || maturityTypes.month,
                    contracts: savedFilter?.contracts?.map(contract => ({
                        ContractName: contract.contract_name,
                        Source: contract.source,
                    })) || [],
                }

                this.setState({
                    selectedFilter,
                    activeFilter: selectedFilter,
                    sources: response.data.success.sources,
                    sourcesToShow: savedFilter?.sources_to_show || [],
                    contractOptions: response.data.success.options
                        .reduce((map: { [key: string]: Contract }, contractDetail) => {
                            map[contractDetail.ContractName] = contractDetail;
                            return map;
                        }, {})
                })

                this.storeData(response.data.success, selectedFilter)
            }


            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            optionsSpinner.hide()
        });
    }

    validateFilter(filter: IState["activeFilter"]): boolean {
        if (!filter.maturity_type) {
            AlertManager.showWarning("Maturity Type is required."); //TODO: message
            return false;
        }

        let requiredColumns: string[] = [];

        switch (filter.maturity_type.key) {
            case maturityTypes.month.key:
                requiredColumns = ["min_date_time", "min_contract_year", "max_contract_year", "min_contract_month", "max_contract_month"]
                break;
            case maturityTypes.quarter.key:
                requiredColumns = ["contract_quarter", "contract_year"]
                break;
            case maturityTypes.year.key:
                requiredColumns = ["contract_year"]
                break;
        }


        const missingColumns = requiredColumns.filter(colKey => {
            const currentValue = filter[colKey as keyof IState["activeFilter"]];

            if (!currentValue && currentValue !== 0)
                return true; //required but not provided

            return false;
        })

        if (isEmpty(filter.contracts))
            missingColumns.push('contracts')

        if (!isEmpty(missingColumns)) {
            AlertManager.showWarning(`${missingColumns.map(c => makeTitle(c))
                .join(', ')} ${missingColumns.length === 1 ? "is" : "are"} required.`); //TODO: message
            return false;
        }

        if (filter.min_contract_year > filter.max_contract_year
            || (filter.min_contract_year === filter.max_contract_year && filter.min_contract_month > filter.max_contract_month)) {
            AlertManager.showWarning("Min Contract Date can not be greater than Max Contract Date"); //TODO: message
            return false;
        }

        return true;
    }

    getData(filter: IState["activeFilter"]) {
        const isFilterValid = this.validateFilter(filter);
        if (!isFilterValid)
            return;

        const spinner = createSpinner();

        getContractPricesData({
            min_date_time: filter.min_date_time as Date,
            max_date_time: filter.max_date_time,
            min_contract_year: filter.min_contract_year as number,
            max_contract_year: filter.max_contract_year as number,
            min_contract_month: filter.min_contract_month as number,
            max_contract_month: filter.max_contract_month as number,
            maturity_type: filter.maturity_type.key,
            contract_quarter: filter.contract_quarter,
            contract_year: filter.contract_year,
            sources_to_show: this.state.sourcesToShow,
            contracts: filter.contracts.map(contract => ({
                contract_name: contract.ContractName,
                source: contract.Source,
            }))
        }).then(response => {
            if (response.data.success)
                this.storeData(response.data.success, filter)

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    getColumns(data_rows: {}[], fixedColumns: { [key: string]: ColumnConfig }) {

        const columns = [
            ...Object.keys(fixedColumns),
            ...Object.keys(data_rows[0]).filter(col => !fixedColumns[col]),
        ]

        return columns
    }

    getUncoloredColumns(columns: { [key: string]: ColumnConfig }) {
        return Object.keys(columns).filter(col => !columns[col].isValue)
    }

    getValueColumns(data_rows: {}[] | undefined, fixedColumns: { [key: string]: ColumnConfig }) {

        const columns = [
            ...Object.keys(fixedColumns).filter(col => fixedColumns[col].isValue),
            ...Object.keys(data_rows?.[0] ?? {}).filter(col => !fixedColumns[col]),
        ]

        return columns
    }

    getVisibleColumns(data_rows: {}[] | undefined, fixedColumns: { [key: string]: ColumnConfig }) {

        const columns = [
            ...Object.keys(fixedColumns).filter(col => fixedColumns[col].visible),
            ...Object.keys(data_rows?.[0] ?? {}).filter(col => !fixedColumns[col]),
        ]

        return columns
    }

    getColumnLabels(data_rows: {}[] | undefined, fixedColumns: { [key: string]: ColumnConfig }) {
        const columns = Object.keys(data_rows?.[0] ?? {});
        return columns.reduce((map: { [key: string]: string }, col) => {
            map[col] = fixedColumns[col]?.label ?? col;
            return map;
        }, {})
    }

    storeData(originalData: IContractPricesResponse['success'], filter: IState['activeFilter']) {
        if (!originalData || !originalData.contract_prices)
            return;

        this.setState({
            monthlyDetails: {
                columns: this.getVisibleColumns(originalData.contract_prices, monthlyDetailsCustomColumns),
                rows: originalData.contract_prices,
            },
            activeFilter: filter
        }, () => {
            setLocalStorage(ContractPrices.params.reportKey, "filter", "mainFilter", {
                [this.getLocalStorageDateTag()]: {
                    min_date_time: toDateString(dateFormat, filter.min_date_time),
                    max_date_time: toDateString(dateFormat, filter.max_date_time)
                },
            });
        })
    }

    makeInputFilter(filterKey: keyof IFilter) {
        return (
            <FormControl style={{ width: 100 }}>
                <InputLabel htmlFor={`${filterKey}_input`} shrink>{makeTitle(filterKey)}</InputLabel>
                <Input
                    id={`${filterKey}_input`}
                    value={this.state.selectedFilter[filterKey]}
                    type="number"
                    onWheel={event => { event.preventDefault(); }}
                    onChange={(newValue) => {
                        this.setState({
                            selectedFilter: { ...this.state.selectedFilter, [filterKey]: +newValue.target.value }
                        })
                    }}
                />
            </FormControl>
        )
    }

    getContractLabel(contract: { ContractName?: any; Source?: any; }) {
        return `${contract.ContractName} (${contract.Source})`
    }

    getFilter() {
        const sourcesToShow = this.state.sourcesToShow;
        const sources = this.state.sources;

        const availableContractOptions = (isEmpty(sourcesToShow) || sourcesToShow.length === sources.length) ?
            Object.keys(this.state.contractOptions).map(key => this.state.contractOptions[key]) // show all
            :
            Object.keys(this.state.contractOptions)
                .filter(key => sourcesToShow.find(s => s === this.state.contractOptions[key].Source)) //show selected
                .map(key => this.state.contractOptions[key]);

        return (
            <VPageFilter showHide
                show={!this.state.filterIsHidden}
                onShowChanged={(show) => this.setState({ filterIsHidden: !show })}
                getActiveFilter={() => {
                    const { contracts, ...rest } = this.state.activeFilter;

                    const clearFilters: { [key: string]: any } = {
                        contracts: contracts.map(contract => contract.ContractName),
                    };

                    Object.entries(rest).forEach(
                        ([key, value]) => clearFilters[key] = value
                    );

                    return clearFilters;
                }}>
                <Grid container item justify='flex-start' alignItems="flex-start" xs={6} style={{ minWidth: '14%' }}>
                    <Grid item xs={3} style={{ minWidth: '14%' }}>
                        <VDatePicker
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            label="Min DateTime"
                            maxDate={this.state.selectedFilter.max_date_time}
                            value={this.state.selectedFilter.min_date_time || null}
                            onChange={(date) => {
                                this.setState({
                                    selectedFilter: { ...this.state.selectedFilter, min_date_time: date?.toDate() }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} style={{ minWidth: '14%' }}>
                        <VDatePicker
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            label="Max DateTime"
                            minDate={this.state.selectedFilter.min_date_time}
                            value={this.state.selectedFilter.max_date_time || null}
                            onChange={(date) => {
                                this.setState({
                                    selectedFilter: { ...this.state.selectedFilter, max_date_time: date?.toDate() }
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <VSelect
                            multiple
                            tagRenderer={option => (<span style={{ margin: "0 3px", padding: "6px 0" }}>{option}</span>)}
                            width="150px"
                            value={this.state.sourcesToShow}
                            title='Sources'
                            options={this.state.sources}
                            getOptionLabel={(option) => option.toString()}
                            onChange={(newValue) => this.setState({ sourcesToShow: newValue as string[] })}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <VSelect
                            multiple
                            width="200px"
                            value={this.state.selectedFilter.contracts}
                            title='Contracts'
                            options={availableContractOptions}
                            sortBy={(row) => row.Direction}
                            getOptionLabel={(option) => this.getContractLabel(option)}
                            onChange={(newValue) => this.contractSelectionChanged(newValue)}
                            renderTags={false}
                        />
                    </Grid>
                    <Grid container item xs={12} justify="flex-start" spacing={0}>

                        <Grid item xs={2}>
                            <VSelect
                                disableClearable
                                width="100px"
                                value={this.state.selectedFilter.maturity_type || maturityTypes.month}
                                title='Maturity Type'
                                options={Object.values(maturityTypes)}
                                getOptionLabel={(option) => option.label}
                                onChange={newValue => {
                                    this.setState({
                                        selectedFilter: { ...this.state.selectedFilter, maturity_type: newValue as { key: string, label: string } }
                                    })
                                }}
                                renderTags={false}
                            />
                        </Grid>

                        {(this.state.selectedFilter.maturity_type.key === maturityTypes.quarter.key) &&
                            <Grid item xs={2}>
                                <VSelect
                                    disableClearable
                                    width="100px"
                                    value={this.state.selectedFilter.contract_quarter}
                                    title='Contract Quarter'
                                    options={[1, 2, 3, 4]}
                                    getOptionLabel={(option) => `Q${option}`}
                                    onChange={newValue => {
                                        this.setState({
                                            selectedFilter: { ...this.state.selectedFilter, contract_quarter: +(newValue || 1) }
                                        })
                                    }}
                                    renderTags={false}
                                />
                            </Grid>
                        }

                        {(this.state.selectedFilter.maturity_type.key === maturityTypes.quarter.key || this.state.selectedFilter.maturity_type.key === maturityTypes.year.key) &&
                            <Grid item xs={1}>
                                <FormControl style={{ width: 60 }}>
                                    <InputLabel htmlFor="contract_year_input" shrink>Contract Year</InputLabel>
                                    <Input
                                        id="contract_year_input"
                                        value={this.state.selectedFilter.contract_year || ""}
                                        type="number"
                                        onWheel={event => { event.preventDefault(); }}
                                        onChange={(newValue) => {
                                            this.setState({
                                                selectedFilter: {
                                                    ...this.state.selectedFilter,
                                                    contract_year: +newValue.target.value
                                                }
                                            })
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        }

                        {(!this.state.selectedFilter.maturity_type || this.state.selectedFilter.maturity_type.key === maturityTypes.month.key) &&
                            <>
                                <Grid item xs={1}>
                                    <VSelect
                                        disableClearable
                                        width="70px"
                                        value={this.state.selectedFilter.min_contract_month ?
                                            {
                                                label: shortMonthNames[this.state.selectedFilter.min_contract_month],
                                                value: this.state.selectedFilter.min_contract_month
                                            } : ""}
                                        title='Min Month'
                                        options={Object.keys(shortMonthNames).map((monthNum) =>
                                            ({ label: shortMonthNames[monthNum], value: monthNum }))}
                                        sortBy={(row) => +row.value}
                                        getOptionLabel={(option) => option.label}
                                        onChange={newValue => {
                                            this.setState({
                                                selectedFilter: { ...this.state.selectedFilter, min_contract_month: (newValue as { value: number })?.value }
                                            })
                                        }}
                                        renderTags={false}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl style={{ width: 60 }}>
                                        <InputLabel htmlFor="min_contract_year_input" shrink>Min Year</InputLabel>
                                        <Input
                                            id="min_contract_year_input"
                                            value={this.state.selectedFilter.min_contract_year || ""}
                                            type="number"
                                            onWheel={event => { event.preventDefault(); }}
                                            onChange={(newValue) => {
                                                this.setState({
                                                    selectedFilter: {
                                                        ...this.state.selectedFilter,
                                                        min_contract_year: +newValue.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography style={{ verticalAlign: "middle", fontSize: '0.85rem', whiteSpace: 'nowrap' }}>
                                        &le; Contract Date &le;
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <VSelect
                                        disableClearable
                                        width="70px"
                                        value={this.state.selectedFilter.max_contract_month ?
                                            {
                                                label: shortMonthNames[this.state.selectedFilter.max_contract_month],
                                                value: this.state.selectedFilter.max_contract_month
                                            } : ""}
                                        title='Max Month'
                                        options={Object.keys(shortMonthNames).map((monthNum) =>
                                            ({ label: shortMonthNames[monthNum], value: monthNum }))}
                                        sortBy={(row) => +row.value}
                                        getOptionLabel={(option) => option.label}
                                        onChange={newValue => {

                                            this.setState({
                                                selectedFilter: { ...this.state.selectedFilter, max_contract_month: (newValue as { value: number })?.value }
                                            })
                                        }}
                                        renderTags={false}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl style={{ width: 60 }}>
                                        <InputLabel htmlFor="max_contract_year_input" shrink>Max Year</InputLabel>
                                        <Input
                                            id="max_contract_year_input"
                                            value={this.state.selectedFilter.max_contract_year || ""}
                                            type="number"
                                            onWheel={event => { event.preventDefault(); }}
                                            onChange={(newValue) => {
                                                this.setState({
                                                    selectedFilter: {
                                                        ...this.state.selectedFilter,
                                                        max_contract_year: +newValue.target.value
                                                    }
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                <Grid container item xs={6}>
                    <VChipArray
                        defaultText="-"
                        style={{ minHeight: 50 }}
                        elements={sortBy(this.state.selectedFilter.contracts, d => d.ContractName).map(contract => this.getContractLabel(contract))}
                        onDelete={((_, removedElement) => this.setState({
                            selectedFilter: {
                                ...this.state.selectedFilter,
                                contracts: this.state.selectedFilter.contracts.filter(d => this.getContractLabel(d) !== removedElement)
                            }
                        }))}
                    />
                </Grid>
                <Grid container item xs={12} justify="flex-end">
                    <Button variant="contained" onClick={() => this.getData(this.state.selectedFilter)}>
                        Apply
                    </Button>
                </Grid>
            </VPageFilter>
        )
    }

    contractSelectionChanged(items: any) {
        if (!items)
            return;

        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                contracts: items
            }
        });
    }

    generateHeatMapTabContent(columns: IHeatMapProps["columns"], rows: IHeatMapProps["rows"], title: string) {
        if (isEmpty(rows))
            return <Typography>Nothing to show</Typography>

        return (
            <>
                <ExcelDownloadButton
                    title={title}
                    sheets={[{
                        columns: columns.map(col => getColumnLabel(col)),
                        rows: rows.map(row => columns.map(c => row?.[getColumnKey(c)])),
                    }]}
                />
                <VHeatMap style={{
                    height: 520,
                    maxWidth: 'calc(100vw - 60px)',
                }}
                    stickyHeader stickyFirstColumn
                    columns={columns} rows={rows}
                    uncoloredColumns={this.getUncoloredColumns(monthlyDetailsCustomColumns)}
                />
            </>
        )
    }

    monthlyDetailsMemoizer: MemoizeParams = {
        inprocessFlag: false,
        cache: null,
        ownersMap: [
            { current: () => this.state.monthlyDetails, used: null }
        ],
        elementCreator: () => {
            const rows = this.state.monthlyDetails?.rows;
            const columns = sortBy(
                this.state.monthlyDetails?.columns.map(col => ({ key: col, label: monthlyDetailsCustomColumns[col]?.label ?? col })),
                col => monthlyDetailsCustomColumns[col.key]?.order ?? col.label
            );

            if (!columns || !rows)
                return null;

            return this.generateHeatMapTabContent(columns, rows,
                `Contract Prices Monthly Details`);
        }
    }

    render() {
        return (
            <ReportViewer {...ContractPrices.params}
                onRefresh={() => this.getData(this.state.activeFilter)}>
                <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
                    <Grid item xs={12}>
                        {this.getFilter()}
                    </Grid>
                    <Grid item xs={12} style={{ maxWidth: '100%', ...styles.borderedContainer, margin: 4 }}>
                        <Tabs value={this.state.selectedTabIndex}
                            onChange={(_, value) => this.setState({ selectedTabIndex: value })} >
                            <Tab key='tab_charts'
                                label='Charts'
                                style={{ minWidth: 100 }}
                            />
                            <Tab key='tab_contract_prices'
                                label='Details'
                                style={{ minWidth: 100 }}
                            />
                        </Tabs>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={{ padding: 0 }}
                            index={0}
                            key='tab_charts'>
                            {
                                isEmpty(this.state.monthlyDetails?.rows) ?
                                    <Typography>Nothing to show</Typography>
                                    :
                                    <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
                                        <Grid item xs={6}>
                                            <ContractPriceChart
                                                dateColumn="DateTime"
                                                chartConfig={this.state.chartConfig1}
                                                updateChartConfig={(newConfig) => this.setState({ chartConfig1: newConfig })}
                                                valueColumns={this.getValueColumns(this.state.monthlyDetails?.rows, monthlyDetailsCustomColumns)}
                                                rows={this.state.monthlyDetails?.rows}
                                                columnLabels={this.getColumnLabels(this.state.monthlyDetails?.rows, monthlyDetailsCustomColumns)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ContractPriceChart
                                                dateColumn="DateTime"
                                                chartConfig={this.state.chartConfig2}
                                                updateChartConfig={(newConfig) => this.setState({ chartConfig2: newConfig })}
                                                valueColumns={this.getValueColumns(this.state.monthlyDetails?.rows, monthlyDetailsCustomColumns)}
                                                rows={this.state.monthlyDetails?.rows}
                                                columnLabels={this.getColumnLabels(this.state.monthlyDetails?.rows, monthlyDetailsCustomColumns)}
                                            />
                                        </Grid>
                                    </Grid>
                            }

                        </TabPanel>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={{ padding: 0 }}
                            index={1}
                            key='tab_contract_prices'>
                            {
                                getMemoizedElement(this.monthlyDetailsMemoizer)
                            }
                        </TabPanel>
                    </Grid>
                </Grid>
            </ReportViewer>
        )
    }
}

export default ContractPrices;