import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { Button, Grid, GridSize, Typography } from '@material-ui/core';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import VPageFilter from '../../../components/VPageFilter/VPageFilter';
import { ItemsDict, createCheckboxGroup } from '../../../components/VCheckBoxGroup/VCheckBoxGroup';
import { getPropertyName, handleApiError, toShortDateString } from '../../../utils/common';
import { getRealizedWithForecastPSD, getPSDStaticData } from '../../../apis/vitusApi';
import { createSpinner } from '../../../utils/spinnerManager';
import AlertManager from '../../../utils/alertManager';
import { IDailyWithForecastPSDResponse } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { orderBy } from 'lodash';
import VRadioButtonGroup from '../../../components/VRadioButtonGroup/VRadioButtonGroup';
import VDatePicker from '../../../components/VDatePicker/VDatePicker';
import VChipArray from '../../../components/VChipArray/VChipArray';
import messages from '../../../utils/messages';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

echarts.use(
    [LineChart, GridComponent]
);

const PERIODS = ["Daily", "Monthly", "Hourly"];
const DATA_TYPES = ["Forecast", "Realized"];
const OPERATIONS = ["Avg", "Min", "Max"];
const FUEL_TYPES = ["Demand", "Residual Load", "Solar", "Wind"];
const CUSTOM_YEARS = ["Current", "Last", "History"];
const DEFAULT_SOURCE = "Vitus";

interface IProps {
}

type YearType = "Current" | "Last" | "History" | number;
type DataType = "Forecast" | "Realized";
type OperationType = "None" | "Avg" | "Max" | "Min";

type LineFilter = {
    data_type: DataType,
    forecast_date?: Date,
    source?: string,
    years?: YearType,
    operation?: OperationType,
}

type PSDDataFilter = {
    period: string,
    countries: string[],
    fuel_type: string,
    lines: LineFilter[],
    data_type: DataType,
    source: string,
    operation: OperationType,
    forecast_date?: Date,
    years: YearType,
}

type PSDData = { [key: string]: any }[];

interface IState {
    pageReady: boolean,
    prodCountries: string[],
    consCountries: string[],
    prodSources: string[],
    consSources: string[],
    europeCountries: { [key: string]: string[] },
    selectedFilter: PSDDataFilter,
    activeFilter: PSDDataFilter,
    countryValues: PSDData,
    latestForecasts: PSDData,

    lineValues: { filter: LineFilter, data: PSDData }[],
}

const defaultColumnWidth = 120;

const LatestCountryForecastDateColumns = [
    { field: 'CountryName', headerName: 'Country', width: defaultColumnWidth },
    { field: 'ForecastDateCET', headerName: 'Forecast Date CET', width: defaultColumnWidth + 30 },
]

const ValuesPerCountryColumns = [
    { field: 'DateTime', headerName: 'Date', width: defaultColumnWidth },
    { field: 'CountryName', headerName: 'Country', width: defaultColumnWidth },
    { field: 'Source', headerName: 'Source', width: defaultColumnWidth },
    { field: 'Value', headerName: 'Value', width: defaultColumnWidth },
]

function CustomToolbar() {
    return (
        <Grid container>
            <Grid item container justify="flex-end">
                <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarExport />
                </GridToolbarContainer>
            </Grid>
        </Grid>
    );
}

class PSDRealizedWithForecast extends React.Component<IProps, IState> {
    psdLineChartRef: ReactECharts | null = null;

    lineOptions: ECOption = {
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
            scale: true,
        },
        series: [{
            data: [],
            type: 'line',
        }],
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'valueDesc'
        },
        dataZoom: [{}],
        animation: false
    }

    initialFilter = {
        period: PERIODS[0],
        countries: [],
        fuel_type: FUEL_TYPES[0],
        lines: [],
        data_type: DATA_TYPES[0] as DataType,
        source: DEFAULT_SOURCE,
        operation: OPERATIONS[0] as OperationType,
        forecast_date: new Date(),
        years: CUSTOM_YEARS[0] as YearType
    };

    state: IState = {
        pageReady: false,
        prodCountries: [],
        consCountries: [],
        prodSources: [],
        consSources: [],
        europeCountries: {},
        selectedFilter: { ...this.initialFilter },
        activeFilter: { ...this.initialFilter },
        countryValues: [],
        latestForecasts: [],

        lineValues: [],
    }

    componentDidMount() {
        this.getStaticData();
        this.getData(this.state.selectedFilter);
    }

    updateActiveFilters() {
        this.getData(this.state.selectedFilter);
    }

    clearFilters() {
        this.setState({ selectedFilter: { ...this.initialFilter } });
    }

    getStaticData() {
        const spinner = createSpinner();

        getPSDStaticData().then(response => {
            if (response.data.success) {
                this.setState({
                    prodCountries: response.data.success.prod_countries || [],
                    consCountries: response.data.success.cons_countries || [],
                    europeCountries: response.data.success.europe_countries || [],
                    prodSources: response.data.success.prod_sources || [],
                    consSources: response.data.success.cons_sources || [],
                });
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    getData(filter: PSDDataFilter) {
        this.setState({ lineValues: [] }, () => {
            filter.lines.forEach(line => {
                const spinner = createSpinner();

                getRealizedWithForecastPSD({
                    period: filter.period,
                    countries: filter.countries,
                    fuel_type: filter.fuel_type
                    , ...line
                }).then(response => {
                    if (response.data.success) {
                        this.storeData(line, response.data.success);
                        this.setState({ activeFilter: { ...this.state.selectedFilter } });
                    }

                    if (response.data.error)
                        AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

                }).catch(error => {
                    handleApiError(error);
                }).finally(() => {
                    this.setState({ pageReady: true });
                    spinner.hide()
                })
            });
        });
    }

    storeData(owner: LineFilter, response: IDailyWithForecastPSDResponse["success"]) {
        this.setState({
            countryValues: response.country_values || [],
            lineValues: [...this.state.lineValues, { filter: owner, data: response.total_values }] || [],
            latestForecasts: response.latest_forecasts || this.state.latestForecasts || [],
        }, this.updateCharts);
    }

    updateCharts() {
        if (!this.renderCharts())
            return;

        this.updateLineChart();
    }

    decidePointLabel(idx: number, dataSource: PSDData) {
        let show = false, position;

        try {
            if (idx === 0) {
                // first point
                show = true;
                position = dataSource[1].Value > dataSource[0].Value ? "bottom" : "top";
            } else if (idx === dataSource.length - 1) {
                // last point
                show = true;
                position = dataSource[idx - 1].Value > dataSource[idx].Value ? "bottom" : "top";
            } else {
                // middle point, show if edge
                const prev = dataSource[idx - 1].Value;
                const current = dataSource[idx].Value;
                const next = dataSource[idx + 1].Value;

                if (prev > current && next > current) {
                    show = true;
                    position = "bottom";
                }
                else if (prev < current && next < current) {
                    show = true;
                    position = "top";
                }
            }

        } catch {
            //ignore
        }

        return { show, position };
    }

    updateLineChart = () => {
        const psdChartInstance = this.psdLineChartRef?.getEchartsInstance();
        psdChartInstance.showLoading();

        const allDataSources = orderBy(this.state.lineValues, [(item) => this.getLineName(item.filter)], [])

        psdChartInstance.clear();
        psdChartInstance.setOption({
            ...this.lineOptions,
            series: allDataSources.map(line => {
                const dataSource = line.data;

                return {
                    type: 'line',
                    name: this.getLineName(line.filter),
                    data: dataSource.map((row, idx) => {

                        return {
                            value: [row.DateTime, row.Value],
                            label: {
                                ...this.decidePointLabel(idx, dataSource),
                                formatter: function (d: { value: Array<number> }) {
                                    const value = d.value[1] as number;
                                    const strValue = value.toString();

                                    if (strValue.length > 3)
                                        return Math.round(value / 1000) + "K"
                                    return strValue;
                                }
                            }
                        }
                    })
                }
            }),
            legend: {
                type: 'scroll',
                data: allDataSources.map(line => this.getLineName(line.filter))
            }
        });

        psdChartInstance.hideLoading();
    }

    renderCharts() {
        return this.state.lineValues?.filter(l => l.data?.length > 0)?.length > 0;
    }

    onDataTypeChanged(newValue: string) {
        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                data_type: newValue as DataType
            }
        })
    }

    getCountriesList() {
        const fuelType = this.state.selectedFilter.fuel_type;
        if (fuelType === "Demand")
            return this.state.consCountries;
        else if (fuelType === "Residual Load")
            return this.state.consCountries.filter(c => this.state.prodCountries.find(p => p === c));
        else
            return this.state.prodCountries;
    }

    getSourcesList() {
        const fuelType = this.state.selectedFilter.fuel_type;

        let result: string[] = [];

        if (fuelType) {
            if (fuelType === "Demand")
                result = this.state.consSources;
            else if (fuelType === "Residual Load")
                result = this.state.consSources.filter(c => this.state.prodSources.find(p => p === c));
            else
                result = this.state.prodSources;
        }

        if (!result.length)
            result = [DEFAULT_SOURCE];

        return result;
    }

    getEuropeDescription() {
        const fuelType = this.state.selectedFilter.fuel_type;

        if (!fuelType)
            return null;

        const europeCountries = this.state.europeCountries[fuelType];

        if (!europeCountries)
            return null;

        return (
            <Grid item xs={4}>
                <div style={{ padding: 12, height: "100%", width: "100%", border: "1px solid rgba(224, 224, 224, 1)", wordWrap: "break-word" }}>
                    <Typography>
                        * For {fuelType}, following {europeCountries.length} countries are included in Europe total: {europeCountries.join(', ')}.
                    </Typography>
                </div>
            </Grid>
        );
    }

    handleMultiSelectCheckChange(checkType: string, items: ItemsDict) {
        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                [checkType]: Object.keys(items).filter(k => items[k])
            }
        });
    }

    handleSingleSelectCheckChange(checkType: string, items: ItemsDict) {
        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                [checkType]: Object.keys(items).filter(k => items[k])[0]
            }
        })
    }

    renderForecastFilter() {
        return (
            <React.Fragment>
                <Grid item>
                    <VDatePicker
                        disabled
                        variant="inline"
                        format="DD/MM/yyyy"
                        margin="normal"
                        label="Forecast Date"
                        value={this.state.selectedFilter.forecast_date}
                        onChange={(date) => this.setState({
                            selectedFilter: { ...this.state.selectedFilter, forecast_date: date?.toDate() }
                        })}
                    />
                </Grid>
                <Grid item>
                    {
                        createCheckboxGroup("Source",
                            getPropertyName<LineFilter>().source || "",
                            this.getSourcesList(),
                            [this.state.selectedFilter.source],
                            (...args) => this.handleSingleSelectCheckChange(...args),
                            false)
                    }
                </Grid>
            </React.Fragment>
        );
    }

    renderRealizedFilter() {
        return (
            <React.Fragment>
                <Grid item>
                    {
                        createCheckboxGroup("Years",
                            getPropertyName<LineFilter>().years || "",
                            CUSTOM_YEARS,
                            [this.state.selectedFilter.years],
                            (...args) => this.handleSingleSelectCheckChange(...args),
                            false,
                            "string",
                            (i) => CUSTOM_YEARS.indexOf(i as string))
                    }
                </Grid>
                <Grid item>
                    {
                        createCheckboxGroup("Operation",
                            getPropertyName<LineFilter>().operation || "",
                            OPERATIONS,
                            [this.state.selectedFilter.operation],
                            (...args) => this.handleSingleSelectCheckChange(...args),
                            false,
                            "string",
                            (i) => OPERATIONS.indexOf(i as string))
                    }
                </Grid>
            </React.Fragment>
        )
    }

    updateLineFilter(newLines: string[]) {
        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter, lines: this.state.selectedFilter.lines.filter(l => newLines.find(nl => nl === this.getLineName(l)))
            }
        });
    }

    getLineName(owner: LineFilter) {
        if (owner.data_type === "Forecast")
            return `Forecast-${toShortDateString(owner.forecast_date)}-${owner.source}`;
        return `Realized-${owner.years}-${owner.operation}`;
    }

    getNewLineFilterObject() {
        const { data_type, source, operation, years, forecast_date } = this.state.selectedFilter;

        if (data_type === "Forecast")
            return { data_type, source, forecast_date };
        return { data_type, operation, years };
    }

    addNewLineFilter() {
        const newLine = this.getNewLineFilterObject();

        if (this.state.selectedFilter.lines.find(l => this.getLineName(l) === this.getLineName(newLine))) {
            return;
        }

        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter, lines: [...this.state.selectedFilter.lines, newLine]
            }
        });
    }

    render() {
        const filterInputProps: { [key: string]: GridSize } = { lg: 2, md: 4, sm: 12 };
        const filterActionProps: { [key: string]: GridSize } = { sm: 12 };

        return (
            <Grid container spacing={3} justify="flex-start">
                <Grid item xs={12}>
                    <VPageFilter showHide
                        customToString={{
                            forecast_date: (value) => toShortDateString(value as Date),
                            lines: (value) => {
                                if (value)
                                    return (value as LineFilter[]).map(v => this.getLineName(v)).join(', ');
                                return "";
                            }
                        }}
                        getActiveFilter={() => this.state.activeFilter}>
                        <Grid container justify="flex-start" alignContent="flex-start">
                            <Grid item {...filterInputProps}>
                                {
                                    createCheckboxGroup("Period",
                                        getPropertyName<PSDDataFilter>().period,
                                        PERIODS,
                                        [this.state.selectedFilter.period],
                                        (...args) => this.handleSingleSelectCheckChange(...args),
                                        false)
                                }
                                {
                                    createCheckboxGroup("Fuel Type",
                                        getPropertyName<PSDDataFilter>().fuel_type,
                                        FUEL_TYPES,
                                        [this.state.selectedFilter.fuel_type],
                                        (...args) => this.handleSingleSelectCheckChange(...args),
                                        false)
                                }
                            </Grid>
                            <Grid item {...filterInputProps}>
                                {
                                    createCheckboxGroup("Country",
                                        getPropertyName<PSDDataFilter>().countries,
                                        this.getCountriesList(),
                                        this.state.selectedFilter.countries,
                                        (...args) => this.handleMultiSelectCheckChange(...args))
                                }
                                <VRadioButtonGroup
                                    defaultValue={this.state.selectedFilter.data_type}
                                    onChange={(newValue) => this.onDataTypeChanged(newValue)}
                                    options={
                                        DATA_TYPES.map(dt => { return { value: dt, title: dt } })
                                    } />
                            </Grid>
                            <Grid item {...filterInputProps}>
                                {
                                    this.state.selectedFilter.data_type === "Forecast" ?
                                        this.renderForecastFilter()
                                        :
                                        this.renderRealizedFilter()
                                }
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    style={{ height: "40" }}
                                    variant="contained"
                                    onClick={() => this.addNewLineFilter()}>
                                    Add
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <VChipArray
                                    elements={this.state.selectedFilter.lines.map(l => this.getLineName(l))}
                                    onDelete={(newData => this.updateLineFilter(newData))}
                                />
                            </Grid>
                        </Grid>
                        <Grid item
                            {...filterActionProps}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end">
                            <Button
                                style={{ height: "40" }}
                                variant="contained"
                                onClick={() => this.clearFilters()}>
                                Clear
                            </Button>
                            <Button
                                style={{ height: "40" }}
                                variant="contained"
                                onClick={() => this.updateActiveFilters()}>
                                Apply
                            </Button>
                        </Grid>
                    </VPageFilter>
                </Grid>
                {
                    this.renderCharts() &&
                    <Grid item xs={12} >
                        <VChart
                            height={350}
                            report={
                                <ReactECharts
                                    ref={(e) => { this.psdLineChartRef = e; }}
                                    echarts={echarts}
                                    option={this.lineOptions}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ width: '99%' }}
                                />
                            } >
                        </VChart>
                    </Grid>
                }
                {
                    this.renderCharts() &&
                    <React.Fragment>
                        <Grid item xs={3}>
                            <div style={{ height: 250, width: LatestCountryForecastDateColumns.reduce((a, b) => a + b.width, 0) + 20 }}>
                                <DataGrid
                                    rowHeight={25}
                                    headerHeight={25}
                                    pagination
                                    pageSize={100}
                                    rowsPerPageOptions={[]}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                    rows={this.state.latestForecasts.map(f => { return { ...f, id: f.CountryName } })}
                                    columns={LatestCountryForecastDateColumns} />
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <div style={{ height: 250, width: ValuesPerCountryColumns.reduce((a, b) => a + b.width, 0) + 20 }}>
                                <DataGrid
                                    rowHeight={25}
                                    headerHeight={25}
                                    pagination
                                    pageSize={100}
                                    rowsPerPageOptions={[]}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                    rows={(() => {
                                        if (!this.state.countryValues?.length)
                                            return [];

                                        const source = this.getLineName(this.state.lineValues[this.state.lineValues.length - 1].filter)

                                        return this.state.countryValues.map(f => {
                                            return {
                                                ...f,
                                                Source: source,
                                                id: f.CountryName + f.DateTime
                                            }
                                        })
                                    })()}
                                    columns={ValuesPerCountryColumns} />
                            </div>
                        </Grid>
                        {this.getEuropeDescription()}
                    </React.Fragment>
                }
                {
                    this.state.pageReady && !this.renderCharts() &&
                    <Grid item>
                        <Typography>
                            Data not found!
                        </Typography>
                    </Grid>
                }
            </Grid >
        );
    }
}

export default PSDRealizedWithForecast;
