import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        notFound: {
            textAlign: "center"
        },
    }),
);

const NotFound: React.FC = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.notFound}>
            <Grid item xs={12}>
                <Typography variant="h1">
                    Error
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h3">
                    Page not found
                </Typography>
            </Grid>
        </Grid>
    );
};

export default NotFound;
