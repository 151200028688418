
export const inputLabels: { [key: string]: string } = {
    minDate: "Min Date (CET)",
    maxDate: "Max Date (CET)",
    date: "Date (CET)",
    currency: "Currency",
    priceType: "Price Type",
    priceAreaType: "Price Area Type",
    priceAreaName: "Price Area Name",
    sourcePriceAreaType: "Source Price Area Type",
    sourcePriceAreaName: "Source Price Area Name",
    sinkPriceAreaType: "Sink Price Area Type",
    sinkPriceAreaName: "Sink Price Area Name",
    powerType: "Power Type",
    powerAreaType: "Power Area Type",
    powerAreaName: "Power Area Name",
    viewMode: 'Time Series View Mode',
    frequency: 'Frequency',
}

export enum PriceType {
    Price = 'Price',
    Flow = 'Flow',
    Spread = 'Spread',
}

export const priceTypes = Object.keys(PriceType).filter((item) => {
    return isNaN(Number(item));
})

export enum Frequency {
    Hourly = 'Hourly',
    Daily = 'Daily',
}

export const frequencies = Object.keys(Frequency).filter((item) => {
    return isNaN(Number(item));
})

export const viewModes: { [key: string]: string } = {
    SeperateCharts: "Seperate Charts",
    SingleChartSeperateY: "Single Chart Seperate Y Axis",
    SingleChartSingleY: "Single Chart Single Y Axis",
}

export type Filter = {
    minDate?: Date,
    maxDate?: Date,
    currency?: string,
    priceType: PriceType,
    frequency:Frequency,
    priceAreaType?: string,
    priceAreaName?: string,
    sourcePriceAreaType?: string,
    sourcePriceAreaName?: string,
    sinkPriceAreaType?: string,
    sinkPriceAreaName?: string,
    powerType?: string,
    powerAreaType?: string,
    powerAreaName?: string,

    viewMode?: string,
}

export type Options = {
    currencies: string[];
    priceAreaTypes: string[];
    priceAreaNames: string[];
    sourcePriceAreaNames: string[];
    sinkPriceAreaNames: string[];
    powerTypes: string[];
    powerAreaTypes: string[];
    powerAreaNames: string[];
};

export type FilterOptionsDict = {
    [key: string]: //AreaType
    {
        [key: string]: //AreaName
        string[] //PowerTypes
    }
}
