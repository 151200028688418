import React, { ChangeEvent, SyntheticEvent } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, /*useTheme*/ } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import VUserInfo from '../VUserInfo/VUserInfo';
import { Collapse, Grid, Icon, LinkProps } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { setFavouriteReport } from '../../utils/userSettings';
import { ReportParams, ReportView } from '../../system/ReportBase';
import { isEmpty } from 'lodash';
import { endpointNames } from '../../utils/staticInfo';
import { ViewTypes } from '../../utils/common';

const drawerWidth = 220;
const appBarHeight = 55;
const appBarBgColor = '#212F3D';

const sharedDawerOptions: {
    [key: string]: string | number,
    overflowX: 'hidden',
} = {
    height: "100%",
    overflowX: 'hidden',
};

// Manage dynamically rendered items and sub list items here if needed. Was not implemented because currently not needed.
export const mainMenuItems: {
    [key: string]: { title: string, icon: string, route: string }
} = {
    home: { title: "Home", icon: "fas fa-home", route: endpointNames.main },
    reports: { title: "All Reports", icon: "far fa-chart-bar", route: "#" },
    favouriteReports: { title: "Favourite Reports", icon: "fas fa-star", route: "#" },
    customReports: { title: "Custom Reports", icon: "fas fa-file-contract", route: "#" },
    administration: { title: "Manage", icon: "fas fa-cog", route: "#" },
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        main: {
            padding: theme.spacing(1),
            flexGrow: 1,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            height: appBarHeight,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: appBarBgColor
        },
        appBarShift: {
            marginLeft: 0,
            width: '100%',
        },
        menuButton: {
            padding: "16px 16px",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            ...sharedDawerOptions
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: drawerWidth / 4,
            ...sharedDawerOptions
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            minHeight: appBarHeight,
        },
        grow: {
            flexGrow: 1,
        },
        nested: {
            paddingLeft: theme.spacing(4),
            // paddingTop: 0,
            // paddingBottom: 0,
        },
        icon: {
            minWidth: "30px",
        },
    }),
);

interface IProps {
    isLoggedIn?: boolean,
    reports: typeof ReportView[],
    customReports?: ReportParams[],
    ownedCustomReports?: ReportParams[],
    favouriteReports?: { [key: string]: boolean },
    content: React.ReactNode,
}

interface ILinkProps {
    icon?: string,
    text: string,
    to?: string,
    expandable?: boolean,
    open?: boolean,
    className?: string,
    onClick?: () => void,
    onIconClick?: () => void,
}

const ListItemLink: React.FC<ILinkProps> = (props: ILinkProps) => {
    const { icon, text, to } = props;
    const classes = useStyles();

    const CustomLink = React.useMemo(
        () =>
            React.forwardRef<HTMLAnchorElement, LinkProps>((linkProps, ref) => (
                <Link ref={ref} to={to || ""} {...linkProps} />
            )),
        [to],
    );

    const listClick = (e: SyntheticEvent) => {
        if (props.onClick) {
            props.onClick();
            e.stopPropagation();
        }
    }

    const onIconClick = (e: SyntheticEvent) => {
        if (props.onIconClick) {
            props.onIconClick();
            e.stopPropagation();
            e.preventDefault();
        }
    }

    const iconSize = 1;

    return (
        <ListItem button onClick={listClick} component={to ? CustomLink : "div"} className={props.className ? props.className : ""}>
            {props.icon &&
                <ListItemIcon className={classes.icon} onClick={onIconClick}>
                    <span style={{ fontSize: `${iconSize}rem` }}>
                        <i className={icon} />
                    </span>
                </ListItemIcon>
            }
            <ListItemText primary={text} />
            {props.expandable &&
                (props.open ?
                    <span style={{ fontSize: `${iconSize}rem` }}>
                        <i className="fas fa-angle-up"></i>
                    </span>
                    :
                    <span style={{ fontSize: `${iconSize}rem` }}>
                        <i className="fas fa-angle-down"></i>
                    </span>
                )

            }
        </ListItem>
    );
}

const VSideBar: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [reportSearchText, setReportSearchText] = React.useState("");

    const [expandableMenuItems, setExpandableMenuItems] = React.useState<{ [key: string]: boolean }>({
        favouriteReports: false,
        reports: false,
        customReports: false,
        administration: false,
    });

    const favouriteReports = props.favouriteReports || {};

    const allMenuItems = [
        ...props.reports.map(r => r.params),
        ...(props.ownedCustomReports || []),
        ...(props.customReports?.filter(cr => !props.ownedCustomReports?.find(ocr => ocr.name === cr.name)) || [])
    ]

    const allPages = allMenuItems.filter(r => r.viewType !== ViewTypes.Administration)
    const favouriteReportsMenuItems = allMenuItems.filter(r => favouriteReports[r.reportKey])
    const ownedCustomReportsMenuItems = props.ownedCustomReports;
    const adminPages = [...props.reports.filter(r => r.params.viewType === ViewTypes.Administration).map(r => r.params)];

    const updateNestedItems = (key: string) => {
        setExpandableMenuItems({ ...expandableMenuItems, [key]: !expandableMenuItems[key] })
    }

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent,) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };

    const createExpandableMenuList = (isOpen: boolean, mainMenuItemKey: string, dataSource: ReportParams[]) => {
        const item = mainMenuItems[mainMenuItemKey];

        return (
            <React.Fragment>
                <ListItemLink
                    expandable={true}
                    open={isOpen}
                    onClick={() => updateNestedItems(mainMenuItemKey)}
                    key={`nav_${item.title}`}
                    icon={item.icon}
                    text={item.title}
                />
                <Collapse in={isOpen || !!reportSearchText} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            dataSource
                                .filter(r => !reportSearchText || (r.name.toLowerCase().includes(reportSearchText.toLowerCase())))
                                .sort((a, b) => {
                                    if (a.name < b.name) return -1;
                                    if (a.name > b.name) return 1;
                                    return 0
                                })
                                .map(params => {
                                    return (
                                        <ListItemLink
                                            className={classes.nested}
                                            key={`${mainMenuItemKey}_${params.name}`}
                                            to={params.cleanPath || params.path}
                                            text={params.name}
                                            icon={(favouriteReports[params.reportKey]) ? "fas fa-star" : "far fa-star"}
                                            onIconClick={() => setFavouriteReport(
                                                params.name,
                                                params.reportKey,
                                                !(favouriteReports[params.reportKey])
                                            )}
                                        />
                                    );
                                })
                        }
                    </List>
                </Collapse>
            </React.Fragment>
        );
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                <Toolbar className={classes.toolbar}>
                    {props.isLoggedIn &&
                        <IconButton
                            color="inherit"
                            className={classes.menuButton}
                            onClick={open ? toggleDrawer(false) : toggleDrawer(true)}>
                            <Icon className="fas fa-bars" />
                        </IconButton>
                    }
                    <Link to={mainMenuItems.home.route}>
                        <img src="./logo2.png" alt="" />
                    </Link>
                    <div className={classes.grow} />
                    <Typography variant="h6" noWrap>
                        <VUserInfo />
                    </Typography>
                </Toolbar>
            </AppBar>
            {
                props.isLoggedIn &&
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={toggleDrawer(false)}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}>
                    <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                        <ListItem onClick={((e: SyntheticEvent) => e.stopPropagation())}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <span style={{ fontSize: "0.9rem" }}>
                                        <i className="fas fa-search" />
                                    </span>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        onKeyDown={((e: SyntheticEvent) => e.stopPropagation())}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setReportSearchText(e.currentTarget.value)
                                        }}
                                        size="small"
                                        fullWidth={true}
                                        label="Search Reports"
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Divider />
                        <ListItemLink
                            key={`nav_${mainMenuItems.home.title}`}
                            to={mainMenuItems.home.route}
                            icon={mainMenuItems.home.icon}
                            text={mainMenuItems.home.title}
                        />
                        {!isEmpty(favouriteReportsMenuItems) &&
                            <React.Fragment>
                                <Divider />
                                {createExpandableMenuList(
                                    expandableMenuItems.favouriteReports,
                                    "favouriteReports",
                                    favouriteReportsMenuItems)}
                            </React.Fragment>
                        }
                        {ownedCustomReportsMenuItems && !isEmpty(ownedCustomReportsMenuItems) &&
                            <React.Fragment>
                                <Divider />
                                {createExpandableMenuList(
                                    expandableMenuItems.customReports,
                                    "customReports",
                                    ownedCustomReportsMenuItems)}
                            </React.Fragment>
                        }
                        {
                            <React.Fragment>
                                <Divider />
                                {createExpandableMenuList(
                                    expandableMenuItems.reports,
                                    "reports",
                                    allPages)}
                            </React.Fragment>
                        }
                        {adminPages && !isEmpty(adminPages) &&
                            <React.Fragment>
                                <Divider />
                                {createExpandableMenuList(
                                    expandableMenuItems.administration,
                                    "administration",
                                    adminPages)}
                            </React.Fragment>}
                        <Divider />
                    </List>
                </Drawer>
            }
            <main className={classes.main}>
                <div className={classes.toolbar} />
                {props.content}
            </main>
        </div >
    );
}


export default VSideBar