import { ReportView, ReportParams } from "../../../system/ReportBase";
import { handleApiError, ViewTypes } from "../../../utils/common";
import { Tab, Tabs, Grid } from "@material-ui/core";
import ReportViewer from "../../ReportViewer/ReportViewer";
import { TabPanel } from '../../../components/VTabs/VTabs';
import WorkflowTable from "./WorkflowTable";
import WorkflowTimeTable, { logSources } from "./WorkflowTimeTable";
import { getWorkflowTasks, getWorkflowTimeTable } from "../../../apis/vitusApi";
import AlertManager from "../../../utils/alertManager";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import { IWorkflowTasksResponse, IWorkflowTimeTableRequest, IWorkflowTimeTableResponse } from "../../../apis/vitusApiTypes";

interface IState {
    tasks?: IWorkflowTasksResponse["success"]["tasks"],
    logs?: IWorkflowTimeTableResponse["success"]["logs"],
    selectedTabIndex: number,
}

class WorkflowDashboard extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            viewType: ViewTypes.Administration,
            reportKey: "WORKFLOW_DASHBOARD",
            name: "Workflow Dashboard",
            path: "/workflowDashboard",
            thumbnail: ""
        }
    );

    state: IState = {
        selectedTabIndex: 0,
    }

    getDefaultStartDate() {
        const oneDayAgo = new Date();
        oneDayAgo.setDate(new Date().getDate() - 1);
        return oneDayAgo;
    }

    componentDidMount() {

        this.callGetWorkflowTable();
        this.callGetWorkflowTimeTable({ min_start_date: this.getDefaultStartDate() });
    }

    callGetWorkflowTable() {
        const spinner = createSpinner();

        getWorkflowTasks().then(response => {
            if (response.data.success) {
                this.setState({
                    tasks: response.data.success.tasks,
                })
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    callGetWorkflowTimeTable(filter: IWorkflowTimeTableRequest) {
        const spinner = createSpinner();

        getWorkflowTimeTable(filter).then(response => {
            if (response.data.success) {
                this.setState({
                    logs: response.data.success.logs,
                })
            }

            if (response.data.warn?.message?.InvalidRequest === 'insufficient_filter') {
                AlertManager.showError('Result list is limited to 5000 rows FOR EACH SOURCE, narrow down your query to see proper results.'); //TODO: message
            }

            if (response.data.error) {
                const error_detail = response.data.error?.default?.Unexpected;

                if (error_detail?.startsWith('source_error')) {
                    const failed_source = error_detail.split('|');
                    AlertManager.showError(`Unexpected error when getting source: ${logSources[failed_source[failed_source.length - 1]]['title']}`); //TODO: message
                }
                else {
                    AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                }
            }
        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    render() {
        const tabPanelStyle = { padding: 0 };

        return (
            <ReportViewer {...WorkflowDashboard.params}>
                <Grid container justify="flex-start">
                    <Grid item xs={12}>
                        <Tabs value={this.state.selectedTabIndex}
                            onChange={(_, value) => this.setState({ selectedTabIndex: value })} >
                            <Tab key='tab_time_table'
                                label='Time Table'
                                style={{ minWidth: 100 }}
                            />
                            <Tab key='tab_tasks'
                                label='Tasks'
                                style={{ minWidth: 100 }}
                            />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={tabPanelStyle}
                            index={0}
                            key='tab_content_time_table'>
                            <WorkflowTimeTable
                                callGetWorkflowTimeTable={(filter: IWorkflowTimeTableRequest) => this.callGetWorkflowTimeTable(filter)}
                                logs={this.state.logs}
                                defaultStartDate={this.getDefaultStartDate()} />
                        </TabPanel>
                        <TabPanel value={this.state.selectedTabIndex}
                            style={tabPanelStyle}
                            index={1}
                            key='tab_content_tasks'>
                            <WorkflowTable
                                callGetWorkflowTable={() => this.callGetWorkflowTable()}
                                tasks={this.state.tasks} />
                        </TabPanel>
                    </Grid>
                </Grid>
            </ReportViewer>
        );
    }
}

export default WorkflowDashboard;
