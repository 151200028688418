import React, { CSSProperties } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isArray } from 'lodash';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    style?: CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        overflow: 'visible',
    },
    box: {
        padding: '0 24px'
    }
}));

export function TabPanel(props: TabPanelProps) {
    const classes = useStyles();

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: "100%", height: "100%" }}
            {...other}
        >
            {value === index && (
                <Box className={classes.box} style={props.style ? props.style : {}}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

interface IVerticalTab {
    title: string,
    children?: React.ReactNode,
}

export const VerticalTab: React.FC<IVerticalTab> = () => {
    return null;
}

interface ITabsProps {
    children?: React.ReactElement<IVerticalTab>[] | React.ReactElement<IVerticalTab> | null | undefined,
    onTabChanged?: (newTabName: string) => any,
    tabsFooter?: React.ReactElement | null,
    boxStyle?: CSSProperties;
}

const VerticalTabs: React.FC<ITabsProps> = (props: ITabsProps) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (props.onTabChanged) {
            const result = props.onTabChanged(checkedChildren[newValue].props.title);
            if (result === false)
                return;
        }

        setValue(newValue);
    };

    if (!props.children)
        return null;

    let checkedChildren: React.ReactElement<IVerticalTab>[];

    if (isArray(props.children))
        checkedChildren = props.children;
    else
        checkedChildren = [props.children]

    return (
        <div className={classes.root}>
            {
                props.tabsFooter &&
                <div style={{ position: "relative", zIndex: 9999 }}>
                    <div style={{ position: "absolute", bottom: 0, width: '150px' }}>
                        {props.tabsFooter}
                    </div>
                </div>
            }
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                {checkedChildren.map(c => <Tab label={c.props.title} key={`tab_title_${c.props.title}`} />)}
            </Tabs>
            {checkedChildren.map((c, idx) => {
                return (
                    <TabPanel value={value} index={idx} key={`tab_content_${c.props.title}`}
                        style={props.boxStyle ? props.boxStyle : {}}>
                        {c.props.children}
                    </TabPanel>
                )
            })}
        </div>
    );
}

export default VerticalTabs;