import { ProviderContext, SnackbarKey } from 'notistack';

class AlertManager {
    private static _showMessage: ProviderContext['enqueueSnackbar'];

    static get showMessage(): typeof AlertManager._showMessage { return AlertManager._showMessage };

    static setShowMessage = (showMessageHook: typeof AlertManager._showMessage) => {
        AlertManager._showMessage = showMessageHook;
    }

    static showSuccess(message: string): SnackbarKey {
        return AlertManager.showMessage(message, { variant: 'success', style: { whiteSpace: 'pre-line' } });
    };

    static showError(message: string | { [key: string]: any }): SnackbarKey {
        if (typeof message !== "string")
            message = JSON.stringify(message);

        return AlertManager.showMessage(message, { variant: 'error', style: { whiteSpace: 'pre-line' } });
    };

    static showWarning(message: string): SnackbarKey {
        return AlertManager.showMessage(message, { variant: 'warning', style: { whiteSpace: 'pre-line' } });
    };

    static showInfo(message: string): SnackbarKey {
        return AlertManager.showMessage(message, { variant: 'info', style: { whiteSpace: 'pre-line' } });
    };

    static notImplemented() {
        AlertManager.showInfo("Not implemented!");
    };
}

export default AlertManager;