import { DataSourceTypes, DataSource } from "./types";

export function getDataSourceType(sourceType: string): DataSourceTypes {
    return sourceType as unknown as DataSourceTypes;
}

export function generateDataSourceInfo(sourceType: DataSourceTypes, value: string = "") {
    if (sourceType === DataSourceTypes.Etl)
        return {
            path: value,
        }

    if (sourceType === DataSourceTypes.MySql)
        return {
            query: value,
        }

    throw Error(`Unknown datasource: ${sourceType}`);
}

export function getDataSourceValue(source: DataSource) {
    if (source.SourceType === DataSourceTypes.Etl)
        return source.SourceInfo.path;

    if (source.SourceType === DataSourceTypes.MySql)
        return source.SourceInfo.query;

    throw Error(`Unknown datasource: ${source.SourceType}`);
}
