import { Dataset } from "../../../utils/types";
import { DatasetRow } from './BaseChart';
import BaseTabluarChart from './BaseTabluarChart';
import { Table, Paper, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import React from "react";
import html2canvas from "html2canvas";

interface IState {
    table: {
        data: DatasetRow[];
        columnKeys: string[];
        xAxisName: string;
        minValue: moment.Moment;
        maxValue: moment.Moment;
    } | undefined
}

const stickyColumnStyle: React.CSSProperties = { position: 'sticky', left: 0, background: 'white' };

class PlainTable extends BaseTabluarChart<{}, IState> {
    chartRef = React.createRef<HTMLDivElement>();

    state: IState = {
        table: undefined,
    }

    setChartOptions(all_datasets: Dataset[], all_data?: { [key: string]: { data: DatasetRow[]; index_column_values: string[]; }; }): void {
        const table = this.prepareTableData(all_datasets, all_data)

        if (!table || !table?.data)
            return;

        this.setState({ table });
    }

    getToolBox() {
        const toolbox = this.addApacheEchartsToolbox({}, {
            hideDataView: true,
            customSaveAsImage: () => {
                if (!this.chartRef.current)
                    return;

                const title = this.props.title;

                html2canvas(this.chartRef.current).then(function (canvas) {
                    var link = document.createElement('a');
                    link.download = title;
                    link.href = canvas.toDataURL()
                    link.click();
                });
            }
        });

        return (
            <ReactECharts
                echarts={echarts}
                option={toolbox}
                style={{ width: '99%', height: 40, zIndex: 1001, overflow: "visible" }}
            />
        );
    }

    render() {
        if (!this.state.table)
            return (
                <Paper style={{ width: '100%', height: '100%', display: 'inline-block' }}>
                </Paper>
            );

        const table = this.state.table;

        return (
            <Paper style={{ width: '100%', height: '100%' }}>
                {this.getToolBox()}
                <TableContainer style={{ width: '100%', height: '92%', display: 'inline-block' }} ref={this.chartRef}>
                    <Table stickyHeader style={{ width: '100%', overflow: 'scroll' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ ...stickyColumnStyle, zIndex: 1000 }} key={`th_${table.xAxisName}`}>
                                    {table.xAxisName}
                                </TableCell>
                                {table.columnKeys.filter(c => c !== table.xAxisName).map((column) => (
                                    <TableCell key={`tc_${column}`} >
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {table.data.map((row) => {
                                return (
                                    <TableRow key={`tr_${row[table.xAxisName]}`}>
                                        <TableCell style={stickyColumnStyle} key={`tc_${row[table.xAxisName]}`}>
                                            {row[table.xAxisName]}
                                        </TableCell>

                                        {table.columnKeys.filter(c => c !== table.xAxisName).map((column) => {
                                            return (
                                                <TableCell key={`tc_${row[table.xAxisName]}_${column}`}>
                                                    {row[column]?.toLocaleString()}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }
}

export default PlainTable;
