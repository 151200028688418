import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, GridSize, Icon, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { IWorkflowTimeTableRequest, IWorkflowTimeTableResponse } from '../../../apis/vitusApiTypes';
import Chart from "react-google-charts";
import { longStringWithSecondsToDate, makeTitle } from '../../../utils/common';
import { isEmpty, max, mean, min, round, sortBy, uniq } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import VCollapse from '../../../components/VCollapse/VCollapse';
import VSelect from '../../../components/VSelect/VSelect';
import { VDateTimePicker } from '../../../components/VDatePicker/VDatePicker';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import _ from 'lodash';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { ScatterChart, ScatterSeriesOption } from 'echarts/charts';

// gantt chart source:
// https://www.react-google-charts.com/
// https://www.react-google-charts.com/examples/timeline

type ECOption = echarts.ComposeOption<GridComponentOption | ScatterSeriesOption>

echarts.use(
    [ScatterChart, GridComponent]
);

class FilterType {
    key: string = "";
    title: string = "";
    statePropName: string = "";
    apply?: () => void;
    showListFilters: boolean = false;

    public constructor(init?: Partial<FilterType>) {
        Object.assign(this, init);
    }
}

class Filter {
    searchText: string = "";
    selectedTaskNames: string[] = [];
    selectedStatuses: string[] = [];
    selectedScriptServers: string[] = [];
    minStartDate?: Date;
    maxStartDate?: Date;
    minEndDate?: Date;
    maxEndDate?: Date;
    minDuration?: { durationValue: number, durationType: string, durationAsSeconds: number };
    maxDuration?: { durationValue: number, durationType: string, durationAsSeconds: number };
    selectedLogSources: string[] = [];

    [key: string]: any;

    public constructor(init?: Partial<Filter>) {
        Object.assign(this, init);
    }

    public generateNewObject(fields: { [key: string]: any }) {
        const newObject: Filter = new Filter();

        newObject.searchText = this.searchText
        newObject.selectedLogSources = this.selectedLogSources
        newObject.selectedTaskNames = this.selectedTaskNames
        newObject.selectedStatuses = this.selectedStatuses
        newObject.selectedScriptServers = this.selectedScriptServers
        newObject.minStartDate = this.minStartDate;
        newObject.maxStartDate = this.maxStartDate;
        newObject.minEndDate = this.minEndDate;
        newObject.maxEndDate = this.maxEndDate;
        newObject.minDuration = this.minDuration;
        newObject.maxDuration = this.maxDuration;

        Object.keys(fields).forEach(key => {
            newObject[key] = fields[key];
        })

        return newObject;
    }
}

interface IState {
    logs: IWorkflowTimeTableResponse["success"]["logs"],
    rowCount: number,
    screenFilters: Filter,
    requestFilters: Filter,
    currentTaskNames: string[],
    currentStatuses: string[],
    currentScriptServers: string[],
    showSummaryGenerator: boolean,
    taskSummaryInfo?: {
        selectedTaskName: string,
        totalRunCount: number,
        avgDuration: string,
        minDuration: string,
        maxDuration: string,
    }
    [key: string]: any,
}

interface IProps {
    callGetWorkflowTimeTable: (filter: IWorkflowTimeTableRequest) => void,
    logs?: IState["logs"],
    defaultStartDate: Date,
}

class Status {
    static Running = 'Running'
    static Success = 'Success'
    static Failed = 'Failed'
    static EnforceStart = 'EnforceStart'
}

const statusColors: { [key: string]: string } = {
    [Status.Running]: '#ffa500',
    [Status.Success]: '#3cb371',
    [Status.Failed]: '#ff6347',
    [Status.EnforceStart]: '#F85BE7',
}

function getStatusColor(status: string) {
    const color = statusColors[status];

    if (!color)
        return '#A9A9A9';
    return color;
}

const columns = [
    { type: "string", id: "TaskName" },
    { type: "string", id: "BarLabel" },
    { type: "string", role: "tooltip" },
    { type: 'string', role: 'style' },
    { type: "date", id: "StartDate" },
    { type: "date", id: "EndDate" },
];

export const logSources: { [key: string]: { title: string } } = {
    'vitus_geo_workflow_log': { title: 'VitusGeo.WorkFlowLog' },
    'vitus_api': { title: 'VitusApi' },
};


const filterContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 20,
};
const filterLabelStyle: CSSProperties = { paddingRight: 10 };
const filterValueStyle: CSSProperties = { width: 200, margin: 5 };

class WorkflowTimeTable extends React.Component<IProps, IState> {
    summaryChartRef: ReactECharts | null = null;
    summaryChartOptions: ECOption = {};

    state: IState = {
        logs: [] as IState["logs"],
        rowCount: 0,
        screenFilters: new Filter(),
        requestFilters: new Filter({ minStartDate: this.props.defaultStartDate }),
        currentTaskNames: [],
        currentStatuses: [],
        currentScriptServers: [],
        showSummaryGenerator: false,
    }

    ScreenFilter = new FilterType({
        key: 'Screen',
        title: 'Screen Filter',
        statePropName: 'screenFilters',
        showListFilters: true,
    })

    RequestFilter = new FilterType({
        key: 'Request',
        title: 'Request Filter',
        statePropName: 'requestFilters',
        apply: () => {
            const filters = this.state.requestFilters;

            this.setState({ taskSummaryInfo: undefined });

            this.props.callGetWorkflowTimeTable({
                min_start_date: filters.minStartDate,
                max_start_date: filters.maxStartDate,
                min_end_date: filters.minEndDate,
                max_end_date: filters.maxEndDate,
                min_duration: filters.minDuration?.durationAsSeconds,
                max_duration: filters.maxDuration?.durationAsSeconds,
                search_task_name: filters.searchText,
                status: filters.selectedStatuses,
                servers: filters.selectedScriptServers,
                log_sources: filters.selectedLogSources,
            })
        },
    })

    componentDidMount() {
        if (this.props.logs)
            this.updateLogs();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.logs !== this.props.logs)
            this.updateLogs();
    }

    beautifyDuration(diffSeconds: number) {
        let diffMinutes = 0
        let diffHours = 0

        if (diffSeconds > 60) {
            diffMinutes = Math.floor(diffSeconds / 60);

            if (diffMinutes > 60)
                diffHours = Math.floor(diffMinutes / 60);
        }

        let timeString = `${diffSeconds ? round(diffSeconds % 60, 3) : 'Less than 1'} seconds`;

        if (diffMinutes)
            timeString = `${diffMinutes % 60} minutes, ${timeString}`;

        if (diffHours)
            timeString = `${diffHours} hours, ${timeString}`;

        return timeString;
    }

    getBarTooltip(log: IState['logs'][0]) {
        return `
        <div style='white-space:nowrap; font-family: Arial; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); transition: 0.3s;'>
            <div style='padding: 5px 10px;'>
                <h4 style='margin:1px;'><b>${log.taskName}</b></h4>
                <hr/>
                <strong>Start:</strong> ${log.startDate}<br />
                <strong>End:</strong> ${log.endDate}<br />
                <strong>Duration:</strong> ${this.beautifyDuration(log.duration)}<br />
                <strong>Status:</strong> <strong><span style='color: ${getStatusColor(log.status)}'>${log.status}</span></strong><br />
                <strong>Script Server:</strong> ${log.script_server}<br />
                <strong>Log Create Date (UTC):</strong> ${log.cDate}<br />
                <strong>Log Update Date (UTC):</strong> ${log.uDate}<br />
            </div>
        </div>
        `
    }

    getRelevantLogs() {
        const searchText = this.state.screenFilters.searchText.toUpperCase();
        const screenFilters = this.state.screenFilters;

        const result = this.props.logs?.filter(log => {
            const startDate = longStringWithSecondsToDate(log.startDate);
            const endDate = longStringWithSecondsToDate(log.endDate);

            const searchMatches = !searchText || log.taskName.toString().toUpperCase().indexOf(searchText) >= 0;
            const taskNamesMatch = !screenFilters.selectedTaskNames.length || !!screenFilters.selectedTaskNames.find(selectedTaskName => selectedTaskName === log.taskName);

            const statusMatch = !screenFilters.selectedStatuses.length || !!screenFilters.selectedStatuses.find(status => status === log.status);

            const serversMatch = !screenFilters.selectedScriptServers.length || !!screenFilters.selectedScriptServers.find(server => server === log.script_server);

            const startDatesMatch = ((!screenFilters.minStartDate || !startDate || startDate >= screenFilters.minStartDate)
                && (!screenFilters.maxStartDate || !startDate || startDate <= screenFilters.maxStartDate));

            const endDatesMatch = ((!screenFilters.minEndDate || !endDate || endDate >= screenFilters.minEndDate)
                && (!screenFilters.maxEndDate || !endDate || endDate <= screenFilters.maxEndDate));

            const durationsMatch = ((!screenFilters.minDuration || log.duration >= screenFilters.minDuration.durationAsSeconds)
                && (!screenFilters.maxDuration || log.duration <= screenFilters.maxDuration.durationAsSeconds));

            const sourcesMatch = !screenFilters.selectedLogSources.length || !!screenFilters.selectedLogSources.find(source => source === log.source);

            return searchMatches
                && taskNamesMatch
                && startDatesMatch
                && endDatesMatch
                && statusMatch
                && serversMatch
                && durationsMatch
                && sourcesMatch;
        })

        if (result)
            return result;

        return [];
    }

    getChartData() {
        let chartData: any[] = [];

        if (this.props.logs?.length) {
            const relevantLogs = this.getRelevantLogs();

            const taskRunCounts = _(relevantLogs)
                .groupBy("taskName")
                .map((value, key) => (
                    {
                        taskName: key,
                        count: value.length,
                    }
                ))
                .keyBy('taskName')
                .mapValues('count')
                .value()

            const rows = relevantLogs.map(log => {
                const startDate = longStringWithSecondsToDate(log.startDate);
                const endDate = longStringWithSecondsToDate(log.endDate);

                const logHeader = `${log.taskName} (${taskRunCounts[log.taskName]} runs)`;

                return [
                    logHeader,
                    "",
                    this.getBarTooltip(log),
                    `color: ${getStatusColor(log.status)};`,
                    startDate,
                    endDate,
                ]
            })

            if (isEmpty(rows))
                return [];

            chartData = [
                columns,
                ...rows
            ]
        }

        return chartData
    }

    updateLogs() {
        const uniqueTaskCount = uniq(this.props.logs?.map(t => t.taskName))?.length;

        this.setState({
            logs: this.props.logs ?? [] as IState["logs"],
            rowCount: uniqueTaskCount + 15,
            screenFilters: new Filter(),
            currentTaskNames: uniq(this.props.logs?.map(t => t.taskName)),
            currentStatuses: Object.keys(Status),
            currentScriptServers: uniq(this.props.logs?.map(t => t.script_server)),
        });
    }

    updateFilters(filterDetails: FilterType, newValues: { [key: string]: any }) {
        this.setState({
            [filterDetails.statePropName]: this.state[filterDetails.statePropName].generateNewObject(newValues)
        })
    }

    getNewDuration(newDurationValue?: number, newDurationType?: string) {
        let newDuration: Duration | undefined = new Duration(newDurationValue, newDurationType);

        if (!newDurationValue)
            newDuration = undefined;

        return newDuration;
    }

    getFilterPanel(filterDetails: FilterType) {
        const gridItemProps: { [key: string]: any } = { xs: 3 };
        const filter: Filter = this.state[filterDetails.statePropName];
        const currentTaskNames: string[] = this.state.currentTaskNames;
        const currentStatuses: string[] = this.state.currentStatuses;
        const currentScriptServers: string[] = this.state.currentScriptServers;

        return (
            <VCollapse
                getSummary={() => Object.keys(filter)
                    .filter(key => !isEmpty(filter[key]))
                    .map(key => `${makeTitle(key)}: ${filter[key]}`).join(', ')}
                title={filterDetails['title']}
                defaultShow={false}>
                <Grid container justify='flex-start'>
                    <Grid container item xs={12}>
                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Search Task Name:
                            </Typography>
                            <FormControl style={filterValueStyle}>
                                <TextField
                                    value={filter.searchText}
                                    label="Search Task Name"
                                    variant="outlined"
                                    type="text"
                                    onChange={(newValue) => this.updateFilters(filterDetails, {
                                        searchText: newValue.target.value
                                    })}
                                    margin="dense"
                                    InputProps={{
                                        style: { padding: 0 },
                                        startAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {
                            filterDetails.showListFilters &&
                            <Grid item {...gridItemProps} style={filterContainerStyle}>
                                <Typography variant='button' style={filterLabelStyle}>
                                    Task Names:
                                </Typography>
                                <VSelect
                                    style={filterValueStyle}
                                    multiple
                                    value={filter.selectedTaskNames}
                                    title='Select Task Names'
                                    options={currentTaskNames}
                                    getOptionLabel={(option) => `${option}`}
                                    onChange={(newValue) => this.updateFilters(filterDetails, {
                                        selectedTaskNames: newValue,
                                    })
                                    }
                                />
                            </Grid>
                        }
                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Status:
                            </Typography>
                            <VSelect
                                style={filterValueStyle}
                                multiple
                                value={filter.selectedStatuses}
                                title='Select Status'
                                options={currentStatuses}
                                getOptionLabel={(option) => `${option}`}
                                onChange={(newValue) => this.updateFilters(filterDetails, {
                                    selectedStatuses: newValue,
                                })}
                            />
                        </Grid>
                        {
                            filterDetails.showListFilters &&
                            <Grid item {...gridItemProps} style={filterContainerStyle}>
                                <Typography variant='button' style={filterLabelStyle}>
                                    Server:
                                </Typography>
                                <VSelect
                                    style={filterValueStyle}
                                    multiple
                                    value={filter.selectedScriptServers}
                                    title='Select Server'
                                    options={currentScriptServers}
                                    getOptionLabel={(option) => `${option}`}
                                    onChange={(newValue) => this.updateFilters(filterDetails, {
                                        selectedScriptServers: newValue,
                                    })}
                                />
                            </Grid>
                        }

                        {
                            !filterDetails.showListFilters &&
                            <>

                                <Grid item {...gridItemProps} style={filterContainerStyle}>
                                </Grid>
                                <Grid item {...gridItemProps} style={filterContainerStyle}>
                                </Grid>
                            </>
                        }

                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Min Start Date:
                            </Typography>
                            <VDateTimePicker
                                style={filterValueStyle}
                                variant="inline"
                                margin="normal"
                                label="Min Start Date"
                                value={filter.minStartDate || null}
                                onChange={(date) => {
                                    this.updateFilters(filterDetails, {
                                        minStartDate: date?.toDate(),
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Max Start Date:
                            </Typography>
                            <VDateTimePicker
                                style={filterValueStyle}
                                variant="inline"
                                margin="normal"
                                label="Min Start Date"
                                value={filter.maxStartDate || null}
                                onChange={(date) => {
                                    this.updateFilters(filterDetails, {
                                        maxStartDate: date?.toDate(),
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Min End Date:
                            </Typography>
                            <VDateTimePicker
                                style={filterValueStyle}
                                variant="inline"
                                margin="normal"
                                label="Min End Date"
                                value={filter.minEndDate || null}
                                onChange={(date) => {
                                    this.updateFilters(filterDetails, {
                                        minEndDate: date?.toDate(),
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Max End Date:
                            </Typography>
                            <VDateTimePicker
                                style={filterValueStyle}
                                variant="inline"
                                margin="normal"
                                label="Min End Date"
                                value={filter.maxEndDate || null}
                                onChange={(date) => {
                                    this.updateFilters(filterDetails, {
                                        maxEndDate: date?.toDate(),
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Min Duration:
                            </Typography>
                            <DurationSelector
                                style={filterValueStyle}
                                durationValue={filter.minDuration?.durationValue}
                                durationType={filter.minDuration?.durationType}
                                onDurationChanged={(newDurationValue, newDurationType) => {
                                    const newDuration = this.getNewDuration(newDurationValue, newDurationType);

                                    this.updateFilters(filterDetails, {
                                        minDuration: newDuration,
                                    })
                                }
                                }
                            />
                        </Grid>

                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Max Duration:
                            </Typography>
                            <DurationSelector
                                style={filterValueStyle}
                                durationValue={filter.maxDuration?.durationValue}
                                durationType={filter.maxDuration?.durationType}
                                onDurationChanged={(newDurationValue, newDurationType) => {
                                    const newDuration = this.getNewDuration(newDurationValue, newDurationType);

                                    this.updateFilters(filterDetails, {
                                        maxDuration: newDuration,
                                    })
                                }
                                }
                            />
                        </Grid>

                        <Grid item {...gridItemProps} style={filterContainerStyle}>
                            <Typography variant='button' style={filterLabelStyle}>
                                Log Sources:
                            </Typography>
                            <VSelect
                                style={filterValueStyle}
                                multiple
                                value={filter.selectedLogSources}
                                title='Select Source'
                                options={Object.keys(logSources)}
                                getOptionLabel={(option) => logSources[option.toString()].title}
                                onChange={(newValue) => this.updateFilters(filterDetails, {
                                    selectedLogSources: newValue,
                                })}
                            />
                        </Grid>

                        <Grid item sm={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end">
                            <Button
                                style={{ height: "40" }}
                                variant="contained"
                                onClick={() => {
                                    this.setState({ [filterDetails.statePropName]: new Filter() })
                                }}>
                                Clear
                            </Button>
                            {
                                filterDetails.apply &&
                                <Button
                                    style={{ height: "40" }}
                                    variant="contained"
                                    onClick={() => filterDetails.apply && filterDetails.apply()}>
                                    Apply
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </VCollapse >
        )
    }

    memoizedChart: JSX.Element | null = null;
    chartParams: {
        logs?: IState['logs'],
        screenFilters?: IState['screenFilters'],
    } = {}

    getChart() {
        if (!this.memoizedChart
            || this.chartParams.logs !== this.state.logs
            || this.chartParams.screenFilters !== this.state.screenFilters) {
            const chartData = this.getChartData();

            this.memoizedChart = isEmpty(chartData) ? null :
                (
                    <>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography variant='h6'>
                                    Workflow Logs
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => this.setState({ showSummaryGenerator: true })}
                                    color="primary"
                                    variant="outlined">
                                    Summary Generator
                                </Button>
                            </Grid>
                        </Grid>
                        <Chart chartType="Timeline"
                            width="100%"
                            height="100%"
                            data={chartData}
                            options={{
                                allowHtml: true,
                                avoidOverlappingGridLines: true,
                                hAxis: {
                                    format: 'dd.MM.yyyy HH:mm'
                                }
                            }}
                        />
                    </>
                );

            this.chartParams.logs = this.state.logs;
            this.chartParams.screenFilters = this.state.screenFilters;
        }

        return this.memoizedChart;
    }

    updateSummaryRunDatesChart(relatedLogs: IState["logs"]) {
        const chartInstance = this.summaryChartRef?.getEchartsInstance();
        chartInstance.showLoading();
        chartInstance.clear();

        const dailyRunDates = _(relatedLogs)
            .groupBy(row => row.startDate.split(" ")[0])
            .map((value, key) => (
                {
                    startDay: key,
                    startDateTimes: value.map(row => [row.startDate.split(" ")[1], key]),
                }
            ))
            .value()

        const xAxisData = sortBy([
            '00:00:00',
            ...dailyRunDates.flatMap(dayData =>
                dayData.startDateTimes.map(runInstance => runInstance[0])),
            '23:59:59',
        ])

        chartInstance.setOption({
            legend: {},
            animation: false,
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: 80,
                right: 70,
                bottom: 60,
                top: 45,
            },
            xAxis: {
                type: 'category',
                data: xAxisData,
            },
            yAxis: {
                type: 'category',
            },
            series: dailyRunDates.map(runDays => ({
                type: 'scatter',
                symbolSize: 3,
                name: runDays.startDay,
                data: runDays.startDateTimes,
            })),
        });

        chartInstance.hideLoading();
    }

    updateSummary(selectedTaskName: string) {
        if (!selectedTaskName)
            return;

        const relatedLogs = this.getRelevantLogs().filter(row => row.taskName === selectedTaskName);

        const totalRunCount = relatedLogs.length;
        const durations = relatedLogs.map(row => row.duration);
        const avgDuration = this.beautifyDuration(mean(durations));
        const minDuration = this.beautifyDuration(min(durations) ?? 0);
        const maxDuration = this.beautifyDuration(max(durations) ?? 0);

        this.updateSummaryRunDatesChart(relatedLogs)

        this.setState({
            taskSummaryInfo: {
                selectedTaskName,
                totalRunCount,
                avgDuration,
                minDuration,
                maxDuration,
            }
        })
    }

    getValueText(value?: number | string) {
        return (value || value === 0) ? value : "-";
    }

    getSummaryGeneratorDialog() {
        const taskSummaryInfo = this.state.taskSummaryInfo;
        const currentTaskNames = this.state.currentTaskNames;
        const labelColumnCount: GridSize = 3;
        const valueColumnCount: GridSize = 9;

        return (
            <Dialog open={this.state.showSummaryGenerator}
                maxWidth='sm'
                onClose={() => this.setState({ showSummaryGenerator: false, taskSummaryInfo: undefined })}>
                <DialogTitle style={{ padding: 10 }}>Task Summary</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <VSelect
                                disableClearable
                                value={taskSummaryInfo?.selectedTaskName}
                                title='Select Task Name'
                                options={currentTaskNames}
                                getOptionLabel={(option) => `${option}`}
                                onChange={(newValue) => this.updateSummary(newValue?.toString() || "")}
                            />
                        </Grid>
                        <Grid item xs={labelColumnCount}>
                            <Typography style={filterLabelStyle}>
                                Total Run Count:
                            </Typography>
                        </Grid>
                        <Grid item xs={valueColumnCount}>
                            <Typography variant='button'>
                                {this.getValueText(taskSummaryInfo?.totalRunCount)}
                            </Typography>
                        </Grid>
                        <Grid item xs={labelColumnCount}>
                            <Typography style={filterLabelStyle}>
                                Average Duration:
                            </Typography>
                        </Grid>
                        <Grid item xs={valueColumnCount}>
                            <Typography variant='button'>
                                {this.getValueText(taskSummaryInfo?.avgDuration)}
                            </Typography>
                        </Grid>
                        <Grid item xs={labelColumnCount}>
                            <Typography style={filterLabelStyle}>
                                Max Duration:
                            </Typography>
                        </Grid>
                        <Grid item xs={valueColumnCount}>
                            <Typography variant='button'>
                                {this.getValueText(taskSummaryInfo?.maxDuration)}
                            </Typography>
                        </Grid>
                        <Grid item xs={labelColumnCount}>
                            <Typography style={filterLabelStyle}>
                                Min Duration:
                            </Typography>
                        </Grid>
                        <Grid item xs={valueColumnCount}>
                            <Typography variant='button'>
                                {this.getValueText(taskSummaryInfo?.minDuration)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='button'>
                                Start Hours:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactECharts
                                ref={(e) => { this.summaryChartRef = e; }}
                                echarts={echarts}
                                option={this.summaryChartOptions}
                                notMerge={true}
                                lazyUpdate={true}
                                style={{ width: '99%' }}
                            />
                        </Grid>
                    </ Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary"
                        onClick={() => this.setState({ showSummaryGenerator: false })}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const rowCount = this.state.rowCount;

        return (
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    {this.getFilterPanel(this.RequestFilter)}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 5 }}>
                    {this.getFilterPanel(this.ScreenFilter)}
                </Grid>
                <Grid item xs={12} style={{ height: 40 * (rowCount + 2), paddingTop: 20 }}>
                    {this.getChart()}
                    {
                        (!this.state.logs?.length) &&
                        <Typography>
                            Nothing to show
                        </Typography>
                    }
                </Grid>
                <Grid item>
                    {this.getSummaryGeneratorDialog()}
                </Grid>
            </Grid >
        );
    }
}

class DurationTypes {
    static Seconds = 'Seconds';
    static Minutes = 'Minutes';
    static Hours = 'Hours';
    static Days = 'Days';
}

class Duration {
    durationValue?: number;
    durationType?: string;
    durationAsSeconds?: number;

    public constructor(durationValue?: number, durationType?: string) {
        this.durationValue = durationValue;
        this.durationType = durationType;

        if (durationValue || durationValue === 0) {
            if (!durationType) { // durationValue can not be used without durationType
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                return;
            }

            this.durationAsSeconds = DurationUtils.convertToSeconds(durationValue, durationType);
        }
    }

    public toString = (): string => {
        return `${this.durationValue} ${this.durationType}`;
    }
}

class DurationUtils {
    static convertToSeconds(durationValue: number, durationType: string) {
        let newDurationValue: number = durationValue;

        switch (durationType) {
            case DurationTypes.Minutes:
                newDurationValue = durationValue * 60;
                break;
            case DurationTypes.Hours:
                newDurationValue = durationValue * 60 * 60;
                break;
            case DurationTypes.Days:
                newDurationValue = durationValue * 60 * 60 * 24;
                break;
        }

        return newDurationValue
    }
}

interface IDurationProps {
    style?: CSSProperties,
    durationValue?: number,
    durationType?: string,
    onDurationChanged?: (newDurationValue?: number, newDurationType?: string) => void,
}

const DurationSelector: React.FC<IDurationProps> = (props: IDurationProps) => {
    const [durationValue, setDurationValue] = React.useState(props.durationValue);
    const [durationType, setDurationType] = React.useState(props.durationType || DurationTypes.Seconds);

    React.useEffect(() => {
        setDurationValue(props.durationValue);
    }, [props.durationValue])

    React.useEffect(() => {
        setDurationType(props.durationType || DurationTypes.Seconds);
    }, [props.durationType])

    const callOnDurationChanged = (newDurationValue?: number, newDurationType?: string) => {
        setDurationValue(props.durationValue);
        setDurationType(props.durationType || DurationTypes.Seconds);

        if (!props?.onDurationChanged)
            return;

        props.onDurationChanged(newDurationValue, newDurationType);
    }

    const clear = () => {
        callOnDurationChanged();
    }

    return (
        <div style={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            minWidth: 210,
            ...props.style
        }}>
            <FormControl style={{ width: 40 }}>
                <InputLabel htmlFor='duration_input' shrink>Duration</InputLabel>
                <Input
                    id='duration_input'
                    value={(durationValue || durationValue === 0) ? durationValue : ''}
                    type="number"
                    onWheel={event => { event.preventDefault(); }}
                    onChange={(newValue) => {
                        callOnDurationChanged(+newValue.target.value, durationType);
                    }}
                />
            </FormControl>
            <FormControl style={{ paddingLeft: 5 }}>
                <VSelect
                    title="Type"
                    disableClearable
                    value={durationType || ''}
                    options={Object.keys(DurationTypes)}
                    getOptionLabel={(option) => `${option}`}
                    onChange={(newValue) => {
                        const val = newValue?.toString();

                        if (val)
                            callOnDurationChanged(durationValue, val);
                    }}
                />
            </FormControl>
            <IconButton
                style={{ padding: "3px 3px 0 3px" }}
                onClick={() => clear()} >
                <Icon fontSize="small" className="fas fa-times" />
            </IconButton>
        </div>
    )
}

export default WorkflowTimeTable;