import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface IProps {
    show: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1
        },
    }),
);

const VSpinner: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    return (
        <Backdrop style={{ zIndex: 99999 }} className={classes.backdrop} open={props.show}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default VSpinner;
