import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { isEmpty, orderBy } from 'lodash';
import { generateUuid } from '../../utils/common';
import { InputLabel, Select, Input, MenuItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: 130
        },
        menuItem: {
            padding: 0
        }
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
        },
    },
    getContentAnchorEl: null,
    disableScrollLock: true,
};

export type ItemsDict = { [key: string]: boolean };
export type CheckKey = string | number;

export function createCheckboxGroup(title: string, checkType: string, dataSource: CheckKey[], selectionSource: CheckKey[],
    handleFilterChange: (checkType: string, items: ItemsDict) => void,
    multiSelect: boolean = true, keyType?: IProps["keyType"], sort?: IProps["sort"]): React.ReactElement {
    return (
        <VCheckBoxGroup
            title={title}
            disabled={isEmpty(dataSource)}
            onCheckChanged={(items) => handleFilterChange(checkType, items)}
            keyType={keyType}
            sort={sort}
            multiSelect={multiSelect}
            items={{
                ...dataSource
                    .reduce((dict: { [key in CheckKey]: boolean }, dataKey: string | number, index: number) => { dict[dataKey] = false; return dict; }, {}),
                ...selectionSource.filter(sr => dataSource.find(dr => sr === dr))
                    .reduce((dict: { [key in CheckKey]: boolean }, dataKey: string | number, index: number) => { dict[dataKey] = true; return dict; }, {})
            }}
        />
    )
}

interface IProps {
    title?: string,
    items: ItemsDict | string[],
    sort?: "asc" | "desc" | ((key: string | number) => number),
    keyType?: "string" | "number",
    width?: number,
    onCheckChanged?: (items: ItemsDict) => void,
    disabled?: boolean,
    multiSelect?: boolean,
}

const selectAllItem = { key: "All", title: "Select All" };

const VCheckBoxGroup: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    const checkId = generateUuid();

    let multiSelect = props.multiSelect;
    if (multiSelect === undefined)
        multiSelect = true;

    let itemObj: ItemsDict;

    if (Array.isArray(props.items))
        itemObj = props.items.reduce((map: ItemsDict, key) => { map[key] = false; return map; }, {});
    else
        itemObj = props.items;

    const sort = props.sort || "asc";
    const keyType = props.keyType || "string";
    const caster = keyType === "string" ? (k: string) => k : (k: string) => +k;

    const allSelected = () => { return Object.keys(itemObj).every(k => itemObj[k]); }

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
        if (props.onCheckChanged) {
            const changedElement = (child as React.ReactElement).props.value;

            if (changedElement === selectAllItem.key) {
                const newValue = !allSelected();

                Object.keys(itemObj).forEach(k => itemObj[k] = newValue);

                props.onCheckChanged({ ...itemObj });
            }
            else {
                let newItemObj = {};

                if (multiSelect)
                    newItemObj = { ...itemObj, [changedElement]: !itemObj[changedElement] };
                else
                    newItemObj = { [changedElement]: !itemObj[changedElement] }

                props.onCheckChanged(newItemObj);
            }
        }
    };

    const castedElements = Object.keys(itemObj).map(k => caster(k));
    const keys = (typeof sort === "function") ?
        orderBy(castedElements, [(item) => sort(item)], ["asc"])
        :
        orderBy(castedElements, [], [sort]);

    return (
        <div>
            <FormControl id={`${checkId}_form`} className={classes.formControl}>
                <InputLabel id={`${checkId}_input`}>{props.title}</InputLabel>
                <Select
                    labelId={`${checkId}_input`}
                    id={`${checkId}_select`}
                    multiple={multiSelect}
                    value={keys.filter(key => itemObj[key.toString()])}
                    input={<Input />}
                    onChange={handleChange}
                    renderValue={
                        (selected) => {
                            const selectedElements: string[] = (selected as string[]);

                            if (selectedElements.length === 1)
                                return selectedElements[0];
                            if (selectedElements.length > 1)
                                return "Multiple selection";
                            return "";
                        }}
                    MenuProps={MenuProps}>
                    {multiSelect &&
                        <MenuItem key={`${checkId}_${selectAllItem.key}`}
                            value={selectAllItem.key}
                            className={classes.menuItem}
                            id={`${checkId}_${selectAllItem.key}`}>
                            <Checkbox
                                disabled={props.disabled}
                                checked={allSelected()}
                                name={selectAllItem.key} />
                            <ListItemText primary={selectAllItem.title} />
                        </MenuItem>
                    }
                    {keys.map(key => {
                        key = key.toString();

                        return (
                            <MenuItem key={key} value={key} className={classes.menuItem}>
                                <Checkbox
                                    disabled={props.disabled}
                                    checked={itemObj[key]}
                                    name={key} />
                                <ListItemText primary={key} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

export default VCheckBoxGroup;