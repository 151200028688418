import React from 'react';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { HeatmapChart, HeatmapSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { getRealizedPowerSupplyDemand } from '../../../apis/vitusApi';
import { isEmpty, orderBy } from 'lodash';
import { Button, Grid, GridSize, Typography } from '@material-ui/core';
import { createCheckboxGroup, ItemsDict } from '../../../components/VCheckBoxGroup/VCheckBoxGroup';
import { createSpinner } from '../../../utils/spinnerManager';
import VPageFilter from '../../../components/VPageFilter/VPageFilter';
import { IPowerSupplyDemandResponse } from '../../../apis/vitusApiTypes';
import VChart from '../../../components/VChart/VChart';
import { getPropertyName, handleApiError } from '../../../utils/common';
import VRadioButtonGroup from '../../../components/VRadioButtonGroup/VRadioButtonGroup';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption | HeatmapSeriesOption>;

echarts.use(
    [LineChart, HeatmapChart, GridComponent]
);

const PERIODS = ["Daily", "Monthly"];

type PSDData = { [key: string]: any }[];

type ValueType = "value" | "maxValue";

type PSDDataFilter = {
    period: string,
    countries: string[],
    fuel_types: string[],
    years: string[],
    months: string[],
    days: string[],
    value_type: ValueType,
    [key: string]: any
}

interface IProps {
}

interface IState {
    originalData: PSDData,
    selectedFilter: PSDDataFilter,
    activeFilter: PSDDataFilter,
    countries: string[],
    fuelTypes: string[],
    groupKeys: string[],
    totalRowCount: number,
    minValue: number,
    maxValue: number,
    pageReady: boolean,
}

const DATA_START_YEAR = 1996;
const DATA_END_YEAR = (new Date()).getFullYear();
const All_YEARS = Array.from({ length: DATA_END_YEAR - DATA_START_YEAR + 1 }, (value, key) => DATA_END_YEAR - key).map(k => k.toString());
const ALL_MONTHS = Array.from({ length: 12 }, (value, key) => key + 1).map(k => k.toString());
const ALL_DAYS = Array.from({ length: 31 }, (value, key) => key + 1).map(k => k.toString());

class PSDRealized extends React.Component<IProps, IState> {
    psdLineChartRef: ReactECharts | null = null;
    psdHeatMapRef: ReactECharts | null = null;

    initialFilter = {
        period: PERIODS[0],
        countries: [],
        fuel_types: [],
        years: ["2021"],
        months: [],
        days: [],
        value_type: "value" as ValueType
    };

    state: IState = {
        originalData: [],
        countries: [],
        fuelTypes: [],
        groupKeys: [],
        selectedFilter: { ...this.initialFilter },
        activeFilter: { ...this.initialFilter },
        totalRowCount: 0,
        minValue: 1,
        maxValue: 1,
        pageReady: false,
    }

    lineOptions: ECOption = {
        animation: false,
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
        },
        series: [{
            data: [],
            type: 'line'
        }],
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'valueDesc'
        },
        dataZoom: [{}],
    }

    heatMapOptions: ECOption = {
        animation: false,
        tooltip: {
            position: 'top',
        },
        xAxis: {
            type: 'category',
            position: 'top',
            axisTick: {
                interval: 0
            },
            splitArea: {
                show: true
            },
            axisLabel: {
                rotate: -45
            }
        },
        yAxis: {
            type: 'category',
            splitArea: {
                show: true
            },
            axisTick: {
                interval: 0
            },
        }
    };

    componentDidMount() {
        this.getData();
    }

    getData() {
        const spinner = createSpinner();

        let years: string[] = this.state.activeFilter.years;

        if (isEmpty(years))
            years = All_YEARS;

        return getRealizedPowerSupplyDemand({ ...this.state.activeFilter, years }).then(response => {
            if (response.data.success)
                this.storeData(response.data.success);

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            this.setState({ pageReady: true });
            spinner.hide()
        });
    }

    renderCharts() {
        return this.state.originalData && !isEmpty(this.state.originalData);
    }

    updateCharts() {
        if (!this.renderCharts())
            return;

        this.updateHeatMap();
        this.updateLineChart();
    }

    storeData(response: IPowerSupplyDemandResponse["success"]) {
        this.setState({
            countries: response.countries || [],
            fuelTypes: response.fuel_types || [],
            groupKeys: response.group_keys || [],
            originalData: response.original_data || [],
            totalRowCount: response.total_row_count,
            minValue: response.min_value,
            maxValue: response.max_value,
        }, this.updateCharts);
    }

    updateLineChart = () => {
        const psdChartInstance = this.psdLineChartRef?.getEchartsInstance();
        psdChartInstance.showLoading();

        const dataSource = this.state.originalData;

        psdChartInstance.clear();
        psdChartInstance.setOption({
            ...this.lineOptions,
            series: this.state.groupKeys.map(key => {
                return {
                    type: 'line',
                    name: key,
                    data: dataSource.map(row => {
                        return [row.Date, row[key]]
                    })
                }
            }),
            legend: {
                type: 'scroll',
                data: this.state.groupKeys
            }
        });

        psdChartInstance.hideLoading();
    }

    updateHeatMap = () => {
        const psdHeatMapInstance = this.psdHeatMapRef?.getEchartsInstance();
        psdHeatMapInstance.showLoading();

        const data = this.state.originalData;
        const headers = orderBy(this.state.groupKeys);
        const minValue = this.state.minValue;
        const maxValue = this.state.maxValue;

        psdHeatMapInstance.clear();
        psdHeatMapInstance.setOption({
            ...this.heatMapOptions,
            xAxis: {
                ...this.heatMapOptions.xAxis,
                data: headers,
            },
            yAxis: {
                ...this.heatMapOptions.yAxis,
                data: data.map(d => d.Date)
            },
            series: [{
                type: 'heatmap',
                data: data.flatMap(d => Object.keys(d).map(key => [key, d.Date, d[key]])),
                label: {
                    show: true
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                itemStyle: {
                    borderWidth: 3,
                    borderColor: '#fff'
                }
            }],
            visualMap: {
                min: minValue,
                max: maxValue,
                calculable: true,
                orient: 'horizontal',
                itemWidth: 10,
                itemHeight: 70,
                inRange: {
                    color: ['#208E0E', '#9EB09B', '#DA4848']
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    id: 'insideX',
                    xAxisIndex: 0,
                    zoomLock: true,
                    start: 0,
                    end: 20,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: false,
                    moveOnMouseWheel: false,
                    brushSelect: false,
                },
                {
                    type: 'slider',
                    xAxisIndex: 0,
                    zoomLock: true,
                    start: 0,
                    end: 20,
                    height: 10,
                    handleSize: 0,
                    showDetail: false,
                    brushSelect: false,
                },
                {
                    type: 'inside',
                    id: 'insideY',
                    yAxisIndex: 0,
                    zoomLock: true,
                    startValue: data.length - 15,
                    endValue: data.length,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true,
                    brushSelect: false,
                },
                {
                    type: 'slider',
                    yAxisIndex: 0,
                    zoomLock: true,
                    width: 10,
                    right: 10,
                    startValue: data.length - 15,
                    endValue: data.length,
                    handleSize: 0,
                    showDetail: false,
                    brushSelect: false,
                }
            ],
        });

        psdHeatMapInstance.hideLoading();
    }

    handleFilterChange(checkType: string, items: ItemsDict) {
        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                [checkType]: Object.keys(items).filter(k => items[k])
            }
        });
    }

    handleSingleSelectCheckChange(checkType: string, items: ItemsDict) {
        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                [checkType]: Object.keys(items).filter(k => items[k])[0]
            }
        })
    }

    updateActiveFilters() {
        this.setState({
            activeFilter: { ...this.state.selectedFilter }
        }, () => {
            this.getData();
        });
    }

    clearFilters() {
        this.setState({
            selectedFilter: { ...this.initialFilter }
        });
    }

    render() {
        const filterInputProps: { [key: string]: GridSize } = { lg: 3, md: 4, sm: 12 };
        const filterActionProps: { [key: string]: GridSize } = { sm: 12 };

        return (
            <Grid container spacing={3} justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <VPageFilter
                        showHide
                        getActiveFilter={() => this.state.activeFilter}>
                        <Grid item {...filterInputProps}>
                            {
                                createCheckboxGroup("Period",
                                    getPropertyName<PSDDataFilter>().period,
                                    PERIODS,
                                    [this.state.selectedFilter.period],
                                    (...args) => this.handleSingleSelectCheckChange(...args),
                                    false)
                            }
                        </Grid>
                        <Grid item {...filterInputProps}>
                            {
                                createCheckboxGroup("Country",
                                    getPropertyName<PSDDataFilter>().countries,
                                    this.state.countries,
                                    this.state.selectedFilter.countries,
                                    (...args) => this.handleFilterChange(...args))
                            }
                        </Grid>
                        <Grid item {...filterInputProps}>
                            {
                                createCheckboxGroup("Fuel Type",
                                    getPropertyName<PSDDataFilter>().fuel_types,
                                    this.state.fuelTypes,
                                    this.state.selectedFilter.fuel_types,
                                    (...args) => this.handleFilterChange(...args))
                            }
                        </Grid>
                        <Grid item {...filterInputProps}>
                            <VRadioButtonGroup
                                defaultValue={this.state.selectedFilter.value_type}
                                onChange={(newValue) => this.setState({
                                    selectedFilter: {
                                        ...this.state.selectedFilter,
                                        value_type: newValue as ValueType
                                    }
                                })}
                                options={[
                                    { value: "value", title: "Value" },
                                    { value: "maxValue", title: "Max Value" },
                                ]} />
                        </Grid>
                        <Grid item >
                        </Grid>
                        <Grid item {...filterInputProps}>
                            {
                                createCheckboxGroup("Year",
                                    getPropertyName<PSDDataFilter>().years,
                                    All_YEARS,
                                    this.state.selectedFilter.years,
                                    (...args) => this.handleFilterChange(...args),
                                    true, "number", "desc")
                            }
                        </Grid>
                        <Grid item {...filterInputProps}>
                            {
                                createCheckboxGroup("Month",
                                    getPropertyName<PSDDataFilter>().months,
                                    ALL_MONTHS,
                                    this.state.selectedFilter.months,
                                    (...args) => this.handleFilterChange(...args),
                                    true, "number")
                            }
                        </Grid>
                        <Grid item {...filterInputProps}>
                            {
                                createCheckboxGroup("Day",
                                    getPropertyName<PSDDataFilter>().days,
                                    ALL_DAYS,
                                    this.state.selectedFilter.days,
                                    (...args) => this.handleFilterChange(...args),
                                    true, "number")
                            }
                        </Grid>
                        <Grid item
                            {...filterActionProps}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-end">
                            <Button
                                style={{ height: "40" }}
                                variant="contained"
                                onClick={() => this.clearFilters()}>
                                Clear
                            </Button>
                            <Button
                                style={{ height: "40" }}
                                variant="contained"
                                onClick={() => this.updateActiveFilters()}>
                                Apply
                            </Button>
                        </Grid>
                    </VPageFilter>
                </Grid>
                {
                    this.renderCharts() &&
                    <Grid item xs={12} >
                        <VChart
                            height={350}
                            report={
                                <ReactECharts
                                    ref={(e) => { this.psdLineChartRef = e; }}
                                    echarts={echarts}
                                    option={this.lineOptions}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ width: '99%' }}
                                />
                            } >
                        </VChart>
                    </Grid>
                }
                {
                    this.renderCharts() &&
                    <Grid item xs={12}>
                        <VChart
                            height={330}
                            report={
                                <ReactECharts
                                    ref={(e) => { this.psdHeatMapRef = e; }}
                                    echarts={echarts}
                                    option={this.heatMapOptions}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ width: '99%' }}
                                />
                            } />
                    </Grid>
                }
                {
                    this.state.pageReady && !this.renderCharts() &&
                    <Grid item>
                        <Typography>
                            Data not found!
                        </Typography>
                    </Grid>
                }
            </Grid>
        );
    }
}

export default PSDRealized;