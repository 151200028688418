import ReportViewer from '../../ReportViewer/ReportViewer';
import { ReportParams, ReportView } from '../../../system/ReportBase';
import { getForecastReportData, getForecastReportParams } from '../../../apis/vitusApi';
import _, { mean, sortBy, uniq } from 'lodash';
import { Button, Grid, GridSize, IconButton, Icon, Typography, List, ListItem, ListItemText, CircularProgress } from '@material-ui/core';
import { createSpinner } from '../../../utils/spinnerManager';
import VPageFilter from '../../../components/VPageFilter/VPageFilter';
import { handleApiError, makeTitle, toLongDateString, toShortDateString } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import messages from '../../../utils/messages';
import { IForecastReportDataResponse, IForecastReportParamsResponse } from '../../../apis/vitusApiTypes';
import VSelect from '../../../components/VSelect/VSelect';
import VDatePicker, { VDateTimePicker } from '../../../components/VDatePicker/VDatePicker';
import moment from 'moment';
import VerticalTabs, { VerticalTab } from '../../../components/VTabs/VTabs';
import { styles } from '../../../utils/styles';
import FRTabularView from './FRTabularView';
import FRChartsView, { IFRChartsViewProps } from './FRChartsView';
import VBox from '../../../components/VBox/VBox';
import { VSimpleDialog } from '../../../dialogs/VSimpleDialog/VSimpleDialog';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageManager';
import { getDate } from './forecastReportUtils';
import ExcelDownloadButton from '../../../components/ExcelDownloadButton/ExcelDownloadButton';
import FRRealTimeForecastTable from './FRRealTimeForecastTable';

const knownIrregularCountries = {
    noWind: ['Slovakia', 'Slovenia', 'Switzerland'],
    noSolar: ['Bosnia', 'Croatia', 'Estonia', 'Finland', 'Ireland', 'Lithuania', 'Macedonia', 'Norway', 'Serbia', 'Sweden'],
}

//value => lowercase without spaces
//add other fuelTypes if needed
const fuelTypes = {
    residualLoad: 'residualload',
}

const halfHourInMilliseconds = 1800000;

type Filter = {
    source: string,
    country: string,
    data_type: string,
    min_forecast_date?: Date,
    max_forecast_date?: Date,
    min_data_date?: Date,
    max_data_date?: Date,
    time_zone?: string,
}

interface IState {
    forecast_params: IForecastReportParamsResponse["success"]["params"],
    activeFilter: Filter,
    selectedFilter: Filter,
    countrieNames: string[],
    fuelTypeNames: string[],
    countries: { [name: string]: number },
    fuelTypes: { [name: string]: { id: number, forecast_type: string } },
    sources: { [country_name: string]: { [forecast_type: string]: string[] } },
    forecasts: IForecastReportDataResponse["success"]["forecasts"],
    used_dates?: {
        [forecastDate: string]: {
            ForecastedDateTime: Date,
            WindForecastedDateTime?: Date,
            SolarForecastedDateTime?: Date,
            ForecastedDateTimeCount?: number,
            WindForecastedDateTimeCount?: number,
            SolarForecastedDateTimeCount?: number,
        }
    },
    activeTab: number,
    listedDataType?: string, // this the latest successfully requested data type, may be different from active filter
    forecastWarnings?: IFRChartsViewProps["warnings"],
    forecastWarningSummary?: string,
    forecastInfoSummary?: string,
    showSummaryInfoDialog: boolean,
    lastRefresh?: Date,
    lastRefreshResult?: boolean,
}

class ForecastReport extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "FORECAST_REPORT",
            name: "Forecast Report",
            path: "/forecastReport",
            thumbnail: ""
        }
    );

    defaultInitialRowCount = 72;

    getDate(dayDiff: number) {
        const customDate = new Date();
        customDate.setDate(new Date().getDate() + dayDiff);
        customDate.setHours(0, 0, 0, 0);
        return customDate;
    }

    localFilter = getLocalStorage(ForecastReport.params.reportKey, "filter", "mainFilter");

    initialFilter: Filter = {
        source: this.localFilter?.source || 'meteologica', // get if local filters available
        country: this.localFilter?.country || 'Germany',
        data_type: this.localFilter?.data_type || 'Wind',
        time_zone: this.localFilter?.time_zone || 'CET',
        ...{
            // get dates from local filter only if they are saved today
            min_forecast_date: this.getDate(-3),
            max_forecast_date: this.getDate(1),
            min_data_date: this.getDate(-1),
            max_data_date: this.getDate(14),
            ...{ ...(this.localFilter?.[this.getLocalStorageDateTag()] || {}) }
        }
    };

    state: IState = {
        forecast_params: [],
        selectedFilter: { ...this.initialFilter },
        activeFilter: { ...this.initialFilter },
        countries: { "Austria": 40, "Belgium": 56, "Bulgaria": 100, "Croatia": 191, "Czech Republic": 203, "Denmark": 208, "Estonia": 233, "Finland": 246, "France": 250, "Germany": 276, "Greece": 300, "Hungary": 348, "Ireland": 372, "Italy": 380, "Lithuania": 440, "Netherlands": 528, "Norway": 578, "Poland": 616, "Portugal": 620, "Romania": 642, "Serbia": 688, "Slovakia": 703, "Slovenia": 705, "Spain": 724, "Sweden": 752, "Switzerland": 756, "Turkey": 792, "United Kingdom": 826, "Bosnia": 70, "Macedonia": 807 },
        fuelTypes: { "Wind": { "id": 5, "forecast_type": "Production" }, "Ror": { "id": 6, "forecast_type": "Production" }, "Solar": { "id": 8, "forecast_type": "Production" }, "Hydro": { "id": 25, "forecast_type": "Production" }, "WindOffshore": { "id": 29, "forecast_type": "Production" }, "WindPotential": { "id": 42, "forecast_type": "Production" }, "Dam": { "id": 1, "forecast_type": "Production" }, "SolarLicensed": { "id": 43, "forecast_type": "Production" }, "SolarUnlicensed": { "id": 44, "forecast_type": "Production" }, "Demand": { "id": -1, "forecast_type": "Consumption" }, "ResidualLoad": { "id": -1, "forecast_type": "Residual_Load" } },
        countrieNames: [],
        fuelTypeNames: [],
        sources: {},
        forecasts: [],
        activeTab: 0,
        showSummaryInfoDialog: false,
    }

    componentDidMount() {
        const spinner = createSpinner();

        getForecastReportParams().then(response => {
            if (response.data.success) {
                const dataShown = this.firstRequestAvailable();

                this.setState({
                    forecast_params: response.data.success.params,
                    countrieNames: _(response.data.success.params.map(f => f.CountryName)).uniq().sortBy().value(),
                    fuelTypeNames: _(response.data.success.params.map(f => f.FuelType)).uniq().sortBy().value(),
                    sources: response.data.success.params.reduce((map: IState["sources"], row) => {
                        map[row.CountryName] = response.data.success.params.filter(r => r.CountryName === row.CountryName)
                            .reduce((sub_map: { [name: string]: string[] }, sub_row) => {
                                if (!sub_map[sub_row.FuelType])
                                    sub_map[sub_row.FuelType] = []
                                sub_map[sub_row.FuelType].push(sub_row.Source);
                                return sub_map;
                            }, {});
                        return map;
                    }, {}),
                    countries: response.data.success.params.reduce((map: { [name: string]: number }, row) => {
                        map[row.CountryName] = row.CountryId;
                        return map;
                    }, {}),
                    fuelTypes: response.data.success.params.reduce((map: IState["fuelTypes"], row) => {
                        map[row.FuelType] = { id: row.FuelTypeId, forecast_type: row.ForecastType };
                        return map;
                    }, {}),
                }, () => { if (!dataShown) this.getData() })
            }

            if (response.data.error)
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });

        this.getData();
    }

    validateFilters() {
        if (this.state.activeFilter.min_forecast_date
            && this.state.activeFilter.max_forecast_date
            && this.state.activeFilter.min_forecast_date > this.state.activeFilter.max_forecast_date) {
            AlertManager.showError('Min forecast date must be smaller or equal max forecast date.');
            return false;
        }

        if (this.state.activeFilter.min_data_date
            && this.state.activeFilter.max_data_date
            && this.state.activeFilter.min_data_date > this.state.activeFilter.max_data_date) {
            AlertManager.showError('Min data date must be smaller or equal max data date.');
            return false;
        }

        if ((!this.state.activeFilter.min_forecast_date || !this.state.activeFilter.max_forecast_date)
            && (!this.state.activeFilter.min_data_date || !this.state.activeFilter.max_data_date)) {
            AlertManager.showError('Please either specify both forecast date boundaries or both data date boundaries.');
            return false;
        }

        return true;
    }

    firstRequestAvailable() {
        return this.state.countries[this.state.activeFilter.country] && this.state.fuelTypes[this.state.activeFilter.data_type];
    }

    getData(filter?: IState["activeFilter"]) {
        if (!this.validateFilters())
            return;

        if (!this.firstRequestAvailable())
            return;

        AlertManager.showMessage('Refreshing data', {
            variant: 'info',
            style: { whiteSpace: 'pre-line', },
            action: (<CircularProgress color="inherit" size={20} />)
        })

        this.setState({ lastRefresh: new Date(), lastRefreshResult: undefined });

        if (!filter)
            filter = this.state.activeFilter;

        getForecastReportData({
            source: filter.source,
            country: this.state.countries[filter.country],
            fuel_type: this.state.fuelTypes[filter.data_type].id,
            forecast_type: this.state.fuelTypes[filter.data_type].forecast_type,
            min_forecast_date: filter.min_forecast_date ? moment(filter.min_forecast_date).format('yyyy/MM/DD 00:00:00') : filter.min_forecast_date,
            max_forecast_date: filter.max_forecast_date ? moment(filter.max_forecast_date).format('yyyy/MM/DD 23:59:59') : filter.max_forecast_date,
            min_data_date: filter.min_data_date ? moment(filter.min_data_date).format('yyyy/MM/DD HH:mm:00') : filter.min_data_date,
            max_data_date: filter.max_data_date ? moment(filter.max_data_date).format('yyyy/MM/DD HH:mm:59') : filter.max_data_date,
        }).then(response => {
            if (response.data.success) {
                if (!response.data.success.forecasts?.length)
                    AlertManager.showWarning('There is no data with these filters.'); //TODO: message

                const used_dates = !response.data.success.used_dates ? undefined : response.data.success.used_dates?.reduce((map: IState["used_dates"], row) => {
                    if (!map)
                        map = {};

                    const wind = moment(row.WindForecastedDateTime, 'yyyy/MM/DD HH:mm:00');
                    const solar = moment(row.SolarForecastedDateTime, 'yyyy/MM/DD HH:mm:00');

                    map[row.ForecastedDateTime] = {
                        ...row,
                        ForecastedDateTime: moment(row.ForecastedDateTime, 'yyyy/MM/DD HH:mm:00').toDate(),
                        WindForecastedDateTime: wind.isValid() ? wind.toDate() : undefined,
                        SolarForecastedDateTime: solar.isValid() ? solar.toDate() : undefined,
                    };
                    return map;
                }, {});

                const listedDataType = filter?.data_type;

                const { warnings, warningSummary, infoSummary } = this.analyzeData(filter?.country, used_dates, listedDataType);

                this.setState({
                    forecasts: response.data.success.forecasts,
                    listedDataType,
                    used_dates,
                    forecastWarnings: warnings,
                    forecastWarningSummary: warningSummary,
                    forecastInfoSummary: infoSummary,
                    activeFilter: { ...(filter || this.state.activeFilter) },
                    lastRefreshResult: true,
                }, () => {
                    setLocalStorage(ForecastReport.params.reportKey, "filter", "mainFilter", {
                        source: filter?.source,
                        country: filter?.country,
                        data_type: filter?.data_type,
                        time_zone: filter?.time_zone,
                        [this.getLocalStorageDateTag()]: {
                            min_forecast_date: filter?.min_forecast_date,
                            max_forecast_date: filter?.max_forecast_date,
                            min_data_date: filter?.min_data_date,
                            max_data_date: filter?.max_data_date,
                        },
                    });
                });
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
                this.setState({ lastRefreshResult: false })
            }

        }).catch(error => {
            handleApiError(error);
            this.setState({ lastRefreshResult: false })
        }).finally(() => {
            AlertManager.showMessage('Data refreshed', {
                variant: 'success',
                style: { whiteSpace: 'pre-line', },
                action: (<Icon className="fas fa-check" />)
            })
        });
    }

    clearFilters() {
        this.setState({
            selectedFilter: { ...this.initialFilter }
        });
    }

    getLocalStorageDateTag() {
        return moment(Date.now()).format('yyyy/MM/DD');
    }

    getTitle() {
        return `${this.state.activeFilter.country} ${this.state.activeFilter.source} ${this.state.activeFilter.data_type} Forecasts`;
    }

    getAvailableFuelTypesForCountry(country: string) {
        return sortBy(uniq(this.state.forecast_params.filter(f => f.CountryName === country).map(f => f.FuelType)));
    }

    validateSingleForecastRow(fuelTypeName: string, demandDate: Date, fuelTypeDate?: Date, demandCount?: number, fuelTypeCount?: number) {
        if (!fuelTypeDate)
            return `There is no ${fuelTypeName} forecast.`


        const fuelTypeForecastAge = Math.abs(demandDate.valueOf() - fuelTypeDate.valueOf());

        if (fuelTypeForecastAge > halfHourInMilliseconds)
            return `${fuelTypeName} forecast is older than Demand forecast more than 30 minutes. Demand Forecast: ${toLongDateString(demandDate)}; ${fuelTypeName} Forecast: ${toLongDateString(fuelTypeDate)}.`

        if (demandCount && fuelTypeCount && demandCount !== fuelTypeCount)
            return `${fuelTypeName} forecast is not awailable for ${demandCount - fuelTypeCount} hours, beware of false peak and base values.`

    }

    analyzeData(countryName?: string, used_dates?: IState["used_dates"], dataType?: string): { warnings?: IState["forecastWarnings"], warningSummary?: string, infoSummary?: string } {
        if (!countryName || !used_dates || dataType?.toLowerCase().replaceAll(" ", "") !== fuelTypes.residualLoad)
            return {};

        const warnings: IState["forecastWarnings"] = {};
        const descriptions: Set<string> = new Set<string>();
        const infos: Set<string> = new Set<string>();

        let checkSolar = true;
        let checkWind = true;

        if (!Object.keys(used_dates || {}).some(k => !!used_dates?.[k].WindForecastedDateTime)) {
            checkWind = false;
            if (knownIrregularCountries.noWind.includes(countryName))
                infos.add("There are no Wind forecasts.")
            else
                descriptions.add("There are no Wind forecasts!")
        }
        if (!Object.keys(used_dates || {}).some(k => !!used_dates?.[k].SolarForecastedDateTime)) {
            checkSolar = false;
            if (knownIrregularCountries.noSolar.includes(countryName))
                infos.add("There are no Solar forecasts.")
            else
                descriptions.add("There are no Solar forecasts!")
        }

        Object.keys(used_dates || {}).forEach(forecastDate => {
            const currentForecast = used_dates[forecastDate];

            if (checkWind) {
                const windWarning = this.validateSingleForecastRow("Wind",
                    currentForecast.ForecastedDateTime,
                    currentForecast.WindForecastedDateTime,
                    currentForecast.ForecastedDateTimeCount,
                    currentForecast.WindForecastedDateTimeCount)

                if (windWarning)
                    warnings[forecastDate] = windWarning;
            }

            if (checkSolar) {
                const solarWarning = this.validateSingleForecastRow(
                    "Solar",
                    currentForecast.ForecastedDateTime,
                    currentForecast.SolarForecastedDateTime,
                    currentForecast.ForecastedDateTimeCount,
                    currentForecast.SolarForecastedDateTimeCount)

                if (solarWarning)
                    warnings[forecastDate] = warnings[forecastDate] ? `${warnings[forecastDate]} ${solarWarning}` : solarWarning;
            }
        });

        if (Object.keys(warnings).length)
            descriptions.add("There are irregular forecasts! Check for forecast dates with warning signs.")

        return { warnings, warningSummary: Array.from(descriptions).join(" "), infoSummary: Array.from(infos).join(" ") }
    }

    getSources() {
        return (!this.state.selectedFilter.country || !this.state.selectedFilter.data_type) ?
            Object.keys(this.state.sources).flatMap(c => Object.keys(this.state.sources[c]).flatMap(f => this.state.sources[c][f]))
            :
            this.state.sources?.[this.state.selectedFilter.country]?.[this.state.selectedFilter.data_type] || []
    }

    specifyActiveTimezone(newFilter: Filter) {
        let matchedParams = this.state.forecast_params.filter(param => {
            return param.CountryName === newFilter.country
                && param.FuelType === newFilter.data_type
                && param.Source === newFilter.source
        })

        if (matchedParams.length === 1)
            return matchedParams[0].TimeZone

        return 'N/A'
    }

    getActiveTimezone() {
        return this.state.selectedFilter.time_zone ?? "-"
    }

    onChangeCountry(newCountry: string) {
        const fuelTypes = this.getAvailableFuelTypesForCountry(newCountry);
        const selectedFuelTypeExists = fuelTypes.some(f => f === this.state.selectedFilter.data_type);
        const newFuelType = selectedFuelTypeExists ? this.state.selectedFilter.data_type : fuelTypes[0];

        const sources = this.state.sources?.[newCountry]?.[newFuelType] || [];
        const selectedSourceExists = sources.some(f => f === this.state.selectedFilter.source);
        const newSource = selectedSourceExists ? this.state.selectedFilter.source : sources[0];

        if (!selectedFuelTypeExists || !selectedSourceExists)
            AlertManager.showWarning(`${newCountry} does not have ${this.state.selectedFilter.data_type}/${makeTitle(this.state.selectedFilter.source)} forecasts, data type and source selection updated.`); //TODO: message

        let newFilter = {
            ...this.state.selectedFilter,
            country: newCountry,
            data_type: newFuelType,
            source: newSource
        };

        this.setState({
            selectedFilter: {
                ...newFilter,
                time_zone: this.specifyActiveTimezone(newFilter)
            }
        })
    }

    onChangeDataType(newValue: string) {
        const sources = this.state.sources?.[this.state.selectedFilter.country]?.[newValue] || [];

        const selectedSourceExists = sources.some(f => f === this.state.selectedFilter.source);
        const newSource = selectedSourceExists ? this.state.selectedFilter.source : sources[0];

        if (!selectedSourceExists)
            AlertManager.showWarning(`${this.state.selectedFilter.country}/${newValue} does not have ${makeTitle(this.state.selectedFilter.source)} forecasts, source selection updated.`); //TODO: message

        let newFilter = {
            ...this.state.selectedFilter,
            data_type: newValue,
            source: newSource
        };

        this.setState({
            selectedFilter: {
                ...newFilter,
                time_zone: this.specifyActiveTimezone(newFilter)
            }
        })
    }

    dailyAveragesOwner: IState["forecasts"] = [];
    dailyAverages: IState["forecasts"] = [];
    getDailyAverages() {
        if (this.dailyAveragesOwner === this.state.forecasts && this.dailyAverages?.length)
            return this.dailyAverages;

        const averages = _(this.state.forecasts).groupBy(row => getDate(row.DateTime))
            .map((value, key) => {
                const currentRow: { DateTime: string, [key: string]: number | string, } = {
                    DateTime: key,
                };

                FRTabularView.getColumns(value, true).forEach(forecastDate => {
                    if (forecastDate === "DateTime") return;

                    const currentAvg = mean(value.map(v => v[forecastDate]));
                    currentRow[forecastDate] = isNaN(currentAvg) ? "" : Math.round(currentAvg * 100) / 100;
                });

                return currentRow;
            }).value();

        this.dailyAverages = averages;
        this.dailyAveragesOwner = this.state.forecasts;
        return this.dailyAverages;
    }

    render() {
        const filterInputProps: { [key: string]: any } = { lg: 1, md: 3, sm: 12, style: { minWidth: '14%' } };
        const filterActionProps: { [key: string]: GridSize } = { sm: 12 };
        const columns = FRTabularView.getColumns(this.state.forecasts);
        const dailyAverages = this.getDailyAverages();
        const dailyAverageColumns = FRTabularView.getColumns(dailyAverages);

        return (
            <ReportViewer {...ForecastReport.params} onRefresh={() => this.getData()}
                autoRefreshMinutes={0.2} lastRefresh={this.state.lastRefresh} lastRefreshResult={this.state.lastRefreshResult}>
                <Grid container
                    spacing={1}
                    justify="flex-start"
                    alignItems="flex-start">
                    <Grid item xs={12}>
                        <VPageFilter
                            showHide
                            getActiveFilter={() => ({
                                ...this.state.activeFilter,
                                min_forecast_date: !this.state.activeFilter.min_forecast_date ? "" : toShortDateString(this.state.activeFilter.min_forecast_date),
                                max_forecast_date: !this.state.activeFilter.max_forecast_date ? "" : toShortDateString(this.state.activeFilter.max_forecast_date),
                                min_data_date: !this.state.activeFilter.min_data_date ? "" : toShortDateString(this.state.activeFilter.min_data_date),
                                max_data_date: !this.state.activeFilter.max_data_date ? "" : toShortDateString(this.state.activeFilter.max_data_date),
                            })}>
                            <Grid item {...filterInputProps}>
                                <VSelect
                                    disableClearable
                                    title="Country"
                                    value={this.state.selectedFilter.country}
                                    getOptionLabel={(option) => `${option}`}
                                    options={this.state.countrieNames}
                                    onChange={(newValue) => this.onChangeCountry(newValue as string)}
                                />
                            </Grid>
                            <Grid item {...filterInputProps}>
                                <VSelect
                                    disableClearable
                                    title="Data Type"
                                    value={this.state.selectedFilter.data_type}
                                    getOptionLabel={(option) => `${option}`}
                                    options={!this.state.selectedFilter.country ?
                                        this.state.fuelTypeNames :
                                        this.getAvailableFuelTypesForCountry(this.state.selectedFilter.country)}
                                    onChange={(newValue) => this.onChangeDataType(newValue as string)}
                                />
                            </Grid>
                            <Grid item {...filterInputProps}>
                                <VSelect
                                    disableClearable
                                    title="Source"
                                    value={this.state.selectedFilter.source}
                                    getOptionLabel={(option) => makeTitle(`${option}`)}
                                    options={this.getSources()}
                                    onChange={(newValue) => this.setState({
                                        selectedFilter: {
                                            ...this.state.selectedFilter,
                                            source: newValue as string
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item {...filterInputProps}>
                                <VDatePicker
                                    variant="inline"
                                    format="DD/MM/yyyy"
                                    margin="normal"
                                    label={`Min Forecast Date (${this.getActiveTimezone()})`}
                                    value={this.state.selectedFilter.min_forecast_date || null}
                                    onChange={(date) => {
                                        this.setState({
                                            selectedFilter: { ...this.state.selectedFilter, min_forecast_date: date?.toDate() }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item {...filterInputProps}>
                                <VDatePicker
                                    variant="inline"
                                    format="DD/MM/yyyy"
                                    margin="normal"
                                    label={`Max Forecast Date (${this.getActiveTimezone()})`}
                                    value={this.state.selectedFilter.max_forecast_date || null}
                                    onChange={(date) => this.setState({
                                        selectedFilter: { ...this.state.selectedFilter, max_forecast_date: date?.toDate() }
                                    })}
                                />
                            </Grid>
                            <Grid item {...filterInputProps}>
                                <VDateTimePicker
                                    variant="inline"
                                    format="DD/MM/yyyy"
                                    margin="normal"
                                    label={`Min Data Date (${this.getActiveTimezone()})`}
                                    maxDate={this.state.selectedFilter.max_data_date}
                                    value={this.state.selectedFilter.min_data_date || null}
                                    onChange={(date) => {
                                        this.setState({
                                            selectedFilter: { ...this.state.selectedFilter, min_data_date: date?.toDate() }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item {...filterInputProps}>
                                <VDateTimePicker
                                    variant="inline"
                                    format="DD/MM/yyyy"
                                    margin="normal"
                                    label={`Max Data Date (${this.getActiveTimezone()})`}
                                    minDate={this.state.selectedFilter.min_data_date}
                                    value={this.state.selectedFilter.max_data_date || null}
                                    onChange={(date) => this.setState({
                                        selectedFilter: { ...this.state.selectedFilter, max_data_date: date?.toDate() }
                                    })}
                                />
                            </Grid>
                            <Grid item
                                {...filterActionProps}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end">
                                <Button
                                    style={{ height: "40" }}
                                    variant="contained"
                                    onClick={() => this.clearFilters()}>
                                    Clear
                                </Button>
                                <Button
                                    style={{ height: "40" }}
                                    variant="contained"
                                    onClick={() => this.getData(this.state.selectedFilter)}>
                                    Apply
                                </Button>
                            </Grid>
                        </VPageFilter>
                    </Grid>
                    {
                        !!this.state.forecasts?.length &&

                        <Grid item xs={12} >
                            <Grid item xs={12} style={{ ...styles.borderedContainer, minHeight: 700 }}>
                                <ExcelDownloadButton
                                    title={this.getTitle().replaceAll(' ', '_')}
                                    sheets={[{
                                        name: "Hourly",
                                        columns,
                                        rows: this.state.forecasts.map(row => columns.map(c => row[c])),
                                    },
                                    {
                                        name: "Daily",
                                        columns: dailyAverageColumns,
                                        rows: dailyAverages.map(row => dailyAverageColumns.map(c => row[c])),
                                    }]}
                                />
                                <VerticalTabs
                                    tabsFooter={(
                                        <VBox title="Data Summary" headerVariant="button">
                                            <IconButton onClick={(e) => this.setState({ showSummaryInfoDialog: true })}
                                                style={{ position: 'absolute', top: 2, right: 7 }}>
                                                <Icon style={{ fontSize: 16 }} className="fas fa-info-circle" />
                                            </IconButton>
                                            <Typography>
                                                {this.state.forecastWarningSummary &&
                                                    <span style={{ color: 'red', fontWeight: 500 }}>{this.state.forecastWarningSummary}</span>
                                                }
                                                {this.state.forecastInfoSummary &&
                                                    <span>{this.state.forecastInfoSummary}</span>
                                                }
                                                {!this.state.forecastWarningSummary && !this.state.forecastInfoSummary &&
                                                    "There are no warnings."
                                                }
                                            </Typography>
                                        </VBox>
                                    )}
                                >
                                    <VerticalTab title="Charts">
                                        <FRChartsView
                                            title={this.getTitle()}
                                            forecasts={this.state.forecasts}
                                            warnings={this.state.forecastWarnings}
                                            filters={[this.state.activeFilter.country, this.state.activeFilter.data_type, this.state.activeFilter.source]}
                                            timeZone={this.getActiveTimezone()}
                                        />
                                    </VerticalTab>
                                    <VerticalTab title="Hourly Table">
                                        <FRTabularView
                                            defaultInitialRowCount={this.defaultInitialRowCount}
                                            forecasts={this.state.forecasts}
                                        />
                                    </VerticalTab>
                                    <VerticalTab title="Daily Table">
                                        <FRTabularView
                                            defaultInitialRowCount={this.defaultInitialRowCount}
                                            forecasts={dailyAverages}
                                        />
                                    </VerticalTab>
                                    <VerticalTab title="Realtime Forecasts">
                                        <FRRealTimeForecastTable
                                            rawForecasts={this.state.forecasts}
                                        />
                                    </VerticalTab>

                                </VerticalTabs>
                            </Grid>
                        </Grid>
                    }
                    <VSimpleDialog
                        fullWidth
                        open={!!this.state.showSummaryInfoDialog}
                        setOpen={(open) => { this.setState({ showSummaryInfoDialog: open }) }}
                        title="Data Summary Info"
                        content={(
                            <List component="div" >
                                <ListItem dense style={{ padding: 0 }}>
                                    <ListItemText primary={<Typography>Result of some controls are shown as Data Summary (only for Residual Load):</Typography>} />
                                </ListItem>
                                <ListItem dense style={{ padding: 0 }}>
                                    <Icon style={{ width: 20, fontSize: 10 }} className="fas fa-circle" />
                                    <ListItemText primary={<Typography>None of the forecasts have Solar or Wind*.</Typography>} />
                                </ListItem>
                                <ListItem dense style={{ padding: 0 }}>
                                    <Icon style={{ width: 20, fontSize: 10 }} className="fas fa-circle" />
                                    <ListItemText primary={<Typography>Some of the forecasts do not have Solar or Wind.</Typography>} />
                                </ListItem>
                                <ListItem dense style={{ padding: 0 }}>
                                    <Icon style={{ width: 20, fontSize: 10 }} className="fas fa-circle" />
                                    <ListItemText primary={<Typography>Solar or Wind forecasts are older more than 30 minutes than Demand forecast.</Typography>} />
                                </ListItem>
                                <hr />
                                <ListItem dense style={{ padding: 0 }}>
                                    <ListItemText primary={<Typography>
                                        *If the country is known not to have one of the supply forecasts, it will still be shown on summary to inform the user but will not be a red text since it is expected. Known countries:
                                        <br /> No Solar: {knownIrregularCountries.noSolar.join(', ')}
                                        <br /> No Wind: {knownIrregularCountries.noWind.join(', ')}
                                    </Typography>} />
                                </ListItem>
                            </List>
                        )}
                        actions={[
                            <Button key="close_button" onClick={() => this.setState({ showSummaryInfoDialog: false })} color="primary">
                                Close
                            </Button>
                        ]}
                    />
                </Grid>
            </ReportViewer >
        )
    }
}

export default ForecastReport;