import React from 'react';
import _ from 'lodash';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import VChart from '../../../components/VChart/VChart';
import { months, shortenNumber, shortMonthNames } from "../../../utils/common";
import { LongTermEuropeGasBalanceData } from '../../../apis/vitusApiTypes';

interface IExhibitProps {
    exhibitNumber: number,
    data?: LongTermEuropeGasBalanceData,
}

interface IExhibitState {
    chartRef?: ReactECharts | null,
}

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

echarts.use(
    [LineChart, GridComponent]
);

class Exhibit extends React.Component<IExhibitProps, IExhibitState> {
    chartRef: ReactECharts | null = null;

    lineChartOptions: ECOption = {
        color: ["#58508d", "#bc5090", "#003f5c", "#ff6361", "#ffa600"],
        animation: false,
        xAxis: { type: 'category' },
        yAxis: {
            type: 'value',
            scale: true,
        },
        tooltip: {
            trigger: 'axis',
            confine: true,
            order: 'valueDesc',
            axisPointer: {
                type: "shadow",
            },
            formatter: (params: any) => {
                const tooltip = `
                    <div>
                        <span>${params[0].data.day} ${params[0].data.month}</span><br />

                        ${_(params.filter((p: any) => !!p.value[p.seriesName]))
                        .sortBy(p => p.seriesName)
                        .reverse()
                        .map((p: any) => (`
                        <span style="background-color: ${p.color}; height: 10px; width: 10px; border-radius: 50%; display: inline-block;"></span>
                        <span>${p.seriesName}<strong style="margin-left:20px; float:right;">${shortenNumber(p.value[p.seriesName].toFixed(2))}</strong></span>
                        `)).join('<br />')}
                    </div>
                    `

                return tooltip;
            },
        },
        series: [{
            data: [],
            type: 'line'
        }],
        dataZoom: [{}],
    }

    componentDidMount() {
        if (this.props.data) {
            this.updateChart();
            this.setState({ chartRef: this.chartRef }); // needed to rerender chart
        }
    }

    componentDidUpdate(prevProps: IExhibitProps) {
        if (this.props.data && prevProps.data !== this.props.data)
            this.updateChart();
    }

    updateChart() {
        if (!this.props.data)
            return;

        const chartInstance = this.chartRef?.getEchartsInstance();
        chartInstance.showLoading();

        const data = this.props.data.data;

        chartInstance.clear();

        const years = _(data.map(row => row.Year)).uniq().sortBy().value();

        const dataset = {
            dimensions: ['date_value', ...years],
            source: _(data)
                .groupBy((row) => `${row.Month}_${row.Day}`)
                .map((value, key) => value.reduce((map: { [year in number | string]: number | string }, row) => {
                    map["date_value"] = `${('0' + row.Month).slice(-2)}_${('0' + row.Day).slice(-2)}`;
                    map["month"] = months[row.Month - 1];
                    map["day"] = row.Day;
                    map[row.Year] = row.Value;
                    return map;
                }, {}))
                .value(),
        }

        const xAxisIntervalDecider = (index: number, value: string) => {
            const day = +value.split('_')[1]
            return day === 1;
        };

        chartInstance.setOption({
            ...this.lineChartOptions,
            legend: {
                padding: [5, 90],
            },
            yAxis: {
                type: 'value',
                scale: true,
                axisLabel: {
                    formatter: (value: number) => {
                        return shortenNumber(value);
                    }
                }
            },
            xAxis: {
                type: 'category',
                axisTick: {
                    interval: xAxisIntervalDecider,
                },
                axisLabel: {
                    interval: xAxisIntervalDecider,
                    formatter: (value: string) => {
                        const month = shortMonthNames[(+value.split('_')[0]).toString()]
                        return month;
                    }
                }
            },
            dataset: dataset,
            series: years.map(y => ({
                type: 'line',
                showAllSymbol: true,
                symbol: (value: { day: number }, _: any) => {
                    return value['day'] === 1 ? 'emptyCircle' : 'none';
                },
            }))
        });

        chartInstance.hideLoading();
    }

    render() {
        return (
            <VChart
                title={`Exhibit ${this.props.exhibitNumber + 1}: ${this.props.data?.title || ""}`}
                description={this.props.data?.description}
                height={320}
                toolbox={{
                    chartRef: this.chartRef,
                    getTableData: () => {
                        const data = this.props.data?.data;

                        if (!data)
                            return {
                                columns: [],
                                rows: []
                            };

                        const rowsDict = data.reduce((
                            dict: { [key: string]: { [key: string]: any } }, row) => {
                            const date = `${row.Day} ${months[row.Month - 1]}`

                            if (!dict[date])
                                dict[date] = { "Date": date }
                            dict[date][row.Year.toString()] = row.Value.toFixed(2)
                            return dict;
                        }, {});

                        return {
                            columns: ['Date', ..._(data.map(l => l.Year.toString())).uniq().sortBy().value()],
                            rows: Object.keys(rowsDict).map(r => rowsDict[r])
                        };
                    },
                }}
                report={
                    <ReactECharts
                        ref={(e) => { this.chartRef = e; }}
                        echarts={echarts}
                        option={this.lineChartOptions}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{ width: '99%', height: 250 }}
                    />
                } />
        )
    }
}

export default Exhibit;