export type MemoizeParams = {
    cache: React.ReactElement | null,
    ownersMap: { current: () => any, used: any }[] | null,
    inprocessFlag: Boolean,
    elementCreator: () => React.ReactElement | null,
}

export const getMemoizedElement = (params: MemoizeParams) => {
    if (params.cache
        && params.ownersMap
        && params.ownersMap.every(owner => owner.current() === owner.used))
        return params.cache;

    params.inprocessFlag = true;

    params.cache = params.elementCreator();

    params.ownersMap?.forEach(owner => {
        owner.used = owner.current();
    })

    params.inprocessFlag = false;

    return params.cache;
}