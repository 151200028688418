import ReactECharts from 'echarts-for-react';
import { Dataset } from "../../../utils/types";
import { groupBy, isEmpty, sortBy, union } from "lodash";
import * as echarts from 'echarts/core';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import { ScatterChart, ScatterSeriesOption } from 'echarts/charts';
import BaseChart, { ChartType, DatasetRow } from './BaseChart';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption | BarSeriesOption | ScatterSeriesOption>

echarts.use(
    [GridComponent, LineChart, BarChart, ScatterChart]
);

class CoordinateSystemChart extends BaseChart {
    chartRef: ReactECharts | null = null;
    chartOptions: ECOption = {};

    static specifyDatapartName(datasetName: string, chartGroup?: string[], firstRow?: { [key: string]: any }) {
        let datapartName = datasetName;

        if (chartGroup && firstRow && !isEmpty(chartGroup) && !isEmpty(firstRow)) {
            datapartName = `${datapartName}|${chartGroup.map(c => firstRow[c]).join('|')}`;
        }

        return datapartName;
    }

    setChartOptions(all_datasets: Dataset[], all_data?: { [key: string]: { data: DatasetRow[], index_column_values: string[] } }) {
        if (!all_data)
            return;

        const chartInstance = this.chartRef?.getEchartsInstance();
        chartInstance.showLoading();

        const datasets = all_datasets.filter(dataset => dataset?.chart_type && dataset?.x_axis && dataset?.y_axis);
        let tableXAxisName = "";

        const series = datasets.flatMap(dataset => {
            const [xAxisName, yAxisName] = [dataset.x_axis ?? "", dataset.y_axis ?? ""];
            tableXAxisName = tableXAxisName || xAxisName;

            let data: DatasetRow[][];
            const datasetdata = all_data[dataset.alias]?.data;

            if (dataset.chart_groups?.length) {
                const groupedData = groupBy(datasetdata, item => dataset.chart_groups?.map(grKey => item[grKey]).join('|'));

                data = Object.keys(groupedData).map(key => sortBy(groupedData[key], item => datasetdata.indexOf(item)));
            }
            else {
                data = [datasetdata];
            }

            return data.map(dataPart => {
                return {
                    data: (dataPart as { [key: string]: any }[])?.map(row => !row ? [] : [
                        row[xAxisName],
                        row[yAxisName],
                        `${xAxisName}:${row[xAxisName]}`,
                        `${yAxisName}:${row[yAxisName]}`,
                        ...Object.keys(row).filter(k => ![xAxisName, yAxisName].includes(k) && row[k]).map(k => `${k}:${row[k]}`)
                    ]),
                    name: CoordinateSystemChart.specifyDatapartName(dataset.alias, dataset.chart_groups, dataPart?.[0]),
                    type: dataset.chart_type?.toLowerCase() as ChartType,
                }
            })
        });

        const tableRows: { [key: string]: { [subKey: string]: any } } = {};
        series.forEach(serie => {
            serie.data?.forEach(row => {
                if (!tableRows[row[0]])
                    tableRows[row[0]] = { [tableXAxisName]: row[0] };
                tableRows[row[0]][serie.name] = row[1];
            })
        })

        const tableColumns = [tableXAxisName, ...series.map(s => s.name)];

        this.setTableData(tableColumns, Object.keys(tableRows).map(row => tableRows[row]));

        const xAxisValues = union(...Object.keys(all_data).map(alias => all_data[alias].index_column_values));

        const chartOptions = this.addApacheEchartsToolbox({
            xAxis: {
                type: 'category',
                data: xAxisValues
            },
            yAxis: {
                type: 'value',
                scale: true,
            },
            series: series,
            legend: {
                type: 'scroll',
                data: series?.map(dataset => dataset.name)
            },
            tooltip: {
                trigger: "axis",
                formatter: function (params: { data: any[] }[]) {
                    return params.map(param => param.data.slice(2).join("|")).join("<br />");
                }
            },
            dataZoom: [{}],
            animation: false
        });

        chartInstance.clear();
        chartInstance.setOption(chartOptions)

        chartInstance.hideLoading();
    }

    render() {
        return (
            <ReactECharts
                ref={(e) => { this.chartRef = e; }}
                echarts={echarts}
                option={this.chartOptions}
                notMerge={true}
                lazyUpdate={true}
                style={{ width: '99%', ...this.props.style }}
            />
        );
    }
}

export default CoordinateSystemChart;